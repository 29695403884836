export const FEATURES = [
  {
    label: 'Kill Details',
    description: 'Select More to view or download your kill data.',
    imgPath:
      '/images/what_new_kill_details.png',
  },
  /* {
    label: 'PATRONAGE REWARDS TOGGLE',
    description: 'You can now switch between YTD or Last 12 Months SU supplied.',
    imgPath:
      '/images/features/latest-features-petronage-rewards-toggle-web.png',
  },
    {
    label: 'PAYMENT DOWNLOAD',
    description: 'Export your payment Information into a CSV file.  Load it into your finance system.',
    imgPath:
    '/images/features/latest-features-export-payment.png',
  },
  {
    label: 'CARCASE REPORT',
    description: 'You can now view your Carcase Performance Report from your Kill Sheet.',
    imgPath:
    '/images/features/latest-features-carcase-report.png',
  },
  {
    label: 'IMPROVED ANALYTICS',
    description: 'Interact with your volume and performance analytics. Filter by date range, species, and stock type.',
    imgPath:
    '/images/features/latest-features-slice-dice.png',
  } */
];