import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import * as DATEFORMAT from '../App/Constants/DateFormat';
import * as INVOICE from '../App/Constants/Invoice';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import OutstandingInvoiceDetailLine from '../App/Models/OutstandingInvoiceDetailLine';
import OutstandingInvoiceSummaryLine from '../App/Models/OutstandingInvoiceSummaryLine';

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props {
  invoices?: number;
  outstandingInvoiceSummaryLine: OutstandingInvoiceSummaryLine;
  downloadInvoiceDocument: (lineItem: OutstandingInvoiceDetailLine) => Promise<void>;
}

@observer
class OustandingInvoiceSummary extends Component<Props> {

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.PAYMENTS, TelemetryPageEventType.PageStop);
  }

  render() {
    const { outstandingInvoiceSummaryLine, invoices } = this.props;
    let accumulatedTotal = 0;
    outstandingInvoiceSummaryLine.invoices.forEach(lineItem => {
      if (lineItem) {
        accumulatedTotal += lineItem.amount;
      }
    });

    return (
      <Grid container={true} spacing={32} className="c-outstandingInvoiceSummaryLine__item grid-container" onClick={this.onClickExpandInvoice}>
        <Grid item={true} xs={1} className="c-outstandingInvoiceSummaryLine__item icon-container">
          <ExpandMoreIcon className="c-outstandingInvoiceSummaryLine__item icon" />
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-outstandingInvoiceSummaryLine__item capitalise">
            {_propertyHelper.formatValue(outstandingInvoiceSummaryLine.paymentDate, VALUE_TYPE.DATE, DATEFORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
            <div className="c-outstandingInvoiceSummaryLine__item-subheading">{INVOICE.PAYMENT_DATE}</div>
          </div>
        </Grid>
        <Grid item={true} xs={6}>
          <div className="c-outstandingInvoiceSummaryLine__item">
            {_propertyHelper.formatValue(invoices, VALUE_TYPE.STRING)}
            <div className="c-outstandingInvoiceSummaryLine__item-subheading">{INVOICE.INVOICES}</div>
          </div>
        </Grid>
        <Grid item={true} xs={3}>
          <div className="c-outstandingInvoiceSummaryLine__item right">
            {_propertyHelper.formatValue(accumulatedTotal, VALUE_TYPE.CURRENCY)}
            <div className="c-outstandingInvoiceSummaryLine__item-subheading">{INVOICE.TOTAL_AMOUNT}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1} className="c-outstandingInvoiceSummaryLine__item border-filler-container" />
        <Grid item={true} xs={11}>
          <div className="c-outstandingInvoiceSummaryLine__item bottom-border" />
        </Grid>
      </Grid>
    );
  }

  onClickExpandInvoice = async () => {
    const { outstandingInvoiceSummaryLine, downloadInvoiceDocument } = this.props;
    outstandingInvoiceSummaryLine.invoices.forEach(async lineItem => {
      await downloadInvoiceDocument(lineItem);
    });
  }
}

export default OustandingInvoiceSummary;