import React, { Component } from 'react';
import NaitNumber from './../App/Models/NaitNumber';

interface Props {
  naitNumber: NaitNumber;
}

class NaitItem extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { naitNumber } = this.props;

    return (
      <div className="c-nait-item__field">
        <div className="c-nait-item__species">{naitNumber.species}</div>
        <div className="c-nait-item__wrapper">
          <div className="c-nait-item__label">NAIT Number</div>
          <div className="c-nait-item__value">{naitNumber.naitNumber}</div>
        </div>
        <div className="c-nait-item__wrapper">
          <div className="c-nait-item__label">Contact ID</div>
          <div className="c-nait-item__value">{naitNumber.fullName}</div>
        </div>
      </div>
    );
  }
}

export default NaitItem;