import { Paper } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import UpdateIcon from '@material-ui/icons/Update';
import classNames from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import * as DATE_FORMAT from '..//App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as FARM_ASSURANCE from '../App/Constants/FarmAssurance';
import * as MENU from '../App/Constants/Menu';
import * as MY_ACCOUNT from '../App/Constants/MyAccount';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import * as TILE_TITLES from '../App/Constants/TileTitles';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import StockHelper from '../App/Helpers/StockHelper';
import AccountStore from '../App/Stores/AccountStore';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import MenuStore from '../App/Stores/MenuStore';
import Tile from './Tile';

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  menuStore: MenuStore;
  accountStore: AccountStore;
  loadData: Function;
  initialStatus?: TileStatus;
  speciesName: string;
  assuranceStatus: string;
  singleTile: boolean;
  expiryDate?: Date;
}

const _stockHelper: StockHelper = new StockHelper();

@observer
class FarmAssuranceTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
    let { dashboardStore, title, initialStatus } = this.props;

    if (!_.isUndefined(initialStatus)) {
      dashboardStore.setTileStatus(title, initialStatus);
    }
  }

  handleFarmAssuranceClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_FARM_ASSURANCE);
    this.props.menuStore!.setSelectedIndex(MENU.MY_ACCOUNT_INDEX);
    this.props.accountStore!.accountPageTabIndex = 3;
  }

  getTileStatusIcon = (statusClass: string) => {
    switch (statusClass) {
      case FARM_ASSURANCE.STATUS_RED:
        return <ErrorOutlineIcon />;
      case FARM_ASSURANCE.STATUS_AMBER:
        return <UpdateIcon />;
      default:
        return <CheckCircleOutlineIcon />;
    }
  }

  render() {
    const { dashboardStore, assuranceStatus, expiryDate, speciesName, singleTile } = this.props;
    let tileStatus = '';
    let statusClass = FARM_ASSURANCE.STATUS_GREEN;
    let expiredClass = assuranceStatus === MY_ACCOUNT.STATUS_EXPIRED ? _.toLower(MY_ACCOUNT.STATUS_EXPIRED) : DEFAULT_DATA.UNDEFINED;
    let expiringSoon = false;
    const species = speciesName === FARM_ASSURANCE.SPECIES_CATTLE ? FARM_ASSURANCE.SPECIES_BEEF : speciesName;
    const speciesIcon = _stockHelper.getFarmAssuranceIcon(species, singleTile);

    tileStatus = assuranceStatus === MY_ACCOUNT.STATUS_NON_COMPLIANT ? MY_ACCOUNT.STATUS_NON_COMPLIANT.replace(' ', '-') : assuranceStatus;

    switch (assuranceStatus) {
      case MY_ACCOUNT.STATUS_NON_COMPLIANT:
      case MY_ACCOUNT.STATUS_EXPIRED:
        statusClass = FARM_ASSURANCE.STATUS_RED;
        break;
      case MY_ACCOUNT.STATUS_ASSESSED:
      case MY_ACCOUNT.STATUS_FARM_ASSURED:
        let expiryDays = moment(expiryDate).diff(moment(), 'days');
        statusClass = expiryDays <= 90 ? FARM_ASSURANCE.STATUS_RED : expiryDays <= 120 ? FARM_ASSURANCE.STATUS_AMBER : FARM_ASSURANCE.STATUS_GREEN;
        expiringSoon = expiryDays <= 90;
        break;
      default:
        statusClass = FARM_ASSURANCE.STATUS_GREEN;
        break;
    }

    if (dashboardStore!.farmAssuranceStatus === TileStatus.LOADED) {
      return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleFarmAssuranceClick}>
          <Paper className="c-dashboard__tile">
            <div className={classNames('c-dashboard__tile-farm-assure', statusClass, expiredClass)}>
              <div className="c-dashboard__tile-farm-assure-subject-container">
                <span className="c-dashboard__tile-farm-assure-species-icon">
                  {speciesIcon}
                  <span>{speciesName}</span>
                </span>
                <span className="c-dashboard__tile-farm-assure-species-expiry">
                  <span className="c-dashboard__tile-farm-assure-species-expiry-text">{expiredClass ? TILE_TITLES.FARM_ASSURED : 'Expiry Date'}</span>
                  {!expiredClass && (
                    <span className="c-dashboard__tile-farm-assure-species-expiry-date">{_propertyHelper.formatValue(expiryDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}</span>
                  )}
                  <span className="c-dashboard__tile-farm-assure-type">{tileStatus}{this.getTileStatusIcon(statusClass)}</span>
                  {expiringSoon && (
                    <span className="c-dashboard__tile-farm-assure-species-expiry-soon-text">
                      Farm Assurance expiring soon.
                      <span>Please renew.</span>
                    </span>
                  )}
                </span>
              </div>
            </div>
          </Paper>
        </a>
      );
    } else {
      return super.render();
    }
  }
}

export default FarmAssuranceTile;