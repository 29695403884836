import { Button, CircularProgress, TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as FEEDBACK from '../App/Constants/Feedback';
import FeedbackService from '../App/Services/FeedbackService';
import DialogStore from '../App/Stores/DialogStore';

const _feedbackService: FeedbackService = new FeedbackService();

interface Props {
  dialogStore?: DialogStore;
}

interface State {
  characterCount: number;
  feedbackValue: string;
  isSubmitting: boolean;
}

@inject('dialogStore')
@observer
class Feedback extends React.Component<Props, State> {
  state = {
    characterCount: DEFAULT_DATA.NUMBER,
    feedbackValue: DEFAULT_DATA.STRING,
    isSubmitting: false
  };

  clearFeedback = () => {
    this.setState({ characterCount: DEFAULT_DATA.NUMBER, feedbackValue: DEFAULT_DATA.STRING });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (e.currentTarget.value) {
      this.setState({ characterCount: e.currentTarget.value.length, feedbackValue: e.currentTarget.value });
    } else {
      this.setState({ characterCount: DEFAULT_DATA.NUMBER, feedbackValue: DEFAULT_DATA.STRING });
    }
  }

  handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const inputElement = document.getElementById('feedback-textarea');
    inputElement!.className = 'c-feedback__textarea focused';
  }

  handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const inputElement = document.getElementById('feedback-textarea');
    inputElement!.className = 'c-feedback__textarea';
  }

  submitFeedback = () => {
    const { dialogStore } = this.props;
    const { feedbackValue } = this.state;

    if (_feedbackService && dialogStore) {
      this.setState({ isSubmitting: true });
      const username = FEEDBACK.ORIGIN;

      _feedbackService.postFeedback(feedbackValue, username)
        .then(() => {
          dialogStore.setDialogOpen(FEEDBACK.DIALOG_HEADER_TITLE, FEEDBACK.DIALOG_CONTENT_MESSAGE, () => { this.clearFeedback(); dialogStore.setDialogClosed(); }, false);
          this.setState({ isSubmitting: false });
        })
        .catch(() => {
          dialogStore.setDialogOpen(FEEDBACK.DIALOG_ERROR_TITLE, FEEDBACK.DIALOG_ERROR_CONTENT_MESSAGE, () => { dialogStore.setDialogClosed(); }, false);
          this.setState({ isSubmitting: false });
        });
    }
  }

  render() {
    const { characterCount, feedbackValue, isSubmitting } = this.state;

    return (
      <div className="c-feedback__container">
        <div className="c-feedback__content-container">
          <div className="c-feedback__header">{FEEDBACK.TITLE}</div>
          <div id="feedback-textarea" className="c-feedback__textarea">
            <TextField
              id="feedback-text-field"
              multiline={true}
              margin="normal"
              className="c-feedback__input"
              value={feedbackValue}
              rows={FEEDBACK.INPUT_TEXTAREA_ROWS}
              fullWidth={true}
              inputProps={{ maxLength: FEEDBACK.MAX_INPUT_LENGTH }}
              placeholder={FEEDBACK.INPUT_PLACEHOLDER_TEXT}
              onBlur={e => this.handleBlur(e)}
              onChange={e => this.handleChange(e)}
              onFocus={e => this.handleFocus(e)}
              disabled={isSubmitting}
            />
            <div className="c-feedback__counter">
              {`${characterCount}/${FEEDBACK.MAX_INPUT_LENGTH}`}
            </div>
          </div>
          <div className="c-feedback__action-container">
            <Button variant="outlined" className="c-feedback__submit-button" fullWidth={true} onClick={() => this.submitFeedback()} disabled={characterCount === DEFAULT_DATA.NUMBER || isSubmitting}>
              {isSubmitting ? <CircularProgress className="c-feedback__feedback-submission-progress" /> : FEEDBACK.SUBMIT_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Feedback;