export const INFO_HEADER_FULLY_SHARED = 'Fully shared';
export const INFO_HEADER_OVER_SHARED = 'Over shared';
export const INFO_HEADER_NOT_ELIGIBLE = 'Not eligible';
export const INFO_HEADER_NO_SHARES_OWNED = 'No shares owned';

export const INFO_TEXT_FULLY_SHARED = 'Optimum share and stock unit balance.';
export const INFO_TEXT_OVER_SHARED = 'Patronage Reward is paid on lesser of Eligible Shares Owned or Share Standard.';
export const INFO_TEXT_NOT_ELIGIBLE = 'minimum supply of 400 stock units is required.';
export const INFO_TEXT_NO_SHARES_OWNED = 'Shares are required for patronage rewards.';
export const INFO_TEXT_SHARES_NEEDED = 'Buy more shares to be fully shared.';

export const SHARES_OWNED = 'Total Shares Owned';
export const STOCK_SUPPLIED = 'Stock Units';


export const PATRONAGE_HEADER_FULLY_SHARED = 'FULLY SHARED';
export const PATRONAGE_HEADER_OVER_SHARED = 'PAST';
export const PATRONAGE_HEADER_NOT_ELIGIBLE = 'NOT ELIGIBLE';
export const PATRONAGE_HEADER_NEEDED = 'NEEDED';

export const PATRONAGE_SUBHEADER_OVER_SHARED = 'FULLY SHARED';
export const PATRONAGE_SUBHEADER_NOT_ELIGIBLE = 'FOR PATRONAGE REWARDS';
export const PATRONAGE_SUBHEADER_NEEDED = 'UNTIL FULLY SHARED';

export const PATRONAGE_OPTIMUM = 'This level of stock units and shares is optimal for patronage rewards.';
export const PATRONAGE_OPTIMISE = 'To optimise your patronage rewards, you can buy more shares and become fully shared.';
export const PATRONAGE_INELIGIBLE = 'To be eligible for patronage rewards, a minimum supply of 400 stock units is required.';
export const PATRONAGE_OVERSHARED = 'Patronage Reward is paid on lesser of Eligible Shares Owned or Share Standard.';
export const PATRONAGE_NO_SHARES = 'To benefit from patronage rewards, purchase shares.';

export const TOOLTIP_TEXT_STOCK_SUPPLIED_12_MONTHS = 'Stock units supplied within last 12 months.';
export const TOOLTIP_TEXT = 'Stock Units supplied from 1 January.';
export const TOOLTIP_TEXT_LAMB = '1 lamb/ewe = 1 SU';
export const TOOLTIP_TEXT_BOBBY = '1 bobby = 1 SU';
export const TOOLTIP_TEXT_DEER = '1 deer = 6 SU';
export const TOOLTIP_TEXT_CATTLE = '1 cattle beast = 12 SU';

export const SHARES_HISTORY_TOOLTIP_TEXT = 'Patronage Reward is paid on lesser of Eligible Shares Owned or Share Standard';

export const FIND_OUT_MORE_URL = 'https://www.silverfernfarms.coop/dividends';

export const MINIMUM_STOCK_UNITS_FOR_ELIGIBILITY = 400;
export const MINIMUM_STOCK_ERROR_MESSAGE = `*Minimum supply threshold for patronage rewards is ${MINIMUM_STOCK_UNITS_FOR_ELIGIBILITY} SU.`;

export const CALCULATOR_SHARES_STEP_SIZE = 500;
export const CALCULATOR_STOCK_UNITS_STEP_SIZE = 100;

export const CALCULATOR_STOCK_UNITS_MAX = 999900;
export const CALCULATOR_SHARES_MAX = 500000;

export const LABEL_CRN = 'CRN';
export const LABEL_SHARES_TYPE = 'Shares Type';
export const LABEL_QUANTITY = 'Quantity';

export const LABEL_ELIGIBLE_SHARES_OWNED = 'Eligible Shares Owned';
export const LABEL_STOCK_UNITS_SUPPLIED = 'Stock Units Supplied';
export const LABEL_SHARE_STANDARD = 'Share Standard';
export const LABEL_FINANCIAL_YEAR_END = 'Financial Year End';

export const SHARE_TREND_DOWN = -1;
export const SHARE_TREND_FLAT = 0;
export const SHARE_TREND_UP = 1;

export const SHARE_TREND_ICONS = [
  { name: 1, iconUrl: '/images/shareprice/trending_up.svg' },
  { name: -1, iconUrl: '/images/shareprice/trending_down.svg' },
  { name: 0, iconUrl: '/images/shareprice/trending_flat.svg' }
];
export const SHARE_TREND_GREEN_ICONS = [
  { name: 1, iconUrl: '/images/shareprice/trending_up_green.svg' },
  { name: -1, iconUrl: '/images/shareprice/trending_down_green.svg' },
  { name: 0, iconUrl: '/images/shareprice/trending_flat_green.svg' }
];