import { Fade } from '@material-ui/core';
import Lottie from 'lottie-web';
import React, { Component } from 'react';
import { FADE_DELAY } from '../App/Constants/Transition';

class Loading extends Component {

  componentDidMount = () => {
    Lottie.loadAnimation({
      container: document.getElementById('c-loading__spinner'), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/SFFLoadingAnimation.json' // the path to the animation json
    });
  }

  render() {
    return (
      <Fade in={true} timeout={FADE_DELAY}>
        <div className="c-loading__container">
          <div id="c-loading__spinner" className="c-loading__spinner" />
        </div>
      </Fade >
    );
  }
}

export default Loading;