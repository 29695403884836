import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MobileStepper,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 450,
      flexGrow: 1,
      margin: '50px 24px 24px 32px',
    },
    smallRoot: {
      maxWidth: 450,
      flexGrow: 1,
      margin: '32px 24px 24px 32px',
    },
    background: {
      position: 'absolute',
      height: 350,
      width: 350,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 26,
    },
    backgroundMedium: {
      position: 'absolute',
      height: 300,
      width: 300,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 20,
    },
    backgroundSmall: {
      position: 'absolute',
      height: 210,
      width: 210,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 10,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 100,
      marginTop: -30,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '1em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    mediumHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      marginTop: -30,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '0.85em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    smallHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      marginTop: -10,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '0.65em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    closeButton: {
      position: 'absolute',
      right: '8px',
      top: '8px',
      color: 'white',
    },
    closeButtonSmall: {
      position: 'absolute',
      right: '8px',
      top: '8px',
      color: 'white',
    },
    description: {
      zIndex: 1,
      maxWidth: 425,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '1em',
      fontSize: '0.9em',
    },
    descriptionMedium: {
      zIndex: 1,
      maxWidth: 285,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '1em',
      fontSize: '0.8em',
    },
    descriptionSmall: {
      zIndex: 1,
      maxWidth: 285,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '0.5em',
      fontSize: '0.7em',
    },
    img: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '100%',
    },
    imgMedium: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '90%',
      marginLeft: '14px',
    },
    imgSmall: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '60%',
      marginLeft: '80px',
    },
    title: {
      paddingBottom: 0,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1.2em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    titleMedium: {
      paddingBottom: 0,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1.1em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    titleSmall: {
      paddingBottom: 0,
      paddingTop: 4,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    content: {
      overflow: 'hidden',
    },
    actions: {
      justifyContent: 'center',
    },
    okaybutton: {
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 40px',
        fontSize: '1.2em',
      },
    },
    okaybuttonMedium: {
      fontSize: '0.75rem',
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 40px',
        fontSize: '1.2em',
      },
    },
    okaybuttonSmall: {
      fontSize: '0.75rem',
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 30px',
        fontSize: '1.1em',
      },
    },
    dialog: {
      '&& div[role="document"] > div': {
        width: '640px',
        maxWidth: '640px',
        height: '680px',
        padding: '38px 38px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    dialogMedium: {
      '&& div[role="document"] > div': {
        width: '560px',
        maxWidth: '640px',
        height: '600px',
        padding: '24px 24px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    dialogSmall: {
      '&& div[role="document"] > div': {
        width: '560px',
        maxWidth: '640px',
        height: '440px',
        padding: '24px 24px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    stepper: {
      justifyContent: 'center',
      backgroundColor: '#427b5d',
    },
    dotActive: {
      backgroundColor: 'white',
    },
    navigation: {
      width: 44,
      color: 'white',
      position: 'absolute',
      top: '40%',
      backgroundColor: '#325542',
      '&:hover': {
        backgroundColor: '#b0b569',
      },
      '&:disabled': {
        display: 'none',
      },
    },
    navigationSmall: {
      width: 44,
      color: 'white',
      position: 'absolute',
      top: '40%',
      backgroundColor: '#325542',
      '&:hover': {
        backgroundColor: '#b0b569',
      },
      '&:disabled': {
        display: 'none',
      },
    },
    navigationNext: {
      right: 38,
    },
    navigationPrevious: {
      left: 38,
    },
    backdrop: {
      opacity: 0.3,
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  activeFeature: number;
  height: 'large' | 'medium' | 'small';
  isOpen: boolean;
}

@observer
class CookieDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeFeature: 0,
      height: 'large',
      isOpen: true,
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    window.logOut();
  };

  render() {
    const { classes } = this.props;
    const backDropProps = {
      invisible: true,
      classes: {
        invisible: classes.backdrop,
      },
    };

    return (
      <Dialog
        open={this.state.isOpen}
        className='dialogContent'
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.handleClose();
          }
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropProps={backDropProps}
      >
        <DialogTitle id='alert-dialog-title' className='dialogTitle'>
          Browser Settings
        </DialogTitle>
        <DialogContent className='dialogContent'>
          <DialogContentText id='alert-dialog-description'>
            <label className='labelContent'>
              To use the Silver Fern Farmer website you must 'Enable Cookies'
              and allow 'Third Party Cookies' (Cross Site Tracking).
            </label>
            <br />
            <br />
            <label className='labelFooter'>
              <strong>Alternatively contact us on 0800 362 362</strong>
            </label>
            <br />

            <Button
              className='dialogButton'
              onClick={this.handleClose}
              autoFocus={true}
            >
              Ok
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CookieDialog);
