import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import KillSheet from '../App/Models/KillSheet';
import RatioBar from '../App/Models/RatioBar';

interface Props {
  killSheet: KillSheet;
}

class HitRate extends React.Component<Props> {
  render() {
    if (!this.props.killSheet || !this.props.killSheet.killsheetEatingQualitySpecification) {
      return (<div />);
    }
    const killSheet = this.props.killSheet;
    const killsheetEatingQualitySpecification = killSheet.killsheetEatingQualitySpecification;
    const hitRatePercentage = killsheetEatingQualitySpecification.percentInSpec;

    const eqRatioBarStyle = this.getEqRatioBarStyle(killsheetEatingQualitySpecification.inSpec, killsheetEatingQualitySpecification.outOfSpec);

    return (
      <>
        <div className="c-kill-sheet-hit-rate__eq">
          <div className="c-kill-sheet-hit-rate__eq-image-container">
            <img src="/images/eq.svg" className="c-kill-sheet-hit-rate__eq-image" />
          </div>
          <div className="c-kill-sheet-hit-rate__eq-rate">{`${hitRatePercentage}${KILLSHEET.PERCENTAGE_UNIT} Hit Rate`}</div>
        </div>
        <div className="c-kill-sheet-hit-rate__grading-text">
          Your grading showed {killsheetEatingQualitySpecification.inSpec} out of {killSheet.tally} of your mob have achieved the master grade.
                </div>
        <div className="c-kill-sheet-hit-rate__ratio-bar">
          <div className="c-kill-sheet-hit-rate__achieved" style={eqRatioBarStyle.achieved}>
            {killsheetEatingQualitySpecification.inSpec}
          </div>
          <div className="c-kill-sheet-hit-rate__unachieved" style={eqRatioBarStyle.unachieved}>
            {killsheetEatingQualitySpecification.outOfSpec}
          </div>
        </div>
      </>
    );
  }

  private getEqRatioBarStyle = (inSpec: number, outOfSpec: number) => {
    const tally = inSpec + outOfSpec;
    const inSpecWidth = inSpec / tally * DEFAULT_DATA.PERCENTAGE;
    const outOfSpecWidth = outOfSpec / tally * DEFAULT_DATA.PERCENTAGE;
    let _eqRatioBarStyle = new RatioBar();

    if (inSpecWidth !== DEFAULT_DATA.NUMBER && outOfSpec !== DEFAULT_DATA.NUMBER) {
      _eqRatioBarStyle = {
        achieved: {
          width: `${inSpecWidth}${KILLSHEET.PERCENTAGE_UNIT}`
        },
        unachieved: {
          width: `${outOfSpecWidth}${KILLSHEET.PERCENTAGE_UNIT}`
        }
      };
    } else if (inSpecWidth === DEFAULT_DATA.NUMBER) {
      _eqRatioBarStyle = {
        achieved: {
          display: `none`
        },
        unachieved: {
          width: `${outOfSpecWidth}${KILLSHEET.PERCENTAGE_UNIT}`,
          borderRadius: `1em`
        }
      };
    } else if (outOfSpec === DEFAULT_DATA.NUMBER) {
      _eqRatioBarStyle = {
        achieved: {
          width: `${inSpecWidth}${KILLSHEET.PERCENTAGE_UNIT}`,
          borderRadius: `1em`
        },
        unachieved: {
          display: `none`
        }
      };
    }
    return _eqRatioBarStyle;
  }
}

export default HitRate;