import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class ShareholderService extends HttpService {
  getShareholderDetails = async (supplierId: string) => {
    const getShareholdersApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.SHAREHOLDERS}`;
    return super.get(getShareholdersApiUrl);
  }

  getCurrentSharePrice = () => {
    const getCurrentSharePriceApiUrl =
      `${CONNECTION.CONTENT}/${CONNECTION.API_VERSION}/${CONNECTION.SHARES}/${CONNECTION.CURRENTSHAREPRICE}`;
    return super.get(getCurrentSharePriceApiUrl);
  }
}

export default ShareholderService;
