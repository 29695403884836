import _ from 'lodash';
import KillSheet from '../Models/KillSheet';
import moment from 'moment';

import * as ARRAY from '../Constants/Array';
import * as DATE_FORMAT from '../Constants/DateFormat';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as KILLSHEET from '../Constants/KillSheet';
import Entity from '../Models/Entity';

class TimelineHelper {
  public sortKillSheets = (killSheets: Array<KillSheet>) => {
    let _year = DEFAULT_DATA.STRING;

    if (!_.isEmpty(killSheets)) {
      // Sorting is done based on the following criteria (in the exact order):
      // 1) Order by processedDate descending
      // 2) Order by kill sheet number ascending
      killSheets = _.orderBy(
        killSheets,
        [KILLSHEET.PROCESSED_DATE, KILLSHEET.KILLSHEET_NUMBER],
        [ARRAY.DESCENDING_ORDER, ARRAY.ASCENDING_ORDER]
      );

      killSheets.forEach(killSheet => {
        killSheet.year = moment(killSheet.processedDate).format(
          DATE_FORMAT.YEAR_ONLY
        );
        killSheet.isFirstElementForYear = killSheet.year !== _year;
        _year = killSheet.year;
      });
      return killSheets;
    }
    return new Array<KillSheet>();
  }

  public populateFarmDetails = (killSheets: Array<KillSheet>, farms: Array<Entity>) => {
    if (!_.isEmpty(killSheets) && !_.isEmpty(farms)) {
      killSheets.forEach(killSheet => {
        const _farmName = farms.find(f => f.id === killSheet.supplier);

        if (_farmName) {
          killSheet.farmName = _farmName.name;
        }
      });
    }
    return killSheets;
  }
}

export default TimelineHelper;
