import React, { Component } from 'react';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import { TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import SettingsPanel from '../../components/Settings/SettingsPanel';

interface Props { }

class Settings extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.SETTINGS, TelemetryPageEventType.PageStart);
  }

  render() {
    return (
      <SettingsPanel />
    );
  }

}

export default Settings;
