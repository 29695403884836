import ArtTrack from '@material-ui/icons/ArtTrack';
import classNames from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import Tile from './Tile';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  loadData: Function;
  initialStatus?: TileStatus;
}

@observer
class NewsStream extends Tile<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleNewsClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_NEWS_STREAM);
  }

  render() {

    const { dashboardStore } = this.props;
    const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

    if (dashboardStore!.newsArticlesStatus === TileStatus.LOADED || dashboardStore!.newsArticlesCurrentPage > 1) {
      return (
        <>
          {dashboardStore!.newsArticles.map(article => (
            <div className="c-dashboard__tile-stream-news-card" key={article.id}>
              <div className="c-dashboard__tile-stream-news-info-header">
                <span className="c-dashboard__tile-stream-news-info-header-icon">
                  <ArtTrack style={{ fontSize: 40 }} />
                </span>
                <span className="c-dashboard__tile-stream-news-info-header-title">
                  NEWS
                </span>
              </div>
              <div className={classNames('c-dashboard__tile-stream-news-image', _.isEmpty(article.thumbnail) ? 'no-thumbnail' : '')}>
                <a href={article.moreInfo} target="_blank" onClick={this.handleNewsClick}>
                  <img src={article.thumbnail} />
                </a>
              </div>
              <div className="c-dashboard__tile-stream-news-info">
                <div className={classNames('c-dashboard__tile-stream-news-info-title-content', _.isEmpty(article.title) ? 'no-summary' : '')}>
                  <span className="c-dashboard__tile-stream-news-info-content-title">
                    <a href={article.moreInfo} target="_blank" onClick={this.handleNewsClick}>
                      {article.title}
                    </a>
                  </span>
                </div>
                <div className={classNames('c-dashboard__tile-stream-news-info-content', _.isEmpty(article.summary) ? 'no-summary' : '')}>
                  <ResponsiveEllipsis
                    className="c-dashboard__tile-stream-news-info-content-text"
                    text={article.summary}
                    maxLine="4"
                    ellipsis="..."
                  />
                </div>
                <div className="c-dashboard__tile-stream-news-info-footer">
                  <span>
                    {moment(article.publishingDate).format('D MMM YYYY')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    } else {
      return super.render();
    }

  }
}

export default NewsStream;