import _ from 'lodash';
import * as SPECIES_FILTER from '../Constants/SpeciesFilter';

class FilterHelper {
  public getSpeciesFilterItems = () => {
    let _species = SPECIES_FILTER.ITEMS;
    _.filter(_species, 'isEnabled');
    _.orderBy(_species, ['order'], ['asc']);
    return _species;
  }
}

export default FilterHelper;
