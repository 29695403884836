import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Button,
} from "@material-ui/core";
import _ from "lodash";
import React from "react";

import { observer } from "mobx-react";
import { observable } from "mobx";
import * as SHARES from '../App/Constants/Shares';

const styles = (theme: Theme) => createStyles({});

interface Props extends WithStyles<typeof styles> {
  toolTipTextHandler: ((text: string) => void);
  adjustTogglePatronageHandler: ((isToggled: boolean) => void);
}

@observer
class ImageToggler extends React.Component<Props> {
  @observable
  private toggle: boolean;

  constructor(props: Props) {
    super(props);
    this.toggle = true;
    this.props.toolTipTextHandler(SHARES.TOOLTIP_TEXT);
    this.props.adjustTogglePatronageHandler(this.toggle);
  }

  handleToggle = () => {
    this.toggle = !this.toggle;
    this.props.toolTipTextHandler(this.toggle ? SHARES.TOOLTIP_TEXT : SHARES.TOOLTIP_TEXT_STOCK_SUPPLIED_12_MONTHS);
    this.props.adjustTogglePatronageHandler(this.toggle);
  };

  render() {
    return (
      <>
        <div className="c-image-toggler__container">
          <div className="c-image-toggler__toggler" onClick={this.handleToggle}>
            {this.toggle ? (
              <img src={"/images/YTDToggle.png"} />
            ) : (
              <img src={"/images/LastYearToggle.png"} />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ImageToggler);
