import { Button } from '@material-ui/core';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AnimalCategories from '../AnimalCategories/AnimalCategories';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as STOCK from '../App/Constants/Stock';
import * as TELEMETRY from '../App/Constants/Telemetry';
import KillSheetHelper from '../App/Helpers/KillSheetHelper';
import KillSheet from '../App/Models/KillSheet';
import KillSheetDetails from '../App/Models/KillSheetDetails';
import KillSheetDetailsGrade from '../App/Models/KillSheetDetailsGrade';
import CarcaseReportStore from '../App/Stores/CarcaseReportStore';
import CustomContentDialogBox from '../CustomContentDialogBox/CustomContentDialogBox';
import GradeItem from './GradeItem';
import GradeState from './GradeState';
import StockFilter from './StockFilter';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import CarcaseReport from './CarcaseReport';

interface Props {
  carcaseReportStore?: CarcaseReportStore;
  killSheet?: KillSheet;
  authorizationStore?: AuthorizationStore;
  selectedAnimalCategory?: string;
}

interface State {
  expanded?: string;
  selectedCategory: string;
}
@inject('authorizationStore')
@inject('carcaseReportStore')
@observer
class Grades extends React.Component<Props, State> {
  private _killSheetHelper: KillSheetHelper = new KillSheetHelper();
  private _animalCategories: string[] = [];
  private _singleAnimalCategory?: string;
  private _isInterim: boolean;

  constructor(props: Props) {
    super(props);
    this.state = { expanded: DEFAULT_DATA.STRING, selectedCategory: STOCK.ALL };
  }
  openDialog = () => {
    if (this._animalCategories.length > 1) {
      this.props.carcaseReportStore!.handleOpenAnimalCategories();
    } else {
      const title = this._singleAnimalCategory!.toUpperCase() + ' CARCASE REPORT';
      this.props.carcaseReportStore!.handleOpenChart(title);
    }
  }

  handleChange = (panel: any) => (event: any, expanded: any) => {
    if (expanded) {
      window.telemetry.logEvent(TELEMETRY.EVENTS.KILLSHEET_GRADE_BREAKDOWN);
    }
    this.setState({
      expanded: expanded ? panel : false,
    });
  }

  setStockFilter = (stock: any) => {
    this.setState({
      selectedCategory: stock
    });
  }

  render() {    

    const { authorizationStore, carcaseReportStore, killSheet } = this.props;

    if (!killSheet && !killSheet!.killsheetDetails) {
      return <div />;
    }

    if (killSheet!.status === KILLSHEET.INTERIM_TYPE) {
      this._isInterim = true;
    } else {
      this._isInterim = false;
    }

    const killSheetDetails = killSheet!.killsheetDetails;
    const detailCategories = this._killSheetHelper.getDistinctDetailCategories(killSheetDetails);
    this._animalCategories = detailCategories.filter(category => category.name !== STOCK.ALL).map(category => category.value);
    const stateCategories = this._killSheetHelper.getDistinctStateCategories(killSheetDetails);
    const killSheetDetailCondemned = this._killSheetHelper.getKillSheetDetailStates(killSheetDetails, KILLSHEET.CONDEMNED);
    const killSheetDetailDead = this._killSheetHelper.getKillSheetDetailStates(killSheetDetails, KILLSHEET.DEAD);
    
    let _filteredKillSheetDetails: Array<KillSheetDetails> = new Array<KillSheetDetails>();
    let _grades: Array<KillSheetDetailsGrade> = new Array<KillSheetDetailsGrade>();
    let _tally: number = 0;

    _filteredKillSheetDetails = this._killSheetHelper.filterGradesByCategory(killSheetDetails, this.state.selectedCategory);
    _filteredKillSheetDetails.map(ksd => ksd.killsheetDetailsGrades.forEach(grade => { _tally += grade.quantity; }));
    _grades = this._killSheetHelper.sortGrades(_filteredKillSheetDetails);

    if (detailCategories.length === 0) {
      this._singleAnimalCategory = killSheet!.killsheetDetails[0].animalCategory;
    } else { 
      this._singleAnimalCategory =  carcaseReportStore!.category;
    }

    const detailsCondemned = (
      <GradeState
        key={`grade-state-condemned-${killSheet!.id}`}
        categories={stateCategories}
        details={killSheetDetailCondemned}
        expanded={this.state.expanded}
        handleChange={this.handleChange}
        type={KILLSHEET.CONDEMNED}
        tally={_tally}
      />
    );

    const detailsDead = (
      <GradeState
        key={`grade-state-dead-${killSheet!.id}`}
        categories={stateCategories}
        details={killSheetDetailDead}
        expanded={this.state.expanded}
        handleChange={this.handleChange}
        type={KILLSHEET.DEAD}
        tally={_tally}
      />
    );

    const gradesContainerClass = `${'c-grades__container'} ${(detailCategories && detailCategories.length > KILLSHEET.SINGLE_STOCK_ELEMENT) ? 'multiple-category' : 'single-category'}`;

    return (
      <>
        <div className={gradesContainerClass}>
          {!_.isEmpty(detailCategories) ? <div className="c-grades__stock-filter">
            <StockFilter categories={detailCategories} selectedCategory={this.state.selectedCategory} setStockFilter={this.setStockFilter} />
          </div> : <div />}
          {_grades.map((grade, index) => {
            if (grade && killSheet!.species) {
              return (<GradeItem
                key={`${killSheet!.species}-${grade.grade}-${index}`}
                species={killSheet!.species}
                handleChange={this.handleChange}
                expanded={this.state.expanded}
                killSheetDetailsGrade={grade}
                tally={_tally}
              />);
            }
          })}

          <>
            {detailsCondemned}
            {detailsDead}
          </>

          <div className="c-grades__total-tally">
            <div className="c-grades__tally-title">TALLY</div>
            <div className="c-grades__tally-value">{_tally}</div>
            <div className="c-grades__divider" />
          </div>
          <div className="c-grades__carcase-report">
            <Button variant="outlined" fullWidth={true} onClick={this.openDialog}>VIEW CARCASE REPORT</Button>
          </div>
        </div>
        <CustomContentDialogBox
          dialogStore={carcaseReportStore!.animalDialogStore}
          invisibleBackdrop={false}
          closeButton={true}
          actions={false}
          titleLeftAlign={false}
        >
          <AnimalCategories carcaseReportStore={carcaseReportStore!} buttonStyle="style1" categories={this._animalCategories} />
        </CustomContentDialogBox>
        <CustomContentDialogBox
          dialogStore={carcaseReportStore!.chartDialogStore}
          invisibleBackdrop={false}
          closeButton={true}
          actions={false}
          titleLeftAlign={true}
        >
          <CarcaseReport supplierId={killSheet!.supplier} killSheetNumber={authorizationStore!.selectedKillSheet!.killsheetNumber.toString()} referenceNumber={authorizationStore!.selectedKillSheet!.reference} category={this._singleAnimalCategory} isInterim={this._isInterim} />
        </CustomContentDialogBox>
      </>
    );
  }
}

export default Grades;