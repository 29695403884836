import { Button, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Info from '@material-ui/icons/Info';
import React from 'react';
import * as SHARES from '../App/Constants/Shares';
import PatronageEligibility from '../App/Models/PatronageEligibility';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import CalculatorBarGraph from './CalculatorBarGraph';
import PlusMinusButton from './PlusMinusButton';

const styles = (theme: Theme) => createStyles({
  button: {
    borderRadius: '2.5em',
    border: '0.05em solid rgb(65, 122, 93)',
    color: 'rgb(65, 122, 93)',
    fontFamily: 'Graphik-Medium',
    fontSize: '0.6em',
    padding: '0.3em 1.5em',
    '&:hover': {
      color: 'rgb(65,122, 93)',
      textDecoration: 'none'
    }
  },
  closeIcon: {
    verticalAlign: 'middle',
    marginRight: 20,
    marginBottom: 2
  },
  infoIcon: {
    fontSize: 'medium'
  },
  tooltipPopper: {
    opacity: 1
  },
  tooltip: {
    backgroundColor: '#FFF',
    minWidth: '17.917em',
    color: 'rgb(51, 51, 51)',
    fontFamily: 'Graphik-Medium',
    fontSize: '0.6em',
    letterSpacing: '0.03em',
    boxShadow: '0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16)',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-0.5em',
      borderWidth: '0.35em',
      borderStyle: 'solid',
      borderColor: '#FFF transparent transparent transparent'
    }
  }
});

const animals = {
  sheep: '/images/sheep_shares.svg',
  beef: '/images/beef_shares.svg',
  deer: '/images/deer_shares.svg',
  bobby: '/images/bobby_shares.svg'
};

interface Props extends WithStyles<typeof styles> {
  showHidePatronage: () => void;
  patronageEligibility: any;
}

interface State {
  patronageEligibility: PatronageEligibility;
}

class PatronageCalculator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      patronageEligibility: props.patronageEligibility,
    };
  }

  getInfoText() {
    const { patronageEligibility } = this.state;

    if (!patronageEligibility.isEligible()) {
      return SHARES.PATRONAGE_INELIGIBLE;
    }
    if (patronageEligibility.isOverShared()) {
      return SHARES.PATRONAGE_OVERSHARED;
    }
    if (patronageEligibility.percentFull() === 100) {
      return SHARES.PATRONAGE_OPTIMUM;
    }
    if (patronageEligibility.percentFull() === 0) {
      return SHARES.PATRONAGE_NO_SHARES;
    }

    return SHARES.PATRONAGE_OPTIMISE;
  }

  handleSharesMinus = () => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      // negative ints not allowed
      newPatronageEligibility.totalShares = Math.max(
        0,
        newPatronageEligibility.totalShares - SHARES.CALCULATOR_SHARES_STEP_SIZE
      );
      newPatronageEligibility.eligibleShares = Math.max(
        0,
        Math.max(newPatronageEligibility.eligibleShares - SHARES.CALCULATOR_SHARES_STEP_SIZE, newPatronageEligibility.totalShares)
      );
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  handleSharesPlus = () => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      // cap totalShares to max
      newPatronageEligibility.totalShares = Math.min(
        SHARES.CALCULATOR_SHARES_MAX,
        newPatronageEligibility.totalShares + SHARES.CALCULATOR_SHARES_STEP_SIZE
      );
      // cap eligibleShares to requiredShares
      newPatronageEligibility.eligibleShares = Math.min(
        newPatronageEligibility.requiredShares,
        newPatronageEligibility.eligibleShares + SHARES.CALCULATOR_SHARES_STEP_SIZE
      );
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  handleSharesSet = (newValue: number) => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      newPatronageEligibility.totalShares = Math.min(SHARES.CALCULATOR_SHARES_MAX, newValue);
      newPatronageEligibility.eligibleShares = Math.min(newPatronageEligibility.totalShares, newPatronageEligibility.requiredShares);
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  handleStockUnitsMinus = () => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      // negative ints not allowed
      newPatronageEligibility.stockUnits = Math.max(
        0,
        newPatronageEligibility.stockUnits - SHARES.CALCULATOR_STOCK_UNITS_STEP_SIZE
      );
      newPatronageEligibility.requiredShares = this.getRequiredShares(newPatronageEligibility.stockUnits);
      newPatronageEligibility.eligibleShares = Math.min(newPatronageEligibility.totalShares, newPatronageEligibility.requiredShares);
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  handleStockUnitsPlus = () => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      // cap stockUnits to max
      newPatronageEligibility.stockUnits = Math.min(
        SHARES.CALCULATOR_STOCK_UNITS_MAX,
        newPatronageEligibility.stockUnits + SHARES.CALCULATOR_STOCK_UNITS_STEP_SIZE
      );
      newPatronageEligibility.requiredShares = this.getRequiredShares(newPatronageEligibility.stockUnits);
      newPatronageEligibility.eligibleShares = Math.min(newPatronageEligibility.totalShares, newPatronageEligibility.requiredShares);
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  handleStockUnitsSet = (newValue: number) => {
    this.setState(prevState => {
      const newPatronageEligibility = Object.assign(new PatronageEligibility(), prevState.patronageEligibility);
      newPatronageEligibility.stockUnits = Math.min(SHARES.CALCULATOR_STOCK_UNITS_MAX, newValue);
      newPatronageEligibility.requiredShares = this.getRequiredShares(newPatronageEligibility.stockUnits);
      newPatronageEligibility.eligibleShares = Math.min(newPatronageEligibility.totalShares, newPatronageEligibility.requiredShares);
      return { patronageEligibility: newPatronageEligibility };
    });
  }

  render() {
    const { classes } = this.props;
    const { patronageEligibility } = this.state;

    return (
      // style prop is coming from the parent component being a material-ui Transition
      // see https://v3.material-ui.com/utils/transitions/#transitions
      <div className="c-patronage__container" style={(this.props as any).style}>
        <div className="c-patronage__header">
          <Close className={classes.closeIcon} onClick={this.props.showHidePatronage} />
                    PATRONAGE CALCULATOR
                </div>
        <div className="c-patronage__graph">
          <CalculatorBarGraph patronageEligibility={patronageEligibility} />
        </div>
        <div className="c-patronage__info-container">
          <div className="c-patronage__info-text">{this.getInfoText()}</div>
          <div className="c-patronage__info-button">
            <Button
              className={classes.button}
              href={SHARES.FIND_OUT_MORE_URL}
              target="_blank"
            >
              FIND OUT MORE
                        </Button>
          </div>
        </div>
        <div className="c-patronage__calculator">
          <div className="c-patronage__calculator-shares">
            <p>SHARES</p>
            <PlusMinusButton
              value={patronageEligibility.totalShares}
              onMinus={this.handleSharesMinus}
              onPlus={this.handleSharesPlus}
              onChange={this.handleSharesSet}
            />
          </div>
          <div className="c-patronage__calculator-stock-units">
            <p>
              STOCK UNITS (SU)
                            <CustomTooltip
                classes={{
                  popper: classes.tooltipPopper,
                  tooltip: classes.tooltip
                }}
                placement="top"
                disableTouchListener={true}
                title={
                  <>
                    <div className="c-patronage__tooltip">
                      <img src={animals.sheep} className="c-patronage__img" />
                      <div className="c-patronage__tooltip-animal">{SHARES.TOOLTIP_TEXT_LAMB}</div>
                    </div>
                    <div className="c-patronage__tooltip">
                      <img src={animals.bobby} className="c-patronage__img" />
                      <div className="c-patronage__tooltip-animal">{SHARES.TOOLTIP_TEXT_BOBBY}</div>
                    </div>
                    <div className="c-patronage__tooltip">
                      <img src={animals.deer} className="c-patronage__img" />
                      <div className="c-patronage__tooltip-animal">{SHARES.TOOLTIP_TEXT_DEER}</div>
                    </div>
                    <div className="c-patronage__tooltip">
                      <img src={animals.beef} className="c-patronage__img" />
                      <div className="c-patronage__tooltip-animal">{SHARES.TOOLTIP_TEXT_CATTLE}</div>
                    </div>
                  </>
                }
              >
                <Info className={classes.infoIcon} />
              </CustomTooltip>
            </p>
            <PlusMinusButton
              value={patronageEligibility.stockUnits}
              onMinus={this.handleStockUnitsMinus}
              onPlus={this.handleStockUnitsPlus}
              onChange={this.handleStockUnitsSet}
              isError={!patronageEligibility.isEligible()}
            />
            {!patronageEligibility.isEligible()
              ? <div className="c-patronage__eligibility-error">{SHARES.MINIMUM_STOCK_ERROR_MESSAGE}</div>
              : null}
          </div>
        </div>
      </div>
    );
  }

  // see: https://datacomgroup.atlassian.net/wiki/spaces/SFFREQ/pages/394691324/Patronage+Calculator+Rules
  private getRequiredShares(stockUnits: number) {
    if (stockUnits <= 5000) {
      return stockUnits * 8;
    }
    return 40000 + (stockUnits - 5000) * 3;
  }
}

export default withStyles(styles)(PatronageCalculator);