export const ITEMS = [
  {
    text: 'All DATES',
    value: ''
  },
  {
    text: 'Last 30 Days',
    value: '30 days'
  },
  {
    text: 'Last 3 Months',
    value: '3 months'
  },
  {
    text: 'Last 6 Months',
    value: '6 months'
  },
  {
    text: 'Last 12 Months',
    value: '12 months'
  }
];