export class RouteStore {

  public history: any;

  public location(): string {
    return this.history.location.pathname;
  }

  public setRoute(history: any): void {
    this.history = history;
  }

  public goBack() {
    this.history.goBack();
  }

  public goTo(path: string) {
    this.history.push(path);
  }
}

export default RouteStore;