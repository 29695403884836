import { inject, observer } from 'mobx-react';
import React from 'react';
import MenuStore from '../App/Stores/MenuStore';
import BannerStore from '../App/Stores/BannerStore';
import Banner from '../Banner/Banner';

interface Props {
  menuStore?: MenuStore;
  bannerStore?: BannerStore;
}

@inject('menuStore')
@inject('bannerStore')
@observer
class MainContainer extends React.Component<Props> {
  render() {
    const { menuStore, bannerStore } = this.props;
    const component = menuStore!.menuItems.filter(m => m.Index === menuStore!.selectedIndex)[0].Component;
    
    if (component) {
      let ContainerComponent = component;
      return (
        <>
          <Banner bannerStore={bannerStore!} />
          <ContainerComponent />
        </>
      );
    } else {
      return <p>&nbsp;</p>;
    }
  }
}

export default MainContainer;
