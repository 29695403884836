import { Grid } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as DATEFORMAT from '../App/Constants/DateFormat';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import OutstandingInvoiceDetailLine from '../App/Models/OutstandingInvoiceDetailLine';

interface Props {
  farmName?: string;
  detailLine: OutstandingInvoiceDetailLine;
  openDocument: Function;
  isInvoiceDocumentDownloaded: boolean;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

@observer
class OutstandingInvoiceDetailLine_Item extends Component<Props> {
  render() {
    const { detailLine, openDocument, farmName, isInvoiceDocumentDownloaded } = this.props;
    return (
      <Grid container={true} spacing={32}>
        <Grid item={true} xs={1} className="c-outstandingInvoiceDetailLine__item filler-container" />
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item capitalise">
            {_propertyHelper.formatValue(detailLine.documentDate, VALUE_TYPE.DATE, DATEFORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            {_propertyHelper.formatValue(detailLine.supplierId, VALUE_TYPE.STRING)}
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-outstandingInvoiceDetailLine__item no-overflow">
            {_propertyHelper.formatValue(farmName, VALUE_TYPE.STRING)}
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            {_propertyHelper.formatValue(detailLine.tally, VALUE_TYPE.NUMBER)}
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item capitalise">
            {_propertyHelper.formatValue(detailLine.species, VALUE_TYPE.STRING)}
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-outstandingInvoiceDetailLine__item capitalise">
            <div className={'c-outstandingInvoiceDetailLine__item ' + ((isInvoiceDocumentDownloaded) ? 'download-pdf' : 'no-download-pdf')} onClick={event => openDocument(event, detailLine.supplierId, detailLine.killsheetNumber, detailLine.referenceNumber)}>
              <LibraryBooksIcon />
              <span className="c-outstandingInvoiceDetailLine__collapsible-icon-text">
                {_propertyHelper.formatValue(detailLine.referenceNumber, VALUE_TYPE.INVOICE_REFERENCE, undefined, detailLine.killsheetNumber)}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className={`c-outstandingInvoiceDetailLine__item collapsible-item-column documentType capitalise ${_.kebabCase(detailLine.documentType)}`}>
            {detailLine.documentType}
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item right">
            {_propertyHelper.formatValue(detailLine.amount, VALUE_TYPE.CURRENCY)}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default OutstandingInvoiceDetailLine_Item;