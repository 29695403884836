import * as pdfjslib from 'pdfjs-dist';
import { PDFJSStatic } from 'pdfjs-dist';
import * as React from 'react';
import Terms from './../App/Models/Terms';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const StartingPageNumber: number = 1;
const HundredPercentScale: number = 1;

interface Props {
  terms?: Terms;
  scalingFactor?: number;
}

export class TermsPdf extends React.Component<Props> {
  private pdfCanvas: any;
  private scalingFactor: number;

  componentDidMount = () => {
    if (this.props.scalingFactor) {
      this.scalingFactor = this.props.scalingFactor;
      this.renderPDFInCanvas(this.props.terms!.base64Data);
    } else {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }
  }

  handleResize = () => {
    const windowSize = window.innerWidth;
    if (windowSize < 1024) {
      this.scalingFactor = 1.5;
    } else {
      this.scalingFactor = 2.5;
    }
    this.renderPDFInCanvas(this.props.terms!.base64Data);
  }

  public render() {
    return (
      <span
        ref={e => this.pdfCanvas = e}
      />
    );
  }

  private renderPDFInCanvas = (base64Data: string | undefined) => {
    while (this.pdfCanvas && this.pdfCanvas.firstChild) {
      this.pdfCanvas.removeChild(this.pdfCanvas.firstChild);
    }
    let pdfData = this.base64ToUint8Array(base64Data);

    PDFJS.disableWorker = false;
    if (!pdfData) {
      return;
    }

    pdfjslib.getDocument(pdfData).promise.then(pdf => {
      for (let num = StartingPageNumber; num <= pdf.numPages; num++) {
        pdf.getPage(num).then(page => {
          this.renderPage(page, num);
        });
      }
    });
  }

  private renderPage(page: pdfjslib.PDFPageProxy, index: number) {
    let canvas: any = document.createElement('canvas');
    canvas.className = index === 1 ? 'c-userterms__terms-pdf c-userterms__terms-pdf-first' : 'c-userterms__terms-pdf';
    let pdfWidth: number = page.getViewport({ scale: HundredPercentScale }).width;
    let pdfHeight: number = page.getViewport({ scale: HundredPercentScale }).height;
    canvas.width = pdfWidth * this.scalingFactor;
    canvas.height = pdfHeight * this.scalingFactor;
    let scale = canvas.width / page.getViewport({ scale: HundredPercentScale }).width;
    let viewport = page.getViewport({ scale: scale });
    let context = canvas!.getContext('2d');
    this.pdfCanvas.appendChild(canvas);

    page.render({ canvasContext: context, viewport: viewport });
  }

  private base64ToUint8Array(base64: string | undefined) {
    if (!base64) {
      return undefined;
    }

    var raw = atob(base64);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  }
}

export default TermsPdf;
