// This constants file contains values that relate to kill sheet properties
export const ANIMAL_CATEGORY = 'animalCategory';
export const CONDEMNED = 'COND';
export const DEAD = 'DEAD';
export const DEFAULT_PAGE = 0;
export const DETAILS_HEADER = 'KILL DETAILS';
export const HEALTH_REPORT_QUANTITY = 'quantity';
export const INTERIM_KILLSHEET_LEGEND_TEXT = 'Interim Kill Sheet';
export const INTERIM_TYPE = 'Interim';
export const KILLSHEET_LEGEND_TEXT = 'Kill Sheet';
export const KILLSHEET_NUMBER = 'killsheetNumber';
export const KILLSHEET_NUMBER_PREFIX = '#';
export const KILLSHEET_TYPE = 'KillSheet';
export const PAGE_SIZE = 5;
export const PERCENTAGE_UNIT = '%';
export const PROCESSED_DATE = 'processedDate';
export const QUANTITY = 'quantity';
export const SINGLE_STOCK_ELEMENT = 2;
export const TIMELINE_HEADER = 'KILL INFORMATION';
export const WEIGHT_UNIT = 'kg';

export const TALLY = 'Tally';
export const AVERAGE_KG_PER_HEAD = 'Average kg/Head';
export const AVERAGE_GROSS_PRICE_PER_HEAD = 'Average Gross Price/Head';
export const AVERAGE_GROSS_PRICE_PER_KG = 'Average Gross Price/kg';
export const KILL_SHEET_NUMBER = 'Kill Sheet Number'
export const DATE_PROCESSED = 'Date Processed';
export const LIVESTOCK_REPRESENTATIVE = 'Livestock Representative';
export const TOTAL_PAYMENT = 'Total Payment';
export const PAYMENT_DATE = 'Payment Date';
export const PAYMENT_TYPE = 'Payment Type';
export const PRESENTATION_GRADE = 'Presentation Grade';
export const ADDITIONAL_PREMIUMS_DEDUCTIONS = 'Additional Premiums/Deductions';

export const DOWNLOAD_MENU_PDF = 'Kill Sheet PDF';
export const VIEW_MENU_PDF = 'View Kill Sheet'
export const DOWNLOAD_MENU_XLSX = 'Download Kill Sheet';
export const DOWNLOAD_KILL_DATA_FILE = 'Download Kill Data File'

export const ICONS = [
    {
        name: 'sheep',
        iconUrl: '/images/sheep_kill_sheet_details.svg'
    },
    {
        name: 'beef',
        iconUrl: '/images/beef_kill_sheet_details.svg'
    },
    {
        name: 'deer',
        iconUrl: '/images/deer_kill_sheet_details.svg'
    },
    {
        name: 'bobby',
        iconUrl: '/images/bobby_kill_sheet_details.svg'
    }
];