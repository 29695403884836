import { Fade } from '@material-ui/core';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FADE_DELAY } from '../App/Constants/Transition';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import ForecastStore from '../App/Stores/ForecastStore';
import LoadError from '../LoadError/LoadError';
import ProgressIndicator from '../Progress/ProgressIndicator';
import StockItem from './StockItem';

interface Props {
  authorizationStore?: AuthorizationStore;
  forecastStore?: ForecastStore;
}

interface State {
  isDisplayLoadingAnimation: boolean;
}

@inject('authorizationStore')
@inject('forecastStore')
@observer
class Forecasts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDisplayLoadingAnimation: false
    };
  }

  render() {
    const { forecastStore, authorizationStore } = this.props;

    if (authorizationStore!.selectedFarmId && forecastStore!.isForecastsLoaded && forecastStore!.isForecastsLoadError) {
      return (
        <div className="c-stocks__load-error-container">
          <LoadError
            onPress={() => {
              forecastStore!.getForecasts(authorizationStore!.selectedFarmId);
            }}
          />
        </div>
      );
    } else if (forecastStore!.isForecastsLoaded && !forecastStore!.isForecastsLoadError) {
      const forecasts = forecastStore!.forecasts;

      return (
        <>
          <Fade in={true} timeout={FADE_DELAY}>
            {forecasts && !_.isEmpty(forecasts) ?
              <div className="c-stocks__timeline">
                {forecasts.map(forecast => (
                  <div key={forecast.id}>
                    <StockItem isTimelineYearDisplayed={forecast.isFirstElementForYear} stock={forecast} />
                  </div>
                ))}
              </div>
              :
              <div className="c-stocks__empty-stocks-container">
                <div className="c-stocks__empty-stocks-text">
                  No forecasts available for
                  <br />
                  {authorizationStore!.selectedFarm!.name}
                </div>
              </div>
            }
          </Fade>
        </>
      );
    } else {
      return (
        <div className='c-progress__stocks-container'>
          <ProgressIndicator
            isDisplayed={true}
            id='c-progress__stocks-forecasts'
          />
        </div>
      );
    }
  }
}

export default Forecasts;