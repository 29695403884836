import { Paper } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { TileStatus } from '../App/Constants/TileStatus';
import * as TILE_TITLES from '../App/Constants/TileTitles';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import LoadError from '../LoadError/LoadError';
import ProgressIndicator from '../Progress/ProgressIndicator';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  loadData: Function;
  initialStatus?: TileStatus;
}

class Tile<P extends Props> extends React.Component<P> {

  constructor(props: P) {
    super(props);

    let { dashboardStore, title, initialStatus } = this.props;

    if (!_.isUndefined(initialStatus)) {
      dashboardStore.setTileStatus(title, initialStatus);
    }
  }

  protected getStatus(title: string): TileStatus | undefined {
    return this.props.dashboardStore!.getTileStatus(title);
  }

  render() {

    let { authorizationStore, title, loadData } = this.props;
    switch (this.getStatus(title)) {
      case TileStatus.LOADING:
        return (
          <Paper id={_.toLower(title)} className="c-dashboard__tile loading">
            <div>
              <ProgressIndicator
                isDisplayed={true}
                id={`c-progress__timeline-${_.toLower(title)}`}
              />
            </div>
          </Paper>
        );
      case TileStatus.LOADED_NO_DATA:
        return (
          <Paper className="c-dashboard__tile no-data">
            <div>
              <div className="c-dashboard__tile-header-1">{_.toUpper(title)}</div>
              {title === TILE_TITLES.BOOKING ? <p>No bookings available for {authorizationStore!.selectedFarm && authorizationStore!.selectedFarm!.name}</p> :
                title === TILE_TITLES.FORECAST ? <p>No forecasts available for {authorizationStore!.selectedFarm && authorizationStore!.selectedFarm!.name}</p> :
                  title === TILE_TITLES.SOCIAL ? <p>No feed available</p> :
                    title === TILE_TITLES.NEWS ? <p>No feed available</p> :
                      title === TILE_TITLES.EVENTS ? <p>No feed available</p> :
                        title === TILE_TITLES.SHARE_PRICE ? <p>No data found</p> :
                          title === TILE_TITLES.LATEST ? <p>No feed available</p> :
                            title === TILE_TITLES.KILLSHEET ? <p>No kill sheets available for {authorizationStore!.selectedFarm && authorizationStore!.selectedFarm!.name}</p> :
                              title === TILE_TITLES.SCHEDULE ? <p>No schedule available for {authorizationStore!.selectedFarm && authorizationStore!.selectedFarm!.name}</p> :
                                <p>No data found</p>}
            </div>
          </Paper>
        );
      case TileStatus.LOAD_ERROR:
        return (
          <Paper id={title} className="c-dashboard__tile error">
            <div>
              <LoadError onPress={() => loadData()} />
            </div>
          </Paper>
        );
      case TileStatus.COMING_SOON:
        return (
          <Paper className="c-dashboard__tile coming-soon">
            <div>
              <p className="c-dashboard__tile coming-soon-title">{_.toUpper(title)}</p>
              <p className="c-dashboard__tile coming-soon-description">Coming soon...</p>
            </div>
          </Paper>
        );
      default:
        return (
          <Paper className="c-dashboard__tile loading">
            <div>
              <ProgressIndicator
                isDisplayed={true}
                id={`c-progress__timeline-${_.toLower(title)}`}
              />
            </div>
          </Paper>
        );
    }
  }
}

export default Tile;