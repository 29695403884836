import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as DATEFORMAT from '../App/Constants/DateFormat';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PaymentHistoryDetailLine from '../App/Models/PaymentHistoryDetailLine';

interface Props {
  detailLine: PaymentHistoryDetailLine;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

@observer
class PaymentHistoryPaymentLine_Item extends Component<Props> {
  render() {
    let { detailLine } = this.props;
    return (
      <Grid container={true} spacing={32} className="c-paymentHistoryPaymentLine">
        <Grid item={true} xs={1} className="c-paymentHistoryDetailLine__item filler-container" />
        <Grid item={true} xs={3}>
          <div className="c-paymentHistoryDetailLine__item capitalise">
            {_propertyHelper.formatValue(detailLine.paymentDate, VALUE_TYPE.DATE, DATEFORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
          </div>
        </Grid>
        <Grid item={true} xs={6} />
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item right">
            {_propertyHelper.formatValue(detailLine.amount, VALUE_TYPE.CURRENCY)}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default PaymentHistoryPaymentLine_Item;