import { action, observable } from 'mobx';

import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as TRANSITION from '../Constants/Transition';

class DialogStore {
  @observable public action?: Function;
  @observable public isConfirmationRequired: boolean;
  @observable public isDialogOpen: boolean;
  @observable public response: boolean;
  @observable public title: string;
  @observable public content: string | React.ComponentClass<any>;
  @observable public leftButton: string;
  @observable public rightButton: string;
  @observable public alternateAction?: Function;

  constructor() {
    this.isConfirmationRequired = true;
    this.isDialogOpen = false;
    this.response = false;
  }

  @action
  public setDialogOpen = (title: string, content: string | React.ComponentClass<any>, actionToExecute?: Function, isConfirmationRequired: boolean = true, leftButton: string = 'Cancel', rightButton: string = 'Ok', alternateAction?: Function) => {

    this.action = actionToExecute;
    this.content = content;
    this.isConfirmationRequired = isConfirmationRequired;
    this.response = false;
    this.leftButton = leftButton;
    this.rightButton = rightButton;
    this.title = title;
    this.alternateAction = alternateAction;
    this.isDialogOpen = true;
  }

  @action
  public setDialogClosed = () => {
    this.isDialogOpen = false;
    setTimeout(() => {
      this.action = DEFAULT_DATA.UNDEFINED;
      this.content = DEFAULT_DATA.STRING;
      this.isConfirmationRequired = false;
      this.response = false;
      this.title = DEFAULT_DATA.STRING;
    }, TRANSITION.DIALOG_DELAY);
  }

  @action
  public setResponse = (response: boolean) => {
    this.response = response;
  }

}

export default DialogStore;