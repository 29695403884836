import _ from 'lodash';
import moment from 'moment';

import * as CURRENCY_FORMAT from '../Constants/CurrencyFormat';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as KILLSHEET from '../Constants/KillSheet';
import * as VALUE_TYPE from '../Constants/ValueType';

class PropertyHelper {
  private _currencyFormatter = new Intl.NumberFormat(CURRENCY_FORMAT.LOCALE, {
    style: CURRENCY_FORMAT.STYLE,
    currency: CURRENCY_FORMAT.CURRENCY
  });

  private _numberWithCommasFormatter = new Intl.NumberFormat(CURRENCY_FORMAT.LOCALE);

  public formatValue = (value: number | string | Date | undefined, type: string, format?: string, additionalValueParam?: any): any => {
    if (_.isNil(value) || _.isNaN(value)) {
      return DEFAULT_DATA.HYPHEN;
    }

    switch (type) {
      case VALUE_TYPE.CURRENCY:
        return this._currencyFormatter.format(value as number);
      case VALUE_TYPE.DATE:
        if (format) {
          return moment(value).format(format);
        }
        return value;
      case VALUE_TYPE.KILLSHEET_NUMBER:
        return `${KILLSHEET.KILLSHEET_NUMBER_PREFIX}${value}`;
      case VALUE_TYPE.INVOICE_REFERENCE:
        if (additionalValueParam) {
          return `${_.toString(value)}/${_.toString(additionalValueParam)}`;
        }
        return _.toString(value);
      case VALUE_TYPE.NUMBER:
      case VALUE_TYPE.STRING:
        return _.toString(value);
      case VALUE_TYPE.NUMBER_WITH_COMMAS:
        return this._numberWithCommasFormatter.format(value as number);
      case VALUE_TYPE.WEIGHT:
        return `${value}${KILLSHEET.WEIGHT_UNIT}`;
      default:
        return DEFAULT_DATA.HYPHEN;
    }
  }
}

export default PropertyHelper;
