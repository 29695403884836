import { Component } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { TelemetryExceptionType, TelemetryPageEventType, TelemetryEventType } from '../Constants/Telemetry';
import * as TELEMETRY from '../../App/Constants/Telemetry';
import * as DEFAULT_DATA from '../../App/Constants/DefaultData';
import * as APPLICATION from '../../App/Constants/Application';
import ReactGA from 'react-ga';
import _ from 'lodash';
import ClaimsHelper from '../Helpers/ClaimsHelper';
import { AxiosError } from 'axios';
import moment from 'moment';
import StorageHelper from '../Helpers/StorageHelper';
import * as STORAGE from '../Constants/Storage';


interface Props {
  appInsightsInstrumentKey: string;
}

interface CustomLogProperties {
  Action?: string;
  ApplicationName?: string;
  ApplicationPlatform?: string;
  ApplicationVersion?: string;
  AuthTokenExpiry?: string;
  AuthTokenIssuedAt?: string;
  AuthTokenPolicyName?: string;
  CorrelationId?: string;
  Description?: string;
  Duration?: string;
  EndTime?: string;
  HttpStatusCode?: string;
  MessageBody?: string;
  StackTrace?: string;
  StartTime?: string;
  Url?: string;
  UserId?: string;
  SupplierId?: string;
  UserRole?: string;
}

const SFFApplicationVersion = 'SFF-ApplicationVersion';
const SFFApplicationName = 'SFF-ApplicationName';
const SFFApplicationPlatform = 'SFF-ApplicationPlatform';
const SFFCorrelationId = 'SFF-CorrelationId';
const SFFSupplierIds = 'SFF-SupplierIds';

interface CustomApiProperties {
  [SFFApplicationVersion]: string;
  [SFFApplicationName]: string;
  [SFFApplicationPlatform]: string;
  [SFFCorrelationId]: string;
  [SFFSupplierIds]: string;
}

const _claimsHelper: ClaimsHelper = new ClaimsHelper();
const _storageHelper: StorageHelper = new StorageHelper();

export default class TelemetryService extends Component<Props> {

  private _appInsightsService: ApplicationInsights;

  constructor(props: Props) {
    super(props);
    this._appInsightsService = new ApplicationInsights({
      config: {
        instrumentationKey: this.props.appInsightsInstrumentKey
      }
    });
    this._appInsightsService.loadAppInsights();
  }

  logException = (error: any, exceptionType?: TelemetryExceptionType) => {
    switch (exceptionType) {
      case TelemetryExceptionType.AdError:
        this._appInsightsService.trackEvent({ name: 'AD ERROR', properties: this.customLogProperties(error, true) });
        this._appInsightsService.trackException({ error: new Error(`AD ERROR: ${error!.message || error}`) });
        break;
      case TelemetryExceptionType.ApiError:
        this._appInsightsService.trackEvent({ name: 'API ERROR', properties: this.customLogProperties(error, true) });
        this._appInsightsService.trackException({ error: new Error(`API ERROR [Code]: ${error.code || 'unknown'} [Message]: ${error!.message || error}`) });
        break;
      default:
        this._appInsightsService.trackEvent({ name: 'GENERAL ERROR', properties: this.customLogProperties(error, true) });
        this._appInsightsService.trackException({ error: new Error(`GENERAL ERROR: ${error.stack || error}`) });
        break;
    }
    ReactGA.exception({ description: error!.message || error });

  }

  logEvent = (event: string, customEventType?: TelemetryEventType) => {
    switch (customEventType) {
      case TELEMETRY.TelemetryEventType.Page:
        this._appInsightsService.trackEvent({ name: `PAGE: ${event}`, properties: this.customLogProperties() });
        break;
      default:
        this._appInsightsService.trackEvent({ name: `EVENT: ${event}`, properties: this.customLogProperties() });
        ReactGA.event({ category: TELEMETRY.CATEGORIES.USER, action: event });
    }
  }

  triggerPageTimer = (name: string, pageEventType: TelemetryPageEventType) => {
    switch (pageEventType) {
      case TelemetryPageEventType.PageStart:
        this._appInsightsService.startTrackPage(name);
        ReactGA.set({ page: name });
        break;
      case TelemetryPageEventType.PageStop:
        this._appInsightsService.stopTrackPage(name);
        ReactGA.pageview(name);
        break;
      default:
        break;
    }
  }

  logApiRequest = (customRequestProperties: object) => {
    this._appInsightsService.trackEvent({ name: 'API REQUEST', properties: this.customLogProperties(customRequestProperties) });
  }

  logApiResponse = (customResponseProperties: object) => {
    this._appInsightsService.trackEvent({ name: 'API RESPONSE', properties: this.customLogProperties(customResponseProperties) });
  }

  getApiCustomRequestProperties = (correlationId: string, supplierId: string, url: string, action: string): any => {
    let requestAttributes = { 'Url': url, 'Action': _.upperCase(action) };
    return { ...this.customApiProperties(correlationId, supplierId), ...requestAttributes };
  }

  private customLogProperties = (additionalProperties?: any, exception: boolean = false): Object => {
    let customLogProperties = this.baseLogProperties();

    /// Determine if this is an AxiosError
    if (exception && additionalProperties && additionalProperties.config) {
      let errorProperties: AxiosError = additionalProperties;
      if (errorProperties.config.headers.Action) { customLogProperties.Action = errorProperties.config.headers.Action; }
      if (errorProperties.config.headers.Authorization) {
        let authToken = _claimsHelper.parseJwt(errorProperties.config.headers.Authorization.substring(7));
        customLogProperties.AuthTokenExpiry = moment.unix(authToken.exp).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        customLogProperties.AuthTokenIssuedAt = moment.unix(authToken.iat).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        customLogProperties.AuthTokenPolicyName = authToken.tfp;
      }
      if (errorProperties.config.headers[SFFCorrelationId]) { customLogProperties.CorrelationId = errorProperties.config.headers[SFFCorrelationId]; }
      if (errorProperties.response!.data) { customLogProperties.Description = errorProperties.response!.data.message; }
      if (errorProperties.config.headers.EndTime) { customLogProperties.EndTime = errorProperties.config.headers.EndTime; }
      if (errorProperties.response!.status) { customLogProperties.HttpStatusCode = errorProperties.response!.status.toString(); }
      if (errorProperties.config.headers.MessageBody) { customLogProperties.MessageBody = errorProperties.config.headers.MessageBody; }
      if (errorProperties.config.headers.StartTime) { customLogProperties.StartTime = errorProperties.config.headers.StartTime; }
      if (errorProperties.config.headers.FullUrl) { customLogProperties.Url = errorProperties.config.headers.FullUrl; }
    } else if (additionalProperties !== DEFAULT_DATA.UNDEFINED && typeof additionalProperties !== 'string') {
      if (additionalProperties.Action) { customLogProperties.Action = additionalProperties.Action; }
      if (additionalProperties.CorrelationId || additionalProperties[SFFCorrelationId]) { customLogProperties.CorrelationId = additionalProperties.CorrelationId || additionalProperties[SFFCorrelationId]; }
      if (additionalProperties.message) { customLogProperties.Description = additionalProperties.message; }
      if (additionalProperties.Duration) { customLogProperties.Duration = additionalProperties.Duration; }
      if (additionalProperties.EndTime) { customLogProperties.EndTime = additionalProperties.EndTime; }
      if (additionalProperties.HttpStatusCode) { customLogProperties.HttpStatusCode = additionalProperties.HttpStatusCode; }
      if (additionalProperties.MessageBody) { customLogProperties.MessageBody = additionalProperties.MessageBody; }
      if (additionalProperties.stack) { customLogProperties.StackTrace = additionalProperties.stack; }
      if (additionalProperties.StartTime) { customLogProperties.StartTime = additionalProperties.StartTime; }
      if (additionalProperties.FullUrl) { customLogProperties.Url = additionalProperties.FullUrl; }
      if (additionalProperties.Authorization) {
        let authToken = _claimsHelper.parseJwt(additionalProperties.Authorization.substring(7));
        customLogProperties.AuthTokenExpiry = moment.unix(authToken.exp).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        customLogProperties.AuthTokenIssuedAt = moment.unix(authToken.iat).utc().format('YYYY-MM-DDTHH:mm:ss.SSS');
        customLogProperties.AuthTokenPolicyName = authToken.tfp;
      }
    } else if (typeof additionalProperties === 'string') {
      customLogProperties.Description = additionalProperties;
    }

    return customLogProperties;
  }

  private customApiProperties = (correlationId: string, supplierId: string,): Object => {
    let customApiProperties: CustomApiProperties;
    return customApiProperties = {
      [SFFApplicationVersion]: _.isEmpty(process.env.REACT_APP_VERSION) ? window.appVersion : process.env.REACT_APP_VERSION!,
      [SFFApplicationName]: window.appSettings.applicationName,
      [SFFApplicationPlatform]: window.appSettings.applicationPlatform,
      [SFFCorrelationId]: correlationId,
      [SFFSupplierIds]: supplierId,
    };
  }

  private baseLogProperties = (): CustomLogProperties => {
    return {
      'ApplicationVersion': _.isEmpty(process.env.REACT_APP_VERSION) ? window.appVersion : process.env.REACT_APP_VERSION,
      'ApplicationName': window.appSettings.applicationName,
      'ApplicationPlatform': window.appSettings.applicationPlatform,
      'UserId': _claimsHelper.getUserEmail(),
      'SupplierId': _storageHelper.getKeyValue(STORAGE.SELECTED_FARM_ID)!,
      'UserRole': _storageHelper.getKeyValue(STORAGE.CURRENT_USER_ROLE)!,
    };
  }

}