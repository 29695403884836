import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import AuthStore from '../App/Stores/AuthStore';
import UserTermsStore from '../App/Stores/UserTermsStore';
import ScrollingDialogBox from '../ScrollingDialogBox/ScrollingDialogBox';
import CustomDialogBox from './../CustomDialogBox/CustomDialogBox';
import SingleButtonDialogBox from './../SingleButtonDialogBox/SingleButtonDialogBox';

interface Props {
  userTermsStore?: UserTermsStore;
  authStore?: AuthStore;
}

@inject('userTermsStore')
@inject('authStore')
@observer
class UserTermsDialog extends Component<Props> {

  constructor(props: Props) {
    super(props);
    this.props.userTermsStore!.authStore = this.props.authStore!;
  }

  render() {
    return (
      <>
        <SingleButtonDialogBox dialogStore={this.props.userTermsStore!.singleButtonDialogStore} />
        <ScrollingDialogBox dialogStore={this.props.userTermsStore!.scrollingDialogStore} />
        <CustomDialogBox dialogStore={this.props.userTermsStore!.dialogStore} />
      </>
    );
  }
}

export default UserTermsDialog;