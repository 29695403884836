import DialogStore from './DialogStore';
import { observable, action } from 'mobx';
import KillSheetService from '../Services/KillSheetService';
import * as pbi from 'powerbi-client';
import TilesEmbedDetail from '../Models/TilesEmbedDetail';
import TileEmbedDetail from '../Models/TileEmbedDetail';
import * as CONSTANTS from '../Constants/PowerBIDashboard';
import * as HTTP_RESPONSE_CODE from '../Constants/HttpResponseCode';

export class CarcaseReportStore {
  public service = new KillSheetService();
  public powerbi: pbi.service.Service;
  @observable animalDialogStore: DialogStore = new DialogStore();
  @observable chartDialogStore: DialogStore = new DialogStore();
  @observable public tileDetails: TilesEmbedDetail = new TilesEmbedDetail;
  @observable public embedContainer: HTMLDivElement;
  @observable public tiles: pbi.Tile[] = [];

  @observable public resizeEvent: any = false;
  @observable public supplierId: string;
  @observable public killSheetNumber?: string;
  @observable public referenceNumber?: string;
  @observable public category?: string;
  @observable public isInterim: boolean;
  
  public timesCalled: number = 0;
  private _responseCode?: number;
  constructor() {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  }

  getConfig = (tileDetail: TileEmbedDetail) => {

    var embedConfiguration: pbi.IEmbedConfiguration = {
      accessToken: tileDetail.embeddedToken,
      embedUrl: tileDetail.embedUrl,
      id: tileDetail.tileId,
      tokenType: pbi.models.TokenType.Embed,
      type: CONSTANTS.REPORT,
      dashboardId: tileDetail.dashboardId,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: pbi.models.BackgroundType.Transparent,
        layoutType: pbi.models.LayoutType.Custom,
        customLayout: {
          displayOption: pbi.models.DisplayOption.FitToPage,
        }
      }
    };
    return embedConfiguration;
  }

  getEmbedReport = (supplierId: string, isInterim: boolean, killSheetNumber?: string, referenceNumber?: string, category?: string) => {

      this.service.getCarcaseReport(supplierId, isInterim, killSheetNumber, referenceNumber, category).then(result => {
        this._responseCode = result!.status;
        if (this._responseCode !== HTTP_RESPONSE_CODE.SUCCESS) {
          this.service.isLoaded = true;
          this.service.hasError = true;
        }
        this.tileDetails.tileDetails = [];
        this.tileDetails.tileDetails = result!.data.tileDetails as TileEmbedDetail[];
      }).then(() => {
        while (this.embedContainer && this.embedContainer.firstChild) {
          this.embedContainer.removeChild(this.embedContainer.firstChild);
        }
        this.tiles = [];
        this.tileDetails.tileDetails.forEach((tile) => {

          let config: pbi.IEmbedConfiguration;
          config = this.getConfig(tile);
     
          let tileDiv = document.createElement(CONSTANTS.DIV);
          this.embedContainer.appendChild(tileDiv);
          tileDiv.classList.add(CONSTANTS.GRAPH_CARCASE_CLASS);
          this.tiles.push(this.powerbi.embed(tileDiv, config) as pbi.Report);
      
          this.service.isLoaded = true;
          this.service.hasError = false;
          this.resizeEvent = false;
      
        });
      }).catch(error => {
        window.appDialogStore!.setDialogClosed();
        this.service.isLoaded = true;
        this.service.hasError = true;
      });
    }
 

  @action
  handleOpenChart = (title: string) => {
    this.chartDialogStore.setDialogOpen(title, '');
  }

  @action
  handleCloseChart = () => {
    this.chartDialogStore.setDialogClosed();
  }

  @action
  handleOpenAnimalCategories = () => {
    this.animalDialogStore.setDialogOpen('CHOOSE ANIMAL CATEGORY', '');
  }

  @action
  handleCloseAnimalCategories = () => {
    this.animalDialogStore.setDialogClosed();
  }

}

export default CarcaseReportStore;