import { Component } from "react";
import StorageHelper from "../../components/App/Helpers/StorageHelper";
import MobileAppRequiredDialog from "../../components/MobileAppRequiredDialog/MobileAppRequiredDialog";
import * as STORAGE from '../../components/App/Constants/Storage';

class MobileAppRequired extends Component {
	private _storageHelper: StorageHelper = new StorageHelper();

  componentDidMount = () => {
	this._storageHelper.storeKeyValue(STORAGE.MOBILE_APP_REQUIRED_DIALOG,false);
	window.MobileAppRequiredDialog!.setDialogOpen('', '', undefined, false, '');
    }
	render() {
       
		return (
		       <div className="p-login__login-page">
			 <div className="p-login__login-container">
			   <div className="p-login__links-container">
			   <MobileAppRequiredDialog dialogStore={window.MobileAppRequiredDialog!}/>
			   </div>
			 </div>
		       </div>
		     );
	};
       }
       
export default MobileAppRequired;
       