import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  ListSubheader,
} from '@material-ui/core';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as MENU from '../App/Constants/Menu';
import * as PAYMENT from '../App/Constants/Payment';
import * as TELEMETRY from '../App/Constants/Telemetry';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import InvoiceStore from '../App/Stores/InvoiceStore';
import MenuStore from '../App/Stores/MenuStore';
import PaymentsStore from '../App/Stores/PaymentsStore';
import { color } from '@storybook/addon-knobs';
import StorageHelper from '../App/Helpers/StorageHelper';
import * as STORAGE from '../App/Constants/Storage';

interface Props {
  authorizationStore?: AuthorizationStore;
  menuStore?: MenuStore;
  paymentsStore?: PaymentsStore;
  invoiceStore?: InvoiceStore;
  dashboardStore?: DashboardStore;
}

interface State {
  isInitialRender: boolean;
  open: boolean;
}
const _storageHelper: StorageHelper = new StorageHelper();
@inject('authorizationStore')
@inject('menuStore')
@inject('paymentsStore')
@inject('invoiceStore')
@inject('dashboardStore')
@observer
class FarmSwitcher extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isInitialRender: true,
      open: false,
    };
  }

  componentWillMount = () => {
    const { authorizationStore, paymentsStore, invoiceStore } = this.props;
    authorizationStore!.getUserFarms();
    paymentsStore!.setPaymentSelectedSupplierId(
      authorizationStore!.selectedFarmId
    );
    invoiceStore!.setInvoiceSelectedSupplierId(
      authorizationStore!.selectedFarmId
    );
  };

  handleChange = (event: any) => {
    const { authorizationStore, paymentsStore, invoiceStore, dashboardStore } =
      this.props;

    if (
      authorizationStore!.selectedFarmId !== event.target.value ||
      (authorizationStore!.isAllFarmsSelected === true &&
        authorizationStore!.selectedFarmId !== event.target.value)
    ) {
      authorizationStore!.setSelectedFarm(event.target.value);
      authorizationStore!.toggleAllFarms = false;
      authorizationStore!.isAllFarmsSelected = false;
      window.telemetry.logEvent(TELEMETRY.EVENTS.FARM_SWITCHER);
      _storageHelper.storeKeyValue(
        STORAGE.ALL_FARM_IDS,
        authorizationStore!.selectedFarmId
      );
    } else if (
      authorizationStore!.isAllFarmsSelected === true &&
      authorizationStore!.selectedFarmId === event.target.value
    ) {
      this.toggleAllFarms();
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  toggleAllFarms = () => {
    const { authorizationStore, paymentsStore, invoiceStore } = this.props;
    const { isInitialRender } = this.state;
    const isDialogDisplayed =
      window.appDialogStore!.isDialogOpen ||
      window.appLatestFeaturesDialogStore!.isDialogOpen;

    if (isDialogDisplayed) {
      return;
    }

    if (
      authorizationStore!.isKillSheetsLoaded ||
      paymentsStore!.isPaymentsLoaded
    ) {
      if (isInitialRender) {
        this.setState({ isInitialRender: false });
      }

      authorizationStore!.toggleSelectedAllFarms();

      if (authorizationStore!.isAllFarmsSelected) {
        paymentsStore!.setPaymentSelectedSupplierId(PAYMENT.ALL_FARMS_ID);
        invoiceStore!.setInvoiceSelectedSupplierId(PAYMENT.ALL_FARMS_ID);
      }
    }
  };

  renderSelectedClass = (baseClassName: string, supplierId: string) => {
    const { authorizationStore } = this.props;
    const { isInitialRender } = this.state;

    if (isInitialRender) {
      return baseClassName;
    } else {
      return `${baseClassName}${DEFAULT_DATA.WHITESPACE}${
        !isInitialRender &&
        !authorizationStore!.isAllFarmsSelected &&
        authorizationStore!.selectedFarmId === supplierId
          ? `selected`
          : `defocus`
      }`;
    }
  };

  render() {
    const { authorizationStore, menuStore } = this.props;
    const isDialogDisplayed =
      window.appDialogStore!.isDialogOpen ||
      window.appLatestFeaturesDialogStore!.isDialogOpen;
    const isDisplayAllFarmsButton = _.includes(
      MENU.ALL_FARMS_PAGE_INDEXES,
      menuStore!.selectedIndex
    );

    if (!authorizationStore!.isUserFarmsLoaded) {
      return (
        <div className="c-farm-switcher__progress-container">
          <CircularProgress className="c-farm-switcher__progress" size={24} />
        </div>
      );
    }

    const isAnyTeamAccessUserFarms =
      authorizationStore!.teamAccessUserFarms.length > 0;
    let teamAccessGroupTitle;
    if (isAnyTeamAccessUserFarms) {
      teamAccessGroupTitle = (
        <ListSubheader
          className="subcategory-name"
          disableGutters={true}
          disableSticky={true}
          onClickCapture={(e) => e.stopPropagation()}
        >
          MOBILE VIEW ONLY
        </ListSubheader>
      );
    } else {
      teamAccessGroupTitle = '';
    }
    let _allFarmsButtonClass = `c-farm-switcher__all-farms-button`;
    const _allFarmsContainerClass = `c-farm-switcher__all-farms-container${
      DEFAULT_DATA.WHITESPACE
    }${isDisplayAllFarmsButton ? `show` : `hide`}`;

    const _farmNameClass = `farm-name`;
    const _farmIdClass = `farm-id`;

    _allFarmsButtonClass = `${_allFarmsButtonClass}${DEFAULT_DATA.WHITESPACE}${
      authorizationStore!.isAllFarmsSelected ? `selected` : `unselected`
    }`;

    return (
      <div className="c-farm-switcher__container">
        <FormControl className="c-farm-switcher__form-control">
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={authorizationStore!.selectedFarmId}
            onChange={this.handleChange}
            inputProps={{
              name: 'selectedFarmId',
              id: 'c-farm-switcher__select',
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              getContentAnchorEl: null,
            }}
            disabled={isDialogDisplayed}
          >
            {authorizationStore!.userFarms
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((farm) => (
                <MenuItem
                  key={farm.id}
                  value={farm.id}
                  disabled={isDialogDisplayed}
                >
                  <div className="farm-item">
                    <div
                      className={this.renderSelectedClass(
                        _farmNameClass,
                        farm.id
                      )}
                    >
                      {farm.name}
                    </div>
                    <div
                      className={this.renderSelectedClass(
                        _farmIdClass,
                        farm.id
                      )}
                    >
                      {farm.id}
                    </div>
                  </div>
                </MenuItem>
              ))}

            {teamAccessGroupTitle}
            {authorizationStore!.teamAccessUserFarms
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((farm) => (
                <MenuItem key={farm.id} value={farm.id} disabled={true}>
                  <div className="farm-item">
                    <div
                      className={this.renderSelectedClass(
                        _farmNameClass,
                        farm.id
                      )}
                    >
                      {farm.name}
                    </div>
                    <div
                      className={this.renderSelectedClass(
                        _farmIdClass,
                        farm.id
                      )}
                    >
                      {farm.id}
                    </div>
                  </div>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className={_allFarmsContainerClass}>
          <div
            className={_allFarmsButtonClass}
            onClick={() => this.toggleAllFarms()}
          >
            View All Farms
          </div>
        </div>
      </div>
    );
  }
}

export default FarmSwitcher;
