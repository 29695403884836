import Fade from '@material-ui/core/Fade';
import { inject } from 'mobx-react';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as TELEMETRY from '../App/Constants/Telemetry';
import FilterHelper from '../App/Helpers/FilterHelper';
import AuthorizationStore from '../App/Stores/AuthorizationStore';

interface Props {
  authorizationStore?: AuthorizationStore;
}

interface State {
  isFiltered: boolean;
  selectedSpecies?: string;
}

@inject('authorizationStore')
class SpeciesFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFiltered: false,
      selectedSpecies: props.authorizationStore!.selectedSpecies
    };
  }

  toggleFilter = (species?: string) => {
    const { authorizationStore } = this.props;
    window.telemetry.logEvent(`${TELEMETRY.EVENTS.KILLSHEET_SPECIES_FILTER} - ${species}`);
    authorizationStore!.isKillSheetsLoaded = false;

    if (authorizationStore!.isFiltered) {
      this.setState({ selectedSpecies: DEFAULT_DATA.UNDEFINED });
      authorizationStore!.selectedSpecies = DEFAULT_DATA.UNDEFINED;
    } else {
      this.setState({ selectedSpecies: species });
      authorizationStore!.selectedSpecies = species;
    }
    authorizationStore!.isFiltered = !this.props.authorizationStore!.isFiltered;
    authorizationStore!.resetKillSheetList();
    authorizationStore!.isKillSheetDetailsLoaded = false;

    if (authorizationStore!.isAllFarmsSelected) {
      authorizationStore!.getAllKillSheets();
    } else {
      authorizationStore!.getKillSheets(authorizationStore!.selectedFarmId);
    }
  }

  render() {
    const { authorizationStore } = this.props;
    const _filterHelper = new FilterHelper();
    const _species = _filterHelper.getSpeciesFilterItems();

    const label = this.props.authorizationStore!.isKillSheetsLoaded ?
      <span className="c-species-filter__label">Species Filter:</span> :
      DEFAULT_DATA.STRING;

    const icons =
      authorizationStore!.isKillSheetsLoaded
        ? _species.map(species => (
          <span
            className="c-species-filter__item"
            key={species.name + species.order}
            onClick={() => this.toggleFilter(species.name)}
          >
            <Fade
              in={
                !authorizationStore!.isFiltered ||
                (authorizationStore!.isFiltered &&
                  authorizationStore!.selectedSpecies === species.name)
              }
            >
              <img
                src={species.imageUrl}
                className={
                  !authorizationStore!.isFiltered ||
                    (authorizationStore!.isFiltered &&
                      authorizationStore!.selectedSpecies === species.name)
                    ? 'show'
                    : 'hide'
                }
              />
            </Fade>
            <Fade
              in={
                authorizationStore!.isFiltered &&
                authorizationStore!.selectedSpecies !== species.name
              }
            >
              <img
                src={species.disabledImageUrl}
                className={
                  authorizationStore!.isFiltered &&
                    authorizationStore!.selectedSpecies !== species.name
                    ? 'show'
                    : 'hide'
                }
              />
            </Fade>
          </span>
        ))
        : _species.map(species => (
          <span
            className="c-species-filter__item"
            key={species.name + species.order}
          >
            <Fade in={authorizationStore!.isKillSheetsLoaded}>
              <img src={species.disabledImageUrl} className="show" />
            </Fade>
          </span>
        ));

    return (
      <div className="c-species-filter__container">
        {label}
        {icons}
      </div>
    );
  }
}

export default SpeciesFilter;
