// This constants file contains values that relate to my account property.
export const MY_ACCOUNT_HEADER = 'My Account';
export const SUPPLIER_NUMBER = 'Supplier Number';
export const FARM = 'Farm';
export const ACCOUNT_CONTACT = 'Account Contact';
export const FARM_CONTACT = 'Farm Contact';
export const PAY = 'Pay';
export const BANK_ACCOUNT = 'Bank Account';
export const PAYMENT_METHOD = 'Payment Method';
export const GST_NUMBER = 'GST Number';
export const SEND_KILLSHEET_BY = 'Send Killsheet by';
export const KILLSHEET_EMAIL = 'Killsheet Email';
export const ALSO_SEND_KILLSHEET_TO = 'Also Send Killsheet to';
export const REMITTANCE_EMAIL_TO = 'Remittance Email to';
export const POSTAL_ADDRESS = 'Postal Address';
export const FARM_ADDRESS = 'Farm Address';
export const LIVESTOCK_REPRESENTATIVE = 'Livestock Representative';
export const WHO_CAN_SEE_THIS_INFORMATION = 'Who can see this information?';

export const DIRECT_CREDIT = 'Direct Credit';

export const SHARE_HOLDER_HEADER = 'My Shares';
export const CRN = 'CRN';
export const SHARE_TYPE = 'Share Type';
export const QUANTITIY = 'Quantity';

/* Nzfap Statuses */
export const STATUS_FARM_ASSURED = 'Farm Assured';
export const STATUS_NON_COMPLIANT = 'Non Compliant';
export const STATUS_ASSESSED = 'Assessed';
export const STATUS_INACTIVE = 'Inactive';
export const STATUS_EXPIRED = 'Expired';