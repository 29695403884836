import { isNullOrUndefined } from 'util';
import * as STORAGE from '../Constants/Storage';
import StorageHelper from './StorageHelper';

class ClaimsHelper {

  private _storageHelper: StorageHelper = new StorageHelper();

  getUserClaims = (): any => {
    let storedClaims = this._storageHelper.getKeyValue(STORAGE.USER_ACCESS_TOKEN) || this._storageHelper.getKeyValue(STORAGE.MSAL_IDTOKEN);
    if (storedClaims === '') {
      return storedClaims;
    } else if (!isNullOrUndefined(storedClaims)) {
      if (storedClaims.indexOf('auth_time') !== -1 && storedClaims.indexOf('tfp') !== -1) {
        return storedClaims;
      } else {
        return this.parseJwt(storedClaims);
      }
    } else {
      return null;
    }
  }
  
  getAuthorizedParty = (): any =>  {
    let claims = this.getUserClaims();
    if (claims == null || !claims.hasOwnProperty('azp')) {
      return 'unknown';
    } else {
      return claims.azp;
    }
  }

  getUserEmail = (): any => {
    let claims = this.getUserClaims();
    if (claims == null || !claims.hasOwnProperty('email')) {
      return 'unknown';
    } else {
      return claims.email;
    }
  }

  hasTokenExpired = (token?: string | null): boolean => {
    if (!token) {
      token = this._storageHelper.getKeyValue(STORAGE.USER_ACCESS_TOKEN);
    }
    let claims = this.parseJwt(token!);
    if (claims.exp && claims.exp * 1000 > Date.now()) {
      return false;
    }
    return true;
  }

  /**
   * Parse Jwt Token
   * ref: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
   */
  parseJwt = (token: string): any => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }
}

export default ClaimsHelper;
