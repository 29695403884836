import React, { PureComponent } from 'react';
import { Button, Collapse, Fade } from '@material-ui/core';
import PaymentsStore from '../App/Stores/PaymentsStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import DocumentHelper from '../App/Helpers/DocumentHelper';
import * as MIME_TYPE from '../App/Constants/MimeType';
import * as DOCUMENT from '../App/Constants/Document';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import { SUCCESS } from '../App/Constants/HttpResponseCode';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as RESPONSE_DIALOG_MESSAGE from '../App/Constants/ResponseDialogMessage';

const _documentHelper: DocumentHelper = new DocumentHelper();

interface Props {
  paymentsStore: PaymentsStore;
  authorizationStore: AuthorizationStore;
}

interface State {
  exportDialogOpen: boolean;
  dateFilterChanged: boolean;
}

@inject('authorizationStore')
@inject('paymentsStore')
@inject('invoiceStore')
@observer
class PaymentHistoryExport extends PureComponent<Props, State> {

  exportAnchorElement?: HTMLButtonElement;

  constructor(props: Props) {
    
    super(props);
    this.state = {
      exportDialogOpen: false,
      dateFilterChanged: false,
    };
  }

  handleCSVExport = (event: any) => {
    this.exportAnchorElement = event.currentTarget; 
    this.setState({ exportDialogOpen: false });
    this.setState({ dateFilterChanged: false });   
    
    const { authorizationStore, paymentsStore } = this.props;
    const downloadFile = paymentsStore!.getPaymentsExportByFarm(
      authorizationStore!.isAllFarmsSelected,
      authorizationStore!.selectedFarmId,
      paymentsStore!.selectedStartDate,
      paymentsStore!.selectedEndDate
    );
   
    if (downloadFile) {
      downloadFile
        .then(blobReponse => {
          if (blobReponse && blobReponse.data) {
            if (blobReponse.status === SUCCESS) {
              const [, filename] = blobReponse.headers['content-disposition'].split('filename=');
              const nameArr = filename.split(';');
              _documentHelper.accessDocument(blobReponse.data, MIME_TYPE.CSV, DOCUMENT.DOWNLOAD_ACTION, DEFAULT_DATA.STRING, DEFAULT_DATA.STRING, nameArr[0]);
            }
          }
        })
        .catch(() => {
          let messageTitle: string;
          let messageContent: string;
          let action: Function | undefined;
          let buttonText: string;
          messageTitle = RESPONSE_DIALOG_MESSAGE.DEFAULT_MESSAGE_TITLE;
          messageContent = RESPONSE_DIALOG_MESSAGE.DEFAULT_MESSAGE_SHORT_CONTENT;
          action = () => {
            window.appDialogStore!.setDialogClosed();
          };
          buttonText = RESPONSE_DIALOG_MESSAGE.NOT_FOUND_BUTTON_TEXT;
          window.appDialogStore!.setDialogOpen(messageTitle, messageContent, action, false, buttonText);
        });
    }
  }

  render() {
    const { paymentsStore } = this.props;
    let buttonMessage;

    if (paymentsStore!.isPaymentsLoaded && (paymentsStore!.paymentHistorySummaryLines && _.isEmpty(paymentsStore!.paymentHistorySummaryLines))) {
      buttonMessage = 'Download CSV';
      return (
        <div className="c-paymentHistoryExport">
          <Button disabled={true} className="c-paymentHistoryExport-disablebutton" onClick={this.handleCSVExport}>{buttonMessage}</Button>
        </div>
      );
    } else { 
      buttonMessage = 'Download CSV';
      return (
        <div className="c-paymentHistoryExport">
          <Button className="c-paymentHistoryExport-button" onClick={this.handleCSVExport}>{buttonMessage}</Button>
        </div>
      );
    }
  }
}

export default PaymentHistoryExport;