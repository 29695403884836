import React, { Component } from 'react';
import * as CONSTANTS from '../App/Constants/PowerBIDashboard';
import * as pbi from 'powerbi-client';
import TileEmbedDetail from '../App/Models/TileEmbedDetail';

interface Props {
    reports: TileEmbedDetail[];
    tabId: number;
    tabName: string;
  }

interface State {
    reports: TileEmbedDetail[];
  }

class TabContent extends React.Component<Props, State> {
    public powerbi: pbi.service.Service;

    constructor(props: Props) {
        super(props);
        this.powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );
        this.state = {
            reports: this.props.reports
        }
       
    }

    generateTabReport = (reportNames: TileEmbedDetail[], tabIndex: number) => {
        var counter = 0;

        reportNames.forEach((tile, index) => {
            
            counter++;

            if (tabIndex === counter) {
                var accessToken = tile.embeddedToken;
                var embedReportId = tile.tileId;
                var embedUrl = tile.embedUrl;
                
                var reportConfig: pbi.IEmbedConfiguration = {
                    accessToken: accessToken,
                    embedUrl: embedUrl,
                    id: embedReportId,
                    tokenType: pbi.models.TokenType.Embed,
                    type: CONSTANTS.REPORT,
                    dashboardId: tile.dashboardId,
                    settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                        background: pbi.models.BackgroundType.Default
                    }
                };

                // Create middle container div
                const divReportTabs = document.createElement('div');
                divReportTabs.className = "c-my-report__tabs";

                // Create power bi div
                var divPower = document.createElement('div');
                divPower.className = "c-power-bi__Tab";
                divPower.id = this.props.tabName;

                // Append child to middle and middle to parent
                divReportTabs.appendChild(divPower);
                var reportContainer = document.getElementById('rep' + this.props.tabName);
                reportContainer!.appendChild(divReportTabs);

                // Embed report
                var report = this.powerbi.embed(divPower!, reportConfig);
                
            }
        });
    }
   
    public render() {
        return (
            <div className="c-my-report__container" id={'rep' + this.props.tabName} />
        );
    }

    clearDivsChildren = (name: any) => {
        var reportContainer = document.getElementById(name);
        if (reportContainer !== null) {
            reportContainer!.innerHTML = "";
        }
    }

    componentDidMount() { 
        if (this.props.reports !== undefined) { 
            this.generateTabReport(this.state.reports, this.props.tabId);        
        }
    }

    componentDidUpdate() {
        if (this.state.reports !== this.props.reports && this.props.reports !== undefined) {
            this.setState({
                reports: this.props.reports
            })
            this.clearDivsChildren('rep' + this.props.tabName);
            this.generateTabReport(this.props.reports, this.props.tabId);       
        }
    }    
}
export default TabContent;
