import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as DATEFORMAT from '../App/Constants/DateFormat';
import * as DOCUMENT_TYPE from '../App/Constants/DocumentType';
import * as PAYMENT from '../App/Constants/Payment';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PaymentHistoryDetailLine from '../App/Models/PaymentHistoryDetailLine';
import PaymentHistorySummaryLine from '../App/Models/PaymentHistorySummaryLine';

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props {
  farmName?: string;
  paymentHistorySummaryLine: PaymentHistorySummaryLine;
  downloadPaymentDocument: (supplierId: string, lineItem: PaymentHistoryDetailLine) => Promise<void>;
}

@observer
class PaymentHistorySummary extends Component<Props> {

  render() {
    let { paymentHistorySummaryLine, farmName } = this.props;
    let accumulatedTotal = 0;
    paymentHistorySummaryLine.payments.forEach(lineItem => {
      if (lineItem && lineItem.isTotal) {
        accumulatedTotal += lineItem.amount;
      }
    });

    return (
      <Grid container={true} spacing={32} className="c-paymentHistorySummaryLine__item grid-container" onClick={this.onClickExpandHistory}>
        <Grid item={true} xs={1} className="c-paymentHistorySummaryLine__item icon-container">
          <ExpandMoreIcon className="c-paymentHistorySummaryLine__item icon" />
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-paymentHistorySummaryLine__item capitalise">
            {_propertyHelper.formatValue(paymentHistorySummaryLine.paymentDate, VALUE_TYPE.DATE, DATEFORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
            <div className="c-paymentHistorySummaryLine__item-subheading">{PAYMENT.PAYMENT_DATE}</div>
          </div>
        </Grid>
        <Grid item={true} xs={2} className="c-paymentHistorySummaryLine__collapsible-item-column">
          <div className="c-paymentHistorySummaryLine__item">
            {_propertyHelper.formatValue(paymentHistorySummaryLine.supplierId, VALUE_TYPE.STRING)}
            <div className="c-paymentHistorySummaryLine__item-subheading">{PAYMENT.SUPPLIER_ID}</div>
          </div>
        </Grid>
        <Grid item={true} xs={4}>
          <div className="c-paymentHistorySummaryLine__item">
            {_propertyHelper.formatValue(farmName, VALUE_TYPE.STRING)}
            <div className="c-paymentHistorySummaryLine__item-subheading">{PAYMENT.FARM_NAME}</div>
          </div>
        </Grid>
        <Grid item={true} xs={3}>
          <div className="c-paymentHistorySummaryLine__item right">
            {_propertyHelper.formatValue(accumulatedTotal, VALUE_TYPE.CURRENCY)}
            <div className="c-paymentHistorySummaryLine__item-subheading">{PAYMENT.TOTAL_AMOUNT}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1} className="c-paymentHistorySummaryLine__item border-filler-container" />
        <Grid item={true} xs={11}>
          <div className="c-paymentHistorySummaryLine__item bottom-border" />
        </Grid>
      </Grid >
    );
  }

  onClickExpandHistory = async () => {
    const { paymentHistorySummaryLine, downloadPaymentDocument } = this.props;
    paymentHistorySummaryLine.payments.filter(p => p.documentType === DOCUMENT_TYPE.INVOICE || p.documentType === DOCUMENT_TYPE.CREDIT_MEMO).forEach(async lineItem => {
      await downloadPaymentDocument(paymentHistorySummaryLine.supplierId, lineItem);
    });
  }
}

export default PaymentHistorySummary;