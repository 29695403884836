class Stock {
  public id: string;
  public species: string;
  public animalCategory: string;
  public paymentType: string;
  public quantity: number;
  public specialProgramme: string;
  public contractNumber: string;
  public year: string;
  public isFirstElementForYear: boolean;
}

export default Stock;