import _ from 'lodash';
import { PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import * as msal from "@azure/msal-browser";
import * as CONNECTION from '../Constants/Connection';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as STORAGE from '../Constants/Storage';
import * as TELEMETRY from '../Constants/Telemetry';
import { TelemetryExceptionType } from '../Constants/Telemetry';
import ClaimsHelper from '../Helpers/ClaimsHelper';
import StorageHelper from '../Helpers/StorageHelper';
import UserRegistrationService from '../Services/UserRegistrationService';
import DialogStore from './DialogStore';
import { b2cPolicies, loginRequest, msalConfig } from '../../../authConfig';


export class AuthStore {
    public token: string;
    //private agent: UserAgentApplication;
    private isPasswordReset: boolean; // user has requested for password reset  
    private _userRegistrationService: UserRegistrationService = new UserRegistrationService();
    private _storageHelper: StorageHelper = new StorageHelper();
    private _claimsHelper: ClaimsHelper = new ClaimsHelper();
    private pca: PublicClientApplication;

  constructor(pca: PublicClientApplication){
    this.pca = pca;
    window.appDialogStore = new DialogStore();
  }

    /**
     * Initialise the auth store
     */
    public init = (policy: string): PublicClientApplication => {
        // let options = {
        //     cacheLocation: 'localStorage',
        //     redirectUri: window.appSettings.redirectUri,
        //     postLogoutRedirectUri: window.appSettings.postLogoutRedirectUri,
        //     validateAuthority: false
        // };

        // let authority = `${window.appSettings.msLogin}${window.appSettings.b2cDomain
        //     }/${policy}`;
        // this.agent = new UserAgentApplication(
        //     window.appSettings.clientID,
        //     authority,
        //     this.authCallback,
        //     options
        // );
        
        window.appSettings.userCurrentPolicyName = policy; // stored current user policy
        window.getToken = this.acquireToken;
        window.logOut = this.logOut;
        window.passwordReset = this.passwordReset;
        window.isAuthenticated = this.IsAuthenticated;
        window.appLatestFeaturesDialogStore = new DialogStore();
        window.appDialogStore = new DialogStore();
        window.MobileAppRequiredDialog = new DialogStore();
        window.appVersion = _.isEmpty(process.env.REACT_APP_VERSION) ? window.appSettings.applicationVersion : process.env.REACT_APP_VERSION!;

        return this.pca;
    }

    /**
     * Password registration for new users
     */
    public passwordRegistration = (newToken: string, linkVersion: string): boolean => {
        let extraQueryParameters = '';
        let policy = window.appSettings.passwordRegistrationPolicyName;
        /* Set extra query parameters according to the magic link version being used */
        switch (linkVersion) {
            case CONNECTION.COMPLETE_REGISTRATION:
                extraQueryParameters = 'id_token_hint=' + newToken;
                break;
            case CONNECTION.COMPLETE_REGISTRATION2:
                extraQueryParameters = 'id_token_hint=' + newToken;
                policy = window.appSettings.passwordRegistrationPolicyName2;
                break;
        }

        this.init(policy);
        try {
            //this.agent.loginRedirect([window.appSettings.clientID], extraQueryParameters);
            let passwordRegistrationPolicy = b2cPolicies.authorities.passwordRegistration;
            
            /* Construct valid passwordRegistrationPolicy for RedirectRequest */
            passwordRegistrationPolicy.extraQueryParameters.id_token_hint = newToken;
            this.pca.loginRedirect(passwordRegistrationPolicy as RedirectRequest);
            return true;
        } catch (error) {
            let errorMessage = 'Error during password registration. ' + JSON.stringify(error);
            window.telemetry.logException(errorMessage, TelemetryExceptionType.AdError);
            return false; // Password Registration fail
        }
    }

    public completeRegistration = async (token: string) => await this._userRegistrationService.completeRegistration(token);

    /**
     * Password reset for existing users
     */
    public passwordReset = () => {
        this.isPasswordReset = true;
        this.init(window.appSettings.passwordResetPolicyName);
        this.pca.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
    }

    /**
     * This method is used through the application to get a jwt token and send it to the API
     */
    public acquireToken = async (): Promise<string | undefined> => {
        try {
            // let _token = await this.agent.acquireTokenSilent(
            //     [window.appSettings.b2cScope],
            //     undefined,
            //     this.getUser()
            // );
            const account = this.pca.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }

            const _token = await this.pca.acquireTokenSilent({
              ...loginRequest,
              account: account
        });


            let _user = this.getUser();
            let _userIdToken = _user.idToken;
            //let _username = `${_userIdToken && _userIdToken!.given_name ? `${_userIdToken.given_name}${DEFAULT_DATA.WHITESPACE}` : DEFAULT_DATA.STRING}${_userIdToken && _userIdToken!.family_name ? `${_userIdToken.family_name}` : DEFAULT_DATA.STRING}`;
            let _username = account.username === "" ? account.name : account.username;
            this._storageHelper.storeKeyValue(STORAGE.USERNAME, `${_username}`);
            this._storageHelper.storeKeyValue(STORAGE.USER_ACCESS_TOKEN, _token.accessToken);
            //this._storageHelper.storeKeyValue(STORAGE.THIRDPARTYCOOKIESENABLED, 'true');

            return _token.accessToken;
        } catch (error) {
            let errorMessage = 'Error acquiring token. ' + error;
            //this._storageHelper.storeKeyValue(STORAGE.THIRDPARTYCOOKIESENABLED, 'false');
            window.telemetry.logException(errorMessage, TelemetryExceptionType.AdError);
            this._storageHelper.clearValue(STORAGE.SELECTED_FARM_ID);
            this._storageHelper.clearValue(STORAGE.ALL_FARM_IDS);
            this._storageHelper.clearValue(STORAGE.CURRENT_USER_ROLE);
            this._storageHelper.clearValue(STORAGE.USER_ACCESS_TOKEN);
            this._storageHelper.clearValue(STORAGE.USERNAME);
            this._storageHelper.clearValue(STORAGE.BANNER_CLOSED);
        }

        return undefined;
    }

    /**
     * Check to see if the user is authenticated
     * If user is not authenticated then redirect for authentication
     */
    public IsAuthenticated = (): boolean => {
        // let _user;
        // if (this.agent === undefined && window.appSettings) {
        //     this.init(window.appSettings.policyName);
        // }

        if (this.isPasswordReset) {
            return false;
        }

        // if (this.agent) {
        //     _user = this.agent.getUser();
        // }

        // if (!_user) {
        //     return false;
        // }

        if (this.pca.getActiveAccount()){
          return true;
        }

        //return true;
        return false;
    }

    /**
     * Log in current user
     */
    public login = (): any => {
        //this.init(window.appSettings.policyName);
        this.pca.loginRedirect(loginRequest);
    }

    /**
     * Logout current user
     */
    public logOut = () => {
        window.telemetry.logEvent(TELEMETRY.EVENTS.LOGOUT);

        /* Construct logout request for b2c logout redirect request */
        const logoutRequest = {
            account: null,
            postLogoutRedirectUri: window.appSettings.postLogoutRedirectUri
          };
        this.pca.logoutRedirect(logoutRequest);
        this._storageHelper.clearValue(STORAGE.SELECTED_FARM_ID);
        this._storageHelper.clearValue(STORAGE.ALL_FARM_IDS);
        this._storageHelper.clearValue(STORAGE.CURRENT_USER_ROLE);
        this._storageHelper.clearValue(STORAGE.USER_ACCESS_TOKEN);
        this._storageHelper.clearValue(STORAGE.USERNAME);
        this._storageHelper.clearValue(STORAGE.BANNER_CLOSED);
    }

    /**
     * Get the current user
     */
    public getUser = (): any => {
        return this.pca.getActiveAccount();
    }

    /**
     * Get Current User Policy 
     */
    public getUserPolicy = (): string => {
        let claims = this._claimsHelper.getUserClaims();
        
        /* Add validation for password reset policy to b2c */ 
        if (window.appSettings.userCurrentPolicyName.length > 1) {
            return window.appSettings.userCurrentPolicyName
        }
        if (claims == null || !claims.hasOwnProperty('tfp')) {
            return window.appSettings.policyName;
        } else {
            return claims.tfp;
        }
    }

    /**
     * Once the login is successful get the token from the Azure AD B2C
     */
    // private authCallback = (
    //     errorDesc: string,
    //     token: string,
    //     error: string,
    //     tokenType: string
    // ) => {
    //     if (errorDesc) {
    //         // handle forgot password error
    //         if (errorDesc.indexOf('AADB2C90118') > -1) {
    //             this.isPasswordReset = true;
    //             this.init(window.appSettings.passwordResetPolicyName);
    //             this.agent.loginRedirect([window.appSettings.b2cScope]);
    //         }
    //     }
    // }
}

export default AuthStore;
