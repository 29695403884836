import { Fade, Tab, Tabs, withStyles } from '@material-ui/core';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import * as ARRAY from '../../components/App/Constants/Array';
import * as MY_ACCOUNT from '../../components/App/Constants/MyAccount';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import { FADE_DELAY } from '../../components/App/Constants/Transition';
import ShareDetails from '../../components/App/Models/ShareDetails';
import AccountStore from '../../components/App/Stores/AccountStore';
import FarmStore from '../../components/App/Stores/FarmStore';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import LoadError from '../../components/LoadError/LoadError';
import Loading from '../../components/Loading/Loading';
import MyAccountItem from '../../components/MyAccount/Models/MyAccountItem';
import MyAccountList from '../../components/MyAccount/MyAccountList';
import Nait from '../../components/MyAccount/Nait';
import Nzfap from './../../components/MyAccount/Nzfap';

const styles = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  }
});

interface Props {
  accountStore?: AccountStore;
  farmStore?: FarmStore;
  authorizationStore?: AuthorizationStore;
  theme: any;
}

interface State {
  isDisplayLoadingAnimation: boolean;
  value: number;
}

@inject('accountStore')
@inject('farmStore')
@inject('authorizationStore')
@observer
class MyAccount extends Component<Props, State> {
  private selectedFarmId: string;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      if (this.props.authorizationStore!.selectedFarmId !== this.selectedFarmId) {
        this.selectedFarmId = this.props.authorizationStore!.selectedFarmId;
        this.props.farmStore!.getFarmDetails(this.selectedFarmId);
        this.props.farmStore!.getNaitNumbers(this.selectedFarmId);
        this.props.farmStore!.getFarmAccessContacts(this.selectedFarmId);
        this.props.farmStore!.getNzfapCertificates(this.selectedFarmId);
        this.props.accountStore!.getAccountDetails(this.selectedFarmId);
      }
    });

    this.state = {
      value: 0,
      isDisplayLoadingAnimation: false
    };
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_ACCOUNT, TelemetryPageEventType.PageStart);
  }

  componentDidMount = () => {
    this.handleChangeIndex(this.props.accountStore!.accountPageTabIndex);
    window.telemetry.logEvent(PAGE_NAME.MY_ACCOUNT, TelemetryEventType.Page);
  }

  handleChange = (event: any, value: any) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  render() {
    const { accountStore, farmStore, authorizationStore, theme } = this.props;
    if (accountStore!.isAccountDetailLoadError
      || farmStore!.isFarmDetailsLoadError
      || farmStore!.isFarmAccessContactsLoadError
      || farmStore!.isNaitNumbersLoadError
      || farmStore!.isNzfapCertificatesLoadError) {
      return (
        <div className="c-my-account__load-error-container">
          < LoadError
            onPress={() => {
              farmStore!.getFarmDetails(this.selectedFarmId);
              farmStore!.getNaitNumbers(this.selectedFarmId);
              farmStore!.getNzfapCertificates(this.selectedFarmId);
              farmStore!.getFarmAccessContacts(this.selectedFarmId);
              accountStore!.getAccountDetails(authorizationStore!.selectedFarmId);
            }}
          />
        </div>
      );
    } else if (accountStore!.isAccountDetailLoaded
      && farmStore!.isFarmDetailsLoaded
      && farmStore!.isFarmAccessContactsLoaded
      && farmStore!.isNaitNumbersLoaded
      && farmStore!.isNzfapCertificatesLoaded) {
      const myAccountTable = this.generateMyAccountTable();
      const permissionTable = this.generatePermissionTable();
      const naitNumbers = this.getNaitNumbers();
      const nzfapCertificates = this.getNzfapCertificates();
      return (
        <Fade in={true} timeout={FADE_DELAY}>
          <div className="c-my-account__container">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              className="c-my-account__tabs"
            >
              <Tab className="c-my-account__tab" label="My Account" disableRipple={true} />
              <Tab className="c-my-account__tab" label="Permissions" disableRipple={true} />
              <Tab className="c-my-account__tab" label="NAIT" disableRipple={true} />
              <Tab className="c-my-account__tab" label="NZFAP" disableRipple={true} />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              <MyAccountList myAccountTable={myAccountTable} />
              <MyAccountList myAccountTable={permissionTable} />
              <Nait farmName={authorizationStore!.selectedFarm!.name} naitNumbers={naitNumbers} />
              <Nzfap farmName={authorizationStore!.selectedFarm!.name} nzfapCertificates={nzfapCertificates} />
            </SwipeableViews>
          </div>
        </Fade>
      );
    } else {
      return <Loading />;
    }
  }

  generateMyAccountTable = () => {
    const { accountStore, farmStore } = this.props;
    const account = accountStore!.accountDetail.accountDetails;
    const farm = farmStore!.farmDetail.farmDetails;

    let myAccountTable = new Array<MyAccountItem>();

    this.addItemIfExists(myAccountTable, MY_ACCOUNT.SUPPLIER_NUMBER, farm.supplierId);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.FARM, account.name);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.ACCOUNT_CONTACT, account.primaryPersonOnAccount);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.FARM_CONTACT, farm.primaryContact);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.LIVESTOCK_REPRESENTATIVE, farm.fieldRepFullName);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.PAY, account.payToVendor);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.PAYMENT_METHOD, account.paymentMethod);
    (!account.payToVendor && account.paymentMethod === MY_ACCOUNT.DIRECT_CREDIT) &&
      this.addItemIfExists(myAccountTable, MY_ACCOUNT.BANK_ACCOUNT, account.bankAccountNumber);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.GST_NUMBER, account.gstRegistrationNumber);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.SEND_KILLSHEET_BY, account.taxInvoiceDeliveryMethod);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.KILLSHEET_EMAIL, account.emailTaxInvoiceTo);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.ALSO_SEND_KILLSHEET_TO, account.additionalTaxInvoiceRecipientList);
    this.addItemIfExists(myAccountTable, MY_ACCOUNT.REMITTANCE_EMAIL_TO, account.emailRemittanceTo);
    (account.postalAddress1) &&
      this.addItemIfExists(myAccountTable, MY_ACCOUNT.POSTAL_ADDRESS, [
        account.postalAddress1,
        account.postalAddress2,
        account.postalAddress3,
        account.postalAddress4,
        account.postalAddressCity,
        account.postalAddressCountry,
        account.postalAddressPostcode,
      ]);
    (farm.physicalAddress1) &&
      this.addItemIfExists(myAccountTable, MY_ACCOUNT.FARM_ADDRESS, [
        farm.physicalAddress1,
        farm.physicalAddress2,
        farm.physicalAddress3,
        farm.physicalAddress4,
        farm.physicalAddressCity,
        farm.physicalAddressCountry,
        farm.physicalAddressPostcode,
      ]);

    return myAccountTable;
  }

  generatePermissionTable = () => {
    const { farmStore } = this.props;
    let permissionTable = new Array<MyAccountItem>();
    const farm = farmStore!.farmDetail.farmDetails;

    permissionTable.push({ label: MY_ACCOUNT.WHO_CAN_SEE_THIS_INFORMATION, value: farm.farmAccessContacts && farm.farmAccessContacts!.length !== ARRAY.EMPTY ? farm.farmAccessContacts : [] })
    return permissionTable;
  }

  getNaitNumbers = () => {
    const { farmStore } = this.props;

    return farmStore!.naitNumbers;
  }

  getNzfapCertificates = () => {
    const { farmStore } = this.props;

    return farmStore!.nzfapCertificates;
  }

  addItemIfExists = (
    myAccountTable: Array<MyAccountItem>,
    label: string, value: string | string[],
  ) => {
    value &&
      myAccountTable.push({
        label: label,
        value: value
      })
  }

  generateShareHolderTable = () => {
    const { accountStore } = this.props;
    const shareList: Array<ShareDetails> = accountStore!.accountDetail.accountDetails.shareList;

    if (!shareList || shareList.length !== ARRAY.EMPTY) {
      return shareList;
    }
  }
}

export default withStyles(styles, { withTheme: true })(MyAccount);
