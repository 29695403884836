import { Button, Fab, Fade } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as EVENT from '../App/Constants/Events';
import * as TRANSITION from '../App/Constants/Transition';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import LoadError from '../LoadError/LoadError';
import ProgressIndicator from '../Progress/ProgressIndicator';
import SearchTimelineHeader from '../TimelineHeader/SearchTimelineHeader';
import TimelineHeader from '../TimelineHeader/TimelineHeader';
import TimelineItem from './TimelineItem';

interface Props {
  authorizationStore?: AuthorizationStore;
}

interface State {
  isDisplayLoadingAnimation: boolean;
}

@inject('authorizationStore')
@observer
class Timeline extends React.Component<Props, State> {

  private timelineHeight: number = TRANSITION.CONTAINER_TOP_POSITION;
  private timeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDisplayLoadingAnimation: false
    };
  }

  refreshTimeline = () => {
    const { authorizationStore } = this.props;
    const _selectedFarmId = authorizationStore!.selectedFarmId;

    if (authorizationStore!.isKillSheetsSearchResult) {
      authorizationStore!.getKillSheetsByNumber(authorizationStore!.lastSearchQuery);
    } else if (authorizationStore!.isAllFarmsSelected) {
      authorizationStore!.setSelectedFarm(_selectedFarmId);
      authorizationStore!.getAllKillSheets();
    } else {
      authorizationStore!.getFarmKillSheets(_selectedFarmId);
    }
  }

  scrollToTop = () => {
    const _element = document.getElementById('c-timeline__container');

    if (_element) {
      animateScrollTo(TRANSITION.CONTAINER_TOP_POSITION, { element: _element!, horizontal: false, speed: TRANSITION.TIMELINE_SCROLL_TOP__SPEED });
    }
  }

  scrollToNextPage = () => {
    let _element = document.getElementById('c-timeline__container');

    if (_element) {
      this.timelineHeight = _element!.clientHeight;
      var elementScrollHeight = _element.scrollTop;
      var scrollTo = elementScrollHeight + this.timelineHeight;
      animateScrollTo(scrollTo, { element: _element!, horizontal: false, speed: TRANSITION.TIMELINE_SCROLL_NEXT_PAGE_SPEED });
    }
  }

  timelineContainerDidMount = (node: any) => {
    if (node) {
      node.addEventListener(EVENT.MOUSE_SCROLL, this.trackScrolling.bind(this));
    }
  }

  trackScrolling = (event: any) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const _node: any = event.target;
      const { authorizationStore } = this.props;
      const isBottomOfDiv =
        _node.scrollHeight - Math.ceil(_node.scrollTop) <= _node.clientHeight;

      if (isBottomOfDiv && !authorizationStore!.isKillSheetsComplete && _node.scrollTop > 0) {
        this.setState({ isDisplayLoadingAnimation: true });
        if (authorizationStore!.isAllFarmsSelected) {
          authorizationStore!.getAllKillSheets().then(() => {
            this.setState({ isDisplayLoadingAnimation: false });
          });
        } else {
          authorizationStore!.getKillSheets(authorizationStore!.selectedFarmId).then(() => {
            this.setState({ isDisplayLoadingAnimation: false });
          });
        }
      }
    }, TRANSITION.TIMELINE_SCROLL_DELAY);
  }

  render() {
    const { authorizationStore } = this.props;
    const timelineContainerClass = `c-timeline__container${!(authorizationStore!.selectedKillSheetId) ? '-extended' : DEFAULT_DATA.STRING}${authorizationStore!.isAllFarmsSelected || authorizationStore!.isKillSheetsSearchResult ? `${DEFAULT_DATA.WHITESPACE}farm-details` : DEFAULT_DATA.STRING}`;

    if (authorizationStore!.selectedFarmId) {
      if (authorizationStore!.isKillSheetsLoadError) {
        return (
          <div
            id="c-timeline__container"
            ref={this.timelineContainerDidMount}
            className={`${timelineContainerClass}`}
          >
            <TimelineHeader />
            <div className="c-timeline__load-error-container">
              <Fade in={authorizationStore!.isKillSheetsLoaded}>
                <LoadError onPress={this.refreshTimeline} />
              </Fade>
            </div>
          </div>
        );
      }
      return (
        <div
          id="c-timeline__container"
          ref={this.timelineContainerDidMount}
          className={`${timelineContainerClass}`}
        >
          {authorizationStore!.isKillSheetsSearchResult ? <SearchTimelineHeader /> : <TimelineHeader />}
          {!_.isEmpty(authorizationStore!.killSheets) && authorizationStore!.isKillSheetsLoaded ? (
            <>
              <Fade in={authorizationStore!.isDisplayTimeline}>
                <div className="c-timeline__timeline">
                  {authorizationStore!.killSheets.map(killSheet => (
                    <TimelineItem
                      key={`${killSheet.supplier}-${killSheet.id}-${killSheet.killsheetNumber}`}
                      killSheet={killSheet}
                      isFarmDetailsDisplayed={authorizationStore!.isAllFarmsSelected || Boolean(authorizationStore!.isKillSheetsSearchResult)}
                      isTimelineYearDisplayed={killSheet.isFirstElementForYear}
                    />
                  ))}
                </div>
              </Fade>
              <div
                className={classNames({
                  'c-timeline-fab__container': true,
                  'c-timeline__hidden': authorizationStore!.isKillSheetsSearchResult === 'number',
                })}
              >
                <Fab className="c-timeline-fab up" aria-label="Scroll to top" onClick={this.scrollToTop}>
                  <FirstPageIcon />
                </Fab>
                <Fab className="c-timeline-fab" aria-label="Scroll to next page" onClick={this.scrollToNextPage}>
                  <KeyboardArrowDownIcon />
                </Fab>
              </div>
            </>
          ) : (
              <Fade in={authorizationStore!.isKillSheetsLoaded}>
                <div className="c-timeline__empty-kill-sheets-container">
                  <div className="c-timeline__empty-kill-sheets-text">
                    {authorizationStore!.isKillSheetsSearchResult
                      ? 'No results found'
                      : `No Kill Sheets available for ${authorizationStore!.selectedFarm!.name}.`}
                  </div>
                  {authorizationStore!.isKillSheetsSearchResult
                    ? null
                    : <div className="c-timeline__refresh-button-container">
                      <Button onClick={this.refreshTimeline}>
                        <RefreshIcon className="c-timeline__refresh-icon" />
                                                  Refresh list
                                              </Button>
                    </div>
                  }
                </div>
              </Fade>
            )}
          <ProgressIndicator
            isDisplayed={!authorizationStore!.isKillSheetsLoaded || this.state.isDisplayLoadingAnimation}
            id="c-progress__timeline-timeline"
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Timeline;
