import _ from 'lodash';
import React, { Component } from 'react';
import NaitNumber from './../App/Models/NaitNumber';
import NaitItem from './NaitItem';

interface Props {
  farmName: string;
  naitNumbers: NaitNumber[];
}
class Nait extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { naitNumbers } = this.props;
    return (
      naitNumbers && !_.isEmpty(naitNumbers) ?
        <div className="c-nait-item__container">
          {naitNumbers.map((item: any, index: number) => {
            return (
              <NaitItem naitNumber={item} key={index} />
            );
          })}
        </div>
        :
        <div className="c-nait__empty-nait-numbers-container">
          <div className="c-nait__empty-nait-numbers-text">
            No NAIT numbers for
                        <br />
            {this.props.farmName}
          </div>
        </div>
    );
  }
}

export default Nait;