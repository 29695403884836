import Feedback from '../../Feedback/Feedback';
import LegalTerms from '../../LegalTerms/LegalTerms';
import SettingsActionItem from '../Models/SettingsActionItem';
import SettingsComponentItem from '../Models/SettingsComponentItem';
import SettingsListSection from '../Models/SettingsListSection';
import * as TELEMETRY from '../Constants/Telemetry';

/* NOTE: The specific prototype assignment is required for type checking on the settings component. This allows the component to
   differentiate between items which requires different actions e.g. SettingsActionItem allows redirection to an external URL 
   with or without a prompting the user with a dialog box. */
const CHANGE_PASSWORDS_ITEM = Object.setPrototypeOf(
  {
    action: () => { window.passwordReset(); },
    label: 'Change Password',
    prompt: false
  },
  SettingsActionItem.prototype
);

const LEGAL_TERMS_ITEM = Object.setPrototypeOf(
  {
    component: LegalTerms,
    
    /* Use AEM content for view user terms*/
     action: () => {
       window.telemetry.logEvent(TELEMETRY.EVENTS.VIEW_LEGAL_TERMS);
       window.open(window.appSettings.aemContentUrl, "_blank");},
    label: 'Legal Terms',
    prompt: false,
    props: {}
  },
  SettingsActionItem.prototype
  //SettingsComponentItem.prototype
);

const FEEDBACK_ITEM = Object.setPrototypeOf(
  {
    component: Feedback,
    label: 'Feedback',
    prompt: true,
    props: {}
  },
  SettingsComponentItem.prototype
);

const LOG_OUT_ITEM = Object.setPrototypeOf(
  {
    action: () => {
      window.logOut();
    },
    content: 'You will need to be online to log in again by using your email address and password only.',
    label: 'Log Out',
    prompt: true,
    title: 'Are you sure you want to log out?',
  },
  SettingsActionItem.prototype
);

export const ITEMS: Array<SettingsListSection> = [
  {
    header: 'Security',
    items: [
      CHANGE_PASSWORDS_ITEM
    ] as Array<SettingsActionItem | SettingsComponentItem>
  },
  {
    header: 'About This App',
    items: [
      LEGAL_TERMS_ITEM,
      FEEDBACK_ITEM
    ] as Array<SettingsActionItem | SettingsComponentItem>
  },
  {
    header: 'Log Out',
    items: [
      LOG_OUT_ITEM
    ] as Array<SettingsActionItem | SettingsComponentItem>
  }
];