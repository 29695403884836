import { inject } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ERROR_CODES from '../../components/App/Constants/ErrorCodes';
import * as URL from '../../components/App/Constants/ExternalUrls';
import * as QUERYSTRING from '../../components/App/Constants/QueryString';
import * as STORAGE from '../../components/App/Constants/Storage';
import { TelemetryExceptionType } from '../../components/App/Constants/Telemetry';
import StorageHelper from '../../components/App/Helpers/StorageHelper';
import AuthStore from '../../components/App/Stores/AuthStore';
import UserTermsStore from '../../components/App/Stores/UserTermsStore';
import { Utils } from '../../components/App/Utils';
import LoginButton from '../../components/Login/LoginButton';
import '../../stylesheets/main.css';
import ErrorBoundary from '../Routes/ErrorBoundary';
import CookieDialog from '../../components/Login/CookieDialog';
import AppStore from '../../components/App/Stores/AppStore';

interface Props {
  authStore?: AuthStore;
  userTermsStore?: UserTermsStore;
}

interface State {
  thirdPartyEnabled?: boolean;
  cookieIsEnabled?: boolean;
}

@inject('authStore')
@inject('dialogStore')
@inject('userTermsStore')
  
export class Login extends Component<Props, State> {
  private _storageHelper: StorageHelper = new StorageHelper();
  

  constructor(props: Props) {
    super(props);
    this.state = { thirdPartyEnabled: false };
    this.state = { cookieIsEnabled: true };
    
    if (window.location.href.indexOf('#error=server_error') !== -1) {
      let errorMessage = Utils.getQueryString(QUERYSTRING.B2C_ERROR_DESCRIPTION);
      window.telemetry.logException(errorMessage, TelemetryExceptionType.AdError);
      if (!this._storageHelper.getKeyValue(STORAGE.B2C_ERROR)) {
        this._storageHelper.storeKeyValue(STORAGE.B2C_ERROR, errorMessage);
        // Compulsory to stop the looping
        this.props.authStore!.logOut();
      }
      // If just performed a forgotten password, the automatic login will fail, so need to logout
      // before it hits the site
    } else if (this.props.authStore!.getUserPolicy() === window.appSettings.passwordResetPolicyName) 
    {
      this.props.authStore!.logOut();
      this._storageHelper.clearValue(STORAGE.ALL_FARM_IDS);
      this._storageHelper.clearValue(STORAGE.SELECTED_FARM_ID);
      this._storageHelper.clearValue(STORAGE.USER_ACCESS_TOKEN);
      this._storageHelper.clearValue(STORAGE.USERNAME);
      this._storageHelper.clearValue(STORAGE.BANNER_CLOSED);
    }
  }

  handleClose = () => {
    window.appDialogStore!.isDialogOpen = false;
  }

  async componentDidMount() {
   
    await this.initialiseAuthentication();
    
  }

  render() {
    //check 3rd party cookie
    var isThirdPartyCookieEnabled = true;
    let iphoneIpadAppleDevice = false;
    if (/^iP|^ip/.test(navigator.platform)) {
        iphoneIpadAppleDevice = true;
    } 

    let ThirdPartyEnabled = this._storageHelper.getKeyValue(STORAGE.THIRDPARTYCOOKIESENABLED);
    isThirdPartyCookieEnabled = ThirdPartyEnabled!=="false";

    if ((!isThirdPartyCookieEnabled) && !iphoneIpadAppleDevice && this.props.authStore!.IsAuthenticated()) {
      return (
           <div className="c-whats-new-primary__container" id="c-whats-new-primary__container">
          <CookieDialog />
          </div>
      );
    } else {
      if (this.props.authStore!.IsAuthenticated()) {
        this.props.userTermsStore!.getUserTerms();
        return <Redirect to="/app" />;
      } else if (this._storageHelper.getKeyValue(STORAGE.B2C_ERROR)) {
        let errorMessage = this._storageHelper.getKeyValue(STORAGE.B2C_ERROR)!;
        return <ErrorBoundary message={errorMessage} />;
      } else {
        return (
          <div className="p-login__login-page">
            <div className="p-login__login-container">
              <header className="p-login__login-header">
                <img src="/images/logo.png" className="app-logo" alt="logo" />
              </header>
              <div className="p-login__b2c-button-container">
                <LoginButton authStore={this.props.authStore}/>
              </div>
              <div className="p-login__links-container">
                <a
                  href={URL.OUR_COMPANY}
                  target="_blank"
                  className="p-login__about-sff-link p-login__link" rel="noreferrer"
                >
                  About Silver Fern Farms
              </a>
                <a
                  href={URL.CONTACT_US}
                  target="_blank"
                  className="p-login__contact-us-link p-login__link" rel="noreferrer"
                >
                  Contact Us
              </a>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  private initialiseAuthentication = async (): Promise<boolean> => {
    try {
      this.props.authStore!.init(window.appSettings.policyName);
      if (this.props.authStore!.IsAuthenticated()) {
        return true;
      }
    } catch (error) {
      const errorMessage = 'Error initiating authentication. ' + JSON.stringify(error);
      window.telemetry.logException(errorMessage, TelemetryExceptionType.AdError);

      if (error.status === ERROR_CODES.UNAUTHORISED) {
        this.props.authStore!.logOut();
      }
      return false;
    }
    return true;
  }
}

export default Login;