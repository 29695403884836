import React, { Component } from 'react';
import * as CONSTANTS from '../App/Constants/PowerBIDashboard';
import * as pbi from 'powerbi-client';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import {
    withStyles,
    Tabs,
    Tab,
    createStyles,
    WithStyles,
    Fade
} from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';
import TileEmbedDetail from '../App/Models/TileEmbedDetail';
import SwipeableViews from 'react-swipeable-views';
import { FADE_DELAY } from '../App/Constants/Transition';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PowerBIDashboardStore from '../App/Stores/PowerBIDashboardStore';
import TabContent from './TabContent';

const styles = (theme: any) =>
  createStyles({
  root: {
      flexGrow: 1,
      marginTop: '60px',
      width: '100%',
      backgroundColor: theme.palette.background.paper
  },
  appBar: {
      color: 'inherit',
      backgroundColor: theme.palette.background.paper
      },
  });

interface Props extends WithStyles<typeof styles> {
    reports: TileEmbedDetail[];
    theme?: any;
    supplierId: string;
  }

interface State {
  tabList: ITab[];
  value: number;
  reports: TileEmbedDetail[];
}

interface ITab { 
  title: string;
  key: number;
  id: number;
}

class PowerBiTabs extends React.Component<Props, State> {
    public powerbi: pbi.service.Service;
    public EmbedContainer: HTMLDivElement;


    constructor(props: Props) {
        super(props);
        this.state = { value: 0, tabList: [{ title: DEFAULT_DATA.STRING, key: DEFAULT_DATA.NUMBER, id: DEFAULT_DATA.NUMBER}], reports: this.props.reports };
        this.powerbi = new pbi.service.Service(
            pbi.factories.hpmFactory,
            pbi.factories.wpmpFactory,
            pbi.factories.routerFactory
        );
       
    }
    tabTitle = (reportNames: TileEmbedDetail[], tabIndex: number) => {
      if (reportNames !== undefined) {
        reportNames.forEach((tile, index) => {
            this.generateTab(tile.name, reportNames.length);       
        });
      }
    }

    generateTab = (tabTitle: string, arrLen: number) => {
            this.setState((state, props) => {
            let tabList = cloneDeep(state.tabList);
            let id = tabList[tabList.length-1].id + 1;
            tabList.push({
                title: tabTitle,
                key: id,
                id: id
            });
              if (tabList.length > arrLen) { 
                 tabList.splice(0, 1);
              }
            return {
                tabList
            };
        });
    }

    handleTabChange = (event: any, value: any) => {
        this.setState({ value });

    }

    handleChangeIndex = (index: number) => {
        this.setState({ value: index });
    }

    public render() {
        const { theme } = this.props;
        const { value } = this.state;
 
        return (
            <Fade in={true} timeout={FADE_DELAY}>
                <div className="c-my-powerbi-report__container">
            <Tabs
            value={value}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="c-my-powerbi-report__tabs"
          >
                        {this.state.tabList.map((tab) => (
                            <Tab
                                key={tab.key - 1}
                                value={tab.id - 1}
                                label={tab.title}
                                className="c-my-powerbi-report__tab"
                                disableRipple={true}
                            />
                        ))
                        }
            </Tabs>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
              containerStyle={{ height: '100%' }}
              style={{ height: '100%' }}
            >
                {this.state.tabList.map((tab) => (
                <div className="c-power-bi__Contents" key={tab.key}>
                  <TabContent reports={this.props.reports} tabId={tab.id} tabName={tab.key.toString()}/>
                </div>
              ))
              }
            </SwipeableViews>
                    </div>
        </Fade>
        );
    }

    componentDidMount() {
        if (this.state.reports != null) { 
          this.tabTitle(this.state.reports, 1);  
        }     
    }

    componentDidUpdate() {     
      if (this.state.reports !== this.props.reports) {
        this.setState({
          reports: this.props.reports,
          tabList: [{ title: DEFAULT_DATA.STRING, key: DEFAULT_DATA.NUMBER, id: DEFAULT_DATA.NUMBER}]  
        });    
        this.tabTitle(this.props.reports, 1);       
      }
  }
}
export default withStyles(styles, { withTheme: true })(PowerBiTabs);