import Forecast from '../Models/Forecast';
import ForecastService from '../Services/ForecastService';
import { observable, action } from 'mobx';
import StockHelper from '../Helpers/StockHelper';

class ForecastStore {
  @observable public forecasts: Array<Forecast>;
  @observable public isForecastsInitialLoad: boolean;
  @observable public isForecastsLoaded: boolean;
  @observable public isForecastsLoadError: boolean;
  @observable public isForecastsPageDisplayed: boolean;

  private _forecastService: ForecastService = new ForecastService();
  private _stockHelper: StockHelper = new StockHelper();

  constructor() {
    this.forecasts = new Array<Forecast>();
    this.isForecastsInitialLoad = true;
    this.isForecastsLoaded = false;
    this.isForecastsLoadError = false;
    this.isForecastsPageDisplayed = false;
  }

  @action
  getForecasts = (farmId: string) => {
    this.isForecastsLoaded = false;
    this.isForecastsLoadError = false;

    return this._forecastService.getForecasts(farmId)
      .then(response => {
        this.forecasts = new Array<Forecast>();
        if (response!.data) {
          let _forecasts = response!.data.forecasts;
          let _forecastsResult = new Array<Forecast>();
          _forecasts.map((forecast: Forecast) => {
            _forecastsResult.push(Object.setPrototypeOf(forecast, Forecast.prototype));
          });
          this.forecasts = this._stockHelper.sortForecasts(_forecastsResult);
        }
        this.isForecastsInitialLoad = false;
        this.isForecastsLoaded = true;
      }).catch(() => {
        this.isForecastsLoadError = true;
        this.isForecastsLoaded = true;
      });
  }

  @action
  setForecastsPageDisplayed = (forecastsPageDisplayed: boolean = !this.isForecastsPageDisplayed) => {
    this.isForecastsPageDisplayed = forecastsPageDisplayed;
  }
}

export default ForecastStore;
