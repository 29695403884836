import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as MENU from '../App/Constants/Menu';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import StockHelper from '../App/Helpers/StockHelper';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import ForecastStore from '../App/Stores/ForecastStore';
import MenuStore from '../App/Stores/MenuStore';
import Tile from './Tile';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  menuStore: MenuStore;
  loadData: Function;
  initialStatus?: TileStatus;
  forecastStore: ForecastStore;
}

const _stockHelper: StockHelper = new StockHelper();
const _propertyHelper: PropertyHelper = new PropertyHelper();

@observer
class ForecastTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
    let { dashboardStore, title, initialStatus } = this.props;

    if (!_.isUndefined(initialStatus)) {
      dashboardStore.setTileStatus(title, initialStatus);
    }
  }

  handleMyStockClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_FORECAST);
    this.props.menuStore!.setSelectedIndex(MENU.MY_STOCK_INDEX);
    this.props.forecastStore!.setForecastsPageDisplayed(true);
  }

  render() {
    const { dashboardStore } = this.props;
    const speciesName = _.lowerCase(dashboardStore!.forecastTileIcon);
    const speciesIcon = _stockHelper.getSpeciesIconForBooking(speciesName);
    const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

    if (dashboardStore!.forecastsStatus === TileStatus.LOADED) {
      return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleMyStockClick}>
          <Paper className="c-dashboard__tile">
            <div className="c-dashboard__tile-forecast-view-all">VIEW ALL</div>
            <div className="c-dashboard__tile-forecast">
              <div className="c-dashboard__tile-header-1">FORECAST</div>
              <div className="c-dashboard__tile-forecast-info">
                <span className="c-dashboard__tile-forecast-icon-container">
                  {speciesIcon ? (
                    <img src={speciesIcon.iconUrl} alt={speciesIcon.name} className="c-dashboard__tile-forecast-icon" />
                  ) : (
                      DEFAULT_DATA.STRING
                    )}
                </span>
                <span className="c-dashboard__tile-forecast-next">
                  <span className="c-dashboard__tile-forecast-next-text">Next Forecast</span>
                  <span className={classNames('c-dashboard__tile-forecast-next-number', speciesName)}>{_.upperCase(_propertyHelper.formatValue(dashboardStore!.forecastTileDate, VALUE_TYPE.DATE, DATE_FORMAT.DAY_AND_MONTH_ONLY))}</span>
                </span>
                <span className="c-dashboard__tile-forecast-quantity">
                  <span className="c-dashboard__tile-forecast-quantity-text">Quantity</span>
                  <span className={classNames('c-dashboard__tile-forecast-quantity-number', speciesName)}>{dashboardStore!.forecastTileQuantity}</span>
                </span>
                <span className="c-dashboard__tile-forecast-programme">
                  <span className="c-dashboard__tile-forecast-programme-text">Programme</span>

                  <span className={classNames('c-dashboard__tile-forecast-programme-number', speciesName)}>
                    {dashboardStore!.bookingsCompleted && dashboardStore!.forecastsCompleted && (
                      <ResponsiveEllipsis
                        className={classNames('c-dashboard__tile-forecast-programme-number-ellipsis', 'c-dashboard__tile-forecast-programme-name')}
                        text={dashboardStore!.forecastProgramme}
                        maxLine="1"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    )}
                  </span>
                </span>
              </div>
            </div>
          </Paper>
        </a>
      );
    } else {
      return super.render();
    }
  }
}

export default ForecastTile;