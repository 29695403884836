import { observable, action } from 'mobx';
import ShareholderService from '../Services/ShareholderService';
import ShareholderDetail from '../Models/ShareholderDetail';
import ShareholderDetails from '../Models/ShareholderDetails';
import PatronageEligibility from '../Models/PatronageEligibility';

class ShareholderStore {
  @observable public shareholderDetail: ShareholderDetail;
  @observable public isShareholderDetailLoaded: boolean;
  @observable public isShareholderDetailLoadError: boolean;

  private _shareholderService: ShareholderService = new ShareholderService();

  @action
  getShareholderDetails = (farmId: string) => {
    this.isShareholderDetailLoaded = false;
    this.isShareholderDetailLoadError = false;

    return this._shareholderService.getShareholderDetails(farmId)
      .then(response => {
        const body = response!.data as ShareholderDetails;
        // copy plain object keys into an instance of PatronageEligibility
        // so that we can access function members of the class
        let currentPatronageEligibility = new PatronageEligibility();
        if (body.shareholderDetails.currentPatronageEligibility) {
          currentPatronageEligibility = Object.assign(currentPatronageEligibility, body.shareholderDetails.currentPatronageEligibility);
        }
        let rolling12PatronageEligibility = new PatronageEligibility();
        if (body.shareholderDetails.rolling12PatronageEligibility) {
          rolling12PatronageEligibility = Object.assign(rolling12PatronageEligibility, body.shareholderDetails.rolling12PatronageEligibility);
        }

        this.shareholderDetail = {
          currentPatronageEligibility: currentPatronageEligibility,
          supplierId: body.shareholderDetails.supplierId,
          historicalPatronageEligibility: body.shareholderDetails.historicalPatronageEligibility,
          currentShareList: body.shareholderDetails.currentShareList,
          rolling12PatronageEligibility: rolling12PatronageEligibility
        };
        this.isShareholderDetailLoaded = true;
      })
      .catch(error => {
        this.isShareholderDetailLoaded = true;
        this.isShareholderDetailLoadError = true;
      });
  }
}

export default ShareholderStore;
