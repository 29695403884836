import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
    Button, Paper
  } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import * as SHARES from "../App/Constants/Shares";
import * as VALUE_TYPE from "../App/Constants/ValueType";
import PropertyHelper from "../App/Helpers/PropertyHelper";
import SharePriceHelper from "../App/Helpers/SharePriceHelper";
import DashboardStore from "../App/Stores/DashboardStore";
import { TileStatus } from "../App/Constants/TileStatus";
import * as DEFAULT_DATA from "../App/Constants/DefaultData";
import * as DATE_FORMAT from "../App/Constants/DateFormat";
import AuthorizationStore from "../App/Stores/AuthorizationStore";
import MenuStore from "../App/Stores/MenuStore";
import Tile from "../Dashboard/Tile";
import { observer } from "mobx-react";
import PowerBIDashboardStore from "../App/Stores/PowerBIDashboardStore";
import * as TILE_TITLES from '../App/Constants/TileTitles';

import { observable } from "mobx";
import Loading from "../Loading/Loading";
import LoadError from "../LoadError/LoadError";
  
const styles = (theme: Theme) =>
    createStyles({
      patronageButton: {
        color: "#FFF",
        backgroundColor: "rgb(65, 122, 93)",
        fontFamily: "Graphik-Semibold",
        fontWeight: "bold",
        letterSpacing: "0.075em",
        height: "2.6em",
        fontSize: "1em",
        width: "25em",
        textAlign: "center",
        position: "relative",
      },
    });
  
interface Props extends WithStyles<typeof styles> {
    farmName: string;
    dashboardStore: DashboardStore;
    authorizationStore: AuthorizationStore;
    title: string;
    menuStore: MenuStore;
    loadData: Function;
    initialStatus?: TileStatus;
    indexChange: (index: number) => void;
  }  
@observer
class SharePriceHistory extends React.Component<Props> {
  @observable public store: PowerBIDashboardStore;
    
  constructor(props: Props) {
    super(props);
    this.store = new PowerBIDashboardStore();
  }

  componentDidMount() {
    this.store.service.historyIsLoaded = false;
    this.store.getEmbeddedShareHistoryReport();
  }

  componentDidUpdate() {
    this.store.getEmbeddedShareHistoryReport();
  }
  
  handleButtonClick() {
    this.store.service.historyHasError = false;
    this.store.service.historyIsLoaded = false;
    this.store.getEmbeddedShareHistoryReport();
  }
  
  render() {
    if (this.store.service.historyHasError && this.store.service.historyIsLoaded) {
      return (
        <div className="c-share-price-card__alternative-container">
          <Paper className="c-dashboard__tile error">
            <div>
              <LoadError onPress={() => { this.handleButtonClick(); }} />
            </div>
          </Paper>
        </div>
      );
    } else {
      return (
        <div className="c-power-bi__container">
          <div className="c-power-bi__inner-container c-power-bi__width-adjustment">
            <div className="c-power-bi__history-container">
              <div className="c-power-bi__history-container-title">SHARE PRICE HISTORY</div>
              <div className="c-power-bi__embed-container" ref={(div) => { if (div) { this.store.historyEmbedContainer = div; } }} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(styles)(SharePriceHistory);