import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

class AnalyticsIcon extends React.Component {
  render() {
    return (
      <SvgIcon width="75" height="75" viewBox="0 0 35 20">
        <path fill="currentColor" d="M0 0h24v24H0z" />
        <path d="M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z" fill="white" />
      </SvgIcon>
    );
  }
}

export default AnalyticsIcon;