import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Render } from '../Routes/Render';
import AppStore from '../../components/App/Stores/AppStore';
import RouteStore from '../../components/App/Stores/RouteStore';

interface Props {
  history?: any;
  routeStore?: RouteStore;
}

@inject('routeStore')
@observer
export class AuthenticatedLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.routeStore!.setRoute(this.props.history);
  }

  render() {
    return (
      <div className="row content-wrapper">
        <div className="col-12">
          <Render styleName="" />
        </div>
      </div>
    );
  }
}

export default AuthenticatedLayout;
