import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import KillSheet from '../App/Models/KillSheet';
import FinalKillSheetSummary from '../FinalKillSheetSummary/FinalKillSheetSummary';
import InterimKillSheetSummary from '../InterimKillSheetSummary/InterimKillSheetSummary';
import TimelineYear from './TimelineYear';

interface Props {
  isFarmDetailsDisplayed: boolean;
  isTimelineYearDisplayed: boolean;
  killSheet: KillSheet;
}

@observer
class TimelineItem extends React.Component<Props> {
  render() {
    const { isFarmDetailsDisplayed, isTimelineYearDisplayed, killSheet } = this.props;

    const year = killSheet.processedDate
      ? moment(killSheet.processedDate).format(DATE_FORMAT.YEAR_ONLY)
      : DEFAULT_DATA.STRING;

    return (
      <>
        <TimelineYear
          year={year}
          isDisplayed={isTimelineYearDisplayed}
        />
        {killSheet.status === KILLSHEET.INTERIM_TYPE ? (
          <InterimKillSheetSummary
            isFarmDetailsDisplayed={isFarmDetailsDisplayed}
            killSheet={killSheet}
            key={killSheet.killsheetNumber}
          />
        ) : (
            <FinalKillSheetSummary
              isFarmDetailsDisplayed={isFarmDetailsDisplayed}
              killSheet={killSheet}
              key={killSheet.killsheetNumber}
            />
          )}
      </>
    );
  }
}

export default TimelineItem;
