export const B2C_ERROR = 'sff.b2cError';
export const MSAL_IDTOKEN = 'msal.idtoken';
export const SELECTED_FARM_ID = 'sff.selectedFarmId';
export const CURRENT_USER_ROLE = 'sff.currentUserRole';
export const USER_ACCESS_TOKEN = 'sff.userAccessToken';
export const USERNAME = 'sff.userName';
export const APP_VERSION = 'sff.appVersion';
export const BANNER_CLOSED = 'sff.bannerClosed';
export const MOBILE_APP_REQUIRED_DIALOG = 'sff.showMobilAppRequiredDialog';
export const ALL_FARM_IDS = 'sff.allFarmsIds';
export const THIRDPARTYCOOKIESENABLED = 'sff.thridPartyCookiesEnabled';
