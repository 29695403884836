import { Paper } from '@material-ui/core';
import React from 'react';
import { TileStatus } from '../App/Constants/TileStatus';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import Tile from './Tile';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as MENU from '../App/Constants/Menu';
import MenuStore from '../App/Stores/MenuStore';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  loadData: Function;
  initialStatus?: TileStatus;
  menuStore: MenuStore;
}

class AnalyticsTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleAnalyticsClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_ANALYTICS);
    this.props.menuStore!.setSelectedIndex(MENU.ANALYTICS_INDEX);
  }

  render() {

    const { title } = this.props;
    return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleAnalyticsClick}>
        <Paper className="c-dashboard__tile">
        <div className="c-dashboard__tile-analytics">
            <div className="c-dashboard__tile-header-1-inline">ANALYTICS</div>
            <div className="c-dashboard__tile-analytics-view-all">VIEW ALL</div>
              <img className="c-dashboard__tile-analytics-img" src={'/images/dashboard_analytics.svg'} /> 
          </div>
        </Paper>
      </a>
      );
  }
}

export default AnalyticsTile;