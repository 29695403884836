import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PaymentHistoryDetailLine from '../App/Models/PaymentHistoryDetailLine';
import PaymentHistoryPaymentLine_Header from './PaymentHistoryPaymentLine_Header';
import PaymentHistoryPaymentLine_Item from './PaymentHistoryPaymentLine_Item';

interface Props {
  paymentHistoryPaymentLines?: Array<PaymentHistoryDetailLine>;
}

@observer
class PaymentHistoryPayments extends Component<Props> {

  render() {
    let { paymentHistoryPaymentLines } = this.props;
    let paymentLinesExist = _.filter(paymentHistoryPaymentLines, ['isTotal', true]);
    if (!paymentHistoryPaymentLines || paymentLinesExist.length <= 1) {
      return (<div />);
    }
    return (
      <>
        <div className="c-paymentHistoryDetailLine__div">
          {
            paymentHistoryPaymentLines!.map(detailRow => {
              return (
                <PaymentHistoryPaymentLine_Item key={`${detailRow.killsheetNumber}-${detailRow.referenceNumber}`} detailLine={detailRow} />
              );
            })
          }
          <PaymentHistoryPaymentLine_Header />
        </div>
      </>
    );
  }
}

export default PaymentHistoryPayments;