import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import * as LEGALTERMS from '../App/Constants/LegalTerms';
import UserTermsStore from '../App/Stores/UserTermsStore';
import LoadError from '../LoadError/LoadError';
import Loading from '../Loading/Loading';
import TermsPdf from '../Terms/TermsPdf';

interface Props {
  userTermsStore?: UserTermsStore;
}

@inject('userTermsStore')
@observer
class LegalTerms extends Component<Props> {

  private scalingFactor: number;

  render() {

    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    if (mediaQuery.matches) {
      this.scalingFactor = LEGALTERMS.SCALING.DESKTOP;
    } else {
      this.scalingFactor = LEGALTERMS.SCALING.TABLET;
    }

    if (this.props.userTermsStore!.isTermsLoaded) {
      return (
        <div className="c-legal-terms__container">
          <div className="c-legal-terms__header">{LEGALTERMS.HEADER}</div>
          <div className="c-legal-terms__textarea">
            <TermsPdf terms={this.props.userTermsStore!.terms} scalingFactor={this.scalingFactor} />
          </div>
        </div>
      );
    } else if (this.props.userTermsStore!.isTermsLoadError) {
      return <LoadError onPress={() => { this.props.userTermsStore!.getLegalTerms(); }} />;
    } else {
      this.props.userTermsStore!.getLegalTerms();
      return <Loading />;
    }
  }
}

export default LegalTerms;