class Schedule {  
  public title: string;
  public bodyText: number;
  public attachment: string;
  public url?: string;
  public base64Data?: string;
  blobData: Blob;
  public validFromDatetime?: Date;
}

export default Schedule;