import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class AuthorizationService extends HttpService {
  getUserAssignment = async () => {
    const getUserAssignmentApiUrl = `${CONNECTION.AUTH}${CONNECTION.API_VERSION}${CONNECTION.GET_USER_ASSIGNMENT}`;

    return super.get(getUserAssignmentApiUrl);
  }

}

export default AuthorizationService;
