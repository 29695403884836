import { Grid } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import { autorun, IReactionDisposer } from "mobx";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { PAGE_NAME } from "../../components/App/Constants/Application";
import * as DEFAULT_DATA from "../../components/App/Constants/DefaultData";
import { TelemetryEventType } from "../../components/App/Constants/Telemetry";
import { TileStatus } from "../../components/App/Constants/TileStatus";
import * as TILE_TITLES from "../../components/App/Constants/TileTitles";
import AccountStore from "../../components/App/Stores/AccountStore";
import DashboardStore from "../../components/App/Stores/DashboardStore";
import AuthorizationStore from "../../components/App/Stores/AuthorizationStore";
import ForecastStore from "../../components/App/Stores/ForecastStore";
import MenuStore from "../../components/App/Stores/MenuStore";
import AnalyticsTile from "../../components/Dashboard/AnalyticsTile";
import BookingTile from "../../components/Dashboard/BookingTile";
import FarmAssuranceTile from "../../components/Dashboard/FarmAssuranceTile";
import ForecastTile from "../../components/Dashboard/ForecastTile";
import KillSheetTile from "../../components/Dashboard/KillSheetTile";
import ScheduleTile from "../../components/Dashboard/ScheduleTile";
import SharePriceTile from "../../components/Dashboard/SharePriceTile";
import StreamTile from "../../components/Dashboard/StreamTile";
import Tile from "../../components/Dashboard/Tile";
import AuthStore from "../../components/App/Stores/AuthStore";
import UserTermsStore from "../../components/App/Stores/UserTermsStore";
import { Redirect } from "react-router-dom";
import StorageHelper from "../../components/App/Helpers/StorageHelper";
import * as STORAGE from '../../components/App/Constants/Storage';

interface Props {
  authorizationStore?: AuthorizationStore;
  dashboardStore?: DashboardStore;
  menuStore?: MenuStore;
  forecastStore?: ForecastStore;
  accountStore?: AccountStore;
  authStore?: AuthStore;
  userTermsStore?: UserTermsStore;
}

@inject("authorizationStore")
@inject("dashboardStore")
@inject("menuStore")
@inject("forecastStore")
@inject("accountStore")
@inject("authStore")
@inject("userTermsStore")
@observer
class Dashboard extends Component<Props> {
  private disposer: IReactionDisposer;
  private _storageHelper: StorageHelper = new StorageHelper();

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      const { dashboardStore, authorizationStore, authStore, userTermsStore } = this.props;
      if (authorizationStore!.selectedFarmId) {
        dashboardStore!.refreshDashboard(authorizationStore!.selectedFarm!.data!.island, true);
      }
      
      /* Check user team access only, if true, set mobile app required dialog true */
      const teamAccess = this.props.authorizationStore!.teamAccessOnly;
      if (teamAccess) {
        this._storageHelper.storeKeyValue(STORAGE.MOBILE_APP_REQUIRED_DIALOG, 'true');
        window.location.reload();
      }

      /* Check user accept terms after successful login, if not accepted redirect to user terms dialogue */
      if (authStore!.IsAuthenticated()) {
        if (!this.props.userTermsStore!.userTerms) {
          userTermsStore!.getUserTerms();
          return <Redirect to="/" />;
        }
      }
    });
  }

  componentDidMount = () => {
    window.telemetry.logEvent(PAGE_NAME.DASHBOARD, TelemetryEventType.Page);
  };

  render() {
    const {
      dashboardStore,
      menuStore,
      authorizationStore,
      forecastStore,
      accountStore,
    } = this.props;
    let certificateCount = dashboardStore!.farmAssuranceCertificates.length;

    return (
      <div className="p-dashboard__container">
        <div className="p-dashboard__tiles">
          <h1 className="p-dashboard__header">DASHBOARD</h1>
          <p className="p-dashboard__sub-header">
            {authorizationStore!.selectedFarm && authorizationStore!.selectedFarm!.name}
            <span>{authorizationStore!.selectedFarm && authorizationStore!.selectedFarmId}</span>
          </p>
          <Grid
            container={true}
            spacing={3}
            // direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            className="p-dashboard__grid"
          >
            <Grid
              item={true}
              sm={5}
              md={6}
              lg={7}
              className={classNames(
                "p-dashboard__scroll-container",
                "p-dashboard__grid-mdlg-tiles"
              )}
            >
              <Grid
                container={true}
                spacing={3}
                // direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item={true} md={12} lg={6}>
                  <KillSheetTile
                    dashboardStore={dashboardStore!}
                    menuStore={menuStore!}
                    authorizationStore={authorizationStore!}
                    title={TILE_TITLES.KILLSHEET}
                    loadData={() => dashboardStore!.getKillSheetTileData()}
                  />
                </Grid>
                <Grid item={true} md={12} lg={6}>
                  <ScheduleTile
                    dashboardStore={dashboardStore!}
                    menuStore={menuStore!}
                    authorizationStore={authorizationStore!}
                    title={TILE_TITLES.SCHEDULE}
                    loadData={() =>
                      dashboardStore!.getScheduleTileData(
                        authorizationStore!.selectedFarm!.data!.island
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container={true}
                spacing={3}
                // direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid
                  item={true}
                  hidden={
                    dashboardStore!.bookingsCompleted &&
                    dashboardStore!.forecastsCompleted
                  }
                >
                  <Tile
                    dashboardStore={dashboardStore!}
                    authorizationStore={authorizationStore!}
                    title={TILE_TITLES.BOOKING_FORECAST_LOADING}
                    loadData={() => null}
                  />
                </Grid>
                <Grid
                  item={true}
                  md={12}
                  lg={!_.isEmpty(dashboardStore!.forecastProgramme) ? 6 : 12}
                  hidden={
                    !dashboardStore!.bookingsCompleted ||
                    !dashboardStore!.forecastsCompleted
                  }
                >
                  <BookingTile
                    dashboardStore={dashboardStore!}
                    menuStore={menuStore!}
                    authorizationStore={authorizationStore!}
                    forecastStore={forecastStore!}
                    title={TILE_TITLES.BOOKING}
                    loadData={() => dashboardStore!.getBookingTileData()}
                  />
                </Grid>
                <Grid
                  item={true}
                  md={12}
                  lg={6}
                  hidden={
                    !dashboardStore!.bookingsCompleted ||
                    !dashboardStore!.forecastsCompleted ||
                    _.isEmpty(dashboardStore!.forecastProgramme)
                  }
                >
                  <ForecastTile
                    dashboardStore={dashboardStore!}
                    menuStore={menuStore!}
                    authorizationStore={authorizationStore!}
                    forecastStore={forecastStore!}
                    title={TILE_TITLES.FORECAST}
                    loadData={() => dashboardStore!.getForecastTileData()}
                  />
                </Grid>
              </Grid>
              <Grid
                container={true}
                spacing={3}
                // direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid
                  item={true}
                  hidden={dashboardStore!.farmAssuranceCompleted}
                >
                  <Tile
                    dashboardStore={dashboardStore!}
                    authorizationStore={authorizationStore!}
                    title={TILE_TITLES.FARM_ASSURED_LOADING}
                    loadData={() => null}
                  />
                </Grid>
                {dashboardStore!.farmAssuranceCertificates.length > 0 ? (
                  dashboardStore!.farmAssuranceCertificates.map(
                    (certificate, index) => (
                      <Grid
                        key={index}
                        item={true}
                        md={12}
                        lg={6}
                        xl={
                          certificateCount === 1
                            ? 6
                            : certificateCount === 2
                            ? 4
                            : certificateCount === 3
                            ? 4
                            : 6
                        }
                        hidden={!dashboardStore!.farmAssuranceCompleted}
                      >
                        <FarmAssuranceTile
                          dashboardStore={dashboardStore!}
                          authorizationStore={authorizationStore!}
                          menuStore={menuStore!}
                          accountStore={accountStore!}
                          title={`${TILE_TITLES.FARM_ASSURED}-${_.lowerCase(
                            certificate.speciesName
                          )}`}
                          loadData={() =>
                            dashboardStore!.getFarmAssuranceTileData()
                          }
                          speciesName={_.lowerCase(certificate.speciesName)}
                          assuranceStatus={
                            certificate.farmAssuranceCertificationStatus
                          }
                          singleTile={
                            dashboardStore!.farmAssuranceCertificates.length ===
                            1
                          }
                          expiryDate={certificate.expiryDate}
                        />
                      </Grid>
                    )
                  )
                ) : (
                  <Grid
                    item={true}
                    md={12}
                    lg={6}
                    xl={6}
                    hidden={
                      !dashboardStore!.farmAssuranceCompleted ||
                      dashboardStore!.getTileStatus(
                        TILE_TITLES.FARM_ASSURED
                      ) === TileStatus.LOADED_NO_DATA
                    }
                  >
                    <FarmAssuranceTile
                      dashboardStore={dashboardStore!}
                      authorizationStore={authorizationStore!}
                      menuStore={menuStore!}
                      accountStore={accountStore!}
                      title={TILE_TITLES.FARM_ASSURED}
                      loadData={() =>
                        dashboardStore!.getFarmAssuranceTileData()
                      }
                      speciesName={DEFAULT_DATA.STRING}
                      assuranceStatus={DEFAULT_DATA.STRING}
                      singleTile={true}
                      expiryDate={DEFAULT_DATA.UNDEFINED}
                    />
                  </Grid>
                )}
                <Grid
                  // direction="column"
                  item={true}
                  sm={
                    (certificateCount <= 1 || certificateCount === 3) &&
                    dashboardStore!.farmAssuranceCompleted &&
                    !_.isEmpty(dashboardStore!.farmAssuranceCertificates)
                      ? 12
                      : certificateCount > 1
                      ? 12
                      : 12
                  }
                  md={
                    (certificateCount <= 1 || certificateCount === 3) &&
                    dashboardStore!.farmAssuranceCompleted &&
                    !_.isEmpty(dashboardStore!.farmAssuranceCertificates)
                      ? 12
                      : certificateCount > 1
                      ? 12
                      : 12
                  }
                  lg={
                    (certificateCount <= 1 || certificateCount === 3) &&
                    dashboardStore!.farmAssuranceCompleted &&
                    !_.isEmpty(dashboardStore!.farmAssuranceCertificates)
                      ? 6
                      : certificateCount > 1
                      ? 6
                      : 6
                  }
                  xl={
                    certificateCount <= 1 &&
                    dashboardStore!.farmAssuranceCompleted &&
                    !_.isEmpty(dashboardStore!.farmAssuranceCertificates)
                      ? 6
                      : certificateCount === 2
                      ? 4
                      : certificateCount > 2
                      ? 6
                      : 6
                  }
                >
                  <SharePriceTile
                    dashboardStore={dashboardStore!}
                    authorizationStore={authorizationStore!}
                    menuStore={menuStore!}
                    title={TILE_TITLES.SHARE_PRICE}
                    loadData={() => dashboardStore!.getSharePriceTileData()}
                  />
                </Grid>
                <Grid item={true} sm={12} md={12} lg={6} xl={6}>
                  <AnalyticsTile
                    dashboardStore={dashboardStore!}
                    authorizationStore={authorizationStore!}
                    title={TILE_TITLES.ANALYTICS}
                    loadData={() => dashboardStore!.getAnalyticsTileData()}
                    initialStatus={TileStatus.COMING_SOON}
                    menuStore={menuStore!}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item={true}
              sm={7}
              md={6}
              lg={5}
              className="p-dashboard__grid-mdlg-streams"
            >
              <StreamTile
                dashboardStore={dashboardStore!}
                authorizationStore={authorizationStore!}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Dashboard;
