import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as DOCUMENT from '../Constants/Document';
import * as DOCUMENT_TYPE from '../Constants/DocumentType';
import * as EVENT from '../Constants/Events';
import * as MIME_TYPE from '../Constants/MimeType';

class DocumentHelper {
  public accessDocument = (blob: any, mimeType: string, action: string, supplier: string, killSheetNumber: string, apiFileName?: string) => {
    if (blob) {
      
      let fileName = this.getFileName(supplier, killSheetNumber, mimeType);

      if (apiFileName)
      {
        fileName = apiFileName;
      }

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // Create a blob object from response stream
        let _blobObject = new Blob(
          [blob],
          { type: mimeType });
        // Build a URL from the blob object
        let _objectUrl = URL.createObjectURL(_blobObject);
        switch (action) {
          case DOCUMENT.DOWNLOAD_ACTION:
            if (window.navigator.userAgent.match(/iPad/i)) {
              window.open(_objectUrl, EVENT.WINDOW_NEW_TAB);
              if (mimeType === MIME_TYPE.CSV) {
                var reader = new FileReader();
                reader.onload = function (e) {
                  const a = document.createElement(DOCUMENT.ANCHOR_ELEMENT);
                  a.style.display = DOCUMENT.DISPLAY_NONE;
                  document.body.appendChild(a);
                  a.href = reader.result!.toString();
                  a.setAttribute(DOCUMENT.DOWNLOAD_ATTRIBUTE, fileName);
                  a.click();
                  window.URL.revokeObjectURL(a.href);
                  document.body.removeChild(a);
                };
                reader.readAsDataURL(_blobObject);
              }
            } else {
              // Create an invisible A element
              const a = document.createElement(DOCUMENT.ANCHOR_ELEMENT);
              a.style.display = DOCUMENT.DISPLAY_NONE;
              document.body.appendChild(a);

              // Set the HREF to a Blob representation of the data to be downloaded
              a.href = _objectUrl;

              // Use download attribute to set set desired file name
              a.setAttribute(DOCUMENT.DOWNLOAD_ATTRIBUTE, fileName);

              // Trigger the download by simulating click
              a.click();

              // Cleanup
              window.URL.revokeObjectURL(a.href);
              document.body.removeChild(a);
            }
            break;
          case DOCUMENT.OPEN_IN_NEW_TAB_ACTION:
          default:
            window.open(_objectUrl, EVENT.WINDOW_NEW_TAB);
            break;
        }
      }
    }
    return DEFAULT_DATA.UNDEFINED;
  }

  private getFileName = (supplier: string, killSheetNumber: string, mimeType: string) => {
    const _baseFileName = `${supplier}_${killSheetNumber}_${Date.now() + Math.random()}`;

    switch (mimeType) {
      case MIME_TYPE.EXCEL:
        return `${_baseFileName}.${DOCUMENT_TYPE.XLSX}`;
      case MIME_TYPE.CSV:
          return `${_baseFileName}.${DOCUMENT_TYPE.CSV}`;
      case MIME_TYPE.PDF:
      default:
        return `${_baseFileName}.${DOCUMENT_TYPE.PDF}`;
    }
  }
}

export default DocumentHelper;
