import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as STOCK from '../App/Constants/Stock';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import StockHelper from '../App/Helpers/StockHelper';
import Booking from '../App/Models/Booking';
import Forecast from '../App/Models/Forecast';
import StockYear from './StockYear';

interface Props {
  isTimelineYearDisplayed: boolean;
  stock: Booking | Forecast;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();
const _stockHelper: StockHelper = new StockHelper();

class StockItem extends React.Component<Props> {
  render() {
    const { isTimelineYearDisplayed, stock } = this.props;
    let _conditionalAttribute, _conditionalAttributeLabel, _stockDate, _stockDateLabel, _additionalConditionalAttribute, _additionalConditionalAttributeLabel;

    if (!stock) {
      return;
    }

    if (stock instanceof Booking) {
      _stockDate = stock.arrivalDate;
      _stockDateLabel = STOCK.BOOKING_DATE_LABEL;
    } else if (stock instanceof Forecast) {
      _stockDate = stock.weekStartDate;
      _stockDateLabel = STOCK.FORECAST_DATE_LABEL;
    }

    const year = _stockDate
      ? moment(_stockDate).format(DATE_FORMAT.YEAR_ONLY)
      : DEFAULT_DATA.STRING;

    let columnWidth = 3;

    if (stock.contractNumber && stock.contractNumber.length > 1) {
      _conditionalAttribute = _propertyHelper.formatValue(stock.contractNumber, VALUE_TYPE.KILLSHEET_NUMBER);
      _conditionalAttributeLabel = STOCK.CONTRACT_NUMBER_LABEL;
    } else if (stock.specialProgramme && stock.specialProgramme.length > 1) {
      _conditionalAttribute = _propertyHelper.formatValue(stock.specialProgramme, VALUE_TYPE.STRING);
      _conditionalAttributeLabel = STOCK.SPECIAL_PROGRAMME_LABEL;
      columnWidth = 5;
    } else {
      _conditionalAttribute = DEFAULT_DATA.STRING;
      _conditionalAttributeLabel = DEFAULT_DATA.STRING;
    }

    if (columnWidth === 3 && stock.specialProgramme && stock.specialProgramme.length > 1) {
      _additionalConditionalAttribute = _propertyHelper.formatValue(stock.specialProgramme, VALUE_TYPE.STRING);
      _additionalConditionalAttributeLabel = STOCK.SPECIAL_PROGRAMME_LABEL;
    }

    const speciesName = _.lowerCase(stock.species);
    const speciesIcon = _stockHelper.getSpeciesIcon(stock.species);
    const contentContainerClass = `c-stocks__content-container c-stocks__right ${speciesName}`;
    const stocksContentClass = `c-stocks__content ${speciesName}`;

    return (
      <>
        <StockYear
          year={year}
          isDisplayed={isTimelineYearDisplayed}
        />
        <div className={contentContainerClass}>
          <div className="c-stocks__period">
            {_.upperCase(_propertyHelper.formatValue(_stockDate, VALUE_TYPE.DATE, DATE_FORMAT.DAY_AND_MONTH_ONLY))}
          </div>
          <div className={stocksContentClass}>
            <div className="c-stocks__type-detail">
              <div className="c-stocks__type-header c-stocks__dashed-bottom-border">
                <div className="c-stocks__species">
                  {_.upperCase(stock.animalCategory)}
                </div>
                <div className="c-stocks__type-container">
                  {stock.paymentType &&
                    <div className="c-stocks__payment-type">
                      {_.upperCase(stock.paymentType)}
                    </div>
                  }
                  <div className="c-stocks__species-icon">
                    {speciesIcon ? (
                      <img src={speciesIcon.iconUrl} alt={speciesIcon.name} />
                    ) : (
                        DEFAULT_DATA.STRING
                      )}
                  </div>
                </div>
              </div>
              <div className="c-stocks__attribute-container">
                <div className="c-stocks__attribute primary primary-attribute-column-1">
                  {_propertyHelper.formatValue(stock.quantity, VALUE_TYPE.NUMBER)}
                  <div className="c-stocks__attribute-subheading">Quantity</div>
                </div>
                <div className="c-stocks__attribute primary primary-attribute-column-2">
                  {_.upperCase(_propertyHelper.formatValue(_stockDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR))}
                  <div className="c-stocks__attribute-subheading">
                    {_stockDateLabel}
                  </div>
                </div>
                <div className={`c-stocks__attribute primary primary-attribute-column-${columnWidth}`}>
                  {_conditionalAttribute}
                  <div className="c-stocks__attribute-subheading">
                    {_conditionalAttributeLabel}
                  </div>
                </div>
                {columnWidth === 3 && stock.specialProgramme && stock.specialProgramme.length > 1 ? (
                  <div className="c-stocks__attribute primary primary-attribute-column-4">
                    {_additionalConditionalAttribute}
                    <div className="c-stocks__attribute-subheading">
                      {_additionalConditionalAttributeLabel}
                    </div>
                  </div>
                ) : (
                    DEFAULT_DATA.STRING
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StockItem;
