import _ from "lodash";
import React from "react";
import { observer } from "mobx-react";
import BannerStore from "../App/Stores/BannerStore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Close from "@material-ui/icons/Close";
import * as TELEMETRY from '../App/Constants/Telemetry';

interface Props {
  bannerStore: BannerStore;
}

@observer
class Banner extends React.Component<Props> {

  componentDidMount = () =>  {
    this.props.bannerStore!.getBanner();
  }

  seeMoreHandler = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.BANNNER_LINK_CLICK);
    if (this.props.bannerStore!.openInNewTab) {
      window.open(this.props.bannerStore!.link);
    } else {
      window.open(this.props.bannerStore!.link,"_self");
    }
  };

  render() {
    const { bannerStore } = this.props;

    return (
      (!bannerStore.userClosedBanner && bannerStore.bannerDataAvailable) && (
          <div className={"c-banner__container c-banner__background-" + this.props.bannerStore!.priority} onClick={this.seeMoreHandler}>
            <div className="c-banner__icon">
              <img
                src={"/images/error_outline.svg"}
              />
            </div>
            <div className="c-banner__message-container">
              <div className="c-banner__title"> {bannerStore!.title} </div>
              <div className="c-banner__description"> {bannerStore.messageText} </div>
            </div>
            <div className="c-banner__close"  onClick={(e) => bannerStore!.bannerClosedHandler(e)} >
              <Close className="c-banner__close-icon"/>
            </div>
          </div>
      )
    );
  }
}

export default (Banner);
