import { createStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import * as APPLICATION from '../../components/App/Constants/Application';
import * as STORAGE from '../../components/App/Constants/Storage';
import StorageHelper from '../../components/App/Helpers/StorageHelper';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import DialogStore from '../../components/App/Stores/DialogStore';
import CustomDialogBox from '../../components/CustomDialogBox/CustomDialogBox';
import Header from '../../components/Header/Header';
import LatestFeaturesDialog from '../../components/LatestFeaturesDialog/LatestFeaturesDialog';
import MainContainer from '../../components/MainContainer/MainContainer';
import Menu from '../../components/Menu/Menu';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      width: '100%',
      padding: 0,
      position: 'relative',
      overflow: 'hidden'
    }
  });

interface Props extends WithStyles<typeof styles> {
  authorizationStore?: AuthorizationStore;
  dialogStore?: DialogStore;
}

@inject('dialogStore')
@inject('authorizationStore')
@observer
class Home extends Component<Props> {

  private _storageHelper: StorageHelper = new StorageHelper();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount = () => {
    if (this._storageHelper.getKeyValue(STORAGE.APP_VERSION) !== window.appVersion) {
      window.appLatestFeaturesDialogStore!.setDialogOpen('What\'s New', '', undefined, true, 'Got it! Don\'t show again');
    }
    const appVersion = _.isEmpty(process.env.REACT_APP_VERSION)
    ? window.appVersion
    : process.env.REACT_APP_VERSION;
    
    this._storageHelper.storeKeyValue(STORAGE.APP_VERSION, appVersion);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className="p-home__page">
          <div className="p-home__container">
            <div className={classes.root}>
              <CssBaseline />
              <Menu />
              <div className={classes.content}>
                <Header
                  title={APPLICATION.NAME}
                  className={``}
                />
                <div id="main" className="p-home__content">
                  <MainContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDialogBox dialogStore={window.appDialogStore!} />
        <LatestFeaturesDialog dialogStore={window.appLatestFeaturesDialogStore!} />
      </>
    );
  }
}

export default withStyles(styles)(Home);
