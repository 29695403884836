import { Paper } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as MENU from '../App/Constants/Menu';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import MenuStore from '../App/Stores/MenuStore';
import Tile from './Tile';

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  menuStore: MenuStore;
  loadData: Function;
  initialStatus?: TileStatus;
}

@observer
class KillSheetTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
    let { dashboardStore, title, initialStatus } = this.props;

    if (!_.isUndefined(initialStatus)) {
      dashboardStore.setTileStatus(title, initialStatus);
    }
  }
  handleKillSheetClick = () => {
    this.props.menuStore!.setSelectedIndex(MENU.KILL_INFORMATION_INDEX);
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_KILLSHEET);
    this.props.menuStore!.setSelectedIndex(MENU.KILL_INFORMATION_INDEX);
  }

  render() {
    const { dashboardStore } = this.props;

    if (dashboardStore!.killsheetStatus === TileStatus.LOADED) {
      return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleKillSheetClick}>
          <Paper className="c-dashboard__tile c-dashboard__tile-killsheet-height">
            <div className="c-dashboard__tile-killsheet-view-all">VIEW ALL</div>
            <div className="c-dashboard__tile-killsheet">
              <div className="c-dashboard__tile-header-1">KILLSHEET</div>
              <div className="c-dashboard__tile-killsheet-info">
                <span className="c-dashboard__tile-killsheet-icon-container">
                  <LibraryBooksIcon className="c-dashboard__tile-killsheet-library_books-24px" />
                </span>
                <span className="c-dashboard__tile-killsheet-quantity">
                  <span className="c-dashboard__tile-killsheet-quantity">{dashboardStore!.killsheetCount}</span>
                </span>
                <span className="c-dashboard__tile-killsheet-data-text">
                  <span className="c-dashboard__tile-killsheet-text">Killsheets</span>
                  <span className="c-dashboard__tile-killsheet-text">Since {_propertyHelper.formatValue(dashboardStore!.killsheetSeasonStartDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}</span>
                </span>
              </div>
            </div>
          </Paper>
        </a>
      );
    } else {
      return super.render();
    }
  }
}

export default KillSheetTile;