import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Tab, Tabs, WithStyles, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import * as DEFAULT_DATA from '../../components/App/Constants/DefaultData';
import * as DOCUMENT from '../../components/App/Constants/Document';
import * as EVENT from '../../components/App/Constants/Events';
import * as MIME_TYPE from '../../components/App/Constants/MimeType';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import * as TRANSITION from '../../components/App/Constants/Transition';
import DocumentHelper from '../../components/App/Helpers/DocumentHelper';
import PaymentsHelper from '../../components/App/Helpers/PaymentsHelper';
import OutstandingInvoiceDetailLine from '../../components/App/Models/OutstandingInvoiceDetailLine';
import PaymentHistoryDetailLine from '../../components/App/Models/PaymentHistoryDetailLine';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import InvoiceStore from '../../components/App/Stores/InvoiceStore';
import PaymentsStore from '../../components/App/Stores/PaymentsStore';
import LoadError from '../../components/LoadError/LoadError';
import OutstandingInvoiceDetail from '../../components/Payments/OutstandingInvoiceDetail';
import OutstandingInvoiceSummary from '../../components/Payments/OutstandingInvoiceSummary';
import PaymentHistoryDateSelector from '../../components/Payments/PaymentHistoryDateSelector';
import PaymentHistoryDetail from '../../components/Payments/PaymentHistoryDetail';
import PaymentHistoryPayments from '../../components/Payments/PaymentHistoryPayments';
import PaymentHistorySummary from '../../components/Payments/PaymentHistorySummary';
import ProgressIndicator from '../../components/Progress/ProgressIndicator';
import PaymentHistoryExport from '../../components/Payments/PaymentHistoryExport';

const styles = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  }
});

interface Props extends WithStyles<typeof styles> {
  authorizationStore?: AuthorizationStore;
  paymentsStore?: PaymentsStore;
  invoiceStore?: InvoiceStore;
  theme: any;
}

interface State {
  isDisplayLoadingAnimation: boolean;
  value: number;
}

const documentHelper: DocumentHelper = new DocumentHelper();
const paymentsHelper: PaymentsHelper = new PaymentsHelper();

@inject('authorizationStore')
@inject('paymentsStore')
@inject('invoiceStore')
@observer
class Payments extends Component<Props, State> {
  private currentSelectedStartDate?: Date;
  private currentSelectedEndDate?: Date;

  private disposer: IReactionDisposer;
  private timeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      isDisplayLoadingAnimation: false
    };

    const { authorizationStore, invoiceStore, paymentsStore } = this.props;

    invoiceStore!.resetPaymentDocuments();
    paymentsStore!.resetPaymentDocuments();

    this.disposer = autorun(() => {
      paymentsStore!.getPaymentsByFarm(authorizationStore!.isAllFarmsSelected, authorizationStore!.selectedFarmId, this.currentSelectedStartDate, this.currentSelectedEndDate);
      invoiceStore!.getInvoicesByFarm(authorizationStore!.isAllFarmsSelected, authorizationStore!.selectedFarmId);
    });
  }

  componentDidMount = () => {
    window.telemetry.logEvent(PAGE_NAME.PAYMENTS, TelemetryEventType.Page);
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.PAYMENTS, TelemetryPageEventType.PageStart);
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  // https://github.com/mobxjs/mobx-react/issues/361
  // According to the discussion in the URL above, we need to find some sort of 
  // middle ground when using the @inject decorator directly on components versus 
  // passing props to child component
  openInvoice = (event: any, supplierId?: string, killSheetNumber?: number, referenceNumber?: string) => {
    const blob = this.props.invoiceStore!.downloadedInvoiceDocuments!.find(i => i.referenceNumber === referenceNumber);
    if (blob !== DEFAULT_DATA.UNDEFINED) {
      documentHelper.accessDocument(blob!.document, MIME_TYPE.PDF, DOCUMENT.OPEN_IN_NEW_TAB_ACTION, supplierId!, _.toString(killSheetNumber));
    }
  }

  openPayment = (event: any, supplierId?: string, killSheetNumber?: number, referenceNumber?: string) => {
    const blob = this.props.paymentsStore!.downloadedPaymentDocuments!.find(i => i.referenceNumber === referenceNumber);
    if (blob !== DEFAULT_DATA.UNDEFINED) {
      documentHelper.accessDocument(blob!.document, MIME_TYPE.PDF, DOCUMENT.OPEN_IN_NEW_TAB_ACTION, supplierId!, _.toString(killSheetNumber));
    }
  }

  downloadInvoiceDocument = async (lineItem: OutstandingInvoiceDetailLine) => {
    // Do not retrieve the documents if already retrieved
    if (this.props.invoiceStore!.downloadedInvoiceDocuments!.findIndex(i => i.referenceNumber === lineItem.referenceNumber) === -1) {
      this.props.invoiceStore!.downloadPaymentDocument(lineItem.supplierId, lineItem.killsheetNumber, lineItem.referenceNumber);
    }
  }

  downloadPaymentDocument = async (supplierId: string, lineItem: PaymentHistoryDetailLine) => {
    // Do not retrieve the documents if already retrieved
    if (this.props.paymentsStore!.downloadedPaymentDocuments!.findIndex(i => i.referenceNumber === lineItem.referenceNumber) === -1) {
      this.props.paymentsStore!.downloadPaymentDocument(supplierId, lineItem.killsheetNumber!, lineItem.referenceNumber);
    }
  }

  isInvoiceDocumentDownloaded = (referenceNumber: string) => {
    return this.props.invoiceStore!.downloadedInvoiceDocuments.findIndex(doc => doc.referenceNumber === referenceNumber && doc.document !== DEFAULT_DATA.UNDEFINED) !== -1;
  }

  isPaymentDocumentDownloaded = (referenceNumber: string) => {
    return this.props.paymentsStore!.downloadedPaymentDocuments.findIndex(doc => doc.referenceNumber === referenceNumber && doc.document !== DEFAULT_DATA.UNDEFINED) !== -1;
  }

  dateFilterChanged = (startDate?: Date, endDate?: Date) => {
    const { authorizationStore, paymentsStore } = this.props;
    this.currentSelectedStartDate = startDate;
    this.currentSelectedEndDate = endDate;
    paymentsStore!.paymentHistorySummaryLines.length = 0;
    paymentsStore!.getPaymentsByFarm(authorizationStore!.isAllFarmsSelected, authorizationStore!.selectedFarmId, this.currentSelectedStartDate, this.currentSelectedEndDate);
  
  }

  handleChange = (event: any, value: any) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  }

  paymentsContainerDidMount = (node: any) => {
    if (node) {
      node.addEventListener(EVENT.MOUSE_SCROLL, this.trackScrolling.bind(this));
    }
  }

  render() {
    const { theme, paymentsStore, authorizationStore } = this.props;

    return (
      <div className="p-payments__container">
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className="p-payments__tabs"
        >
          <Tab className="p-payments__tab" label="Upcoming Payments" disableRipple={true} />
          <Tab className="p-payments__tab" label="Payments History" disableRipple={true} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <div className="c-invoices__container">
            {this.renderOutstandingInvoices()}
          </div>
          <div className="c-payments__container" ref={this.paymentsContainerDidMount}>
            <div className="c-payments__container-tools">
              <PaymentHistoryDateSelector label="Listed" onDateSelectionChanged={this.dateFilterChanged} paymentsStore={paymentsStore} />
              <PaymentHistoryExport paymentsStore={paymentsStore!} authorizationStore={authorizationStore!} />
            </div>
            {this.renderPaymentHistory()}
          </div>
        </SwipeableViews>
      </div>
    );
  }

  trackScrolling = (event: any) => {
    const node: any = event.target;
    const { authorizationStore, paymentsStore } = this.props;
    const isBottomOfDiv = node.scrollHeight - Math.ceil(node.scrollTop) <= node.clientHeight;

    if (isBottomOfDiv && paymentsStore!.isPaymentsLoaded && !paymentsStore!.isPaymentsCompleted) {
      this.setState({ isDisplayLoadingAnimation: true });
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        paymentsStore!.getPaymentsByFarm(authorizationStore!.isAllFarmsSelected, authorizationStore!.selectedFarmId, this.currentSelectedStartDate, this.currentSelectedEndDate)
          .then(() => {
            this.setState({ isDisplayLoadingAnimation: false });
          });
      },                        TRANSITION.PAYMENT_SCROLL_DELAY);

    }
  }

  renderOutstandingInvoices = () => {
    const { authorizationStore, invoiceStore } = this.props;

    if (invoiceStore!.isInvoiceLoadError) {
      window.telemetry.triggerPageTimer(PAGE_NAME.PAYMENTS, TelemetryPageEventType.PageStop);
      return (
        <div className="c-invoices__load-error-container">
          <LoadError onPress={() => invoiceStore!.getInvoicesByFarm(authorizationStore!.isAllFarmsSelected, invoiceStore!.selectedSupplierId)} />
        </div >
      );
    } else if (invoiceStore!.isInvoiceLoaded && (invoiceStore!.outstandingInvoiceSummaryLines && _.isEmpty(invoiceStore!.outstandingInvoiceSummaryLines))) {
      window.telemetry.triggerPageTimer(PAGE_NAME.PAYMENTS, TelemetryPageEventType.PageStop);
      return (
        <div className="c-invoices__empty-stocks-container">
          <div className="c-invoices__empty-stocks-text">
            No outstanding invoices available for
            <br />
            {authorizationStore!.isAllFarmsSelected ? ` all available farms` : ` ${authorizationStore!.selectedFarm!.name}`}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {invoiceStore!.outstandingInvoiceSummaryLines.map((row, index) => {
            return (
              <Fragment key={`outstanding-invoice-summary-line-${row.paymentDate}-${index}`}>
                <div className="c-outstandingInvoiceSummaryLine__div">
                  <ExpansionPanel classes={{ expanded: 'expanded' }}>
                    <ExpansionPanelSummary id="c-outstandingInvoiceDetailLine__panel-summary">
                      <OutstandingInvoiceSummary outstandingInvoiceSummaryLine={row} invoices={row.invoices.length} downloadInvoiceDocument={this.downloadInvoiceDocument} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="c-outstandingInvoiceDetailLine__div invoices">
                      <OutstandingInvoiceDetail outstandingInvoiceDetailLines={row.invoices} userFarms={authorizationStore!.userFarms} openDocument={this.openInvoice} isInvoiceDocumentDownloaded={this.isInvoiceDocumentDownloaded} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </Fragment>
            );
          })
          }
          <ProgressIndicator
            isDisplayed={!invoiceStore!.isInvoiceLoaded || this.state.isDisplayLoadingAnimation}
            id="c-progress__invoices"
          />
        </>
      );
    }

  }

  renderPaymentHistory = () => {
    const { authorizationStore, paymentsStore } = this.props;

    if (paymentsStore!.isPaymentLoadError) {
      return (
        <div className="c-payments__load-error-container">
          <LoadError onPress={() => paymentsStore!.getPaymentsByFarm(authorizationStore!.isAllFarmsSelected, paymentsStore!.selectedSupplierId)} />
        </div >
      );
    } else if (paymentsStore!.isPaymentsLoaded && (paymentsStore!.paymentHistorySummaryLines && _.isEmpty(paymentsStore!.paymentHistorySummaryLines))) {
      return (
        <div className="c-payments__empty-stocks-container">
          <div className="c-payments__empty-stocks-text">
            No payment history available for
            <br />
            {authorizationStore!.isAllFarmsSelected ? ` all available farms` : ` ${authorizationStore!.selectedFarm!.name}`}
          </div>
        </div>
      );
    } else {
      return (
        <>
          {paymentsStore!.paymentHistorySummaryLines.map((row, index) => {
            return (
              <Fragment key={`payment-history-summary-line-${row.groupingId}-${index}`}>
                <div className="c-paymentHistorySummaryLine__div">
                  <ExpansionPanel classes={{ expanded: 'expanded' }}>
                    <ExpansionPanelSummary id="c-paymentHistorySummaryLine__panel-summary">
                      <PaymentHistorySummary paymentHistorySummaryLine={row} farmName={paymentsHelper.getFarmName(row.supplierId, authorizationStore!.userFarms)} downloadPaymentDocument={this.downloadPaymentDocument} />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="c-paymentHistoryDetailLine__div">
                      <PaymentHistoryPayments paymentHistoryPaymentLines={row.payments} />
                      <PaymentHistoryDetail openDocument={this.openPayment} supplierId={row.supplierId} paymentHistoryDetailLines={row.payments} isPaymentDocumentDownloaded={this.isPaymentDocumentDownloaded} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </Fragment>
            );
          })
          }
          <ProgressIndicator
            isDisplayed={!paymentsStore!.isPaymentsLoaded || this.state.isDisplayLoadingAnimation}
            id="c-progress__payments"
          />
        </>
      );
    }
  }
}

export default withStyles(styles, { withTheme: true })(Payments);