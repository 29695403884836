import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ContactUs from '../../../pages/ContactUs/Index';
import Dashboard from '../../../pages/Dashboard/Index';
import KillInformation from '../../../pages/KillInformation/Index';
import MyAccount from '../../../pages/MyAccount/Index';
import MyShares from '../../../pages/MyShares/Index';
import MyStock from '../../../pages/MyStock/Index';
import Payments from '../../../pages/Payments/Index';
import PowerBIPage from '../../../pages/PowerBIDashboard/Index';
import Schedule from '../../../pages/Schedule/Index';
import Settings from '../../../pages/Settings/Index';
import AnalyticsIcon from './AnalyticsIcon';
import { PAGE_NAME } from './Application';
import BobbyIcon from './BobbyIcon';
import * as URL from './ExternalUrls';

export const DEFAULT_INDEX = 0;
export const DEFAULT_WIDTH = 210;

// Menu Item Index Definition

export const DASHBOARD_INDEX = 0;
export const KILL_INFORMATION_INDEX = 1;
export const MY_ACCOUNT_INDEX = 2;
export const MY_STOCK_INDEX = 3;
export const MY_SHARES_INDEX = 4;
export const SCHEDULE_INDEX = 5;
export const PAYMENTS_INDEX = 6;
export const ANALYTICS_INDEX = 7;
export const CONTACT_US_INDEX = 8;
export const SETTINGS_INDEX = 9;
export const NOTIFICATIONS_INDEX = 10;

export const ALL_FARMS_PAGE_INDEXES = [KILL_INFORMATION_INDEX, PAYMENTS_INDEX];
export const KILLSHEET_SEARCH_INDEXES = [KILL_INFORMATION_INDEX];

export const ITEMS = [
  {
    Component: Dashboard,
    Label: PAGE_NAME.DASHBOARD,
    Icon: HomeIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: DASHBOARD_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: KillInformation,
    Label: PAGE_NAME.KILL_INFORMATION,
    Icon: LibraryBooksIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: KILL_INFORMATION_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: MyAccount,
    Label: PAGE_NAME.MY_ACCOUNT,
    Icon: AssignmentIndIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: MY_ACCOUNT_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: MyStock,
    Label: PAGE_NAME.MY_STOCK,
    Icon: LocalShippingIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: MY_STOCK_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: MyShares,
    Label: PAGE_NAME.MY_SHARES,
    Icon: TrendingUpIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: MY_SHARES_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },  
  {
    Component: Schedule,
    Label: PAGE_NAME.SCHEDULE,
    Icon: AssignmentIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: SCHEDULE_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: Payments,
    Label: PAGE_NAME.PAYMENTS,
    Icon: MonetizationIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: PAYMENTS_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: ContactUs,
    Label: PAGE_NAME.CONTACT_US,
    Icon: ContactPhoneIcon,
    IconStyle: {
      margin: '-0.1em 0em 0em 0em',   
    },
    IconItemStyle: { 
      fontSize: 18 
    },
    Index: CONTACT_US_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: true,
    ExternalUrl: URL.CONTACT_US
  },
  {
    Component: PowerBIPage,
    Label: PAGE_NAME.ANALYTICS,
    Icon: AnalyticsIcon,
    Index: ANALYTICS_INDEX,
    IsBottom: false,
    Divider: false,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  {
    Component: Settings,
    Label: PAGE_NAME.SETTINGS,
    Icon: SettingsIcon,
    IconStyle: undefined,
    IconItemStyle: undefined,
    Index: SETTINGS_INDEX,
    IsBottom: true,
    Divider: true,
    Badge: false,
    IsExternal: false,
    ExternalUrl: undefined
  },
  // TODO: uncomment this once notifications are being added
  // {
  //   Component: Notifications,
  //   Label: 'Notifications',
  //   Icon: NotificationsIcon,
  //   Index: NOTIFICATIONS_INDEX,
  //   IsBottom: true,
  //   Divider: true,
  //   Badge: true,
  //   IsExternal: false,
  //   ExternalUrl: undefined
  // }
];