import HttpService from '../Services/HttpService';

import * as CONNECTION from '../Constants/Connection';
import * as DASHBOARD from '../Constants/Dashboard';

class StreamService extends HttpService {
  getSocialStream = (page?: number, pageSize?: number) => {
    const _getSocialApiUrl =
      this.buildStreamApiUrl(`${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.GET_STREAM}${DASHBOARD.STREAM_SOCIAL}`, page, pageSize);
    return super.get(_getSocialApiUrl.toString());
  }

  getNewsStream = (page?: number, pageSize?: number) => {
    const _getNewsApiUrl =
      this.buildStreamApiUrl(`${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.GET_STREAM}${DASHBOARD.STREAM_NEWS}`, page, pageSize);
    return super.get(_getNewsApiUrl.toString());
  }

  getEventsStream = (page?: number, pageSize?: number) => {
    const _getEventsApiUrl =
      this.buildStreamApiUrl(`${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.GET_STREAM}${DASHBOARD.STREAM_EVENTS}`, page, pageSize);
    return super.get(_getEventsApiUrl.toString());
  }

  getLatestStream = (page?: number, pageSize?: number) => {
    const _getLatestApiUrl =
      this.buildStreamApiUrl(`${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.GET_STREAM}${DASHBOARD.STREAM_LATEST}`);
    return super.get(_getLatestApiUrl.toString());
  }

  private buildStreamApiUrl(apiUrl: string, page?: number, pageSize?: number) {
    let queryParams: string[] = [];

    if (page) { queryParams.push(`${CONNECTION.PAGE_PARAM}=${page}`); }
    if (pageSize) { queryParams.push(`${CONNECTION.PAGE_SIZE_PARAM}=${pageSize}`); }

    if (queryParams.length > 0) {
      return `${apiUrl}?${queryParams.join('&')}`;
    } else {
      return apiUrl;
    }

  }
}

export default StreamService;