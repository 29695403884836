import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as MENU from '../App/Constants/Menu';
import * as SHARES from '../App/Constants/Shares';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import SharePriceHelper from '../App/Helpers/SharePriceHelper';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import MenuStore from '../App/Stores/MenuStore';
import Tile from './Tile';

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  menuStore: MenuStore;
  loadData: Function;
  initialStatus?: TileStatus;
}

const _sharePriceHelper: SharePriceHelper = new SharePriceHelper();

@observer
class SharePriceTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
  }
  handleShareTileClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_SHARE_PRICE);
    this.props.menuStore!.setSelectedIndex(MENU.MY_SHARES_INDEX);
    this.props.dashboardStore!.tabIndex = 3;
  }

  render() {
    const { dashboardStore } = this.props;
    const shareTileTrend = dashboardStore!.shareTileTrend;
    const shareTileTrendIcon = _sharePriceHelper.getSharePriceIcon(shareTileTrend);
    let priceChange = _propertyHelper.formatValue(dashboardStore!.shareTilePriceDifference, VALUE_TYPE.CURRENCY);

    if (shareTileTrend === SHARES.SHARE_TREND_FLAT) {
      priceChange = 'No Change';
    }

    if (dashboardStore!.shareTileStatus === TileStatus.LOADED) {
      return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleShareTileClick}>
          <Paper className="c-dashboard__tile">
            <div className="c-dashboard__tile-shareprice">
              <div className="c-dashboard__tile-shareprice-title">SHARE PRICE</div>
              <div className="c-dashboard__tile-shareprice-leftdiv">
                <span className="c-dashboard__tile-shareprice-price">{_propertyHelper.formatValue(dashboardStore!.shareTileSharePrice, VALUE_TYPE.CURRENCY)}</span>
                <span className="c-dashboard__tile-shareprice-updatedate">Last updated {_propertyHelper.formatValue(dashboardStore!.shareTileUpdateDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}</span>
              </div>
              <div className="c-dashboard__tile-shareprice-rightdiv">
                {shareTileTrendIcon ? (
                  <img src={shareTileTrendIcon.iconUrl} className={classNames('c-dashboard__tile-shareprice-icon', shareTileTrendIcon.name === SHARES.SHARE_TREND_FLAT ? 'c-dashboard__tile-shareprice-iconflat' : '')} />
                ) : (
                    DEFAULT_DATA.STRING
                  )}
                <span className="c-dashboard__tile-shareprice-difference">{priceChange}</span>
              </div>
              <span className="c-dashboard__tile-shareprice-viewdetails">VIEW DETAILS</span>
            </div>
          </Paper>
        </a>
      );
    } else {
      return super.render();
    }
  }
}

export default SharePriceTile;