import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import LoadError from '../../components/LoadError/LoadError';
import Loading from '../../components/Loading/Loading';
import UserTermsStore from '../App/Stores/UserTermsStore';
import { TermsPdf } from '../Terms/TermsPdf';

interface Props {
  userTermsStore?: UserTermsStore;
}

@inject('userTermsStore')
@observer
class RenderTerms extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    /* New AEM content code */
    return (
      <div style={{ width: '880px', height: '800px' }}>
        <iframe
          id="termsFrame"
          src={window.appSettings.aemContentUrl}
          width="98%"
          height="200%"
          scrolling="no"
          frameBorder="0"
          sandbox="allow-same-origin allow-popups allow-scripts"
          title="Terms and Conditions"
        ></iframe>
      </div>
    );
    // if (this.props.userTermsStore!.isTermsLoaded) {
    //   return (
    //     <TermsPdf terms={this.props.userTermsStore!.terms} />
    //   );
    // } else if (this.props.userTermsStore!.isTermsLoadError) {
    //   return <LoadError onPress={() => { this.props.userTermsStore!.getUserTerms(); }} />;
    // } else {
    //   return <Loading />;
    // }
  }
}

export default RenderTerms;
