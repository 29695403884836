import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import * as PAYMENT from '../App/Constants/Payment';

class PaymentHistoryDetailLine_Header extends PureComponent {

  render() {
    return (
      <Grid container={true} spacing={32}>
        <Grid item={true} xs={1} className="c-paymentHistoryDetailLine__item filler-container" />
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.DATE}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-paymentHistoryDetailLine__item">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.TALLY}</div>
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.SPECIES}</div>
          </div>
        </Grid>
        <Grid item={true} xs={3} />
        <Grid item={true} xs={1} />
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item right">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.AMOUNT}</div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default PaymentHistoryDetailLine_Header;