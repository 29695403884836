import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';

import * as PAYMENT from '../App/Constants/Payment';

class PaymentHistoryPaymentLine_Header extends PureComponent {

  render() {
    return (
      <Grid container={true} spacing={32} className="c-paymentHistoryPaymentLine__header">
        <Grid item={true} xs={1} className="c-paymentHistoryDetailLine__item filler-container" />
        <Grid item={true} xs={3}>
          <div className="c-paymentHistoryDetailLine__item">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.PAYMENT_DATE}</div>
          </div>
        </Grid>
        <Grid item={true} xs={6} />
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item right">
            <div className="c-paymentHistoryDetailLine__item-subheading">{PAYMENT.AMOUNT}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1} className="c-paymentHistoryPaymentLine__item border-filler-container" />
        <Grid item={true} xs={11}>
          <div className="c-paymentHistoryPaymentLine__item bottom-border" />
        </Grid>
      </Grid>
    );
  }
}

export default PaymentHistoryPaymentLine_Header;