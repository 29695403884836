import 'react-dates/initialize';
import AppSettings from './AppSettings';
import {AppStore, AppStoreContext } from './Stores/AppStore';
import AuthenticatedLayout from '../../pages/Layouts/AuthenticatedLayout';
import AuthenticatedRoute from '../../pages/Routes/AuthenticatedRoute';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProgressDialog from '../ProgressDialog/ProgressDialog';
import { Provider, observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import UnauthenticatedLayout from '../../pages/Layouts/UnauthenticatedLayout';
import * as CONNECTION from '../App/Constants/Connection';
import '../../stylesheets/main.css';
import axios from 'axios';
import UserTermsDialog from './../UserTermsDialog/UserTermsDialog';
import TelemetryService from './Services/TelemetryService';
import { TelemetryEventType,EVENTS } from '../App/Constants/Telemetry';
import { PAGE_NAME } from '../App/Constants/Application';
import CookieDialog from '../Login/CookieDialog';
import MobileAppRequired from '../../pages/MobileAppRequired/MobileAppRequired';
import StorageHelper from '../App/Helpers/StorageHelper';
import * as STORAGE from '../App/Constants/Storage';
import UAParser from 'ua-parser-js';
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import Login from '../../pages/Login/Index';

interface Props {
  pca: PublicClientApplication
}

@observer
class App extends Component<Props> {
  private appStore: AppStore;
  private cookieEnabled: boolean;
  private storageHelper: StorageHelper = new StorageHelper();

  constructor(props: Props) {
    super(props);
    this.appStore = new AppStore(this.props.pca);
    this.loadAppSettings();
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {

  //   var isThirdPartyCookieEnabled = true;
    let iphoneIpadAppleDevice = false;
		if (/^iP|^ip/.test(navigator.platform)) {
            iphoneIpadAppleDevice = true;
    } 

  //   if ((!this.cookieEnabled)) {
  //       return (
  //         <div className="c-whats-new-primary__container" id="c-whats-new-primary__container">
  //           <CookieDialog />
  //         </div>
  //       );
  //   }

  //  let ThirdPartyEnabled = this.storageHelper.getKeyValue(STORAGE.THIRDPARTYCOOKIESENABLED);
  //  isThirdPartyCookieEnabled = ThirdPartyEnabled!=="false";
        
    // show loading bar when loading the initial data
    if (this.appStore.progressStore.isLoading) {
      return (
        <ProgressDialog
          isLoading={true}
          message={this.appStore.progressStore.message}
        />
      );
    } else if (this.appStore.userTermsStore!.userTerms && !this.appStore.userTermsStore!.userTerms!.termsAccepted) {
      return (
        <>
          <ProgressDialog
            isLoading={true}
            message={this.appStore.progressStore.message}
          />
          <Provider {...this.appStore}>
            <UserTermsDialog />
          </Provider>
        </>
      );
    }
      else if (this.storageHelper.getKeyValue(STORAGE.MOBILE_APP_REQUIRED_DIALOG)==="true"){
      return(
          <MobileAppRequired/>
        );   
      }

    let isNotCompleteRegistration = !/completeregistration/i.test(window.location.pathname);

    if(window.isAuthenticated() && isNotCompleteRegistration){
      if(this.appStore.authorizationStore.isLoading){
        this.appStore.authorizationStore!.getUserFarms();
        return (
          <ProgressDialog
            isLoading={this.appStore.authorizationStore.isLoading}
            message={""}
          />
        );     

      } 
      
      if ( this.appStore.authorizationStore.teamAccessOnly){
          window.localStorage.clear();
          window.telemetry.logEvent(EVENTS.TEAM_ACCESS_OPEN);
          this.storageHelper.storeKeyValue(STORAGE.MOBILE_APP_REQUIRED_DIALOG,true);
          window.logOut();
        }
    //     else if (iphoneIpadAppleDevice && !isThirdPartyCookieEnabled) {
    //         window.localStorage.clear();
    //         window.telemetry.logEvent(EVENTS.IOS_MOBILE_ONLY_OPEN);
    //         this.storageHelper.storeKeyValue(STORAGE.MOBILE_APP_REQUIRED_DIALOG,true);
    //         window.logOut();
    //     }
     }

    window.telemetry.logEvent(PAGE_NAME.DASHBOARD, TelemetryEventType.Page);
    return (
      <>
      <Provider {...this.appStore}>
        <MsalProvider instance={this.props.pca}>
          <React.Fragment>
              <AuthenticatedTemplate>
                <AuthenticatedLayout/>
              </ AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <UnauthenticatedLayout/>
              </ UnauthenticatedTemplate>
          </React.Fragment>
        </MsalProvider>
      </Provider>
        {/* <Provider {...this.appStore}>
          <BrowserRouter>
            <Switch>
              <AuthenticatedRoute path="/app" component={AuthenticatedLayout} />
              <Route path="/" component={UnauthenticatedLayout} />
            </Switch>
          </BrowserRouter>
        </Provider>
        <ProgressDialog
          isLoading={this.appStore.progressStore.isLoading}
          message={this.appStore.progressStore.message}
        />
        <Provider {...this.appStore}>
          <UserTermsDialog />
        </Provider> */}
      </>
    );
  }

  private loadAppSettings = () => {
    this.cookieEnabled = navigator.cookieEnabled;
    if (!this.cookieEnabled) {
      this.cookieEnabled = false;
    } else {
      this.cookieEnabled = true;
      axios.defaults.headers = {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      };
       axios.get(CONNECTION.CONFIGURATION_FILE_PATH)
        .then(response => {
          let _configurationValues: AppSettings = response!.data;

          if (_configurationValues) {
            window.appSettings = _configurationValues;
            window.telemetry = new TelemetryService({ appInsightsInstrumentKey: window.appSettings.appInsightsInstrumentKey });
            ReactGA.initialize(window.appSettings.universalAnalyticsTrackingCode, {
              debug: window.appSettings.universalAnalyticsDebugging,
              titleCase: false,
              gaOptions: { siteSpeedSampleRate: 100 }
            });
            if (!this.appStore.loadInitialData()) {
              this.cookieEnabled = false;
            }
          }
        });
     }
  }
}

export default App;
