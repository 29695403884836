import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import ScheduleStore from '../../components/App/Stores/ScheduleStore';
import LoadError from '../../components/LoadError/LoadError';
import Loading from '../../components/Loading/Loading';
import PdfSchedule from './../../components/Schedule/PdfSchedule';

interface Props {
  authorizationStore?: AuthorizationStore;
  scheduleStore?: ScheduleStore;
}

@inject('authorizationStore')
@inject('scheduleStore')
@observer
class Schedule extends Component<Props> {
  private selectedIsland: string;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      if (this.props.authorizationStore!.selectedFarm!.data!.island !== this.selectedIsland) {
        this.selectedIsland = this.props.authorizationStore!.selectedFarm!.data!.island;
        this.props.scheduleStore!.getSchedulePdf(this.selectedIsland);
      }
    });
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.SCHEDULE, TelemetryPageEventType.PageStart);
  }

  componentDidMount = () => {
    const { authorizationStore } = this.props;
    if (!authorizationStore!.selectedFarm) {
      console.error('No farm loaded');
    } else {
      this.selectedIsland = this.props.authorizationStore!.selectedFarm!.data!.island;
    }
    window.telemetry.logEvent(PAGE_NAME.SCHEDULE, TelemetryEventType.Page);
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  render() {
    const { scheduleStore, authorizationStore } = this.props;

    if (scheduleStore!.isScheduleLoaded && scheduleStore!.isScheduleLoadError) {
      return <LoadError onPress={() => { scheduleStore!.getSchedulePdf(this.selectedIsland); }} />;
    } else if (authorizationStore!.selectedFarm && scheduleStore!.isScheduleLoaded) {
      return (
        <div className="c-schedule__container">
          <h1 className="c-schedule__header">Schedule</h1>
          <PdfSchedule schedule={scheduleStore!.schedule} />
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Schedule;
