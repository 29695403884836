import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { Component } from 'react';
import * as CONNECTION from '../App/Constants/Connection';

interface Props {
  onPress: () => void;
}

class LoadError extends Component<Props> {

  render() {
    const { onPress } = this.props;
    return (
      <div className="c-load-error__container">
        <div className="c-load-error__message">
          {CONNECTION.ERROR_MESSAGE}
        </div>
        <div className="c-load-error__button">
          <Button onClick={onPress}>
            <RefreshIcon className="c-load-error__button-icon" />
            <div className="c-load-error__button-text">
              {CONNECTION.ERROR_RELOAD}
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default LoadError;
