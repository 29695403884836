import { observable } from 'mobx';
import HttpService from './HttpService';
import * as CONNECTION from '../Constants/Connection';
import * as DOCUMENT_TYPE from '../Constants/DocumentType';
import * as DATE_FORMAT from '../Constants/DateFormat';
import moment from 'moment';

class KillSheetService extends HttpService {
  @observable public isLoaded: boolean = false;
  @observable public hasError: boolean = false;

  getAllKillSheets = (species?: string, page?: number, pageSize?: number, startDate?: Date, endDate?: Date) => {
    const _getAllKillSheetsApiUrl = this.buildKillSheetsApiUrl(`${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${CONNECTION.KILLSHEET}`, species, page, pageSize, startDate, endDate);
    return super.get(_getAllKillSheetsApiUrl.toString());
  }

  getKillSheets = (farmId: string, species?: string, page?: number, pageSize?: number, startDate?: Date, endDate?: Date) => {
    const _getKillSheetsApiUrl = this.buildKillSheetsApiUrl(`${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${farmId}/${CONNECTION.KILLSHEET}`, species, page, pageSize, startDate, endDate);
    return super.get(_getKillSheetsApiUrl.toString());
  }

  getKillSheetsByNumber = (killSheetNumber: string) => {
    const _getKillSheetsByNumberApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${CONNECTION.KILLSHEET}${killSheetNumber}`;
    return super.get(_getKillSheetsByNumberApiUrl);
  }

  getDocument = (supplierId: string, killSheetNumber: number, reference: string, type: string = DOCUMENT_TYPE.PDF) => {    
    const _documentApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.KILLSHEET}${killSheetNumber}/${CONNECTION.REFERENCE}${reference}/${CONNECTION.DOCUMENT}?${CONNECTION.DOCUMENT_TYPE_PARAM}=${type}`;
    return super.doDownloadDocument(_documentApiUrl);
  }

  getKillSheetForDashBoard = (farmId: string) => {
    const _getKillSheetForDashBoard = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${farmId}/${CONNECTION.KILLSHEET}${CONNECTION.SEASONOVERVIEW}`;
    return super.get(_getKillSheetForDashBoard, undefined, undefined, undefined, true);
  }
  
  getCarcaseReport = async (supplierId: string, isInterim: boolean, killSheetNumber?: string, reference?: string, animalCategory?: string) => {
    let getChartURL;
    if (isInterim) {
      getChartURL = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.KILLSHEET}${killSheetNumber}/${CONNECTION.PERFORMANCEREPORT}?${CONNECTION.ANIMALCATEGORY}${animalCategory}&${CONNECTION.TARGETREPORTTYPE}`;
    } else {
      getChartURL = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.KILLSHEET}${killSheetNumber}/${CONNECTION.REFERENCE}${reference}/${CONNECTION.PERFORMANCEREPORT}?${CONNECTION.ANIMALCATEGORY}${animalCategory}&${CONNECTION.TARGETREPORTTYPE}`;
    }
    return super.get(`${getChartURL}`);
  }

  private buildKillSheetsApiUrl(apiUrl: string, species?: string, page?: number, pageSize?: number, startDate?: Date, endDate?: Date) {
    let queryParams: string[] = [];

    if (species) { queryParams.push(`${CONNECTION.SPECIES_PARAM}=${species}`); }
    if (page) { queryParams.push(`${CONNECTION.PAGE_PARAM}=${page}`); }
    if (pageSize) { queryParams.push(`${CONNECTION.PAGE_SIZE_PARAM}=${pageSize}`); }
    if (startDate) { queryParams.push(`${CONNECTION.START_DATE_PARAM}=${moment(startDate).format(DATE_FORMAT.HYPHENATED_DAY_MONTH_AND_YEAR)}`); }
    if (endDate) { queryParams.push(`${CONNECTION.END_DATE_PARAM}=${moment(endDate).format(DATE_FORMAT.HYPHENATED_DAY_MONTH_AND_YEAR)}`); }

    if (queryParams.length > 0) {
      return `${apiUrl}?${queryParams.join('&')}`;
    } else {
      return apiUrl;
    }
  }
}

export default KillSheetService;
