import { Fade } from '@material-ui/core';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import { FADE_DELAY } from '../App/Constants/Transition';
import BookingStore from '../App/Stores/BookingStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import LoadError from '../LoadError/LoadError';
import ProgressIndicator from '../Progress/ProgressIndicator';
import StockItem from './StockItem';

interface Props {
  bookingStore?: BookingStore;
  authorizationStore?: AuthorizationStore;
}

interface State {
  isDisplayLoadingAnimation: boolean;
}

@inject('bookingStore')
@inject('authorizationStore')
@observer
class Bookings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isDisplayLoadingAnimation: false
    };
  }

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_STOCK, TelemetryPageEventType.PageStop);
  }

  render() {
    const { bookingStore, authorizationStore } = this.props;

    if (authorizationStore!.selectedFarmId && bookingStore!.isBookingsLoaded && bookingStore!.isBookingsLoadError) {
      return (
        <div className="c-stocks__load-error-container">
          <LoadError
            onPress={() => {
              bookingStore!.getBookings(authorizationStore!.selectedFarmId);
            }}
          />
        </div>
      );
    } else if (bookingStore!.isBookingsLoaded && !bookingStore!.isBookingsLoadError) {
      const bookings = bookingStore!.bookings;
      return (
        <>
          <Fade in={true} timeout={FADE_DELAY}>
            {bookings && !_.isEmpty(bookings) ?
              <div className="c-stocks__timeline">
                {bookings.map(booking => (
                  <div key={booking.id}>
                    <StockItem isTimelineYearDisplayed={booking.isFirstElementForYear} stock={booking} />
                  </div>
                ))}
              </div>
              :
              <div className="c-stocks__empty-stocks-container">
                <div className="c-stocks__empty-stocks-text">
                  No bookings available for
                  <br />
                  {authorizationStore!.selectedFarm!.name}
                </div>
              </div>
            }
          </Fade>
        </>
      );
    } else {
      return (
        <div className='c-progress__stocks-container'>
          <ProgressIndicator
            isDisplayed={true}
            id='c-progress__stocks-bookings'
          />
        </div>
      );
    }
  }
}

export default Bookings;