import RootRef from '@material-ui/core/RootRef';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import * as React from 'react';

interface State {
  isOpen: boolean;
}

/**
 * Material-UI Tooltip that has custom open/close detection in iOS browsers.
 *
 * The disableTouchListener prop on Tooltip behaves differently on Android and iOS
 * browsers. In Android, disabling the touch listener makes the Tooltip open on touch
 * and close when touched anywhere outside. This isn't the case for iOS, where
 * disabling the touch listener will cause the Tooltip to lose touch interactivity.
 */
class CustomTooltip extends React.Component<TooltipProps, State> {

  state = {
    isOpen: false,
  };
  private myRef = React.createRef<HTMLElement>();

  componentDidMount() {
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  handleClickOutside = (event: { target: any }) => {
    if (this.myRef.current && !this.myRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    if (navigator.userAgent.match(/iPad/i)) {
      return (
        <RootRef rootRef={this.myRef}>
          <Tooltip
            {...this.props}
            open={this.state.isOpen}
            onTouchStart={() => this.setState({ isOpen: true })}
          />
        </RootRef>
      );
    }

    return <Tooltip {...this.props} />;
  }
}

export default CustomTooltip;
