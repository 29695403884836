import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class ArticleService extends HttpService {
  getArticles = async (page?: number, pageSize?: number) => {
    const getArticlesApiUrl =
      `${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.GET_CURRENT_ARTICLES}?${CONNECTION.PAGE_PARAM}=${page}&${CONNECTION.PAGE_SIZE_PARAM}=${pageSize}`;
    return super.get(getArticlesApiUrl);
  }

  getArticle = (filename: string) => {
    const articleApiUrl = this.buildArticleUrl(filename);
    return super.doDownloadDocument(articleApiUrl);
  }

  private buildArticleUrl = (filename: string) => {
    return `${window!.appSettings.apiUrl}${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.ARTICLE}${CONNECTION.DOCUMENT}${filename}`;
  }
}

export default ArticleService;
