import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DialogStore from '../App/Stores/DialogStore';

const styles = (theme: Theme) =>
  createStyles({
    title: {
      '&& h2': {
        fontFamily: 'Graphik-Medium',
        fontSize: '1em'
      }
    },
    content: {
      '&& p': {
        fontSize: '0.8em'
      }
    },
    okaybutton: {
      backgroundColor: '#427b5d',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black'
        }
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: 'white',
        padding: '0px 40px'
      }
    },
    cancelbutton: {
      backgroundColor: 'white',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: '#427b5d',
        padding: '0px 22px'
      }
    },
    dialog: {
      '&& div[class^="MuiPaper"]': {
        maxWidth: '450px',
        padding: '12px 16px'
      }
    },
    backdrop: {
      opacity: 0.3
    }
  });

interface Props extends WithStyles<typeof styles> {
  dialogStore?: DialogStore;
}

@observer
class CustomDialogBox extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  handleAction = () => {
    const { dialogStore } = this.props;
    if (dialogStore && dialogStore.action) {
      dialogStore.action();
    }
    this.props.dialogStore!.setDialogClosed();
  }

  handleAlternateAction = () => {
    const { dialogStore } = this.props;
    if (dialogStore && dialogStore.alternateAction) {
      dialogStore.alternateAction();
    }
    this.props.dialogStore!.setDialogClosed();
  }

  render() {
    const { classes, dialogStore } = this.props;
    const backDropProps = {
      invisible: true,
      classes: {
        invisible: classes.backdrop,
      }
    };

    return (
      <Dialog
        className={classes.dialog}
        open={dialogStore!.isDialogOpen}
        onClose={dialogStore!.alternateAction ? () => dialogStore!.alternateAction : dialogStore!.setDialogClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={backDropProps}
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>{dialogStore!.title}</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description">{dialogStore!.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogStore!.isConfirmationRequired && <Button className={classes.cancelbutton} onClick={this.handleAlternateAction} color="primary">{dialogStore!.leftButton}</Button>}
          <Button className={classes.okaybutton} onClick={this.handleAction} color="primary" autoFocus={true}>{dialogStore!.rightButton}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CustomDialogBox);