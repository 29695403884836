import { createStyles, ListItem, ListItemIcon, ListItemText, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as EVENT from '../App/Constants/Events';
import { TelemetryEventType } from '../App/Constants/Telemetry';
import MenuListItem from '../App/Models/MenuListItem';
import MenuStore from '../App/Stores/MenuStore';
import MenuItemIcon from './MenuItemIcon';

interface Props extends WithStyles<typeof styles> {
  isDisabled: boolean;
  menuItem: MenuListItem;
  menuStore?: MenuStore;
}

const styles = () =>
  createStyles({
    listItem: {
      '& $icon': {
        color: 'rgb(186,186,186)'
      },
      padding: '1.25em 1.5em',
      '&:focus': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        border: '0.25em rgb(66, 123, 93) solid',
        borderWidth: '0 0 0 0.25em',
        paddingLeft: '1.25em',
        '& $icon': {
          color: 'rgb(66, 123, 93)'
        }
      },
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        border: '0.25em rgb(66, 123, 93) solid',
        borderWidth: '0 0 0 0.25em',
        paddingLeft: '1.25em',
        '& $icon': {
          color: 'rgb(66, 123, 93)'
        },
        '&& span': {
          color: 'rgb(66, 123, 93) !important'
        }
      },
      '&& div': {
        paddingRight: 0
      }
    },
    listItemTablet: {
      '&:hover': {
        backgroundColor: '#fff !important',
        borderColor: '#fff !important',
        '&& div:nth-child(1)': {
          color: 'rgba(0, 0, 0, 0.54)'
        }
      }
    },
    listItemExternal: {
      '& $icon': {
        color: 'rgb(186,186,186)'
      },
      padding: '1.25em 1.5em',
      '&:focus': {
        backgroundColor: '#fff'
      },
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        border: '0.25em rgb(66, 123, 93) solid',
        borderWidth: '0 0 0 0.25em',
        paddingLeft: '1.25em',
        '& $icon': {
          color: 'rgb(66, 123, 93)'
        },
        '&& span': {
          color: 'rgb(66, 123, 93) !important'
        }
      }
    },
    listItemSelected: {
      backgroundColor: 'rgba(66, 123, 93, 0.07) !important',
      border: '0.25em rgb(66, 123, 93) solid',
      borderWidth: '0 0 0 0.25em',
      paddingLeft: '1.25em',
      '& $icon': {
        color: 'rgb(66, 123, 93)'
      },
      '&& span': {
        color: '#000000 !important'
      }
    },
    icon: {
      margin: 0,
      display: 'inline-block'
    },
    badgeIcon: {
      '&& span': {
        top: 5,
        right: 5,
        height: 10,
        minWidth: 10,
        backgroundColor: '#E0544A'
      }
    },
    drawerOpenListItemText: {
      fontFamily: 'Graphik-Medium',
      fontSize: 14,
      marginLeft: -22, //changed form -2 to -22 to get content aligned better
      marginTop: -3,
      color: 'rgb(186,186,186)',
      whiteSpace: 'pre-wrap',
      width: '113%' //changed from 100% to 113% so formatting of "kill information" is on one line
    },
    drawerCloseListItemText: {
      fontFamily: 'Graphik-Medium',
      fontSize: 14,
      marginLeft: 14,
      color: 'rgb(186,186,186)'
    },
    listItemDivider: {
      display: 'none'
    }
  });

@inject('menuStore')
@observer
class MenuItem extends React.Component<Props> {
  render() {
    const { isDisabled } = this.props;

    const listItemTextResponsiveClass = classNames({
      [this.props.classes.drawerOpenListItemText]: this.props.menuStore!
        .isMenuOpen,
      [this.props.classes.drawerCloseListItemText]: !this.props.menuStore!
        .isMenuOpen
    });

    const handleListItemClick = (
      event: React.MouseEvent<HTMLElement>,
      menuListItem: MenuListItem
    ) => {
      if (menuListItem.IsExternal) {
        window.telemetry.logEvent(menuListItem.Label + ' Link');
        window.open(menuListItem.ExternalUrl, EVENT.WINDOW_NEW_TAB);
      } else {
        window.telemetry.logEvent(menuListItem.Label, TelemetryEventType.Page);
        this.props.menuStore!.setSelectedIndex(menuListItem.Index);
      }
    };

    const isTablet = navigator.userAgent.match(/Android|iPad/i);

    const listItemClass = classNames({ [this.props.classes.listItemTablet]: isTablet && this.props.menuItem.IsExternal }, {
      [this.props.classes.listItemExternal]: this.props.menuItem.IsExternal,
      [this.props.classes.listItem]: !this.props.menuItem.IsExternal
    });

    return (
      <span>
        <ListItem
          button={true}
          className={listItemClass}
          selected={
            this.props.menuStore!.selectedIndex === this.props.menuItem.Index
          }
          onClick={event => handleListItemClick(event, this.props.menuItem)}
          classes={{ selected: this.props.classes.listItemSelected }}
          disabled={isDisabled}
        >
          <ListItemIcon
            key={this.props.menuItem.Index}
            className={this.props.classes.icon}
            style={this.props.menuItem.IconStyle}
          >
            <MenuItemIcon menuItem={this.props.menuItem} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: listItemTextResponsiveClass }}
            primary={this.props.menuItem.Label}
          />
        </ListItem>
      </span>
    );
  }
}

export default withStyles(styles)(MenuItem);
