import * as DEFAULT_DATA from '../Constants/DefaultData';

class StorageHelper {
  storeKeyValue = (key: string, value: any) => {
    if (typeof Storage !== DEFAULT_DATA.UNDEFINED) {
      window.localStorage.setItem(key, value);
    }
  }
  getKeyValue = (key: string) => {
    if (typeof Storage !== DEFAULT_DATA.UNDEFINED) {
      return window.localStorage.getItem(key);
    }
  }
  clearValue = (key: string) => {
    if (typeof Storage !== DEFAULT_DATA.UNDEFINED) {
      window.localStorage.removeItem(key);
    }
  }
  clearAll = () => {
    if (typeof Storage !== DEFAULT_DATA.UNDEFINED) {
      window.localStorage.clear();
    }
  }
}

export default StorageHelper;
