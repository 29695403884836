import _ from 'lodash';
import { AxiosError } from 'axios';
import FarmDetail from '../Models/FarmDetails';
import FarmService from '../Services/FarmService';
import { observable, action } from 'mobx';

import NaitNumber from '../Models/NaitNumber';
import NzfapCertificate from '../Models/NzfapCertificate';
import { TelemetryExceptionType } from '../Constants/Telemetry';

class FarmStore {
  @observable public farmDetail: FarmDetail = {} as FarmDetail;
  @observable public isFarmDetailsLoaded: boolean;
  @observable public isFarmDetailsLoadError: boolean;
  @observable public isFarmAccessContactsLoaded: boolean;
  @observable public isFarmAccessContactsLoadError: boolean;

  @observable public naitNumbers: NaitNumber[] = [];
  @observable public isNaitNumbersLoaded: boolean;
  @observable public isNaitNumbersLoadError: boolean;

  @observable public nzfapCertificates: NzfapCertificate[] = [];
  @observable public isNzfapCertificatesLoaded: boolean;
  @observable public isNzfapCertificatesLoadError: boolean;

  private _farmService: FarmService = new FarmService();

  constructor() {
  }

  getFarmDetails = (farmId: string) => {
    this.isFarmDetailsLoaded = false;
    this.isFarmDetailsLoadError = false;

    return this._farmService.getFarmDetails(farmId).then(response => {
      // there are async operations that can modify farmDetails with no guarantee
      // about which completes first, this ensures we merge with any existing data
      // if another operation modifies farmDetails first
      this.farmDetail.farmDetails = this.farmDetail.farmDetails || {};
      this.farmDetail.farmDetails = { ...this.farmDetail.farmDetails, ...response!.data.farmDetails };
      this.isFarmDetailsLoaded = true;
    }).catch((error: AxiosError) => {
      window.telemetry.logException(error, TelemetryExceptionType.ApiError);
      this.isFarmDetailsLoadError = true;
      this.isFarmDetailsLoaded = true;
    });
  }

  @action
  getNaitNumbers = (farmId: string) => {
    this.isNaitNumbersLoaded = false;
    this.isNaitNumbersLoadError = false;
    this.naitNumbers.length = 0;

    return this._farmService.getNaitNumbers(farmId)
      .then(response => {
        if (response!.data) {
          this.naitNumbers = response!.data.naitNumbers;
        }
        this.isNaitNumbersLoaded = true;
      }).catch(error => {
        this.isNaitNumbersLoaded = true;
        this.isNaitNumbersLoadError = true;
      });
  }

  @action
  getNzfapCertificates = (farmId: string) => {
    this.isNzfapCertificatesLoaded = false;
    this.isNzfapCertificatesLoadError = false;
    this.nzfapCertificates.length = 0;

    return this._farmService.getNzfapCertificates(farmId)
      .then(response => {
        if (response!.data) {
          this.nzfapCertificates = response!.data.farmAssuranceCertificates;
        }
        this.isNzfapCertificatesLoaded = true;
      }).catch(error => {
        this.isNzfapCertificatesLoaded = true;
        this.isNzfapCertificatesLoadError = true;
      });
  }

  @action
  getFarmAccessContacts = (farmId: string) => {
    this.isFarmAccessContactsLoaded = false;
    this.isFarmAccessContactsLoadError = false;

    return this._farmService.getFarmAccessContacts(farmId)
      .then((response) => {
        if (response!.data) {
          // there are async operations that can modify farmDetails with no guarantee
          // about which completes first, this ensures we merge with any existing data
          // if another operation modifies farmDetails first
          this.farmDetail.farmDetails = this.farmDetail.farmDetails || {};
          this.farmDetail!.farmDetails!.farmAccessContacts = response!.data.farmAccessContacts!.contactNames;
          this.isFarmAccessContactsLoaded = true;
        }
      }).catch(error => {
        this.isFarmAccessContactsLoadError = true;
        this.isFarmAccessContactsLoaded = true;
      });
  }
}

export default FarmStore;
