import React from 'react';
import * as KILLSHEET from '../App/Constants/KillSheet';
import KillSheetLegend from '../KillSheetLegend/KillSheetLegend';
import SpeciesFilter from '../SpeciesFilter/SpeciesFilter';

class TimelineHeader extends React.Component {
  render() {
    return (
      <>
        <div className="c-timeline__header-container">
          <h1 className="c-timeline__header">{KILLSHEET.TIMELINE_HEADER}</h1>
          <KillSheetLegend />
          <SpeciesFilter />
        </div>
      </>
    );
  }
}

export default TimelineHeader;
