import React, { Component } from 'react';
import DialogStore from '../App/Stores/DialogStore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Button, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';

const closeableTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '16px 50px 0px',
    },
    rootLeftAlign: {
      padding: '16px 50px 0px 0px'
    },
    title: {
      '&& h6': {
        fontFamily: 'Graphik-Semibold',
        fontSize: '1em',        
        padding: '0px 24px'
      }
    }, 
    [theme.breakpoints.down('md')]: {
      title: {
        '&& h6': {
          fontFamily: 'Graphik-Semibold',
          fontSize: '0.7em',
          padding: '0px 24px'
        }
      }
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 16,
      color: 'rgba(0, 0, 0, 0.87)',
    }    
  });

const styles = (theme: Theme) =>
  createStyles({    
    title: {
      '&& h2': {
        fontFamily: 'Graphik-Medium',
        fontSize: '1em'
      }      
    },
    content: {
      '&& p': {
        fontSize: '0.8em'
      }
    },
    okaybutton: {
      backgroundColor: '#427b5d',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black'
        }
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: 'white',
        padding: '0px 40px'
      }
    },
    cancelbutton: {
      backgroundColor: 'white',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: '#427b5d',
        padding: '0px 22px'
      }
    },    
    dialog: {
      '&& div[role="document"] > div': {
        maxWidth: '630px',
        padding: '12px 60px'
      }
    },
    dialogLeftHeader: {
      '&& div[role="document"] > div': {
        maxWidth: '100vw',
        padding: '12px 60px 12px 12px'
      }
    },    
    alternativeButtons: {
      '&& button': {
        border: '1px solid #427b5d',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#427b5d',
          '& span': {
            color: 'white !important'
          }
        }
      },
      '&& span': {
        color: '#427b5d'
      },
    },
    backdrop: {
      opacity: 0.3
    }
  });

interface DialogTitleProps extends WithStyles<typeof closeableTitleStyles> {
  id: string;
  children: React.ReactNode;
  leftAlign: boolean;
  onClose: () => void;
}

const DialogCloseTitle = withStyles(closeableTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, leftAlign, ...other } = props;
  return (
    <MuiDialogTitle disableTypography={true} className={classNames(leftAlign ? classes.rootLeftAlign : classes.root, classes.title)} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface Props extends WithStyles<typeof styles> {
  dialogStore?: DialogStore;
  invisibleBackdrop: boolean;
  closeButton: boolean;
  actions: boolean;
  titleLeftAlign: boolean;
  children?: any;
}

@observer
class CustomContentDialogBox extends Component<Props> {

  constructor(props: Props) {
    super(props);    
  }  

  handleAction = () => {
    const { dialogStore } = this.props;
    if (dialogStore && dialogStore.action) {
      dialogStore.action();
    }
    this.props.dialogStore!.setDialogClosed();
  }

  handleAlternateAction = () => {
    const { dialogStore } = this.props;
    if (dialogStore && dialogStore.alternateAction) {
      dialogStore.alternateAction();
    }
    this.props.dialogStore!.setDialogClosed();
  }  

  handleClose = () => {
    const { dialogStore } = this.props;
    _.isEmpty(dialogStore!.alternateAction) ? dialogStore!.setDialogClosed() : dialogStore!.alternateAction!();
  }

  render() {
    const { classes, dialogStore, children, closeButton, actions, titleLeftAlign } = this.props;
    const backDropProps = {
      invisible: this.props.invisibleBackdrop,
      classes: {
        invisible: classes.backdrop,
      }
    };    
    
    return (
      <Dialog
        className={titleLeftAlign ? classes.dialogLeftHeader : classes.dialog}
        open={dialogStore!.isDialogOpen}
        onClose={dialogStore!.alternateAction ? () => dialogStore!.alternateAction : dialogStore!.setDialogClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={backDropProps}
      >
        {closeButton ? (
          <DialogCloseTitle
            id="alert-dialog-title"
            onClose={this.handleClose}
            leftAlign={titleLeftAlign}
          >{dialogStore!.title}
          </DialogCloseTitle>
        ) :
          <DialogTitle id="alert-dialog-title" className={classes.title}>{dialogStore!.title}</DialogTitle>
        }
        <DialogContent className={classes.content}>
          {children}
        </DialogContent>
        {actions && (<DialogActions>
          {dialogStore!.isConfirmationRequired && <Button className={classes.cancelbutton} onClick={this.handleAlternateAction} color="primary">{dialogStore!.leftButton}</Button>}
          <Button className={classes.okaybutton} onClick={this.handleAction} color="primary" autoFocus={true}>{dialogStore!.rightButton}</Button>
        </DialogActions>)}
        
      </Dialog>
    );
    
  }
}

export default withStyles(styles)(CustomContentDialogBox);