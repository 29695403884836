import _ from 'lodash';
import KillSheet from '../Models/KillSheet';
import KillSheetService from '../Services/KillSheetService';
import { observable, action } from 'mobx';
import StorageHelper from '../Helpers/StorageHelper';
import TimelineHelper from '../Helpers/TimelineHelper';

import * as ARRAY from '../Constants/Array';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as DOCUMENT_TYPE from '../Constants/DocumentType';
import * as KILLSHEET from '../Constants/KillSheet';
import * as RESPONSE_DIALOG_MESSAGE from '../Constants/ResponseDialogMessage';
import * as STORAGE from '../Constants/Storage';
import ClaimsHelper from '../Helpers/ClaimsHelper';
import AuthorizationService from '../Services/AuthorizationService';
import Entity from '../Models/Entity';

const _storageHelper: StorageHelper = new StorageHelper();
const _timelineHelper: TimelineHelper = new TimelineHelper();
const _claimsHelper: ClaimsHelper = new ClaimsHelper();

class AuthorizationStore {
  @observable public currentPage: number;
  @observable public isAllFarmsSelected: boolean;
  @observable public toggleAllFarms: boolean;
  @observable public isDisplayTimeline: boolean;
  @observable public isFiltered: boolean;
  @observable public isKillSheetDetailsLoaded: boolean;

  @observable public isKillSheetsComplete: boolean;
  @observable public isKillSheetsLoaded: boolean;
  @observable public isKillSheetsLoadError: boolean;
  @observable public isKillSheetsSearchResult: 'number' | 'date' | false;
  @observable public lastSearchQuery: string = '';
  @observable public lastSearchStartDate?: Date;
  @observable public lastSearchEndDate?: Date;

  @observable public isUserFarmsLoaded: boolean;

  @observable public killSheets: Array<KillSheet> = [];
  @observable public selectedFarm?: Entity;
  @observable public selectedFarmId: string;
  @observable public selectedKillSheetId: number;
  @observable public selectedKillSheet?: KillSheet;
  @observable public selectedKillSheetPdfBlob?: string;
  @observable public selectedKillSheetXlsxBlob?: string;
  @observable public selectedSpecies?: string;
  @observable public userFarms: Array<Entity> = [];
  @observable public teamAccessUserFarms: Array<Entity> = [];
  @observable public teamAccessOnly: boolean;
  @observable public isLoading: boolean;

  private _authorizationService: AuthorizationService =
    new AuthorizationService();
  private _isInitialLoad: boolean = true;
  private _killSheetService: KillSheetService = new KillSheetService();

  constructor() {
    this.currentPage = KILLSHEET.DEFAULT_PAGE;
    this.isAllFarmsSelected = false;
    this.isDisplayTimeline = false;
    this.isFiltered = false;
    this.isKillSheetDetailsLoaded = false;
    this.isKillSheetsComplete = false;
    this.isKillSheetsLoaded = false;
    this.isUserFarmsLoaded = false;
    this.selectedFarmId = DEFAULT_DATA.STRING;
    this.teamAccessOnly = false;
    this.isLoading = true;
  }

  @action
  toggleSelectedAllFarms = () => {
    this.setSelectedFarm(this.selectedFarmId);
    this.toggleAllFarms = true;
    this.isAllFarmsSelected = !this.isAllFarmsSelected;
    if (this.isAllFarmsSelected) {
      _storageHelper.storeKeyValue(
        STORAGE.ALL_FARM_IDS,
        this.userFarms.map((f) => f.id).join(',')
      );
      this.getAllKillSheets();
    } else {
      _storageHelper.storeKeyValue(STORAGE.ALL_FARM_IDS, this.selectedFarmId);
      this.getFarmKillSheets(this.selectedFarmId);
    }
  };

  @action
  setSelectedKillSheetId = (selectedKillSheetId: number) => {
    this.selectedKillSheetId = selectedKillSheetId;
    this.selectedKillSheetPdfBlob = DEFAULT_DATA.UNDEFINED;
    this.selectedKillSheetXlsxBlob = DEFAULT_DATA.UNDEFINED;
    this.selectedKillSheet = this.killSheets.find(
      (k) => k.id === this.selectedKillSheetId
    );

    if (
      this.selectedKillSheet &&
      this.selectedKillSheet.supplier &&
      this.selectedKillSheet.killsheetNumber &&
      this.selectedKillSheet.reference
    ) {
      this.downloadAndAssignKillSheetDocument(
        this.selectedKillSheet.supplier,
        this.selectedKillSheet.killsheetNumber,
        this.selectedKillSheet.reference,
        DOCUMENT_TYPE.PDF
      );
      this.downloadAndAssignKillSheetDocument(
        this.selectedKillSheet.supplier,
        this.selectedKillSheet.killsheetNumber,
        this.selectedKillSheet.reference,
        DOCUMENT_TYPE.XLSX
      );
    }

    this.isKillSheetDetailsLoaded = true;
  };

  @action
  setSelectedFarm = (selectedFarmId: string) => {
    this.currentPage = KILLSHEET.DEFAULT_PAGE;
    this.isKillSheetDetailsLoaded = false;
    this.isKillSheetsLoaded = false;
    this.isKillSheetsLoadError = false;
    this.isKillSheetsComplete = false;
    this.killSheets.length = 0;
    this.lastSearchQuery = '';
    this.lastSearchStartDate = undefined;
    this.lastSearchEndDate = undefined;
    this.isKillSheetsSearchResult = false;

    if (this.userFarms) {
      this.selectedFarm = this.userFarms.find((f) => f.id === selectedFarmId);
    }
    if (this.selectedFarm) {
      _storageHelper.storeKeyValue(
        STORAGE.CURRENT_USER_ROLE,
        this.selectedFarm.roles[0].name
      );
    }
    _storageHelper.storeKeyValue(STORAGE.SELECTED_FARM_ID, selectedFarmId);    
    _storageHelper.storeKeyValue(STORAGE.ALL_FARM_IDS, selectedFarmId);
    if(this.isAllFarmsSelected){
      _storageHelper.storeKeyValue(STORAGE.ALL_FARM_IDS,   this.userFarms.map((f) => f.id).join(','));  
    }
    this.selectedFarmId = selectedFarmId;
    this.selectedKillSheetId = DEFAULT_DATA.NUMBER;
    this.selectedKillSheetPdfBlob = DEFAULT_DATA.UNDEFINED;
    this.selectedKillSheetXlsxBlob = DEFAULT_DATA.UNDEFINED;
  };

  @action
  getFarmKillSheets = (selectedFarmId: string) => {
    this.setSelectedFarm(selectedFarmId);

    if (!this.isAllFarmsSelected && selectedFarmId !== DEFAULT_DATA.STRING) {
      return this.getKillSheets(selectedFarmId);
    }
  };

  /**
   * Fetch user farms from backing data source.
   *
   * getUserFarms() is no-op if called after the initial load.
   * Subsequent access to userFarms should be done through the userFarms property.
   */
  @action
  getUserFarms = () => {
    if (!this._isInitialLoad) {
      return;
    }

    let noFarms: boolean = false;
    return this._authorizationService
      .getUserAssignment()
      .then((response) => {
        this.userFarms = response!.data!.entities;

        if (this.userFarms.length == 0) {
          noFarms = true;
        }
        this.teamAccessUserFarms = this.userFarms.filter(
          (farm) => farm.roles[0].name === 'TeamAccess'
        );
        this.userFarms = this.userFarms.filter(
          (farm) => farm.roles[0].name !== 'TeamAccess'
        );
        let _historicalFarmId = _storageHelper.getKeyValue(
          STORAGE.SELECTED_FARM_ID
        );

        if (this._isInitialLoad && _historicalFarmId) {
          this.setSelectedFarm(_historicalFarmId);
        } else {
          const indexOfHistorical = this.userFarms.findIndex(
            (userFarm) => userFarm.id === _historicalFarmId
          );
          const targetIndex =
            indexOfHistorical === -1 ? ARRAY.FIRST_ELEMENT : indexOfHistorical;
          this.setSelectedFarm(
            !_.isEmpty(this.userFarms)
              ? this.userFarms[targetIndex].id
              : DEFAULT_DATA.STRING
          );
        }

        if (
          this.teamAccessUserFarms.length > 0 &&
          this.userFarms.length === 0
        ) {
          this.teamAccessOnly = true;
        }

        this._isInitialLoad = false;
        this.isUserFarmsLoaded = true;
      })
      .catch((error) => {
        if (noFarms && !_claimsHelper.hasTokenExpired()) {
          const appDialogStore = window.appDialogStore;
          const retryAction = () => {
            window.appDialogStore!.setDialogClosed();
            window.location.reload();
          };
          const logoutAction = () => {
            window.appDialogStore!.setDialogClosed();
            window.logOut();
          };
          appDialogStore!.setDialogOpen(
            RESPONSE_DIALOG_MESSAGE.NO_FARMS_MESSAGE_TITLE,
            RESPONSE_DIALOG_MESSAGE.NO_FARMS_MESSAGE_CONTENT,
            logoutAction,
            true,
            RESPONSE_DIALOG_MESSAGE.NO_FARMS_BUTTON_RETRY_TEXT,
            RESPONSE_DIALOG_MESSAGE.NO_FARMS_BUTTON_LOGOUT_TEXT,
            retryAction
          );
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  @action
  getAllKillSheets = (startDate?: Date, endDate?: Date) => {
    if (startDate && endDate) {
      // required for clearing killsheets, and ensuring selected timeline items are animated out      
      this.isAllFarmsSelected = true;
      this.setSelectedFarm(this.selectedFarmId);

      // reset additional properties when making a new date filter query
      this.currentPage = KILLSHEET.DEFAULT_PAGE;
      this.killSheets.length = 0;
      this.lastSearchStartDate = startDate;
      this.lastSearchEndDate = endDate;
      this.isKillSheetsLoaded = false;
      this.isKillSheetsComplete = false;

    }

    this.isKillSheetsLoadError = false;
    this.isKillSheetsSearchResult =
      this.lastSearchStartDate && this.lastSearchEndDate ? 'date' : false;

    return this._killSheetService
      .getAllKillSheets(
        this.selectedSpecies,
        ++this.currentPage,
        KILLSHEET.PAGE_SIZE,
        this.lastSearchStartDate,
        this.lastSearchEndDate
      )
      .then((response) => {
        if (response!.data && response!.data!.killsheets) {
          this.assignOrAppendKillSheets(response!.data!.killsheets);
        } else {
          this.isKillSheetsComplete = true;
        }
        this.isKillSheetsLoaded = true;
        this.isDisplayTimeline = true;
      })
      .catch((error) => {
        this.isKillSheetsLoaded = true;
        this.isKillSheetsLoadError = true;
      });
  };

  @action
  getKillSheetsByNumber = (killSheetNumber: string) => {
    // required for clearing killsheets, and ensuring selected timeline items are animated out    
    this.setSelectedFarm(this.selectedFarmId);    
    _storageHelper.storeKeyValue(STORAGE.ALL_FARM_IDS,   this.userFarms.map((f) => f.id).join(','));  
    this.isKillSheetsLoadError = false;
    this.isKillSheetsLoaded = false;
    this.isKillSheetsSearchResult = 'number';

    return this._killSheetService
      .getKillSheetsByNumber(killSheetNumber)
      .then((response) => {
        // falsy or undefined response will be treated as 404, although this condition is not limited to 404s
        // see HttpService isOverrideDialog() function
        if (!response) {
          this.isKillSheetsLoaded = true;
          this.isDisplayTimeline = true;
          return;
        }

        if (response!.data && response!.data!.killsheets) {
          this.assignOrAppendKillSheets(response!.data!.killsheets);
        } else {
          this.isKillSheetsComplete = true;
        }

        this.isKillSheetsLoaded = true;
        this.isDisplayTimeline = true;
      })
      .catch((error) => {
        this.isKillSheetsLoaded = true;
        this.isKillSheetsLoadError = true;
      })
      .finally(() => {
        this.lastSearchQuery = killSheetNumber;
      });
  };

  @action
  getKillSheets = (farmId: string) => {
    this.isKillSheetsLoadError = false;
    this.isKillSheetsSearchResult = false;

    return this._killSheetService
      .getKillSheets(
        farmId,
        this.selectedSpecies,
        ++this.currentPage,
        KILLSHEET.PAGE_SIZE
      )
      .then((response) => {
        if (response!.data && response!.data!.killsheets) {
          this.assignOrAppendKillSheets(response!.data!.killsheets);
        } else {
          this.isKillSheetsComplete = true;
        }
        this.isKillSheetsLoaded = true;
        this.isDisplayTimeline = true;
      })
      .catch((error) => {
        this.isKillSheetsLoaded = true;
        this.isKillSheetsLoadError = true;
      });
  };

  @action
  resetKillSheetList = () => {
    this.currentPage = KILLSHEET.DEFAULT_PAGE;
    this.killSheets.length = 0;
    this.isKillSheetsComplete = false;
  };

  private downloadAndAssignKillSheetDocument = (
    supplierId: string,
    killSheetNumber: number,
    reference: string,
    type: string
  ) => {
    const downloadTask = this.getDocument(
      supplierId,
      killSheetNumber,
      reference,
      type
    );

    if (downloadTask) {
      downloadTask
        .then((response) => {
          if (response) {
            switch (type) {
              case DOCUMENT_TYPE.XLSX:
                this.selectedKillSheetXlsxBlob = response.data;
                break;
              case DOCUMENT_TYPE.PDF:
              default:
                this.selectedKillSheetPdfBlob = response.data;
                break;
            }
          }
        })
        .catch(() => {
          console.warn('No document found');
        });
    }
  };

  private getDocument = (
    supplierId: string,
    killSheetNumber: number,
    reference: string,
    type: string
  ) => {
    if (supplierId && killSheetNumber && reference) {
      return this._killSheetService.getDocument(
        supplierId,
        killSheetNumber,
        reference,
        type
      );
    }
    return DEFAULT_DATA.UNDEFINED;
  };

  private assignOrAppendKillSheets = (killSheets: Array<KillSheet>) => {
    let _killSheets = killSheets;

    if (_killSheets) {
      // Slight change in the order of processing where we populate the
      // farm details prior to the population of farm details.
      _killSheets = _timelineHelper.populateFarmDetails(
        _killSheets,
        this.userFarms
      );

      if (this.currentPage && this.currentPage > KILLSHEET.DEFAULT_PAGE) {
        this.killSheets.push(..._killSheets);
      } else {
        this.killSheets = _killSheets;
      }
    }

    if (_killSheets.length < KILLSHEET.PAGE_SIZE) {
      this.isKillSheetsComplete = true;
    }
  };
}

export default AuthorizationStore;
