import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PaymentHistoryDetailLine from '../App/Models/PaymentHistoryDetailLine';
import PaymentHistoryDetailLine_Header from './PaymentHistoryDetailLine_Header';
import PaymentHistoryDetailLine_Item from './PaymentHistoryDetailLine_Item';

interface Props {
  openDocument: Function;
  paymentHistoryDetailLines?: Array<PaymentHistoryDetailLine>;
  supplierId: string;
  isPaymentDocumentDownloaded: Function;
}

@observer
class PaymentHistoryDetail extends Component<Props> {
  render() {
    let { openDocument, paymentHistoryDetailLines, supplierId, isPaymentDocumentDownloaded } = this.props;
    let paymentDetailLinesOnly = _.filter(paymentHistoryDetailLines, ['isTotal', false]);

    if (!paymentDetailLinesOnly || paymentDetailLinesOnly.length <= 0) {
      return (<div />);
    }

    return (
      <>
        <div className="c-paymentHistoryDetailLine__div">
          {
            paymentDetailLinesOnly!.map((detailRow, index) => {
              return (
                <PaymentHistoryDetailLine_Item detailLine={detailRow} key={`payment-history-detail-line-${detailRow.killsheetNumber}-${index}`} supplierId={supplierId} openDocument={openDocument} isPaymentDocumentDownloaded={isPaymentDocumentDownloaded(detailRow.referenceNumber)} />
              );
            })
          }
          <PaymentHistoryDetailLine_Header />
        </div>
      </>
    );
  }
}

export default PaymentHistoryDetail;