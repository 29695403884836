import * as React from 'react';

export interface Props {
  href: string;
  src: string;
  alt: string;
  target: string;
  containerClass?: string;
  className?: string;
}

export const Logo = (props: Props) => {
  const { href, src, alt, target, className, containerClass } = props;

  return (
    <a href={href} className={containerClass} rel="home" target={target}>
      <img className={className} src={src} alt={alt} title={alt} />
    </a>
  );
};

export default Logo;