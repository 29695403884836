// ***********************************************
// ** Ref: https://github.com/StephenCleary/Guids
// ***********************************************

class Guid {
  public value: string;  

  constructor(guid: string) {
    if (!guid) { throw new TypeError('Invalid argument; `value` has no value.'); }    
    this.value = guid;    
  }
}

class GuidHelper {

  public newGuid = () => {
    return new Guid([this.gen(2), this.gen(1), this.gen(1), this.gen(1), this.gen(3)].join('-')).value.toString();
  }

  private gen(count: number) {
    let out: string = '';
    for (let i: number = 0; i < count; i++) {
        // tslint:disable-next-line:no-bitwise
        out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return out;
  }
}

export default GuidHelper;