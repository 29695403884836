import { Grid } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as DATEFORMAT from '../App/Constants/DateFormat';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PaymentHistoryDetailLine from '../App/Models/PaymentHistoryDetailLine';

interface Props {
  detailLine: PaymentHistoryDetailLine;
  openDocument: Function;
  supplierId: string;
  isPaymentDocumentDownloaded: boolean;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

@observer
class PaymentHistoryDetailLine_Item extends Component<Props> {
  render() {
    const { detailLine, openDocument, supplierId, isPaymentDocumentDownloaded } = this.props;
    const amountStyle = detailLine.amount < 0 ? 'negative' : '';

    return (
      <Grid container={true} spacing={32}>
        <Grid item={true} xs={1} className="c-paymentHistoryDetailLine__item filler-container" />
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item capitalise">
            {_propertyHelper.formatValue(detailLine.documentDate, VALUE_TYPE.DATE, DATEFORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-paymentHistoryDetailLine__item">
            {_propertyHelper.formatValue(Math.abs(detailLine.tally || 0), VALUE_TYPE.STRING)}
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-paymentHistoryDetailLine__item capitalise">
            {_propertyHelper.formatValue(detailLine.species, VALUE_TYPE.STRING)}
          </div>
        </Grid>
        <Grid item={true} xs={3}>
          <div className="c-paymentHistoryDetailLine__item capitalise">
            <div
              className={'c-paymentHistoryDetailLine__item ' + ((isPaymentDocumentDownloaded) ? 'download-pdf' : 'no-download-pdf')}
              onClick={event => openDocument(event, supplierId, detailLine.killsheetNumber, detailLine.referenceNumber)}
            >
              <LibraryBooksIcon />
              <span className="c-paymentHistoryDetailLine__collapsible-icon-text">
                {_propertyHelper.formatValue(detailLine.referenceNumber, VALUE_TYPE.INVOICE_REFERENCE, undefined, detailLine.killsheetNumber)}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className={`c-paymentHistoryDetailLine__item collapsible-item-column documentType documentType capitalise ${_.kebabCase(detailLine.documentType)}`}>
            {detailLine.documentType}
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className={`c-paymentHistoryDetailLine__item right ${amountStyle}`}>
            {_propertyHelper.formatValue(detailLine.amount, VALUE_TYPE.CURRENCY)}
          </div>
        </Grid>
      </Grid >
    );
  }
}

export default PaymentHistoryDetailLine_Item;