import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import * as FEEDBACK from '../../components/App/Constants/Feedback';
import * as STORAGE from '../../components/App/Constants/Storage';
import * as URL from '../../components/App/Constants/ExternalUrls';
import StorageHelper from '../../components/App/Helpers/StorageHelper';
import LoginButton from '../../components/Login/LoginButton';

interface Props {
  message?: string;
}

@observer
export class ErrorBoundary extends Component<Props> {
  @observable private errorInfo: any;

  private _storageHelper: StorageHelper = new StorageHelper();

  constructor(props: any) {
    super(props);
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.errorInfo = errorInfo;
    // You can also log error messages to an error reporting service here
    let errorMessage = 'Uncaught error. ' + JSON.stringify(error);
    window.telemetry.logException(errorMessage);
  }

  render() {
    if (this.errorInfo || this.props.message) {
      // Error path
      return (
        <div className="p-error-boundary-page">
          <div className="p-error-boundary-container">
            <header className="p-error-boundary-container__header">
              <img src="/images/logo.png" className="p-error-boundary-container__logo" alt="logo" />
            </header>
            <div className="p-error-boundary-container__error">
              <div className="p-error-boundary-container__content">
                <h2>Sorry, something went wrong.</h2>
                <div className="p-error-boundary-container__text">{this.props.message ? this.props.message : FEEDBACK.APPLICATION_ERROR}</div>
              </div>
            </div>
            <div className="p-error-boundary-container__button-container">
              <LoginButton onClick={this.clearErrors} />
            </div>
            <div className="p-error-boundary-container__links-container">
              <a
                href={URL.OUR_COMPANY}
                target="_blank"
                className="p-error-boundary-container__link-text"
              >
                About Silver Fern Farms
              </a>
              <a
                href={URL.CONTACT_US}
                target="_blank"
                className="p-error-boundary-container__link-text contact-us"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }

  private clearErrors = () => {
    this._storageHelper.clearValue(STORAGE.B2C_ERROR);
  }
}

export default ErrorBoundary;
