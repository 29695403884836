import { Slide } from '@material-ui/core';
import animateScrollTo from 'animated-scroll-to';
import React, { Component } from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import * as TRANSITION from '../App/Constants/Transition';
import DialogBox from '../DialogBox/DialogBox';
import SettingsActionPanel from './SettingsActionPanel';
import SettingsHeader from './SettingsHeader';
import SettingsList from './SettingsList';

interface Props { }

interface State {
  component?: any;
  isComponentDisplayed: boolean;
  open: boolean;
}

class SettingsPanel extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      component: DEFAULT_DATA.UNDEFINED,
      isComponentDisplayed: false,
      open: false
    };
  }

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.SETTINGS, TelemetryPageEventType.PageStop);
  }

  setComponent = (component: any) => {
    const element = document.getElementById('settings-container');

    if (this.state.component !== component) {
      this.setState({ isComponentDisplayed: true });
      this.setState({ component: component });

      if (element!.scrollLeft + element!.clientWidth < TRANSITION.SETTINGS_PANEL_WIDTH && component !== DEFAULT_DATA.UNDEFINED) {
        setTimeout(() => {
          animateScrollTo(TRANSITION.CONTAINER_RIGHT_POSITION, { element: element!, horizontal: true, });
        }, TRANSITION.SETTINGS_EXPAND_DELAY);
      }
    } else {
      this.setState({ component: DEFAULT_DATA.UNDEFINED });
      this.setState({ isComponentDisplayed: false });
      setTimeout(() => {
        animateScrollTo(TRANSITION.CONTAINER_LEFT_POSITION, { element: element!, horizontal: true, });
      }, TRANSITION.SETTINGS_SHRINK_DELAY);

      setTimeout(() => {
        this.setState({ component: DEFAULT_DATA.UNDEFINED });
      }, TRANSITION.COMPONENT_DESTRUCTION_DELAY);
    }
  }

  render() {
    return (
      <>
        <div id="settings-container" className="c-settings__container">
          <SettingsHeader />
          <div className="c-settings__list">
            <SettingsList handleComponentChange={this.setComponent} />
            <div className="c-settings__action">
              <Slide
                direction="left"
                in={this.state.isComponentDisplayed}
                mountOnEnter={true}
                unmountOnExit={true}
              >
                <SettingsActionPanel componentName={this.state.component} />
              </Slide>
            </div>
          </div>
        </div>
        <DialogBox />
      </>
    );
  }
}

export default SettingsPanel;