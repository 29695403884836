import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class InvoiceService extends HttpService {
  getInvoiceByFarm = (supplierId: string) => {
    const _getInvoiceByFarmIdApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.INVOICE}`;
    return super.get(_getInvoiceByFarmIdApiUrl);
  }

  getAllInvoices = () => {
    const _getAllInvoicesApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${CONNECTION.INVOICE}`;
    return super.get(_getAllInvoicesApiUrl);
  }
}

export default InvoiceService;
