import HttpService from '../Services/HttpService';

import * as CONNECTION from '../Constants/Connection';

class BookingService extends HttpService {
  getBookings = (supplierId: string) => {
    const getBookingsApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_BOOKINGS}`;

    return super.get(getBookingsApiUrl);
  }
  getBookingsDashboard = (supplierId: string) => {
    const getBookingsApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_BOOKINGS}`;

    return super.get(getBookingsApiUrl, undefined, undefined, undefined, true);
  }
}

export default BookingService;