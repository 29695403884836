import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as QUERYSTRING from '../App/Constants/QueryString';

export class Utils {

  public static supportedLanguages: Array<string> = ['en', 'es'];

  /**
   * A helper method to return the current user language. 
   * If the language is not supported then return english as default
   */
  public static get locale(): string {
    let userLocale = (navigator.languages && navigator.languages[0])
      || navigator.language
      || 'en-US';

    let language = userLocale.split('-')[0];

    let isSupported = Utils.supportedLanguages.indexOf(language);

    return (isSupported > -1) ? language : 'en';
  }

  public static getQueryString(name: string): string {
    /**
     * Parse Querystring
     * ref: https://davidwalsh.name/query-string-javascript
     */
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.href);
    return results ? DEFAULT_DATA.STRING : decodeURIComponent(results![QUERYSTRING.PARAMETER_VALUE].replace(/\+/g, ' '));
  }
}