import { autorun, IReactionDisposer, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import * as DEFAULT_DATA from '../../components/App/Constants/DefaultData';
import { TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import PowerBIDashboard from '../../components/PowerBIDashboard/PowerBIDashboard';

interface Props {
  authorizationStore?: AuthorizationStore;
}

@inject('authorizationStore')
@observer
class PowerBIPage extends Component<Props> {
  @observable private selectedFarmId: string = DEFAULT_DATA.STRING;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      if (this.props.authorizationStore!.selectedFarmId !== this.selectedFarmId && this.props.authorizationStore!.userFarms) {
        this.selectedFarmId = this.props.authorizationStore!.selectedFarmId;
      }
    });
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.ANALYTICS, TelemetryPageEventType.PageStart);
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  render() {
    return (
      <PowerBIDashboard supplierId={this.props.authorizationStore!.selectedFarmId} selectedSpecies={this.props.authorizationStore!.selectedSpecies} />
    );
  }
}

export default PowerBIPage;