import { FormControl, Select, MenuItem, IconButton, Fade, Button, Popover } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, PureComponent, ReactNode, RefObject } from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DATE_SELECTOR from '../App/Constants/DateSelector';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as TELEMETRY from '../App/Constants/Telemetry';
import PaymentsStore from '../App/Stores/PaymentsStore';
import StandaloneDateRangePicker from '../CustomDatePickers/StandaloneDateRangePicker';
import classNames from 'classnames';

interface Props {
  className?: string;
  onDateSelectionChanged: (startDate?: Date, endDate?: Date) => void;
  paymentsStore?: PaymentsStore;
  label: string;
}

interface State {
  selectedDateFilter: string;
  showDateRangePicker: boolean;
}

@observer
class PaymentHistoryDateSelector extends PureComponent<Props, State> {
  readonly StartYearToSubtract: number = 1;
  readonly MaxYearsToSubtract: number = 5;

  dateRangeButtonRef: RefObject<HTMLDivElement>;
  additionalYears: string[];
  dateRangeAnchorElement?: HTMLButtonElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDateFilter: DEFAULT_DATA.STRING,
      showDateRangePicker: false,
    };
    this.dateRangeButtonRef = React.createRef();
    this.additionalYears = _.range(this.StartYearToSubtract, this.MaxYearsToSubtract)
      .map(yearValue => moment().subtract(yearValue, 'years').format(DATE_FORMAT.YEAR_ONLY));
  }

  calculateStartAndEndDate = (selectedDateFilter: string) => {

    let values = _.split(selectedDateFilter, DEFAULT_DATA.WHITESPACE);
    const period = +values[0];
    const timeUnit = values[1];
  
    let startDate;
    let endDate;
    switch (timeUnit) {
      case 'days': {
        startDate = moment().subtract(period, 'days').toDate();
        endDate = moment().subtract(1, 'days').toDate();
        break;
      }
      case 'months': {
        startDate = moment().subtract(period, 'months').toDate();
        endDate = moment().subtract(1, 'days').toDate();
        break;
      }
      case 'years': {
        startDate = moment(`${period}0101`, DATE_FORMAT.YEAR_MONTH_AND_DAY).toDate();
        endDate = moment(`${period}1231`, DATE_FORMAT.YEAR_MONTH_AND_DAY).toDate();
        break;
      }
      default: {
        startDate = DEFAULT_DATA.UNDEFINED;
        endDate = DEFAULT_DATA.UNDEFINED;
      }
     
    }
  
    if (this.props.onDateSelectionChanged) {
      this.props.onDateSelectionChanged(startDate, endDate);
    }
  }

  handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => {
    const selectedDateFilter = event.target.value as string;
    this.setState({ selectedDateFilter: selectedDateFilter });
    this.calculateStartAndEndDate(selectedDateFilter);
  }

  getMenuItemClassName = (itemValue: string) => {
    const { selectedDateFilter } = this.state;
    const selectedClassName = 'selected';

    if (selectedDateFilter === itemValue) {
      return selectedClassName;
    }
    return;
  }

  toggleDayPickerRangeControllerVisibility = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.dateRangeAnchorElement = event.currentTarget;
    this.setState({ showDateRangePicker: true });
  }

  handleDatesChange = (change: { startDate: Moment | null, endDate: Moment | null }) => {
    const { paymentsStore } = this.props;
    if (change.startDate && change.endDate) {
      window.telemetry.logEvent(TELEMETRY.EVENTS.PAYMENT_DATE_PICKER);
      const selectedDateFilter = change.startDate.format('MMM DD, YYYY') + ' - ' + change.endDate.format('MMM DD, YYYY');
      this.props.onDateSelectionChanged(change.startDate.toDate(), change.endDate.toDate());
      this.setState({ showDateRangePicker: false, selectedDateFilter });
      paymentsStore!.clearExportFilter();
      paymentsStore!.setExportFilter(change.startDate.toDate(), change.endDate.toDate(), selectedDateFilter);
    }
  }

  handleDateRangeClose = () => {
    this.dateRangeAnchorElement = undefined;
    this.setState({ showDateRangePicker: false});
  } 

  renderValue = () => {

    for (let item of DATE_SELECTOR.ITEMS) {
      if (item.value === this.state.selectedDateFilter) {
        
        return item.text;
      }
    }
    for (let additionalYear of this.additionalYears) {
      if (new RegExp(`${additionalYear} years$`).test(this.state.selectedDateFilter)) {
     
        return additionalYear;
      }
    }
  
    return this.state.selectedDateFilter;
  }

  render() {
    const { className, paymentsStore } = this.props;
    const { showDateRangePicker, selectedDateFilter } = this.state;
 
    return (
      <div className={classNames(className, 'c-paymentHistoryDateSelector_div')}>
        <FormControl classes={{ root: 'c-paymentHistoryDateSelector' }}>
        <span>Date Range:</span>
          <Select
            className="c-paymentHistoryDateSelector-select"
            displayEmpty={true}
            renderValue={this.renderValue}
            value={selectedDateFilter}
            onChange={this.handleChange}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              classes: { paper: 'c-paymentHistoryDateSelector-menu' }
            }}
            disabled={!paymentsStore!.isPaymentsLoaded}
          >
            {DATE_SELECTOR.ITEMS.map((item, index) => {
              return <MenuItem value={item.value} key={`payment-history-date-selector-default-${index}`} className={this.getMenuItemClassName(item.value)}>{item.text}</MenuItem>;
            })}
            {this.additionalYears.map((year, index) => {
              return (<MenuItem value={`${year} years`} key={`payment-history-date-selector-${index}`}>{year}</MenuItem>);
            })};
          </Select>
          <div ref={this.dateRangeButtonRef}>
            <IconButton
              classes={{
                root: 'c-paymentHistoryDateSelector-daterange',
                disabled: 'c-paymentHistoryDateSelector-daterange-disabled',
              }}
              onClick={this.toggleDayPickerRangeControllerVisibility}
              disabled={!paymentsStore!.isPaymentsLoaded}
            >
              <DateRangeIcon />
            </IconButton>
          </div>
        </FormControl>
        <Popover
                  open={showDateRangePicker}
                  anchorEl={this.dateRangeAnchorElement}
                  onClose={this.handleDateRangeClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
        >
          <StandaloneDateRangePicker
            onDatesChange={this.handleDatesChange}
            onClickAway={event => {
              if ((this.dateRangeButtonRef.current as any).contains(event.target)) {
                return;
              }
              this.setState({ showDateRangePicker: false });
            }}
          />
        </Popover> 
      </div>
    );
  }
}

export default PaymentHistoryDateSelector;