import {
	createStyles,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Theme,
	withStyles,
	WithStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as STORAGE from '../App/Constants/Storage';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as TRANSITION from '../App/Constants/Transition';
import StorageHelper from '../App/Helpers/StorageHelper';
import DialogStore from '../App/Stores/DialogStore';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			maxWidth: 450,
			marginTop: 'auto',
		},
		smallRoot: {
			maxWidth: 450,
			flexGrow: 1,
		},
		closeButton: {
			position: 'absolute',
			right: '8px',
			top: '8px',
			color: 'black',
		},
		closeButtonSmall: {
			position: 'absolute',
			right: '8px',
			top: '8px',
			color: 'black',
		},
		title: {
			paddingBottom: 0,
			'&& h2': {
				fontFamily: 'Graphik',
				fontSize: '1.2em',
				color: 'white',
				textAlign: 'center',
				textTransform: 'uppercase',
			},
		},
		titleMedium: {
			paddingBottom: 0,
			'&& h2': {
				fontFamily: 'Graphik',
				fontSize: '1.1em',
				color: 'white',
				textAlign: 'center',
				textTransform: 'uppercase',
			},
		},
		titleSmall: {
			paddingBottom: 0,
			paddingTop: 4,
			'&& h2': {
				fontFamily: 'Graphik',
				fontSize: '1em',
				color: 'white',
				textAlign: 'center',
				textTransform: 'uppercase',
			},
		},
		content: {
			overflow: 'hidden',
			
		},
		actions: {
			justifyContent: 'center',
		},
		backdrop: {
			opacity: 0.3,
		},
		imageRight: {
			display: 'block',
			marginLeft: 'auto',
		      marginRight: 'auto',
		      width:'80%',
		      height:'50%',
			  maxHeight: '350px',
		},
		largeText: {
			fontSize: 20,
			fontFamily: 'Graphik-Medium',
		},
		smallText: {
			fontSize: 16,
			fontFamily: 'Graphik',
		},
		appStores1:{
			float:'left',
			display: 'block',
			marginRight: 'auto',
			width:'50%',
			align:'right',
		},		
		imageCenter: {
			display: 'block',
			textAlign: 'center',
			margin: 'auto',
			width: '250px',
		},
		appStores2:{
			float:'right',
			display: 'block',
  			marginLeft: 'auto',
			width:'50%',
		},
		teamAccessImage:{
			display: 'block',
  			marginLeft: 'auto',
			marginRight: 'auto',
			width:'80%',
			height:'50%'
			
		},
		
	});

interface Props extends WithStyles<typeof styles> {
	dialogStore?: DialogStore;
}

interface State {
	activeFeature: number;
	height: 'large' | 'medium' | 'small';
}

@inject('dialogStore')
@observer
class MobileAppRequiredDialog extends React.Component<Props, State> {
	private _storageHelper: StorageHelper = new StorageHelper();
	private mediaQuerySmall = window.matchMedia('(max-height: 679px)');
	private mediaQueryMedium = window.matchMedia('(max-height: 768px)');
	private mediaQueryLarge = window.matchMedia('(min-height: 769px)');

	constructor(props: Props) {
		super(props);
		this.state = {
			activeFeature: 0,
			height: 'large',
		};

		window.onorientationchange = () => {
			setTimeout(() => {
				this.mediaCheck();
			}, TRANSITION.DASHBOARD_ORIENTATION_DELAY);
		};
	}

	mediaCheck = () => {
		if (this.mediaQuerySmall.matches) {
			this.setState({ height: 'small' });
		} else if (this.mediaQueryMedium.matches) {
			this.setState({ height: 'medium' });
		} else {
			this.setState({ height: 'large' });
		}
	};

	componentWillMount = () => {
		this.mediaCheck();

		this.mediaQuerySmall.addListener((mq) => {
			if (mq.matches) {
				this.setState({ height: 'small' });
			}
		});
		this.mediaQueryMedium.addListener((mq) => {
			if (mq.matches) {
				this.setState({ height: 'medium' });
			}
		});
		this.mediaQueryLarge.addListener((mq) => {
			if (mq.matches) {
				this.setState({ height: 'large' });
			}
		});
	};

	handleClose = () => {
		const appVersion = _.isEmpty(process.env.REACT_APP_VERSION)
			? window.appVersion
			: process.env.REACT_APP_VERSION;
		this._storageHelper.storeKeyValue(STORAGE.APP_VERSION, appVersion);
		window.telemetry.logEvent(TELEMETRY.EVENTS.MOBILE_APP_DIALOG_CLOSE);
		this.props.dialogStore!.setDialogClosed();
		window.logOut();
	};

	handleClickOnAppleStore = () => {
		const appVersion = _.isEmpty(process.env.REACT_APP_VERSION)
			? window.appVersion
			: process.env.REACT_APP_VERSION;
		this._storageHelper.storeKeyValue(STORAGE.APP_VERSION, appVersion);
		window.telemetry.logEvent(TELEMETRY.EVENTS.APPLE_STORE_CLICK);
	}

	handleClickOnAndroidStore = () => {
		const appVersion = _.isEmpty(process.env.REACT_APP_VERSION)
			? window.appVersion
			: process.env.REACT_APP_VERSION;
		this._storageHelper.storeKeyValue(STORAGE.APP_VERSION, appVersion);
		window.telemetry.logEvent(TELEMETRY.EVENTS.ANDROID_STORE_CLICK);
	}
	handleClickOnEmail = () => {
		window.telemetry.logEvent(TELEMETRY.EVENTS.MOBILE_APP_DIALOG_EMAIL_CLICK);

	}

	render() {
		const { classes, dialogStore } = this.props;
		const { height } = this.state;
		const backDropProps = {
			invisible: true,
			classes: {
				invisible: classes.backdrop,
			},
		};

		const rootClass = classNames({
			[classes.root]: height === 'large' || height === 'medium',
			[classes.smallRoot]: height === 'small',
		});

		const titleClass = classNames({
			[classes.title]: height === 'large',
			[classes.titleMedium]: height === 'medium',
			[classes.titleSmall]: height === 'small',
		});
		const closeButtonClass = classNames({
			[classes.closeButton]: height === 'large' || height === 'medium',
			[classes.closeButtonSmall]: height === 'small',
		});

		let appleDevice = false;
		if (/^iP|^ip/.test(navigator.platform)) {
        		appleDevice = true;
	        } 

		let appDownloadIcons;
			if(!appleDevice)
			{
			appDownloadIcons =	<div >
					<a href="https://apps.apple.com/nz/app/silver-fern-farmer/id1474712307?ign-itscg=30200&ign-itsct=apps_box_link" target="blank" onClick={this.handleClickOnAppleStore}>
						<img className={classes.appStores1}
							src='/images/apple_store_download.png'
							alt='Apple Store'
						/>
					</a>
					<a href="https://play.google.com/store/apps/details?id=com.silverfernfarms.www.sffapp" target="blank" onClick={this.handleClickOnAndroidStore}>
						<img className={classes.appStores2}
							src='/images/play_store_download.png'
							alt='Google Play Store'
						/>
					</a>
				</div>;
			}
			else
			{
				appDownloadIcons =	<div>
					<a href="https://apps.apple.com/nz/app/silver-fern-farmer/id1474712307?ign-itscg=30200&ign-itsct=apps_box_link" target="blank" onClick={this.handleClickOnAppleStore}>
						<img className={classes.imageCenter}
							src='/images/apple_store_download.png'
							alt='Apple Store'
						/>
					</a>
				</div>;
			}
		let appStoreTextOnly;
		if (!appleDevice)
		{
			appStoreTextOnly = <div>
				<div className={classes.largeText}>
					<p>Please use your mobile to download Silver Fern Farmer from the App Store or Google Play.</p>
				</div>
			</div>
		}
		else 
		{
		appStoreTextOnly = <div>
				<div className={classes.largeText}>
					<p>Please use your mobile to download Silver Fern Farmer from the App Store.</p>
				</div>
			</div>
		}

		return (
			<Dialog
				open={dialogStore!.isDialogOpen}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						this.handleClose();
					}
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				BackdropProps={backDropProps}
			>
				<DialogTitle id='alert-dialog-title'>
					{dialogStore!.title}
					<IconButton
						aria-label='close'
						className={closeButtonClass}
						onClick={this.handleClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent className={classes.content}>
				<div>
					<div className={rootClass}>
						{appStoreTextOnly}
						<div className={classes.smallText}>
							
							<p>If you need any assistance, please contact our Customer Services team on 0800 362 362 or
								<a href="mailto:service@silverfernfarms.co.nz?subject=Silver Fern Farmer" onClick={this.handleClickOnEmail}> service@silverfernfarms.co.nz</a>
							</p>
						</div>

					</div>
					<div className="teamAccessDialogImage" >
						<img className={classes.imageRight} 
							
							src='/images/team_access_mobile.png'
							alt='lable'
						/>
					</div>
					{appDownloadIcons}
				</div>
				</DialogContent>
			</Dialog>
		);
	}
}

export default withStyles(styles)(MobileAppRequiredDialog);
