// This constants file contains values that relate to Payment screen
export const DEFAULT_PAGE = 0;
export const PAGE_SIZE = 15;

export const ALL_FARMS_ID = '0';
export const AMOUNT = 'AMOUNT';
export const DATE = 'DATE';
export const FARM_NAME = 'FARM NAME';
export const PAYMENT_DATE = 'PAYMENT DATE';
export const SPECIES = 'SPECIES';
export const SUPPLIER_ID = 'SUPPLIER ID';
export const TALLY = 'TALLY';
export const TOTAL_AMOUNT = 'TOTAL AMOUNT';