// Generic default message
export const DEFAULT_MESSAGE_TITLE = 'An Error Occurred';
export const DEFAULT_MESSAGE_CONTENT = 'There seems to be an issue. Please try again. Should the issue persist, please contact the system administrator.';
export const DEFAULT_BUTTON_TEXT = 'OK';
export const DEFAULT_MESSAGE_SHORT_CONTENT = 'There seems to be an issue. Please try again.';

// HTTP response code messages
// 401 Unauthorised
export const UNAUTHORISED_MESSAGE_TITLE = 'Session Timed Out';
export const UNAUTHORISED_MESSAGE_CONTENT = 'Your session has timed out. Please sign in again. Thank you.';
export const UNAUTHORISED_BUTTON_TEXT = 'Sign in';

// 401 Unauthorised
export const UNAUTHORISED_INVALID_APP_MESSAGE_TITLE = 'Session Invalid';
export const UNAUTHORISED_INVALID_APP_MESSAGE_CONTENT = 'Your session has an invalid token. Please sign in again. Thank you.';

// 404 Not Found
export const NOT_FOUND_MESSAGE_TITLE = 'Resource Not Found';
export const NOT_FOUND_MESSAGE_CONTENT = 'The resource you are looking for is unavailable.';
export const NOT_FOUND_BUTTON_TEXT = 'OK';

// Custom messages
// No farms available on the farm switcher
export const NO_FARMS_MESSAGE_TITLE = 'No Farms Available';
export const NO_FARMS_MESSAGE_CONTENT = 'You currently have no access to any Farms. Please contact Customer Services if this is incorrect.  Thank you.';
export const NO_FARMS_BUTTON_RETRY_TEXT = 'Retry';
export const NO_FARMS_BUTTON_LOGOUT_TEXT = 'Logout';

// No terms and conditions available on startup
export const NO_TERMS_MESSAGE_TITLE = 'No Terms Available';
export const NO_TERMS_MESSAGE_CONTENT = 'Terms and Conditions have failed to load. Please contact Customer Services if this is incorrect.  Thank you.';
export const NO_TERMS_BUTTON_RETRY_TEXT = 'Retry';
export const NO_TERMS_BUTTON_LOGOUT_TEXT = 'Logout';
