import HttpService from '../Services/HttpService';

import * as CONNECTION from '../Constants/Connection';

class BannerService extends HttpService {
  getBanner = () => {
    const getBannerApiUrl =
      `${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.BANNER}`;
    return super.get(getBannerApiUrl);
    // return super.get(getBannerApiUrl, undefined, undefined, undefined, true);
  }
}

export default BannerService;