import _ from 'lodash';
import FilterItem from '../Models/FilterItem';
import KillSheetDetails from '../Models/KillSheetDetails';
import KillSheetDetailsCondemned from '../Models/KillSheetDetailsCondemned';
import KillSheetDetailsDead from '../Models/KillSheetDetailsDead';
import KillSheetDetailsGrade from '../Models/KillSheetDetailsGrade';

import * as ARRAY from '../Constants/Array';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as KILLSHEET from '../Constants/KillSheet';
import * as STOCK from '../Constants/Stock';

class KillSheetHelper {
  public getSpeciesIcon = (species: string) => {
    return KILLSHEET.ICONS.find(i => i.name === _.lowerCase(species));
  }

  public getCategories = (killSheetDetails: Array<KillSheetDetails>) => {
    if (killSheetDetails) {
      return _.map(killSheetDetails, KILLSHEET.ANIMAL_CATEGORY);
    }
    return killSheetDetails;
  }

  public filterGradesByCategory = (killSheetDetails: Array<KillSheetDetails>, category: string) => {
    if (killSheetDetails && category && category !== STOCK.ALL) {
      return _.filter(killSheetDetails, [KILLSHEET.ANIMAL_CATEGORY, category]);
    }
    return killSheetDetails;
  }

  public sortGrades = (killSheetDetails: Array<KillSheetDetails>) => {
    let _grades = new Array<KillSheetDetailsGrade>();

    if (killSheetDetails) {
      _.each(killSheetDetails, killSheetDetail => {
        if (killSheetDetail.killsheetDetailsGrades) {
          _.each(killSheetDetail.killsheetDetailsGrades, grades => {
            if (grades.grade !== KILLSHEET.CONDEMNED && grades.grade !== KILLSHEET.DEAD) {
              grades.animalCategory = killSheetDetail.animalCategory;
              _grades.push(grades);
            }
          });
        }
      });
      _grades = _.orderBy(_grades, [KILLSHEET.QUANTITY], [ARRAY.DESCENDING_ORDER]);
    }
    return _grades;
  }

  public getDistinctDetailCategories = (killSheetDetails: Array<KillSheetDetails>) => {
    let _distinctCategories = this.getDistinctCategories(killSheetDetails);
    let _filterItems = new Array<FilterItem>();

    if (_distinctCategories.length > ARRAY.SINGLE_ELEMENT) {
      _filterItems.push(new FilterItem(STOCK.ALL, STOCK.ALL));
      this.populateFilterItems(_distinctCategories, _filterItems);
    }
    return _filterItems;
  }

  public getDistinctStateCategories = (killSheetDetails: Array<KillSheetDetails>) => {
    let _distinctCategories = this.getDistinctCategories(killSheetDetails);
    let _filterItems = new Array<FilterItem>();

    this.populateFilterItems(_distinctCategories, _filterItems);

    return _filterItems;
  }

  public getKillSheetDetailStates = (details?: Array<KillSheetDetails>, type?: string) => {
    let _detailStates: Map<string, Array<KillSheetDetailsCondemned> | Array<KillSheetDetailsDead>> = new Map();

    if (details && type) {
      switch (type) {
        case KILLSHEET.CONDEMNED:
          _.each(details, detail => {
            if (detail.killsheetDetailsGrades.findIndex(killsheetGrade => killsheetGrade.grade === KILLSHEET.CONDEMNED) !== -1) {
              _.each(detail.killsheetDetailsCondemned, condemned => {
                if (!_detailStates.has(detail.animalCategory)) {
                  _detailStates.set(detail.animalCategory, new Array<KillSheetDetailsCondemned>());
                }
                _detailStates.get(detail.animalCategory)!.push(condemned);
              });
            }
          });
          break;
        case KILLSHEET.DEAD:
          _.each(details, detail => {
            if (detail.killsheetDetailsGrades.findIndex(killsheetGrade => killsheetGrade.grade === KILLSHEET.DEAD) !== -1) {
              _.each(detail.killsheetDetailsDead, dead => {
                if (!_detailStates.has(detail.animalCategory)) {
                  _detailStates.set(detail.animalCategory, new Array<KillSheetDetailsDead>());
                }
                _detailStates.get(detail.animalCategory)!.push(dead);
              });
            }
          });
          break;
        default:
          break;
      }
    }
    return _detailStates;
  }

  public formatUrl = (path?: string) => {
    if (path) {
      return `${window.appSettings.apiUrl}${path}`;
    }
    return DEFAULT_DATA.UNDEFINED;
  }

  private getDistinctCategories = (killSheetDetails: Array<KillSheetDetails>) => {
    if (!_.isEmpty(killSheetDetails)) {
      return [...new Set(killSheetDetails.map(c =>
        c.animalCategory
      ))];
    }
    return new Array<string>();
  }

  private populateFilterItems = (categories: Array<string>, filterItems: Array<FilterItem>) => {
    if (!_.isEmpty(categories)) {
      _.each(categories, category => {
        filterItems.push(new FilterItem(category, category));
      });
    }
  }
}

export default KillSheetHelper;