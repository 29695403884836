import React, { Component } from 'react';
import { Button, createStyles, WithStyles, withStyles } from '@material-ui/core';
import _ from 'lodash';
import { observer } from 'mobx-react';
import CarcaseReportStore from '../App/Stores/CarcaseReportStore';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '0.6em'
    },
    style1: {
      border: '1px solid #427b5d',
      borderRadius: 0,
      backgroundColor: 'white',
      textTransform: 'uppercase',
      margin: 6,
      minWidth: 120,
      '&:hover': {
        backgroundColor: '#427b5d',
        '&& span': {
          color: 'white !important'
        }
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: '#427b5d',
      }
    },
    style2: {
      backgroundColor: '#427b5d',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black'
        }
      },
      '&& span': {
        fontFamily: 'Graphik-Semibold',
        color: 'white',
        minWidth: 120
      }
    }
  });

interface Props extends WithStyles<typeof styles> {
  carcaseReportStore: CarcaseReportStore;
  categories: string[];
  buttonStyle: 'style1' | 'style2';
}

@observer
class AnimalCategories extends Component<Props> {

  constructor(props: Props) {
    super(props);    
  }

  openChart = (category: string) => {
    this.props.carcaseReportStore.category = category;
    this.props.carcaseReportStore.handleCloseAnimalCategories();
    this.props.carcaseReportStore.handleOpenChart(`${_.toUpper(category)} CARCASE REPORT`);
  }

  render() {

    const { classes, buttonStyle, categories } = this.props;

    return (
      <>  
        <div className={classes.root}> 
        {categories.map((category, key) => (
          <Button key={key} className={classes[buttonStyle]} onClick={() => this.openChart(category)}>{category}</Button>        
        ))}   
        </div>        
      </>   
    );
  }
}

export default withStyles(styles)(AnimalCategories);