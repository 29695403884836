import { observer } from 'mobx-react';
import React, { Component } from 'react';
import PaymentsHelper from '../App/Helpers/PaymentsHelper';
import Entity from '../App/Models/Entity';
import OutstandingInvoiceDetailLine from '../App/Models/OutstandingInvoiceDetailLine';
import OutstandingInvoiceDetailLine_Header from './OutstandingInvoiceDetailLine_Header';
import OutstandingInvoiceDetailLine_Item from './OutstandingInvoiceDetailLine_Item';

interface Props {
  outstandingInvoiceDetailLines?: Array<OutstandingInvoiceDetailLine>;
  userFarms: Array<Entity>;
  openDocument: Function;
  isInvoiceDocumentDownloaded: Function;
}

const paymentsHelper: PaymentsHelper = new PaymentsHelper();

@observer
class OutstandingInvoiceDetail extends Component<Props> {

  render() {
    let { outstandingInvoiceDetailLines, userFarms, openDocument, isInvoiceDocumentDownloaded } = this.props;
    if (!outstandingInvoiceDetailLines) {
      return (<div />);
    }
    return (
      <>
        <div className="c-outstandingInvoiceDetailLine__div">
          {
            outstandingInvoiceDetailLines!.map((detailRow, index) => {
              return (
                <OutstandingInvoiceDetailLine_Item key={index} detailLine={detailRow} farmName={paymentsHelper.getFarmName(detailRow.supplierId, userFarms)} openDocument={openDocument} isInvoiceDocumentDownloaded={isInvoiceDocumentDownloaded(detailRow.referenceNumber)} />
              );
            })
          }
          <OutstandingInvoiceDetailLine_Header />
        </div>
      </>
    );
  }
}

export default OutstandingInvoiceDetail;