import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as KILLSHEET from '../App/Constants/KillSheet';
import AuthorizationStore from '../App/Stores/AuthorizationStore';

const styles = (theme: Theme) => createStyles({
  buttonRoot: {
    background: 'white',
    fontFamily: 'Graphik-Medium',
    color: 'rgb(51, 51, 51)',
    boxShadow: '0 0.15em 0.3em 0 rgba(0, 0, 0, 0.16)',
  },
  buttonRightIcon: {
    marginLeft: '0.5em',
    marginBottom: '0.1em',
    fontSize: 'large',
  }
});

interface Props extends WithStyles<typeof styles> {
  authorizationStore?: AuthorizationStore;
}

@inject('authorizationStore')
@observer
class SearchTimelineHeader extends React.Component<Props> {
  onClearFilter = () => {
    const { authorizationStore } = this.props;
    authorizationStore!.killSheets = [];
    authorizationStore!.isKillSheetsLoaded = false;
    // clearing a date filter always clears all farms selection
    if (authorizationStore!.lastSearchStartDate) {
      authorizationStore!.isAllFarmsSelected = false;
    authorizationStore!.setSelectedFarm(authorizationStore!.selectedFarmId);
    }
    if (authorizationStore!.isAllFarmsSelected) {
      authorizationStore!.getAllKillSheets();
    } else {
      authorizationStore!.getFarmKillSheets(authorizationStore!.selectedFarmId);
    }
  }

  render() {
    const { classes, authorizationStore } = this.props;
    return (
      <>
        <div className="c-timeline__header-container">
          <h1 className="c-timeline__header">{KILLSHEET.TIMELINE_HEADER}</h1>
        </div>
        <div
          className={classNames({
            'c-timeline__header-search-result': true,
            'c-timeline__hidden': !authorizationStore!.isKillSheetsLoaded,
          })}
        >
          {(() => {
            switch (authorizationStore!.isKillSheetsSearchResult) {
              case 'number':
                return `Result for "${authorizationStore!.lastSearchQuery}" of all farms`;
              case 'date':
                return `Showing ${authorizationStore!.killSheets.length} results of all farms`;
              default:
                return null;
            }
          })()}
          <Button
            variant="contained"
            classes={{
              root: classes.buttonRoot,
            }}
            onClick={this.onClearFilter}
          >
            CLEAR FILTER
            <CloseIcon className={classes.buttonRightIcon} />
          </Button>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SearchTimelineHeader);
