import { Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import * as DEFAULT_DATA from '../../components/App/Constants/DefaultData';
import * as EVENTS from '../../components/App/Constants/Events';
import * as STOCK from '../../components/App/Constants/Stock';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import BookingStore from '../../components/App/Stores/BookingStore';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import ForecastStore from '../../components/App/Stores/ForecastStore';
import Loading from '../../components/Loading/Loading';
import Bookings from '../../components/Stocks/Bookings';
import Forecasts from '../../components/Stocks/Forecasts';
import StockHeader from '../../components/Stocks/StockHeader';

const styles = (theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  }
});

interface Props extends WithStyles<typeof styles> {
  bookingStore: BookingStore;
  authorizationStore: AuthorizationStore;
  forecastStore: ForecastStore;
  theme: any;
}

interface State {
  value: number;
  innerWidth: number;
}

@inject('bookingStore')
@inject('authorizationStore')
@inject('forecastStore')
@observer
class ManageMyStock extends React.Component<Props, State> {
  private selectedFarmId: string = DEFAULT_DATA.STRING;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: 0,
      innerWidth: window.innerWidth,
    };

    window.addEventListener(EVENTS.WINDOW_RESIZE, this.updateInnerWidth);
    this.disposer = autorun(() => {
      if (this.props.authorizationStore!.selectedFarmId !== this.selectedFarmId) {
        this.selectedFarmId = this.props.authorizationStore!.selectedFarmId;
        this.props.bookingStore!.getBookings(this.selectedFarmId);
        this.props.forecastStore!.getForecasts(this.selectedFarmId);
      }
    });
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_STOCK, TelemetryPageEventType.PageStart);
  }

  componentDidMount = () => {
    window.addEventListener(EVENTS.WINDOW_RESIZE, this.updateInnerWidth);
    this.handleChangeIndex(this.props.forecastStore!.isForecastsPageDisplayed ? 1 : 0);
    window.telemetry.logEvent(PAGE_NAME.MY_STOCK, TelemetryEventType.Page);
  }

  componentWillUnmount = () => {
    window.removeEventListener(EVENTS.WINDOW_RESIZE, this.updateInnerWidth);
    this.disposer();
  }

  updateInnerWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  }

  handleChange = (event: any, value: any) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  }

  renderTabletView = () => {
    const { theme } = this.props;
    return (
      <div className="p-my-stock__tablet-view-container">
        <div className="p-my-stock__container">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="p-my-stock__tabs"
          >
            <Tab className="p-my-stock__tab" label={STOCK.BOOKING_HEADER_TITLE} disableRipple={true} />
            <Tab className="p-my-stock__tab" label={STOCK.FORECAST_HEADER_TITLE} disableRipple={true} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            <div className="c-stocks__container">
              <Bookings />
            </div>
            <div className="c-stocks__container">
              <Forecasts />
            </div>
          </SwipeableViews>
        </div>
      </div>
    );
  }

  renderDesktopView = () => {
    return (
      <div className="p-my-stock__desktop-view-container">
        <div className="p-my-stock__container">
          <div className="c-stocks__container">
            <StockHeader title={STOCK.BOOKING_HEADER_TITLE} />
            <Bookings />
          </div>
          <div className="c-stocks__container-divider" />
          <div className="c-stocks__container">
            <StockHeader title={STOCK.FORECAST_HEADER_TITLE} />
            <Forecasts />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { bookingStore, authorizationStore, forecastStore, theme } = this.props;

    if (
      !authorizationStore!.selectedFarmId &&
      bookingStore.isBookingsInitialLoad &&
      !bookingStore.isBookingsLoaded &&
      forecastStore.isForecastsInitialLoad &&
      !forecastStore.isForecastsLoaded
    ) {
      return <Loading />;
    } else {
      if (window.matchMedia('(max-width: 1919px)').matches) {
        /* the viewport is less than or exactly 1919 pixels wide */
        return this.renderTabletView();
      } else {
        /* the viewport is more than 1920 pixels wide */
        return this.renderDesktopView();
      }
    }
  }
}

export default withStyles(styles, { withTheme: true })(ManageMyStock);