import lottie from 'lottie-web';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';

interface Props {
  isDisplayed: boolean;
  id: string;
}

class ProgressIndicator extends React.Component<Props> {

  componentDidMount = () => {
    const { id } = this.props;
    lottie.loadAnimation({
      container: document.getElementById(id), // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/loading.json' // the path to the animation json
    });
  }

  render() {
    const { id } = this.props;
    const animationClass = this.props.isDisplayed ? DEFAULT_DATA.STRING : 'hide';
    return <div id={id} className={animationClass} />;
  }
}

export default ProgressIndicator;
