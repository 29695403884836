import { CircularProgress } from '@material-ui/core';
import { inject } from 'mobx-react';
import React from 'react';
import * as TELEMETRY from '../App/Constants/Telemetry';
import AuthStore from '../App/Stores/AuthStore';

interface Props {
  authStore?: AuthStore;
  onClick?: () => void;
}

interface State {
  isClicked: boolean;
}

const noop = () => { }; // tslint:disable-line

@inject('authStore')
class LoginButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isClicked: false
    };
  }

  signIn() {
    // eslint-disable-next-line
    this.props.onClick ? this.props.onClick() : noop;
    this.setState({ isClicked: true });
    window.telemetry.logEvent(TELEMETRY.EVENTS.LOGIN);
    this.props.authStore!.login();
  }

  render() {
    if (this.state.isClicked) {
      return (
        <button disabled={true}>
          <CircularProgress className="c-login-button__progress" size={14} />
        </button>
      );
    }

    return (
      <button onClick={() => this.signIn()}>
        <span>Sign In</span>
      </button>
    );
  }
}

export default LoginButton;
