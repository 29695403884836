import _ from 'lodash';
import { observable, action, _interceptReads } from 'mobx';

import Article from '../Models/Article';
import ArticleService from '../Services/ArticleService';

import * as WHATS_NEW from '../Constants/WhatsNew';
import * as ARRAY from '../Constants/Array';
import * as TRANSITION from '../Constants/Transition';

class ArticleStore {

  @observable public articles: Array<Article>;
  @observable public selectedArticleIndex: number;
  @observable public isArticleLoaded: boolean;
  @observable public isArticleLoadError: boolean;
  @observable public isArticleComplete: boolean;
  @observable public currentPage: number;

  private _articleService: ArticleService = new ArticleService();

  constructor() {
    this.articles = new Array<Article>();
    this.selectedArticleIndex = ARRAY.UNDEFINED;
    this.isArticleLoaded = false;
    this.isArticleLoadError = false;
    this.isArticleComplete = false;
    this.currentPage = WHATS_NEW.DEFAULT_PAGE;
  }

  @action
  getArticlePdf = (articleFilename: string) => {
    return this._articleService.getArticle(encodeURI(articleFilename));
  }

  @action
  getArticles = () => {
    this.isArticleLoaded = false;
    this.isArticleLoadError = false;

    return this._articleService.getArticles(++this.currentPage, WHATS_NEW.PAGE_SIZE)
      .then(response => {
        if (response!.data) {
          if (this.currentPage && this.currentPage > WHATS_NEW.DEFAULT_PAGE) {
            this.articles.push(...response!.data.articles);
          } else {
            this.articles = response!.data!.articles;
          }
          if (response!.data!.articles.length < WHATS_NEW.PAGE_SIZE) {
            this.isArticleComplete = true;
          }
        } else {
          this.isArticleComplete = true;
        }

        if (_.isEmpty(this.articles)) {
          this.isArticleLoadError = true;
        }

        this.isArticleLoaded = true;
      }).catch(() => {
        this.isArticleLoadError = true;
        this.isArticleLoaded = true;
      });
  }

  @action
  setSelectedArticleIndex = (selectedArticleIndex: number) => {
    if (this.selectedArticleIndex !== selectedArticleIndex) {
      this.selectedArticleIndex = ARRAY.UNDEFINED;
      setTimeout(() => {
        this.selectedArticleIndex = selectedArticleIndex;
      }, TRANSITION.ARTICLE_EXPAND_DELAY);
    }
  }
}

export default ArticleStore;
