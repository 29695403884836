import React from 'react';
import _ from 'lodash';
import Booking from '../Models/Booking';
import Forecast from '../Models/Forecast';
import moment from 'moment';

import * as ARRAY from '../Constants/Array';
import * as DATE_FORMAT from '../Constants/DateFormat';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as STOCK from '../Constants/Stock';
import DeerIcon from '../Constants/DeerIcon';
import SheepIcon from '../Constants/SheepIcon';
import BeefIcon from '../Constants/BeefIcon';
import classNames from 'classnames';

class StockHelper {
  public sortBookings = (bookings: Array<Booking>) => {
    let _year = DEFAULT_DATA.STRING;

    if (!_.isEmpty(bookings)) {
      bookings = _.orderBy(
        bookings,
        [STOCK.ARRIVAL_DATE],
        [ARRAY.ASCENDING_ORDER]
      );

      bookings.forEach(booking => {
        booking.year = moment(booking.arrivalDate).format(
          DATE_FORMAT.YEAR_ONLY
        );
        booking.isFirstElementForYear = booking.year !== _year;
        _year = booking.year;
      });
      return bookings;
    }
    return new Array<Booking>();
  }

  public sortForecasts = (forecasts: Array<Forecast>) => {
    let _year = DEFAULT_DATA.STRING;

    if (!_.isEmpty(forecasts)) {
      forecasts = _.orderBy(
        forecasts,
        [STOCK.WEEK_START_DATE],
        [ARRAY.ASCENDING_ORDER]
      );

      forecasts.forEach(forecast => {
        forecast.year = moment(forecast.weekStartDate).format(
          DATE_FORMAT.YEAR_ONLY
        );
        forecast.isFirstElementForYear = forecast.year !== _year;
        _year = forecast.year;
      });
      return forecasts;
    }
    return new Array<Forecast>();
  }

  public getSpeciesIcon = (species: string) => {
    return STOCK.ICONS.find(i => i.name === species);
  }

  public getSpeciesIconForBooking = (species: string) => {
    return STOCK.ANIMAL_ICON_SHARES.find(i => i.name === species);
  }

  public getFarmAssuranceIcon = (species: string, singleTile: boolean) => {

    switch (species) {
      case 'sheep':
        return <SheepIcon className={classNames(`c-dashboard__tile-farm-assure-species-icon-${species}`, singleTile ? 'single-tile' : '')} />;
      case 'deer':
        return <DeerIcon className={classNames(`c-dashboard__tile-farm-assure-species-icon-${species}`, singleTile ? 'single-tile' : '')} />;
      case 'beef':
        return <BeefIcon className={classNames(`c-dashboard__tile-farm-assure-species-icon-${species}`, singleTile ? 'single-tile' : '')} />;
      default:
        return <></>;
    }

  }
}

export default StockHelper;