import { observable } from 'mobx';
import AccountStore from './AccountStore';
import ArticleStore from './ArticleStore';
import AuthStore from './AuthStore';
import BookingStore from './BookingStore';
import DialogStore from './DialogStore';
import FarmStore from './FarmStore';
import AuthorizationStore from './AuthorizationStore';
import ForecastStore from './ForecastStore';
import InvoiceStore from './InvoiceStore';
import MenuStore from './MenuStore';
import PaymentsStore from './PaymentsStore';
import ProgressStore from '../Stores/ProgressStore';
import RouteStore from './RouteStore';
import ScheduleStore from './ScheduleStore';
import ERROR_CODES from '../Constants/ErrorCodes';
import PROGRESS_MESSAGES from '../Constants/ProgressMessages';
import UserTermsStore from './UserTermsStore';
import { TelemetryExceptionType } from '../Constants/Telemetry';
import ShareholderStore from './ShareholderStore';
import DashboardStore from './DashboardStore';
import BannerStore from './BannerStore';
import CarcaseReportStore from './CarcaseReportStore';
import ClaimsHelper from '../Helpers/ClaimsHelper';
import * as TELEMETRY from '../Constants/Telemetry';
import { PublicClientApplication } from '@azure/msal-browser';
import React, { createContext } from 'react';

const _claimsHelper: ClaimsHelper = new ClaimsHelper();
/**
 * The root store of the application
 */
export class AppStore {
  accountStore: AccountStore;
  articleStore: ArticleStore;
  authStore: AuthStore;
  bookingStore: BookingStore;
  farmStore: FarmStore;
  authorizationStore: AuthorizationStore;
  forecastStore: ForecastStore;
  dialogStore: DialogStore;
  invoiceStore: InvoiceStore;
  menuStore: MenuStore;
  paymentsStore: PaymentsStore;
  progressStore: ProgressStore;
  routeStore: RouteStore;
  scheduleStore: ScheduleStore;
  userTermsStore: UserTermsStore;
  shareholderStore: ShareholderStore;
  dashboardStore: DashboardStore;
  bannerStore: BannerStore;
  carcaseReportStore: CarcaseReportStore;

  @observable
  public hasError: boolean = false;

  constructor(pca: PublicClientApplication) {
    var cookieEnabled = navigator.cookieEnabled;
    if (cookieEnabled) {
      this.accountStore = new AccountStore();
      this.authStore = new AuthStore(pca);
      this.articleStore = new ArticleStore();
      this.bookingStore = new BookingStore();
      this.dialogStore = new DialogStore();
      this.invoiceStore = new InvoiceStore();
      this.farmStore = new FarmStore();
      this.authorizationStore = new AuthorizationStore();
      this.forecastStore = new ForecastStore();
      this.menuStore = new MenuStore();
      this.paymentsStore = new PaymentsStore();
      this.progressStore = new ProgressStore();
      this.routeStore = new RouteStore();
      this.scheduleStore = new ScheduleStore();
      //this.dialogStore = new DialogStore();
      this.userTermsStore = new UserTermsStore();
      this.shareholderStore = new ShareholderStore();
      this.dashboardStore = new DashboardStore();
      this.bannerStore = new BannerStore();
      this.carcaseReportStore = new CarcaseReportStore();
      window.routeStore = this.routeStore;
    } else { 
      this.hasError = true;
    }
  }

  public loadInitialData = async (): Promise<boolean> => {
    if (this.hasError) {
      return false;
    } else {
      try {
        this.progressStore.startInitialDataLoad(PROGRESS_MESSAGES.AUTHENTICATING);
        this.authStore.init(window.appSettings.policyName);

        let _authorizedParty = _claimsHelper.getAuthorizedParty();
        if(_authorizedParty !== 'unknown' && window.appSettings.clientID!== _authorizedParty){
          window.telemetry.logEvent(TELEMETRY.EVENTS.FORCE_LOGOUT)
          this.authStore.logOut();
        }

      } catch (error : any) {
        let errorMessage = 'Error getting initial data from App Store. ' + JSON.stringify(error);
        window.telemetry.logException(errorMessage, TelemetryExceptionType.AdError);
        // this check is to ensure that user exists in SFF's database
        if (error.status === ERROR_CODES.UNAUTHORISED) {
          this.authStore.logOut();
        }

        // route to error page
        this.hasError = true;
        return false;
      } finally {
        this.progressStore.endInitialDataLoad();
      }

      return true;
    }
  }
}
export default AppStore;
export const AppStoreContext = createContext(AppStore);
