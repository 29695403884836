import { createStyles, Theme, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { WithStyles } from '@material-ui/core/styles';
import ArrowDropUp from '@material-ui/icons/ArrowDropUpRounded';
import React from 'react';

const BorderLinearProgress = withStyles({
  root: {
    height: '1.9em',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to right, rgba(65, 122, 93, 0.1) 87.6%, white 1%)',
    borderRadius: '2em',
    border: '0.05em solid rgba(51, 51, 51, 0.15)',
    width: '100%',
    zIndex: 1,
  },
  bar: {
    backgroundColor: 'rgb(65, 122, 93)',
    zIndex: 0,
  },
  barColorSecondary: {
    backgroundColor: 'rgb(65, 122, 93)',
  },
})(LinearProgress);

const NilProgress = withStyles({
  root: {
    height: '1.9em',
    borderRadius: '2em',
    backgroundColor: 'rgba(51, 51, 51, 0.1)',
    border: '0.05em solid rgba(51, 51, 51, 0.15)',
  },
})(LinearProgress);

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    rootGrid: {
      marginTop: '3.8em',
      marginBottom: '0.75em',
      gridRow: 3,
      gridColumn: 1,
      padding: '0 1em',
    },
    additionalBorder: {
      height: '1.9em',
      position: 'absolute',
      width: '82%',
      borderRight: '0.05em dashed black',
      zIndex: 2,
    },
    pointerTextSmall: {
      float: 'right',
      transform: 'translateX(-58%)',
      fontSize: 'xx-small',
      textTransform: 'uppercase',
      letterSpacing: '0.012em',
      marginTop: '0.8em',
    },
    pointerTextLarge: {
      float: 'right',
      marginRight: '12.5%',
      transform: 'translateX(50%)',
      fontSize: 'small',
      textTransform: 'uppercase',
      letterSpacing: '0.017em',
      marginTop: '1.2em',
    },
    nil: {
      width: '100%',
      position: 'relative',
      textAlign: 'end',
      '&:after': {
        content: '"NIL"',
        position: 'absolute',
        top: '0.25em',
        right: '1em',
        bottom: 0,
        left: 0,
        color: 'rgba(51, 51, 51, 0.45)',
        textAlign: 'right',
      },
    },
    arrowLarge: {
      fontSize: 'x-large',
      position: 'relative',
      bottom: '0.1em',
      left: 'calc(87.5% - 0.5em)',
    },
    arrowSmall: {
      fontSize: 'small',
      position: 'relative',
      bottom: '0.8em',
      left: '86.4%',
    },
    arrowNilPosition: {
      left: 'calc(87.5% - 0.48em)',
    },
    hidden: {
      visibility: 'hidden',
    },
  });

interface Props extends WithStyles<typeof useStyles> {
  percentFull: number;
  nil?: boolean;
  overShared?: boolean;
  pointerText?: string;
  pointerTextSize?: 'small' | 'large';
  arrowSize?: 'small' | 'large' | 'hidden';
  borderStyle?: 'dashed' | 'none';
}

const MIN = -2.75;
const MAX = 114.4;

/**
 * Normalize the progress value to fit a non-standard range.
 *
 * In this case we want to extend the minimum range below the default of 0
 * so that our progress bar starts at 1% after our custom styled border radius.
 * This applies for range of 1-100. For a value of 0, we don't apply the
 * custom non-standard range and just return 0.
 *
 * In addition, if the value supplied is a floating point number greater than 0 but less than 1
 * (0 < value < 1), we'll consider the value to be 1 to avoid displaying partial progress in the
 * range of 0..1. All values above 111 will be automatically adjusted to the MAX of the range
 * to avoid displaying partial progress in the range of 111..MAX.
 *
 * See: https://material-ui.com/components/progress/#non-standard-ranges
 *
 * @param value the value of the progress indicator between 0 and 100
 */
const normalize = (value: number) => {
  if (value === 0) return value;
  if (value < 1) value = 1;
  if (value > 111) value = MAX;
  return (value - MIN) * 100 / (MAX - MIN);
};

class BarGraph extends React.Component<Props> {
  static defaultProps: any = {
    nil: false,
    overShared: false,
    pointerText: '',
    pointerTextSize: 'small',
    arrowSize: 'small',
    borderStyle: 'dashed',
  }

  render() {
    const { classes } = this.props;
    const arrowClass = this.props.arrowSize === 'large' ? classes.arrowLarge : classes.arrowSmall;
    const pointerTextClass = this.props.pointerTextSize === 'large' ? classes.pointerTextLarge : classes.pointerTextSmall;
    const rootClass = this.props.borderStyle === 'none' ? classes.rootGrid : classes.root;
    const arrowVisibilityClass = this.props.arrowSize === 'hidden' ? classes.hidden : '';

    return (
      !this.props.nil ? (
        <div className={rootClass}>
          {this.props.borderStyle !== 'none'
            ? <div className={classes.additionalBorder}></div>
            : null}
          <div>
            <BorderLinearProgress
              variant="determinate"
              color="secondary"
              value={normalize(this.props.percentFull)}
            />
          </div>
          <div className={pointerTextClass}>
            {this.props.pointerText}
          </div>
          <ArrowDropUp className={`${arrowClass} ${arrowVisibilityClass}`} />
        </div>
      ) :
        (
          <div className={rootClass}>
            <div className={classes.nil}>
              <NilProgress
                variant="determinate"
                color="secondary"
                value={0}
              />
            </div>
            <div className={pointerTextClass}>
              {this.props.pointerText}
            </div>
            <ArrowDropUp className={`${arrowClass} ${arrowVisibilityClass} ${classes.arrowNilPosition}`} />
          </div>
        )
    );
  }
}

export default withStyles(useStyles)(BarGraph);

// test specific exports
if (process.env.NODE_ENV === 'test') {
  exports.BorderLinearProgress = BorderLinearProgress;
  exports.NilProgress = NilProgress;
  exports.ArrowDropUp = ArrowDropUp;
}
