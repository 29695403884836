export const DEFAULT_PAGE = 0;

export const TAB_LATEST = 0;
export const TAB_NEWS = 1;
export const TAB_SOCIAL = 2;
export const TAB_EVENTS = 3;

export const STREAM_LATEST = 'Latest';
export const STREAM_NEWS = 'News';
export const STREAM_EVENTS = 'Events';
export const STREAM_SOCIAL = 'Social';

export const STREAM_PAGE_SIZE = 10;