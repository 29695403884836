import _ from 'lodash';
import React from 'react';
import * as SHARES from '../App/Constants/Shares';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import Share from '../App/Models/Share';

interface Props {
  farmName: string;
  shares?: Share[];
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

class SharesList extends React.Component<Props> {
  render() {
    const { shares } = this.props;

    if (!shares || (shares && _.isEmpty(shares))) {
      return (
        <div className="c-shares-list__empty-container">
          <div className="c-shares-list__empty-text">
            No shares for
                        <br />
            {this.props.farmName}
          </div>
        </div>
      );
    }

    return (shares.map((item: Share, index: number) => {
      return (
        <div className="c-share__container" key={index}>
          <div className="c-share__crn">
            <div className="c-share__crn-value">
              {item.crn}
            </div>
            <div className="c-share__crn-label">{SHARES.LABEL_CRN}</div>
          </div>
          <div className="c-share__type-quantity">
            <div className="c-share__type">
              <div className="c-share__type-value">{item.shareType}</div>
              <div className="c-share__type-label">{SHARES.LABEL_SHARES_TYPE}</div>
            </div>
            <div className="c-share__quantity">
              <div className="c-share__quantity-value">{_propertyHelper.formatValue(item.quantity, VALUE_TYPE.NUMBER_WITH_COMMAS)}</div>
              <div className="c-share__quantity-label">{SHARES.LABEL_QUANTITY}</div>
            </div>
          </div>
        </div>
      );
    }));
  }
}

export default SharesList;
