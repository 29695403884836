import { AxiosError } from 'axios';
import { observable, action } from 'mobx';
import Schedule from './../../App/Models/Schedule';
import ScheduleService from '../Services/ScheduleService';
import { TelemetryExceptionType } from '../Constants/Telemetry';

class ScheduleStore {
  @observable public isScheduleLoaded: boolean;
  @observable public isScheduleLoadError: boolean;
  @observable public schedule?: Schedule;

  private _scheduleService: ScheduleService = new ScheduleService();

  constructor() {
    this.isScheduleLoaded = false;
    this.isScheduleLoadError = false;
    this.schedule = new Schedule();
  }

  @action
  getSchedulePdf = (island: string) => {
    this.isScheduleLoaded = false;
    this.isScheduleLoadError = false;

    return this._scheduleService
      .getSchedule(encodeURI(island))
      .then(response => {
        this.schedule!.title = response!.data!.title;
        this.schedule!.bodyText = response!.data!.bodyText;
        let scheduleFileName = response!.data!.attachment;
        this._scheduleService.getScheduleDocument(encodeURI(scheduleFileName))
          .then(blobResponse => {
            var self = this;
            self.schedule!.blobData = blobResponse!.data;
            var reader = new FileReader();
            reader.onloadend = function (readerEvent: any) {
              let rawBase64data = readerEvent!.target!.result;
              self.schedule!.base64Data = self.removeBase64Header(rawBase64data);
              self.isScheduleLoaded = true;
            };
            reader.readAsDataURL(blobResponse!.data);
          })
          .catch((blobError: AxiosError) => {
            window.telemetry.logException(blobError, TelemetryExceptionType.ApiError);
            this.isScheduleLoadError = true;
            this.isScheduleLoaded = true;
          });
      })
      .catch((error: AxiosError) => {
        window.telemetry.logException(error, TelemetryExceptionType.ApiError);
        this.isScheduleLoadError = true;
        this.isScheduleLoaded = true;
      });
  }

  removeBase64Header = (base64String: string | undefined) => {
    return base64String!.substr(base64String!.indexOf(',') + 1);
  }
}

export default ScheduleStore;
