import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import _ from 'lodash';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Moment } from 'moment';
import * as React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as MENU from '../App/Constants/Menu';
import * as SEARCH from '../App/Constants/Search';
import * as STORAGE from '../App/Constants/Storage';
import StorageHelper from '../App/Helpers/StorageHelper';
import AuthStore from '../App/Stores/AuthStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import MenuStore from '../App/Stores/MenuStore';
import DateRangePickerSearchInput from '../CustomDatePickers/DateRangePickerSearchInput';
import FarmSwitcher from '../FarmSwitcher/FarmSwitcher';
import KillSheetNumberSearchInput from './KillSheetNumberSearchInput';
import SearchSwitcher from './SearchSwitcher';

const _storageHelper: StorageHelper = new StorageHelper();

const styles = (theme: Theme) =>
  createStyles({
    input: {
      '&::-ms-clear': {
        display: 'none',
      },
    },
    open: {
      left: MENU.DEFAULT_WIDTH - 1,
      width: `calc(100% - ${MENU.DEFAULT_WIDTH - 1}px)`,
      transition: theme.transitions.create(['width', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    closed: {
      left: theme.spacing(9), //Corrected syntax
      width: `calc(100% - ${theme.spacing(9)}px)`,
      transition: theme.transitions.create(['width', 'left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  });

interface Props extends WithStyles<typeof styles> {
  authStore?: AuthStore;
  authorizationStore?: AuthorizationStore;
  menuStore?: MenuStore;
  className?: string;
  title: string;
}

interface State {
  mobileMoreAnchorEl: null | HTMLElement;
  showSearchIcon: boolean;
  isSearchMode: boolean;
  searchQuery: string;
  selectedSearchOption: string;
}

@inject('authStore')
@inject('authorizationStore')
@inject('menuStore')
@observer
export class Header extends React.Component<Props, State> {
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.state = {
      mobileMoreAnchorEl: null,
      showSearchIcon: false,
      isSearchMode: false,
      searchQuery: '',
      selectedSearchOption: SEARCH.OPTION_DEFAULT,
    };
  }

  componentDidMount = () => {
    this.disposer = autorun(() => {
      const selectedIndex = this.props.menuStore!.selectedIndex;
      const isKillSheetsSearchResult =
        this.props.authorizationStore!.isKillSheetsSearchResult;

      // setup reaction on selectedIndex in menu and disable search functionality when requird
      if (!_.includes(MENU.KILLSHEET_SEARCH_INDEXES, selectedIndex)) {
        this.setState({
          showSearchIcon: false,
          isSearchMode: false,
          searchQuery: '',
        });
        if (isKillSheetsSearchResult) {
          this.props.authorizationStore!.isAllFarmsSelected = false;
        }
        return;
      } else {
        this.setState({ showSearchIcon: true });
      }

      if (!isKillSheetsSearchResult) {
        this.setState({ isSearchMode: false, searchQuery: '' });
      }
    });
  };

  componentWillUnmount = () => {
    this.disposer();
  };

  handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMyAccountClick = () => {
    this.props.menuStore!.setSelectedIndex(MENU.MY_ACCOUNT_INDEX);
  };

  logOut = () => {
    this.props.authStore!.logOut();
  };

  handleSearchOptionChange = (option: string) => {
    this.setState({ selectedSearchOption: option });
  };

  onKillSheetSearchChange = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  onKillSheetSearchSubmit = () => {
    if (this.state.searchQuery.length > 0) {
      this.props.authorizationStore!.killSheets = [];
      this.props.authorizationStore!.getKillSheetsByNumber(this.state.searchQuery);
    }
  };

  handleDatesChange = (change: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    if (change.startDate && change.endDate) {
      this.props.authorizationStore!.getAllKillSheets(
        change.startDate.toDate(),
        change.endDate.toDate()
      );
    }
  };

  openSearch = () => {
    this.setState({ isSearchMode: true });
  };

  closeSearch = () => {
    this.setState({ isSearchMode: false, searchQuery: '' });
  };

  renderHeader() {
    let _userName = _storageHelper.getKeyValue(STORAGE.USERNAME);
    // TODO: unhide once notifications are being added
    const hiddenElement: React.CSSProperties = { display: 'none' };
    const searchIconContainerClass = `c-header__icon-button${
      DEFAULT_DATA.WHITESPACE
    }${this.state.showSearchIcon ? `show` : `hide`}`;

    return (
      <div className={this.props.className}>
        <AppBar position='static' color='default' className='c-header__app-bar'>
          <Toolbar className='c-header__gutter'>
            {/* <div className="c-header__application-title-container">
              <h4 className="c-header__application-title">
                {this.props.title}
              </h4>
              <p className="c-header__application-subtitle">
                {`Welcome${_userName ? `${DEFAULT_DATA.WHITESPACE}${_userName}` : DEFAULT_DATA.STRING}`}!
              </p>
            </div> */}
            <div className='c-header__divider' />
            <div className='c-header__farm-switcher-container'>
              <FarmSwitcher />
            </div>
            <div className='c-header__icon-button' style={hiddenElement}>
              <IconButton color='inherit'>
                <Badge
                  invisible={false}
                  color='secondary'
                  overlap='rectangular'
                  className='notification-badge'
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>
            <div className={searchIconContainerClass}>
              <IconButton color='inherit' onClick={this.openSearch}>
                <SearchIcon />
              </IconButton>
            </div>
            <div className='c-header__icon-button'>
              <IconButton color='inherit' onClick={this.handleMyAccountClick}>
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  renderSearchHeader() {
    const { classes, menuStore } = this.props;
    const { searchQuery, selectedSearchOption } = this.state;
    return (
      <div className={this.props.className}>
        <AppBar
          position='static'
          color='default'
          className={classNames({
            'c-header__app-bar elevated': true,
            [classes.open]: menuStore!.isMenuOpen,
            [classes.closed]: !menuStore!.isMenuOpen,
          })}
        >
          <Toolbar className='c-header__gutter' disableGutters={true}>
            <SearchSwitcher
              options={SEARCH.OPTIONS}
              selectedOption={selectedSearchOption}
              onSelectedOptionChange={this.handleSearchOptionChange}
            />
            {(() => {
              switch (selectedSearchOption) {
                case SEARCH.OPTION_DATE:
                  return (
                    <DateRangePickerSearchInput
                      onDatesChange={this.handleDatesChange}
                      onClose={this.closeSearch}
                    />
                  );
                case SEARCH.OPTION_KILL_SHEET_NUMBER:
                  return (
                    <KillSheetNumberSearchInput
                      searchQuery={searchQuery}
                      onSearchQueryChange={this.onKillSheetSearchChange}
                      onSearchQuerySubmit={this.onKillSheetSearchSubmit}
                      onClose={this.closeSearch}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  render() {
    return this.state.showSearchIcon && this.state.isSearchMode
      ? this.renderSearchHeader()
      : this.renderHeader();
  }
}

export default withStyles(styles)(Header);
