import _ from 'lodash';

import * as DEFAULT_DATA from '../Constants/DefaultData';
import Entity from '../Models/Entity';

class PaymentsHelper {
  public getFarmName = (supplierId: string, farms: Array<Entity>) => {
    if (supplierId && !_.isEmpty(farms)) {
      const farmName = farms.find(f => f.id === supplierId);

      if (farmName) {
        return farmName.name;
      }
      return DEFAULT_DATA.UNDEFINED;
    }
  }
}

export default PaymentsHelper;
