import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/main.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { b2cPolicies, msalConfig } from './authConfig';
import { EventType, PublicClientApplication, EventPayload, AuthenticationResult, AuthError, RedirectRequest } from '@azure/msal-browser';
import { MsalProvider } from "@azure/msal-react";
import * as msal from "@azure/msal-browser";
import { BrowserRouter as Router } from "react-router-dom";

//This is what will override the MUICssBaseLine style which was causing issues with the layout
const MuiCSSBaselineValues = {
          body: {
            fontSize: '1.25rem'
          },
};


const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
};


export const msalInstance = new msal.PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  
  if (event.eventType === EventType.LOGIN_SUCCESS){
    const payload = event.payload as AuthenticationResult;
    if (payload && payload.account) {
      msalInstance.setActiveAccount(payload.account);
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
      const error = event.error as AuthError;
      if (error && error.errorMessage.indexOf("AADB2C90118") > -1) {
          msalInstance.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
      }
  } 
  /* Redirect to log out after complete password reset registration */
  else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    const error = event.error as AuthError;
    if (error && ((error.errorMessage.indexOf("AADB2C90088") > -1) || 
    (error.name === 'BrowserAuthError' && error.errorCode === 'monitor_window_timeout'))
    ) {
      window.logOut();
    }
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_START) {
    const policyName = msalInstance.getActiveAccount()!.idTokenClaims!.tfp;
    if (policyName === window.appSettings.passwordResetPolicyName || policyName === window.appSettings.passwordRegistrationPolicyName){
      window.logOut();
    }
  } 
});

//Here MuiCssBaseLine is getting overrided with our fontSize value
const theme = createTheme({ breakpoints: { values: breakpointValues }, overrides: {MuiCssBaseline: {'@global': MuiCSSBaselineValues }} });

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Router>
        <App pca={msalInstance}/>
      </Router>
    </MuiThemeProvider>
,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();