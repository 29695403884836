import { MINIMUM_STOCK_UNITS_FOR_ELIGIBILITY } from '../Constants/Shares';

class PatronageEligibility {
  constructor(
    public totalShares: number = 0,
    public stockUnits: number = 0,
    public eligibleShares: number = 0,
    public requiredShares: number = 0,
    public patronageYear: string = '',
  ) { }

  percentFull(): number {
    if (this.requiredShares === 0) { return 0; }
    return this.totalShares / this.requiredShares * 100;
  }

  isEligible(): boolean {
    return this.stockUnits >= MINIMUM_STOCK_UNITS_FOR_ELIGIBILITY;
  }

  isOverShared(): boolean {
    return this.isEligible() && this.totalShares > this.requiredShares;
  }

  neededShares(): number {
    return this.requiredShares - this.totalShares;
  }

  excessShares(): number {
    return this.totalShares - this.requiredShares;
  }
}

export default PatronageEligibility;
