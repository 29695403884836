import AccountService from '../Services/AccountService';
import AccountDetail from '../Models/AccountDetail';
import { observable, action } from 'mobx';
import { TelemetryExceptionType } from '../Constants/Telemetry';
import { AxiosError } from 'axios';

class AccountStore {
  @observable public accountDetail: AccountDetail;
  @observable public isAccountDetailLoaded: boolean;
  @observable public isAccountDetailLoadError: boolean;
  @observable public accountPageTabIndex: number = 0;

  private _accountService: AccountService = new AccountService();

  constructor() {
    this.accountDetail = new AccountDetail();
    this.isAccountDetailLoaded = false;
    this.isAccountDetailLoadError = false;
  }

  @action
  getAccountDetails = (farmId: string) => {
    this.isAccountDetailLoaded = false;
    this.isAccountDetailLoadError = false;

    return this._accountService.getAccountDetails(farmId)
      .then(response => {
        this.accountDetail = response!.data;
        this.isAccountDetailLoaded = true;
      }).catch((error: AxiosError) => {
        window.telemetry.logException(error, TelemetryExceptionType.ApiError);
        this.isAccountDetailLoadError = true;
        this.isAccountDetailLoaded = true;
      });
  }
}

export default AccountStore;
