import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class ScheduleService extends HttpService {
  getSchedule = (island: string) => {
    const getSchedulesApiUrl = `${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.SCHEDULE}${island}`;
    return super.get(getSchedulesApiUrl);
  }

  getScheduleDashboard = (island: string) => {
    const getSchedulesApiUrl = `${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.SCHEDULE}${island}`;
    return super.get(getSchedulesApiUrl, undefined, undefined, undefined, true);
  }

  getScheduleDocument = (filename: string) => {
    const getScheduleDocumentApiUrl = `${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.SCHEDULE}${CONNECTION.DOCUMENT}${filename}`;
    return super.doDownloadDocument(getScheduleDocumentApiUrl);
  }
}

export default ScheduleService;
