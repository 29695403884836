import Slide from '@material-ui/core/Slide';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import * as KILLSHEET from '../App/Constants/KillSheet';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import ProgressStore from '../App/Stores/ProgressStore';
import FinalKillSheetDetails from '../FinalKillSheetDetails/FinalKillSheetDetails';
import InterimKillSheetDetails from '../InterimKillSheetDetails/InterimKillSheetDetails';

interface Props {
  authorizationStore?: AuthorizationStore;
  progressStore?: ProgressStore;
}

@inject('authorizationStore')
@inject('progressStore')
@observer
class KillSheetDetails extends React.Component<Props> {
  componentWillMount = () => {
    this.props.progressStore!.startComponentLoad();
  }

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.KILL_INFORMATION, TelemetryPageEventType.PageStop);
    this.props.progressStore!.endComponentLoad();
  }

  render() {
    const selectedKillSheet = this.props.authorizationStore!.selectedKillSheet;
    const selectedKillSheetPdfBlob = this.props.authorizationStore!.selectedKillSheetPdfBlob;
    const selectedKillSheetXlsxBlob = this.props.authorizationStore!.selectedKillSheetXlsxBlob;

    let _killSheetComponent;

    if (!selectedKillSheet) {
      return <div className="c-kill-sheet-details__container" />;
    }

    switch (selectedKillSheet.status) {
      case KILLSHEET.INTERIM_TYPE:
        _killSheetComponent = (
          <InterimKillSheetDetails killSheet={selectedKillSheet} />
        );
        break;
      default:
        _killSheetComponent = (
          <FinalKillSheetDetails killSheet={selectedKillSheet} killSheetPdfDocumentBlob={selectedKillSheetPdfBlob} killSheetXlsxDocumentBlob={selectedKillSheetXlsxBlob} />
        );
        break;
    }

    return (
      <Slide
        direction="left"
        in={
          this.props.authorizationStore!.isKillSheetDetailsLoaded &&
          _killSheetComponent !== undefined
        }
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {_killSheetComponent}
      </Slide>
    );
  }
}

export default KillSheetDetails;
