export const ITEMS = [
  {
    name: 'Sheep',
    imageUrl: '/images/sheep_filter.svg',
    disabledImageUrl: '/images/sheep_filter_disabled.svg',
    order: 1,
    isEnabled: true
  },
  {
    name: 'Beef',
    imageUrl: '/images/beef_filter.svg',
    disabledImageUrl: '/images/beef_filter_disabled.svg',
    order: 2,
    isEnabled: true
  },
  {
    name: 'Deer',
    imageUrl: '/images/deer_filter.svg',
    disabledImageUrl: '/images/deer_filter_disabled.svg',
    order: 3,
    isEnabled: true
  },
  {
    name: 'Bobby',
    imageUrl: '/images/bobby_filter.svg',
    disabledImageUrl: '/images/bobby_filter_disabled.svg',
    order: 4,
    isEnabled: true
  }
];