import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class UserTermsService extends HttpService {

  getUserTerms = () => {
    const userTermsApiUrl = this.buildUserTermsUrl();
    return super.get(userTermsApiUrl);
  }

  updateUserTerms = (flag: boolean) => {
    const userTermsApiUrl = `${this.buildUserTermsUrl()}/${flag}`;
    return super.patch(userTermsApiUrl);
  }

  getTermsAndConditions = () => {
    const termsAndConditionsApiUrl = this.buildTermsAndConditionsUrl();
    return super.doDownloadDocument(termsAndConditionsApiUrl);
  }

  private buildUserTermsUrl = () => {
    return `${window!.appSettings!.apiUrl}${CONNECTION.USER_MANAGEMENT}${CONNECTION.API_VERSION}${CONNECTION.TERMS_ACCEPTED}`;
  }

  private buildTermsAndConditionsUrl = () => {
    return `${window!.appSettings.apiUrl}${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.TERMS_AND_CONDITIONS}`;
  }
}

export default UserTermsService;