import React from 'react';
import { observer } from 'mobx-react';

interface Props {
  componentName?: string;

}

@observer
class SettingsActionPanel extends React.Component<Props> {
  render() {
    const { componentName } = this.props;

    if (componentName) {
      return (
        <div className="c-settings__action-panel">
          {React.createElement(componentName)}
        </div>
      );
    } else {
      return (<div>Component does not exist.</div>);
    }
  }
}

export default SettingsActionPanel; 