import Booking from '../Models/Booking';
import BookingService from '../Services/BookingService';
import { observable, action } from 'mobx';
import StockHelper from '../Helpers/StockHelper';

class BookingStore {
  @observable public bookings: Array<Booking>;
  @observable public isBookingsInitialLoad: boolean;
  @observable public isBookingsLoaded: boolean;
  @observable public isBookingsLoadError: boolean;

  private _bookingService: BookingService = new BookingService();
  private _stockHelper: StockHelper = new StockHelper();

  constructor() {
    this.bookings = new Array<Booking>();
    this.isBookingsInitialLoad = true;
    this.isBookingsLoaded = false;
    this.isBookingsLoadError = false;
  }

  @action
  getBookings = (farmId: string) => {
    this.isBookingsLoaded = false;
    this.isBookingsLoadError = false;

    return this._bookingService.getBookings(farmId)
      .then(response => {
        this.bookings = new Array<Booking>();
        if (response!.data) {
          let _bookings = response!.data.bookings;
          let _bookingsResult = new Array<Booking>();
          _bookings.map((booking: Booking) => {
            _bookingsResult.push(Object.setPrototypeOf(booking, Booking.prototype));
          });
          this.bookings = this._stockHelper.sortBookings(_bookingsResult);
        }
        this.isBookingsInitialLoad = false;
        this.isBookingsLoaded = true;
      }).catch(() => {
        this.isBookingsLoadError = true;
        this.isBookingsLoaded = true;
      });
  }
}

export default BookingStore;
