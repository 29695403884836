import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class FarmService extends HttpService {
  getFarms = async () => {
    const getFarmsApiUrl = `${CONNECTION.USER_MANAGEMENT}${CONNECTION.API_VERSION}${CONNECTION.GET_USER_FARMS}`;

    return super.get(getFarmsApiUrl);
  }

  getFarmDetails = async (supplierId: string) => {
    const getFarmDetailApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_SUPPLIER_FARM_DETAILS}`;

    return super.get(getFarmDetailApiUrl);
  }

  getNaitNumbers = async (supplierId: string) => {
    const getNaitNumbersUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.NAIT_NUMBER}`;

    return super.get(getNaitNumbersUrl);
  }

  getNzfapCertificates = async (supplierId: string) => {
    const getNzfapCertificatesUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.NZFAP_CERT}`;

    return super.get(getNzfapCertificatesUrl);
  }

  getNzfapCertificatesDashboard = async (supplierId: string) => {
    const getNzfapCertificatesUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.NZFAP_CERT}`;

    return super.get(getNzfapCertificatesUrl, undefined, undefined, undefined, true);
  }
  
  getFarmAccessContacts = async(supplierId: string) => {
    const getFarmAccessContactsApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.FARM_ACCESS_CONTACTS}`;

    return super.get(getFarmAccessContactsApiUrl);
  }
}

export default FarmService;
