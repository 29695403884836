import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MobileStepper,
  Paper,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import * as APPLICATION from '../App/Constants/Application';
import { FEATURES } from '../App/Constants/LatestFeatures';
import * as STORAGE from '../App/Constants/Storage';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as TRANSITION from '../App/Constants/Transition';
import StorageHelper from '../App/Helpers/StorageHelper';
import DialogStore from '../App/Stores/DialogStore';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 450,
      flexGrow: 1,
      margin: '50px 24px 24px 32px',
    },
    smallRoot: {
      maxWidth: 450,
      flexGrow: 1,
      margin: '32px 24px 24px 32px',
    },
    background: {
      position: 'absolute',
      height: 350,
      width: 350,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 26,
    },
    backgroundMedium: {
      position: 'absolute',
      height: 300,
      width: 300,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 20,
    },
    backgroundSmall: {
      position: 'absolute',
      height: 210,
      width: 210,
      backgroundColor: '#325542',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '2% auto',
      marginTop: 10,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      // height: 100,
      marginTop: -30,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '1em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    mediumHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      marginTop: -30,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '0.85em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    smallHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      marginTop: -10,
      backgroundColor: '#427b5d',
      '&& p': {
        fontFamily: 'Graphik-Semibold',
        textTransform: 'uppercase',
        fontSize: '0.65em',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        zIndex: 1,
      },
    },
    closeButton: {
      position: 'absolute',
      right: '8px',
      top: '8px',
      color: 'white',
    },
    closeButtonSmall: {
      position: 'absolute',
      right: '8px',
      top: '8px',
      color: 'white',
    },
    description: {
      zIndex: 1,
      maxWidth: 425,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '1em',
      fontSize: '0.9em',
    },
    descriptionMedium: {
      zIndex: 1,
      maxWidth: 285,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '1em',
      fontSize: '0.8em',
    },
    descriptionSmall: {
      zIndex: 1,
      maxWidth: 285,
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '0.5em',
      fontSize: '0.7em',
    },
    img: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '100%',
    },
    imgMedium: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '90%',
      marginLeft: '14px',
    },
    imgSmall: {
      display: 'block',
      maxHeight: 320,
      maxWidth: 450,
      overflow: 'hidden',
      width: '60%',
      marginLeft: '80px',
    },
    title: {
      paddingBottom: 0,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1.2em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    titleMedium: {
      paddingBottom: 0,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1.1em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    titleSmall: {
      paddingBottom: 0,
      paddingTop: 4,
      '&& h2': {
        fontFamily: 'Graphik',
        fontSize: '1em',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',
      },
    },
    content: {
      overflow: 'hidden',
    },
    actions: {
      justifyContent: 'center',
    },
    okaybutton: {
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 40px',
        fontSize: '1.2em',
      },
    },
    okaybuttonMedium: {
      fontSize: '0.75rem',
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 40px',
        fontSize: '1.2em',
      },
    },
    okaybuttonSmall: {
      fontSize: '0.75rem',
      backgroundColor: '#427b5d',
      textTransform: 'none',
      borderRadius: 0,
      border: '1px solid white',
      '&:hover': {
        backgroundColor: 'rgba(66, 123, 93, 0.07)',
        '&& span': {
          color: 'black',
        },
      },
      '&& span': {
        fontFamily: 'Graphik',
        color: 'white',
        padding: '0px 30px',
        fontSize: '1.1em',
      },
    },
    dialog: {
      '&& div[role="document"] > div': {
        width: '640px',
        maxWidth: '640px',
        height: '680px',
        padding: '38px 38px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    dialogMedium: {
      '&& div[role="document"] > div': {
        width: '560px',
        maxWidth: '640px',
        height: '600px',
        padding: '24px 24px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    dialogSmall: {
      '&& div[role="document"] > div': {
        width: '560px',
        maxWidth: '640px',
        height: '440px',
        padding: '24px 24px',
        backgroundColor: '#427b5d',
        color: 'white',
      },
    },
    stepper: {
      justifyContent: 'center',
      backgroundColor: '#427b5d',
    },
    dotActive: {
      backgroundColor: 'white',
    },
    navigation: {
      width: 44,
      color: 'white',
      position: 'absolute',
      top: '40%',
      backgroundColor: '#325542',
      '&:hover': {
        backgroundColor: '#b0b569',
      },
      '&:disabled': {
        display: 'none',
      },
    },
    navigationSmall: {
      width: 44,
      color: 'white',
      position: 'absolute',
      top: '40%',
      backgroundColor: '#325542',
      '&:hover': {
        backgroundColor: '#b0b569',
      },
      '&:disabled': {
        display: 'none',
      },
    },
    navigationNext: {
      right: 38,
    },
    navigationPrevious: {
      left: 38,
    },
    backdrop: {
      opacity: 0.3,
    },
  });

interface Props extends WithStyles<typeof styles> {
  dialogStore?: DialogStore;
}

interface State {
  activeFeature: number;
  height: 'large' | 'medium' | 'small';
}

@inject('dialogStore')
@observer
class LatestFeaturesDialog extends React.Component<Props, State> {
  private _storageHelper: StorageHelper = new StorageHelper();
  private mediaQuerySmall = window.matchMedia('(max-height: 679px)');
  private mediaQueryMedium = window.matchMedia('(max-height: 768px)');
  private mediaQueryLarge = window.matchMedia('(min-height: 769px)');

  constructor(props: Props) {
    super(props);
    this.state = {
      activeFeature: 0,
      height: 'large',
    };

    window.onorientationchange = () => {
      setTimeout(() => {
        this.mediaCheck();
      }, TRANSITION.DASHBOARD_ORIENTATION_DELAY);
    };
  }

  mediaCheck = () => {
    if (this.mediaQuerySmall.matches) {
      this.setState({ height: 'small' });
    } else if (this.mediaQueryMedium.matches) {
      this.setState({ height: 'medium' });
    } else {
      this.setState({ height: 'large' });
    }
  };

  componentWillMount = () => {
    this.mediaCheck();

    this.mediaQuerySmall.addListener((mq) => {
      if (mq.matches) {
        this.setState({ height: 'small' });
      }
    });
    this.mediaQueryMedium.addListener((mq) => {
      if (mq.matches) {
        this.setState({ height: 'medium' });
      }
    });
    this.mediaQueryLarge.addListener((mq) => {
      if (mq.matches) {
        this.setState({ height: 'large' });
      }
    });
  };

  /* handleNext = () => {
    if (this.state.activeFeature + 1 === FEATURES.length - 1) {
      window.telemetry.logEvent(TELEMETRY.EVENTS.WHATS_NEW_ITEMS_VIEWED);
    }
    this.setState({ activeFeature: this.state.activeFeature + 1 });
  };

  handleBack = () => {
    this.setState({ activeFeature: this.state.activeFeature - 1 });
  }; */

  handleFeatureChange = (feature: number) => {
    this.setState({ activeFeature: feature });
  };

  handleClose = () => {
    const appVersion = _.isEmpty(process.env.REACT_APP_VERSION)
      ? window.appVersion
      : process.env.REACT_APP_VERSION;
    this._storageHelper.storeKeyValue(STORAGE.APP_VERSION, appVersion);
    window.telemetry.logEvent(TELEMETRY.EVENTS.WHATS_NEW_CLOSE);
    this.props.dialogStore!.setDialogClosed();
  };

  render() {
    const { classes, dialogStore } = this.props;
    const { height } = this.state;
    const backDropProps = {
      invisible: true,
      classes: {
        invisible: classes.backdrop,
      },
    };
    const maxFeatures = FEATURES.length;

    const rootClass = classNames({
      [classes.root]: height === 'large' || height === 'medium',
      [classes.smallRoot]: height === 'small',
    });

    const backgroundClass = classNames({
      [classes.background]: height === 'large',
      [classes.backgroundMedium]: height === 'medium',
      [classes.backgroundSmall]: height === 'small',
    });
    const imgClass = classNames({
      [classes.img]: height === 'large',
      [classes.imgMedium]: height === 'medium',
      [classes.imgSmall]: height === 'small',
    });
    const dialogClass = classNames({
      [classes.dialog]: height === 'large',
      [classes.dialogMedium]: height === 'medium',
      [classes.dialogSmall]: height === 'small',
    });
    const headerClass = classNames({
      [classes.header]: height === 'large' || height === 'medium',
      [classes.smallHeader]: height === 'small',
    });
    const descriptionClass = classNames({
      [classes.description]: height === 'large',
      [classes.descriptionMedium]: height === 'medium',
      [classes.descriptionSmall]: height === 'small',
    });
    const okayButtonClass = classNames({
      [classes.okaybutton]: height === 'large',
      [classes.okaybuttonMedium]: height === 'medium',
      [classes.okaybuttonSmall]: height === 'small',
    });
    const titleClass = classNames({
      [classes.title]: height === 'large',
      [classes.titleMedium]: height === 'medium',
      [classes.titleSmall]: height === 'small',
    });
    const navigationClass = classNames({
      [classes.navigation]: height === 'large' || height === 'medium',
      [classes.navigationSmall]: height === 'small',
    });
    const navigationPrevious = classNames({
      [classes.navigationPrevious]: height === 'small',
    });
    const closeButtonClass = classNames({
      [classes.closeButton]: height === 'large' || height === 'medium',
      [classes.closeButtonSmall]: height === 'small',
    });

    // const nextButton = classNames(classes.navigation, classes.navigationNext);
    // const previousButton = classNames(navigationClass, navigationPrevious);

    return (
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: '#427b5d',
            color: 'white',
          },
        }}
        className={dialogClass}
        open={dialogStore!.isDialogOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.handleClose();
          }
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        BackdropProps={backDropProps}
      >
        <DialogTitle id='alert-dialog-title' className={titleClass}>
          {dialogStore!.title}
          <IconButton
            aria-label='close'
            className={closeButtonClass}
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <IconButton
          className={previousButton}
          onClick={this.handleBack}
          disabled={this.state.activeFeature === 0}
        >
          <ArrowBackIcon fontSize='small' />
        </IconButton> */}
        <DialogContent className={classes.content}>
          <div className={backgroundClass}>&nbsp;</div>
          <div className={rootClass}>
            <SwipeableViews
              axis={'x'}
              index={this.state.activeFeature}
              onChangeIndex={this.handleFeatureChange}
              enableMouseEvents={true}
              autoPlay={false}
            >
              {FEATURES.map((feature, index) => (
                <div key={feature.label}>
                  {Math.abs(this.state.activeFeature - index) <= 2 ? (
                    <img
                      className={imgClass}
                      src={feature.imgPath}
                      alt={feature.label}
                    />
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <Paper square={true} elevation={0} className={headerClass}>
              <Typography>
                {FEATURES[this.state.activeFeature].label}
              </Typography>
            </Paper>
          </div>
        </DialogContent>
        {/* <IconButton
          className={nextButton}
          onClick={this.handleNext}
          disabled={this.state.activeFeature === maxFeatures - 1}
        >
          <ArrowForwardIcon fontSize='small' />
        </IconButton> */}
        <div className={descriptionClass}>
          {FEATURES[this.state.activeFeature].description}
        </div>
        {/* <MobileStepper
          variant='dots'
          steps={maxFeatures}
          position='static'
          activeStep={this.state.activeFeature}
          className={classes.stepper}
          classes={{ dotActive: classes.dotActive }}
          nextButton={<></>}
          backButton={<></>}
        /> */}
        <DialogActions className={classes.actions}>
          <Button
            className={okayButtonClass}
            onClick={this.handleClose}
            color='primary'
            autoFocus={true}
          >
            {dialogStore!.leftButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(LatestFeaturesDialog);
