import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import _ from 'lodash';
import React from 'react';
import * as SHARES from '../App/Constants/Shares';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PatronageEligibility from '../App/Models/PatronageEligibility';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const styles = (theme: Theme) => createStyles({
  infoIcon: {
    fontSize: '0.8em',
  },
  tooltip: {
    backgroundColor: '#FFF',
    color: 'rgb(51, 51, 51)',
    fontFamily: 'Graphik-Medium',
    fontSize: '0.65em',
    padding: '1.538em',
    maxWidth: '18.5em',
    boxShadow: '0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16)',
  },
  tooltipPlacementLeft: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 0.65em)',
      left: '100%',
      borderWidth: '0.6em',
      borderStyle: 'solid',
      borderColor: 'transparent transparent transparent #FFF',
    },
  },
  tooltipPlacementRight: {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 'calc(50% - 0.65em)',
      right: '100%',
      borderWidth: '0.6em',
      borderStyle: 'solid',
      borderColor: 'transparent #FFF transparent transparent',
    },
  },
  popper: {
    opacity: 1,
    marginLeft: '0.1em',
  },
});

interface Props extends WithStyles<typeof styles> {
  farmName: string;
  historicalPatronageEligibility?: PatronageEligibility[];
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

class SharesHistoryList extends React.Component<Props> {
  render() {
    const { classes, historicalPatronageEligibility } = this.props;

    if (!historicalPatronageEligibility || (historicalPatronageEligibility && _.isEmpty(historicalPatronageEligibility))) {
      return (
        <div className="c-shares-history-list__empty-container">
          <div className="c-shares-history-list__empty-text">
            No shares history for
                        <br />
            {this.props.farmName}
          </div>
        </div>
      );
    }

    return (historicalPatronageEligibility.map((item: PatronageEligibility, index: number) => {
      return (
        <div className="c-share-history__container">
          <div className="c-share-history__tooltip-container">
            <CustomTooltip
              title={
                <>
                  <div className="c-share-history__tooltip">
                    {SHARES.SHARES_HISTORY_TOOLTIP_TEXT}
                  </div>
                </>
              }
              placement="right"
              classes={{
                tooltip: classes.tooltip,
                popper: classes.popper,
                tooltipPlacementLeft: classes.tooltipPlacementLeft,
                tooltipPlacementRight: classes.tooltipPlacementRight,
              }}
              disableTouchListener={true}
            >
              <Info className={classes.infoIcon} />
            </CustomTooltip>
          </div>
          <div className="c-share-history__shares-stock-units">
            <div className="c-share-history__shares">
              <div className="c-share-history__shares-value">{_propertyHelper.formatValue(item.eligibleShares, VALUE_TYPE.NUMBER_WITH_COMMAS)}</div>
              <div className="c-share-history__shares-label">{SHARES.LABEL_ELIGIBLE_SHARES_OWNED}</div>
            </div>
            <div className="c-share-history__stock-units">
              <div className="c-share-history__stock-units-value">{_propertyHelper.formatValue(item.stockUnits, VALUE_TYPE.NUMBER_WITH_COMMAS)}</div>
              <div className="c-share-history__stock-units-label">{SHARES.LABEL_STOCK_UNITS_SUPPLIED}</div>
            </div>
          </div>
          <div className="c-share-history__required-shares-year-end">
            <div className="c-share-history__required-shares">
              <div className="c-share-history__required-shares-value">{_propertyHelper.formatValue(item.requiredShares, VALUE_TYPE.NUMBER_WITH_COMMAS)}</div>
              <div className="c-share-history__required-shares-label">{SHARES.LABEL_SHARE_STANDARD}</div>
            </div>
            <div className="c-share-history__year-end">
              <div className="c-share-history__year-end-value">{item.patronageYear}</div>
              <div className="c-share-history__year-end-label">{SHARES.LABEL_FINANCIAL_YEAR_END}</div>
            </div>
          </div>
        </div>
      );
    }));
  }
}

export default withStyles(styles)(SharesHistoryList);
