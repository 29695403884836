import HttpService from './HttpService';
import moment from 'moment';

import * as CONNECTION from '../Constants/Connection';
import * as DATE_FORMAT from '../Constants/DateFormat';

class PaymentsService extends HttpService {
  getAllPayments = (page?: number, pageSize?: number, startDate?: Date, endDate?: Date) => {
    let getPaymentsByFarmIdApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}/${CONNECTION.PAYMENT}?${CONNECTION.PAGE_PARAM}=${page!.toString()}&${CONNECTION.PAGE_SIZE_PARAM}=${pageSize!.toString()}`;
    getPaymentsByFarmIdApiUrl = this.appendDateFilters(getPaymentsByFarmIdApiUrl, startDate, endDate);
    return super.get(getPaymentsByFarmIdApiUrl);
  }

  getPaymentsByFarmId = (supplierId: string, page?: number, pageSize?: number, startDate?: Date, endDate?: Date) => {
    let getPaymentsByFarmIdApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.PAYMENT}?${CONNECTION.PAGE_PARAM}=${page!.toString()}&${CONNECTION.PAGE_SIZE_PARAM}=${pageSize!.toString()}`;
    getPaymentsByFarmIdApiUrl = this.appendDateFilters(getPaymentsByFarmIdApiUrl, startDate, endDate);
    return super.get(getPaymentsByFarmIdApiUrl);
  }

  getPaymentsExportByFarmId = (supplierId: string, fileFormat?: string, startDate?: Date, endDate?: Date) => {
    let getPaymentsExportByFarmIdApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.PAYMENT}/${CONNECTION.EXPORT}`;
    if (fileFormat || startDate || endDate) { getPaymentsExportByFarmIdApiUrl += '?'; }
    getPaymentsExportByFarmIdApiUrl = this.appendFileFormat(getPaymentsExportByFarmIdApiUrl, fileFormat);
    getPaymentsExportByFarmIdApiUrl = this.appendDateFilters(getPaymentsExportByFarmIdApiUrl, startDate, endDate);
    return super.doDownloadDocument(getPaymentsExportByFarmIdApiUrl);
  }

  getPaymentsExportByFarmList = (fileFormat?: string, startDate?: Date, endDate?: Date) => {
    let getPaymentsExportByFarmListApiUrl = `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${CONNECTION.PAYMENT}/${CONNECTION.EXPORT}`;
    if (fileFormat || startDate || endDate) { getPaymentsExportByFarmListApiUrl += '?'; }
    getPaymentsExportByFarmListApiUrl = this.appendFileFormat(getPaymentsExportByFarmListApiUrl, fileFormat);
    getPaymentsExportByFarmListApiUrl = this.appendDateFilters(getPaymentsExportByFarmListApiUrl, startDate, endDate);
    return super.doDownloadDocument(getPaymentsExportByFarmListApiUrl);
  }

  private appendFileFormat = (apiUrl: string, fileFormat?: string) => {
    if (fileFormat) {
      apiUrl = `${apiUrl}&${CONNECTION.FILE_FORMAT_PARAM}=${fileFormat!.toString()}`;
    }

    return apiUrl;
  }
  
  private appendDateFilters = (apiUrl: string, startDate?: Date, endDate?: Date) => {
    if (startDate) {
      apiUrl = `${apiUrl}&${CONNECTION.START_DATE_PARAM}=${moment(startDate).format(DATE_FORMAT.HYPHENATED_DAY_MONTH_AND_YEAR)}`;
    }
    if (endDate) {
      apiUrl = `${apiUrl}&${CONNECTION.END_DATE_PARAM}=${moment(endDate).format(DATE_FORMAT.HYPHENATED_DAY_MONTH_AND_YEAR)}`;
    }

    return apiUrl;
  }
}

export default PaymentsService;
