import { createStyles, IconButton, InputAdornment, withStyles, WithStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const styles = createStyles({
  input: {
    '&::-ms-clear': {
      display: 'none',
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
  onSearchQuerySubmit: () => void;
  onClose: () => void;
}

class KillSheetNumberSearchInput extends React.Component<Props> {
  sanitizeInput = (input: string) => {
    // sanitize non-numeric input
    return input.replace(/[^0-9]/g, '');
  }

  handleSearchSubmit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.props.onSearchQuerySubmit();
    }
  }

  render() {
    const {
      classes,
      searchQuery,
      onSearchQueryChange,
      onClose,
    } = this.props;

    return (
      <InputBase
        className={searchQuery.length === 0 ? 'c-header__search-input-empty' : 'c-header__search-input'}
        classes={{ input: classes.input }}
        placeholder="Search kill sheet number of all farms"
        type="search"
        autoFocus={true}
        value={searchQuery}
        onChange={event => { onSearchQueryChange(this.sanitizeInput(event.target.value)); }}
        onKeyPress={this.handleSearchSubmit}
        endAdornment={(
          <InputAdornment className="c-header__search-close-icon" position="end">
            <IconButton color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        )}
      />
    );
  }
}

export default withStyles(styles)(KillSheetNumberSearchInput);
