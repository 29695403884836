import { AxiosError, AxiosResponse } from 'axios';
import { observable, action } from 'mobx';
import PaymentsService from '../Services/PaymentsService';
import PaymentHistorySummaryLine from '../Models/PaymentHistorySummaryLine';

import * as PAYMENT from '../Constants/Payment';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as DOCUMENT_TYPE from '../Constants/DocumentType';
import * as HTTP_RESPONSE_CODE from '../Constants/HttpResponseCode';
import KillSheetService from '../Services/KillSheetService';
import PaymentDocument from '../Models/PaymentDocument';

class PaymentsStore {
  @observable public currentPage: number;
  @observable public isPaymentsLoaded: boolean;
  @observable public isPaymentsCompleted: boolean;
  @observable public isPaymentLoadError: boolean;
  @observable public paymentHistorySummaryLines: Array<PaymentHistorySummaryLine>;
  @observable public downloadedPaymentDocuments: Array<PaymentDocument>;
  @observable public selectedSupplierId: string;
  @observable public selectedStartDate?: Date;
  @observable public selectedEndDate?: Date;
  @observable public selectedDateFilter?: string;
  @observable public downloadedInvoiceDocuments: Array<PaymentDocument>;

  private sameDateSelection: boolean;
  private sameSupplier: boolean;
  private _paymentsService: PaymentsService = new PaymentsService();
  private _killSheetService: KillSheetService = new KillSheetService();

  constructor() {
    this.setPaymentSelectedSupplierId('');
    this.downloadedPaymentDocuments = new Array<PaymentDocument>();
  }

  @action
  setExportFilter = (startDate?: Date, endDate?: Date, dateFilter?: string) => {
    this.selectedStartDate = startDate;
    this.selectedEndDate = endDate;
    this.selectedDateFilter = dateFilter;
  }

  @action
  clearExportFilter = () => {
    this.selectedStartDate = new Date;
    this.selectedEndDate = new Date;
    this.selectedDateFilter = '';
  }

  @action
  setPaymentSelectedSupplierId = (supplierId: string) => {
    this.currentPage = PAYMENT.DEFAULT_PAGE;
    this.isPaymentsLoaded = false;
    this.isPaymentsCompleted = false;
    this.isPaymentLoadError = false;
    this.paymentHistorySummaryLines = new Array<PaymentHistorySummaryLine>();
    this.selectedSupplierId = supplierId;
  }

  @action
  getPaymentsExportByFarm = (isAllFarms: boolean, supplierId: string, startDate?: Date, endDate?: Date) => {
    if (isAllFarms) {
      return this._paymentsService.getPaymentsExportByFarmList('csv', startDate, endDate);
    }
    return this._paymentsService.getPaymentsExportByFarmId(supplierId, 'csv', startDate, endDate);
  }

  @action
  getPaymentsByFarm = (isAllFarms: boolean, supplierId: string, startDate?: Date, endDate?: Date) => {
    this.isPaymentsLoaded = false;
    this.isPaymentsCompleted = false;
    this.isPaymentLoadError = false;
    this.sameDateSelection = this.selectedStartDate === startDate && this.selectedEndDate === endDate;
    this.selectedStartDate = startDate;
    this.selectedEndDate = endDate;

    if (isAllFarms) {
      const getAllPaymentsTask = this.getAllPayments(startDate, endDate);
      return this.resolveRequest(getAllPaymentsTask);
    }
    const getPaymentsByFarmIdTask = this.getPaymentsByFarmId(supplierId, startDate, endDate);
    return this.resolveRequest(getPaymentsByFarmIdTask);
  }

  @action
  resetPaymentDocuments = () => {
    this.downloadedPaymentDocuments = new Array<PaymentDocument>();
  }

  @action
  downloadPaymentDocument = (supplierId: string, killSheetNumber: number, reference: string) => {

    if (killSheetNumber) {
      const downloadTask = this.getDocument(supplierId, killSheetNumber, reference, DOCUMENT_TYPE.PDF);
      if (downloadTask) {
        downloadTask.then(response => {
          if (response) {
            let paymentDocument: PaymentDocument = { referenceNumber: reference, document: response.data };
            this.downloadedPaymentDocuments!.push(paymentDocument);
          } else {
            let paymentDocument: PaymentDocument = { referenceNumber: reference, document: DEFAULT_DATA.UNDEFINED };
            this.downloadedPaymentDocuments!.push(paymentDocument);
          }
        }).catch(() => {
          console.warn('No document found');
        });
      }
    } else {
      let paymentDocument: PaymentDocument = { referenceNumber: reference, document: DEFAULT_DATA.UNDEFINED };
      this.downloadedPaymentDocuments!.push(paymentDocument);
    }
  }

  private getDocument = (supplierId: string, killSheetNumber: number, reference: string, type: string) => {
    if (supplierId && killSheetNumber && reference) {
      return this._killSheetService.getDocument(supplierId, killSheetNumber, reference, type);
    }
    return DEFAULT_DATA.UNDEFINED;
  }

  private getPaymentsByFarmId = (supplierId: string, startDate?: Date, endDate?: Date) => {
    this.sameSupplier = this.selectedSupplierId === supplierId;
    this.selectedSupplierId = supplierId;
    this.currentPage = this.sameSupplier && this.sameDateSelection ? this.currentPage : PAYMENT.DEFAULT_PAGE;

    if (this.currentPage === PAYMENT.DEFAULT_PAGE) {
      this.paymentHistorySummaryLines.length = 0;
    }

    return this._paymentsService
      .getPaymentsByFarmId(supplierId, ++this.currentPage, PAYMENT.PAGE_SIZE, startDate, endDate);
  }

  private getAllPayments = (startDate?: Date, endDate?: Date) => {
    this.sameSupplier = this.selectedSupplierId === PAYMENT.ALL_FARMS_ID;
    this.selectedSupplierId = PAYMENT.ALL_FARMS_ID;
    this.currentPage = this.sameSupplier && this.sameDateSelection ? this.currentPage : PAYMENT.DEFAULT_PAGE;

    if (this.currentPage === PAYMENT.DEFAULT_PAGE) {
      this.paymentHistorySummaryLines.length = 0;
    }

    return this._paymentsService
      .getAllPayments(++this.currentPage, PAYMENT.PAGE_SIZE, startDate, endDate);
  }

  private resolveRequest = (paymentRequest: Promise<AxiosResponse<any> | undefined>) => {
    return paymentRequest.then(response => {
      if (response!.data && response!.data!.paymentGroups) {
        if (this.currentPage && this.sameSupplier && this.sameDateSelection) {
          this.paymentHistorySummaryLines.push(...response!.data!.paymentGroups);
        } else {
          this.paymentHistorySummaryLines = response!.data!.paymentGroups;
        }

        if (response!.data!.paymentGroups.length < PAYMENT.PAGE_SIZE) {
          this.isPaymentsCompleted = true;
        }
      } else if (response!.status === HTTP_RESPONSE_CODE.NO_CONTENT) {
        this.isPaymentsCompleted = true;
      } else {
        this.paymentHistorySummaryLines = this.sameSupplier && this.sameDateSelection ? this.paymentHistorySummaryLines : new Array<PaymentHistorySummaryLine>();
      }
      this.isPaymentsLoaded = true;
    })
      .catch((error: AxiosError) => {
        console.warn(error);
        this.isPaymentsLoaded = true;
        this.isPaymentLoadError = true;
        this.isPaymentsCompleted = true;
      });
  }
}

export default PaymentsStore;
