import * as MENU from '../Constants/Menu';
import { action, observable } from 'mobx';
import MenuListItem from '../Models/MenuListItem';

class MenuStore {
  @observable public isMenuOpen: boolean;
  @observable public menuItems: Array<MenuListItem>;
  @observable public selectedIndex: number;

  constructor() {
    this.menuItems = MENU.ITEMS;
    this.selectedIndex = MENU.DEFAULT_INDEX;
  }

  @action
  public setIsMenuOpen = (isMenuOpen: boolean) => {
    this.isMenuOpen = isMenuOpen;
  }

  @action
  public setSelectedIndex = (selectedIndex: number) => {
    this.selectedIndex = selectedIndex;
  }
}

export default MenuStore;
