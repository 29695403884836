// This constants file contains values that relate to APIs
// API endpoint path values
export const ANALYTICS_EMBED = 'analytics/embed/';
export const SLICE_AND_DICE_REPORT = '/sliceAndDiceReport';
export const SHARE_PRICE_HISTORY_EMBED = 'shares/sharepricehistory';
export const API_VERSION = 'v1/';
// TODO: remove API_VERSION_2 and all references once all APIs migrated to v2
export const API_VERSION_2 = 'v2/';
export const BANNER = 'banner/';
export const COMPLETE_REGISTRATION = 'completeregistration';
export const COMPLETE_REGISTRATION2 = 'completeregistration2';
export const CONTENT = 'content/';
export const DOCUMENT = 'document/';
export const GET_CURRENT_ARTICLES = 'getcurrentarticles';
export const FEEDBACK = 'feedback/';
export const GET_BOOKINGS = 'booking/';
export const GET_FORECASTS = 'forecast/';
export const GET_SUPPLIER_FARM_DETAILS = 'farmdetails/';
export const GET_SUPPLIER_ACCOUNT_DETAILS = 'account/';
export const GET_USER_FARMS = 'farms/';
export const GET_USER_ASSIGNMENT = 'userAssignment/';
export const GET_STREAM = 'stream/';
export const INVOICE = 'invoice/';
export const KILLSHEET = 'killsheet/';
export const NAIT_NUMBER = 'naitnumber/';
export const NZFAP_CERT = 'assuranceCertificate/';
export const PAYMENT = 'payment/';
export const REFERENCE = 'reference/';
export const SCHEDULE = 'schedule/';
export const SUPPLIER = 'supplier/';
export const TERMS_AND_CONDITIONS = 'termsandconditions/';
export const ARTICLE = 'article/';
export const USER_MANAGEMENT = 'user/';
export const AUTH = 'auth/';
export const USER_REGISTRATION = 'userregistration/';
export const SHAREHOLDERS = 'shareholders/';
export const SEASONOVERVIEW = 'seasonoverview/';
export const SHARES = 'shares/';
export const CURRENTSHAREPRICE = 'currentshareprice/';
export const EXPORT = 'export/';
export const PERFORMANCEREPORT = 'performancereport';
export const TARGETREPORTTYPE = 'targetReportType=web';
export const ANIMALCATEGORY = 'animalCategory=';


export const FARM_ACCESS_CONTACTS = 'accesscontacts';

// API request parameter names
export const DOCUMENT_TYPE_PARAM = 'documentType';
export const END_DATE_PARAM = 'enddate';
export const PAGE_PARAM = 'page';
export const PAGE_SIZE_PARAM = 'pagesize';
export const SPECIES_PARAM = 'species';
export const START_DATE_PARAM = 'startdate';
export const FILE_FORMAT_PARAM = 'fileformat';

// API error message and button text
export const ERROR_MESSAGE = 'Oops, something went wrong - please try again later.';
export const ERROR_RELOAD = 'Reload';
export const TERMS_ACCEPTED = 'termsaccepted';

// Application configuration file path
export const CONFIGURATION_FILE_PATH = '/data.json';
