import { action, observable } from "mobx";
import BannerService from "../Services/BannerService";
import StorageHelper from "../Helpers/StorageHelper";
import * as STORAGE from '../Constants/Storage';
import * as TELEMETRY from '../Constants/Telemetry';

class BannerStore {
  @observable public userClosedBanner: boolean;
  @observable public bannerDataAvailable: boolean;
  @observable public title: string;
  @observable public messageText: string;
  @observable public link: string;
  @observable public priority: string;
  @observable public openInNewTab: boolean;

  private _bannerService: BannerService = new BannerService();
  private _storageHelper: StorageHelper = new StorageHelper();

  constructor() {
    this.userClosedBanner = false;
    this.bannerDataAvailable = false;
    this.retrieveUserBannerToggle();
  }

  @action
  public retrieveUserBannerToggle = ()  => {
    // Retrieve stored value and assign to class variable
    const bannerIsClosed = this._storageHelper.getKeyValue(STORAGE.BANNER_CLOSED);
    if (JSON.parse(bannerIsClosed!) === null) {
      this.userClosedBanner = false;
      this._storageHelper.storeKeyValue(STORAGE.BANNER_CLOSED, false);
    } else {
      this.userClosedBanner = JSON.parse(bannerIsClosed!);
    }
  };

  @action
  public bannerClosedHandler = (e: React.MouseEvent) => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.BANNNER_CLOSE_CLICK);
    e.stopPropagation();
    // Store new value and set properties
    this._storageHelper.storeKeyValue(STORAGE.BANNER_CLOSED, true);
    this.userClosedBanner = true;
  };

  @action
  getBanner = async () => {
    try {
      const response = await this._bannerService.getBanner();
      if (response && response!.data) {
        if (response.data.success) {
          this.title = response!.data.title;
          this.messageText = response!.data.messageText;
          this.link = response!.data.link;
          this.priority = response!.data.priority;
          this.openInNewTab = response!.data.openInNewTab;
          this.bannerDataAvailable = true;
        }
      }
    } catch (e) {
      // Ignore error
    }
  };
}

export default BannerStore;
