import { CircularProgress, withStyles, WithStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as React from 'react';

const styles = (theme: any) => ({
  progress: {
    margin: theme.spacing(2),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface Props {
  classes?: any;
  isLoading: boolean;
  message?: string;
}

export class ProgressDialog extends React.Component<
  Props & WithStyles<'progress'>,
  {}
> {
  public render() {
    return (
      <Dialog
        open={this.props.message !== null && this.props.isLoading}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent className={this.props.classes.content}>
          <CircularProgress className={this.props.classes.progress} />
          <DialogContentText id='alert-dialog-description'>
            {this.props.message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ProgressDialog);
