import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, MenuItem, Select } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import React from 'react';
import * as ARRAY from '../App/Constants/Array';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import FilterItem from '../App/Models/FilterItem';
import KillSheetDetailsCondemned from '../App/Models/KillSheetDetailsCondemned';
import KillSheetDetailsDead from '../App/Models/KillSheetDetailsDead';

interface Props {
  categories: Array<FilterItem>;
  details?: Map<string, Array<KillSheetDetailsCondemned> | Array<KillSheetDetailsCondemned>>;
  expanded?: string;
  handleChange: Function;
  tally: number;
  type: string;
}

interface State {
  detailItems?: Array<KillSheetDetailsCondemned> | Array<KillSheetDetailsDead>;
  selectedAnimalCategory?: string;
  selectedDetailItem?: KillSheetDetailsCondemned | KillSheetDetailsDead;
  selectedKillSheetNumber?: number;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

class GradeState extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAnimalCategory: DEFAULT_DATA.UNDEFINED,
      selectedDetailItem: DEFAULT_DATA.UNDEFINED,
      selectedKillSheetNumber: DEFAULT_DATA.UNDEFINED
    };
  }

  componentDidMount = () => {
    const animalCategories = this.props.categories;
    const details = this.props.details;
    const detailEntries = details && Array.from(details.entries());

    if (!_.isEmpty(animalCategories) && !_.isEmpty(detailEntries)) {
      const selectedAnimalCategory = animalCategories[ARRAY.FIRST_ELEMENT].value;
      const detailItems = details!.get(selectedAnimalCategory);

      if (!_.isEmpty(detailItems)) {
        const selectedDetailItem = detailItems![ARRAY.FIRST_ELEMENT];
        const selectedKillSheetNumber = selectedDetailItem.killNumber;

        this.setState({
          detailItems: detailItems,
          selectedAnimalCategory: selectedAnimalCategory,
          selectedDetailItem: selectedDetailItem,
          selectedKillSheetNumber: selectedKillSheetNumber
        });
      }
    }
  }

  handleCategoryChange = (event: any) => {
    const animalCategories = this.props.categories;
    const details = this.props.details;

    const selectedAnimalCategory = animalCategories.find(ac => ac.value === event.target.value)!.value;

    if (selectedAnimalCategory && details) {
      const detailItems = details.get(selectedAnimalCategory);

      if (detailItems) {
        const selectedDetailItem = detailItems![ARRAY.FIRST_ELEMENT];
        const selectedKillSheetNumber = selectedDetailItem.killNumber;

        this.setState({
          detailItems: detailItems,
          selectedAnimalCategory: selectedAnimalCategory,
          selectedDetailItem: selectedDetailItem,
          selectedKillSheetNumber: selectedKillSheetNumber
        });
      }
    }
  }

  handleKillSheetNumberChange = (event: any) => {
    const details = this.props.details;
    const selectedAnimalCategory = this.state.selectedAnimalCategory;
    const selectedKillSheetNumber = event.target.value;

    if (details && selectedAnimalCategory && selectedKillSheetNumber) {
      const detailItems = details.get(selectedAnimalCategory);

      if (detailItems) {
        const selectedDetailItem = detailItems!.find(d => d.killNumber === selectedKillSheetNumber);

        this.setState({
          selectedDetailItem: selectedDetailItem,
          selectedKillSheetNumber: selectedKillSheetNumber
        });
      }
    }
  }

  render() {
    const { categories, details, expanded, handleChange, tally, type } = this.props;
    const { detailItems, selectedAnimalCategory, selectedDetailItem, selectedKillSheetNumber } = this.state;
    const panelName = `grade-state-${type}`;

    if (!details || !detailItems || !selectedDetailItem) {
      return <div />;
    }

    const titleClass = 'c-grade-state__title' + DEFAULT_DATA.WHITESPACE + _.lowerCase(this.state.selectedAnimalCategory);
    const iconBarClass = 'c-grade-state__more-icon-bar' + DEFAULT_DATA.WHITESPACE + _.lowerCase(this.state.selectedAnimalCategory);

    const quantity = Array.from(details.entries())[0][1].length;
    const percentageValue = tally ? Math.round(quantity / tally * DEFAULT_DATA.PERCENTAGE) : DEFAULT_DATA.HYPHEN;

    const animalCategorySelectComponent =
      categories.length > ARRAY.SINGLE_ELEMENT ?
        (
          <FormControl>
            <Select
              value={this.state.selectedAnimalCategory}
              onChange={this.handleCategoryChange}
              inputProps={{
                name: 'selectedAnimalCategory',
                id: 'c-grade-state__animal-category-list',
              }}
              disableUnderline={true}
            >
              {
                categories.map(category => {
                  return (
                    <MenuItem key={category.value} value={category.name}>
                      {category.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        )
        : _propertyHelper.formatValue(selectedAnimalCategory, VALUE_TYPE.STRING).toUpperCase();

    const killSheetNumberSelectComponent =
      detailItems.length > ARRAY.SINGLE_ELEMENT ?
        (
          <FormControl>
            <Select
              value={this.state.selectedKillSheetNumber}
              onChange={this.handleKillSheetNumberChange}
              inputProps={{
                name: 'selectedKillSheetNumber',
                id: 'c-grade-state__kill-sheet-number-list',
              }}
              disableUnderline={true}
            >
              {
                detailItems.map(detailItem => {
                  return (
                    <MenuItem key={detailItem.killNumber} value={detailItem.killNumber}>
                      {detailItem.killNumber}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        )
        : _propertyHelper.formatValue(selectedDetailItem.killNumber, VALUE_TYPE.STRING).toUpperCase();

    return (
      <ExpansionPanel
        key={panelName}
        className="c-grade-state__panel"
        expanded={expanded === panelName}
        onChange={handleChange(panelName)}
      >
        <ExpansionPanelSummary className="c-grade-state__summary">
          <div className="c-grade-state__header">
            <div className={titleClass}>{type} {`${percentageValue === 0 ? '< 1' : percentageValue}${KILLSHEET.PERCENTAGE_UNIT}`}</div>
            <div className="c-grade-state__quantity">{quantity}</div>
            <div className={iconBarClass} style={{ width: `${percentageValue}${KILLSHEET.PERCENTAGE_UNIT}` }}>
              <ArrowDropDownIcon />
            </div>
            <div className="c-grade-state__divider" />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="c-grade-state__details">
          <div className="c-grade-state__property">
            <div className="c-grade-state__property-name">Animal Category Code</div>
            <div className="c-grade-state__property-value">{animalCategorySelectComponent}</div>
          </div>
          <div className="c-grade-state__property">
            <div className="c-grade-state__property-name">Animal ID</div>
            <div className="c-grade-state__property-value">{_propertyHelper.formatValue(selectedDetailItem.animalId, VALUE_TYPE.STRING)}</div>
          </div>
          <div className="c-grade-state__property">
            <div className="c-grade-state__property-name">Kill No</div>
            <div className="c-grade-state__property-value">{killSheetNumberSelectComponent}</div>
          </div>
          <div className="c-grade-state__property">
            <div className="c-grade-state__property-name">Condemned By</div>
            <div className="c-grade-state__property-value">{_propertyHelper.formatValue(selectedDetailItem.condemnedBy, VALUE_TYPE.STRING)}</div>
          </div>
          <div className="c-grade-state__property">
            <div className="c-grade-state__property-name">Reason</div>
            <div className="c-grade-state__property-value">{_propertyHelper.formatValue(selectedDetailItem.condemnedReason, VALUE_TYPE.STRING)}</div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default GradeState;