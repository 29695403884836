import React from 'react';
import { observer } from 'mobx-react';
import { Paper } from '@material-ui/core';
import Tile from './Tile';
import DashboardStore from '../App/Stores/DashboardStore';
import MenuStore from '../App/Stores/MenuStore';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as MENU from '../App/Constants/Menu';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import { TileStatus } from '../App/Constants/TileStatus';
import _ from 'lodash';
import AssignmentIcon from '@material-ui/icons/Assignment';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  menuStore: MenuStore;
  loadData: Function;
  initialStatus?: TileStatus;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

@observer
class ScheduleTile extends Tile<Props> {
  constructor(props: Props) {
    super(props);
    let { dashboardStore, title, initialStatus } = this.props;

    if (!_.isUndefined(initialStatus)) {
      dashboardStore.setTileStatus(title, initialStatus);
    }
  }

  handleScheduleClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_SCHEDULE);
    this.props.menuStore!.setSelectedIndex(MENU.SCHEDULE_INDEX);
  }

  render() {
    const { dashboardStore } = this.props;

    if (dashboardStore!.scheduleStatus === TileStatus.LOADED) {
      return (
        <a href="#" className="c-dashboard__tile-hyperlink" onClick={this.handleScheduleClick}>
          <Paper className="c-dashboard__tile c-dashboard__tile-schedule-height">
            <div className="c-dashboard__tile-schedule-tile">
              <div className="c-dashboard__tile-header-1">SCHEDULE</div>
              <div className="c-dashboard__tile-schedule-info">
                <div className="c-dashboard__tile-schedule-leftdiv">
                  <span className="c-dashboard__tile-schedule-icon-container">
                    <AssignmentIcon className="c-dashboard__tile-schedule-icon" />
                  </span>
                  <span className="c-dashboard__tile-schedule-date">{_.upperCase(_propertyHelper.formatValue(dashboardStore!.scheduleValidFromDatetime, VALUE_TYPE.DATE, DATE_FORMAT.DAY_AND_MONTH_ONLY))}</span>
                </div>
                <div className="c-dashboard__tile-schedule-rightdiv">
                  <span className="c-dashboard__tile-schedule-viewschedule">VIEW SCHEDULE</span>
                </div>

              </div>
            </div>
          </Paper>
        </a>
      );
    } else {
      return super.render();
    }
  }
}
export default ScheduleTile;