import * as CONNECTION from '../Constants/Connection';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import HttpService from './HttpService';


class UserRegistrationService extends HttpService {

  completeRegistration = (token: string) => {
    const completeRegistrationApiUrl = `${CONNECTION.USER_REGISTRATION}${CONNECTION.API_VERSION}${CONNECTION.COMPLETE_REGISTRATION}/`;
    return super.patch(completeRegistrationApiUrl, DEFAULT_DATA.UNDEFINED, DEFAULT_DATA.UNDEFINED, DEFAULT_DATA.UNDEFINED, token);
  }
}

export default UserRegistrationService;
