import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as MY_ACCOUNT from '../App/Constants/MyAccount';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import NzfapCertificate from '../App/Models/NzfapCertificate';

interface Props {
  farmName: string;
  nzfapCertificates: NzfapCertificate[];
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

class Nzfap extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { nzfapCertificates } = this.props;

    return (
      nzfapCertificates && !_.isEmpty(nzfapCertificates) ?
        nzfapCertificates.map((item: any, index: number) => {
          let statusColour = classNames('c-nzfap__container', {
            'red': item.farmAssuranceCertificationStatus === MY_ACCOUNT.STATUS_EXPIRED || MY_ACCOUNT.STATUS_NON_COMPLIANT,
            'green': item.farmAssuranceCertificationStatus === MY_ACCOUNT.STATUS_FARM_ASSURED,
            'gold': item.farmAssuranceCertificationStatus === MY_ACCOUNT.STATUS_ASSESSED,
            'grey': item.farmAssuranceCertificationStatus === MY_ACCOUNT.STATUS_INACTIVE
          });
          return (
            <div className={statusColour} key={index}>
              <div className="c-nzfap__field">
                <div className="c-nzfap__species">{item.speciesName}</div>
                {!_.isEmpty(item.expiryDate) ? (
                  <div className="c-nzfap__wrapper">
                    <div className="c-nzfap__value">{_propertyHelper.formatValue(item.expiryDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}</div>
                    <div className="c-nzfap__label">Expiry Date</div>
                  </div>) : (DEFAULT_DATA.STRING)}
              </div>
              <div className="c-nzfap__status">{item.farmAssuranceCertificationStatus}</div>
            </div>
          );
        })
        :
        (
          <div className="c-nzfap__empty-certificates-container">
            <div className="c-nzfap__empty-certificates-text">
              No certificates found for
                    <br />
              {this.props.farmName}
            </div>
          </div>
        )
    );
  }
}

export default Nzfap;