import { action, observable } from 'mobx';
import UserTermsService from '../../App/Services/UserTermsService';
import UserTerms from '../Models/UserTerms';
import AuthStore from './AuthStore';
import Terms from '../Models/Terms';
import RenderTerms from '../../UserTermsDialog/RenderTerms';
import DialogStore from './DialogStore';
import * as RESPONSE_DIALOG_MESSAGE from '../Constants/ResponseDialogMessage';
import { AxiosError } from 'axios';

class UserTermsStore {
  @observable dialogStore: DialogStore = new DialogStore();
  @observable scrollingDialogStore: DialogStore = new DialogStore();
  @observable singleButtonDialogStore: DialogStore = new DialogStore();
  @observable authStore: AuthStore;
  @observable userTerms: UserTerms;
  @observable public isTermsLoaded: boolean;
  @observable public isTermsLoadError: boolean;
  @observable public terms: Terms;
  private _userTermsService: UserTermsService = new UserTermsService();

  constructor() {
    this.isTermsLoaded = false;
    this.isTermsLoadError = false;
    this.terms = new Terms();
  }

  @action
  handleOkay = () => {
    this.authStore!.logOut();
  }

  @action
  handleCancel = () => {
    this.dialogStore!.setDialogClosed();
    this.scrollingDialogStore!.setDialogOpen('TERMS & CONDITIONS', RenderTerms, this.handleAccept, true, 'DECLINE', 'I AGREE', this.handleDecline);
  }

  @action
  handleAccept = () => {
    this._userTermsService.updateUserTerms(true).then(() => {
      this.scrollingDialogStore!.setDialogClosed();
      this.userTerms.termsAccepted = true;
    });
  }

  @action
  handleDecline = () => {
    this.scrollingDialogStore!.setDialogClosed();
    this.dialogStore!.setDialogOpen('Are you sure you want to cancel?', 'Continued use of website needs acceptance of the updated T&Cs and Privacy Policy. If not accepted you will be logged out.',
      this.handleOkay, true, 'BACK', 'LOGOUT', this.handleCancel);
  }

  @action
  handleView = () => {
    this.singleButtonDialogStore.setDialogClosed();
    this.scrollingDialogStore!.setDialogOpen('TERMS & CONDITIONS', RenderTerms, this.handleAccept, true, 'DECLINE', 'I AGREE', this.handleDecline);
  }

  @action
  getUserTerms = () => {
    this._userTermsService.getUserTerms().then((result) => {
      this.userTerms = result!.data;
      if (!this.userTerms.termsAccepted) {
             this.singleButtonDialogStore.setDialogOpen('There has been an update to the T&Cs and Privacy Policy.', '', this.handleView, true, 'View', '');
          }
      
      // The UserTerm document is downloaded from AEM constent instead of backend API    
      // this.getLegalTerms().then(() => {
      //   if (!this.isTermsLoadError && !this.userTerms.termsAccepted) {
      //     this.singleButtonDialogStore.setDialogOpen('There has been an update to the T&Cs and Privacy Policy.', '', this.handleView, true, 'View', '');
      //   }
      // });

    });
  }

  @action
  getLegalTerms = (): any => {
    this.isTermsLoaded = false;
    this.isTermsLoadError = false;
    return this._userTermsService.getTermsAndConditions().then(terms => {
      this.terms!.blobData = terms!.data;
      var reader = new FileReader();
      var self = this;
      reader.onloadend = function (readerEvent: any) {
        let rawBase64Data = readerEvent!.target!.result;
        self.terms!.base64Data = self.removeBase64Header(rawBase64Data);
        self.isTermsLoaded = true;
      };
      reader.readAsDataURL(terms!.data);
    })
      .catch((error: AxiosError) => {
        this.isTermsLoadError = true;
        this.isTermsLoaded = false;
        let errorMessage = 'Error loading terms and conditions - ' + error.stack;
        window.telemetry.logException(errorMessage);
        const appDialogStore = window.appDialogStore;
        const retryAction = () => {
          window.appDialogStore!.setDialogClosed();
          window.location.reload();
        };
        const logoutAction = () => {
          window.appDialogStore!.setDialogClosed();
          window.logOut();
        };
        appDialogStore!.setDialogOpen(RESPONSE_DIALOG_MESSAGE.NO_TERMS_MESSAGE_TITLE, RESPONSE_DIALOG_MESSAGE.NO_TERMS_MESSAGE_CONTENT, logoutAction, true, RESPONSE_DIALOG_MESSAGE.NO_TERMS_BUTTON_RETRY_TEXT, RESPONSE_DIALOG_MESSAGE.NO_TERMS_BUTTON_LOGOUT_TEXT, retryAction);
      });
  }

  private removeBase64Header = (base64String: string | undefined) => {
    return base64String!.substr(base64String!.indexOf(',') + 1);
  }
}

export default UserTermsStore;