import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class AccountService extends HttpService {
  getAccountDetails = async (supplierId: string) => {
    const getAccountDetailsApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_SUPPLIER_ACCOUNT_DETAILS}`;

    return super.get(getAccountDetailsApiUrl);
  }
}

export default AccountService;
