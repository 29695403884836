import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import moment, { Moment } from 'moment';
import { isNullOrUndefined } from 'util';
import * as CONNECTION from '../Constants/Connection';
import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as HTTP_HEADER from '../Constants/HttpHeaders';
import * as HTTP_RESPONSE_CODE from '../Constants/HttpResponseCode';
import * as MIME_TYPE from '../Constants/MimeType';
import * as RESPONSE_DIALOG_MESSAGE from '../Constants/ResponseDialogMessage';
import * as STORAGE from '../Constants/Storage';
import { TelemetryExceptionType } from '../Constants/Telemetry';
import * as URL_MATCHERS from '../Constants/URLMatchers';
import * as VALUE_TYPE from '../Constants/ValueType';
import GuidHelper from '../Helpers/GuidHelper';
import PropertyHelper from '../Helpers/PropertyHelper';
import StorageHelper from '../Helpers/StorageHelper';
import StringHelper from '../Helpers/StringHelper';

const _storageHelper: StorageHelper = new StorageHelper();
const _stringHelper: StringHelper = new StringHelper();
const _propertyHelper: PropertyHelper = new PropertyHelper();
const _guidHelper = new GuidHelper();

export class HttpService {
  private _httpService: AxiosInstance;

  async patch(
    url: string,
    responseType: string = HTTP_HEADER.JSON_RESPONSE_TYPE,
    contentType: string = MIME_TYPE.JSON,
    data?: any,
    customToken?: string
  ) {
    await this.validateServiceInstance(customToken);

    let header = {
      responseType: responseType,
      headers: {
        'Content-Type': contentType,
        'Content-Disposition': HTTP_HEADER.ATTACHMENT,
      },
      data: {},
    };
    let supplierIds = _storageHelper.getKeyValue(STORAGE.ALL_FARM_IDS)!;
    header.headers = {
      ...header.headers,
      ...window.telemetry.getApiCustomRequestProperties(
        _guidHelper.newGuid(),
        supplierIds,
        url,
        HTTP_HEADER.PATCH_REQUEST_TYPE
      ),
    };

    if (url) {
      return this._httpService.patch(url, data, header);
    }
  }

  async get(
    url: string,
    headerAttributes?: Object,
    responseType: string = HTTP_HEADER.JSON_RESPONSE_TYPE,
    contentType: string = MIME_TYPE.JSON,
    overrideDialog: boolean = false
  ) {
    await this.validateServiceInstance();

    let header = {
      responseType: responseType,
      headers: {
        'Content-Type': contentType,
        'Content-Disposition': HTTP_HEADER.ATTACHMENT,
      },
      data: {},
    };
    let supplierIds = _storageHelper.getKeyValue(STORAGE.ALL_FARM_IDS)!;
    header.headers = {
      ...header.headers,
      ...window.telemetry.getApiCustomRequestProperties(
        _guidHelper.newGuid(),
        supplierIds,
        url,
        HTTP_HEADER.GET_REQUEST_TYPE
      ),
      ...{ OverrideDialog: overrideDialog },
    };

    if (headerAttributes) {
      header.headers = { ...header.headers, ...headerAttributes };
    }

    if (url) {
      return this._httpService.get(url, header);
    }
  }

  async doDownloadDocument(url: string) {
    await this.validateServiceInstance();
    let supplierIds = _storageHelper.getKeyValue(STORAGE.ALL_FARM_IDS)!;
    const header = {
      method: HTTP_HEADER.GET_REQUEST_TYPE,
      responseType: HTTP_HEADER.BLOB_RESPONSE_TYPE,
      headers: window.telemetry.getApiCustomRequestProperties(
        _guidHelper.newGuid(),
        supplierIds,
        url,
        HTTP_HEADER.GET_REQUEST_TYPE
      ),
    };

    if (url) {
      return this._httpService.get(url, header);
    }
  }

  async post(url: string, data: any) {
    await this.validateServiceInstance();
    let supplierIds = _storageHelper.getKeyValue(STORAGE.ALL_FARM_IDS)!;
    const header = {
      headers: window.telemetry.getApiCustomRequestProperties(
        _guidHelper.newGuid(),
        supplierIds,
        url,
        HTTP_HEADER.POST_REQUEST_TYPE
      ),
    };

    if (url && data) {
      return this._httpService.post(url, data, header);
    }
  }

  private async validateServiceInstance(customToken?: string) {
    var self = this;

    if (!self._httpService) {
      self._httpService = axios.create({
        baseURL:
          window.appSettings !== DEFAULT_DATA.UNDEFINED
            ? window.appSettings.apiUrl
            : '/',
        timeout: HTTP_HEADER.TIMEOUT_IN_MILLISECONDS,
        headers: {
          'Accept': MIME_TYPE.JSON,
          'Cache-Control': HTTP_HEADER.NO_CACHE,
        },
      });

      let _httpService = self._httpService;

      self._httpService.interceptors.request.use(
        async function (config: AxiosRequestConfig) {
          config.headers = { ...config.headers, ...{ StartTime: new Date() } };
          if (config.url && config.method) {
            config.headers = {
              ...config.headers,
              ...{
                FullUrl: `${
                  config.url.indexOf('http') === -1
                    ? config.baseURL
                    : DEFAULT_DATA.STRING
                }${config.url}`,
              },
            };
            window.telemetry.logApiRequest(config.headers);
          }

          let _token;

          if (customToken !== DEFAULT_DATA.UNDEFINED) {
            _token = customToken;
          } else {
            _token = _storageHelper.getKeyValue(STORAGE.USER_ACCESS_TOKEN);

            if (window.getToken && !_token) {
              _token = await window.getToken();
            }
          }

          if (_token) {
            config.headers.Authorization = `${HTTP_HEADER.BEARER}${_token}`;
          }
          return config;
        },
        function (error: AxiosError) {
          return Promise.reject(error);
        }
      );

      self._httpService.interceptors.response.use(
        function (response: AxiosResponse) {
          let endTime: Moment = moment(response.config.headers.EndTime);
          let startTime: Moment = moment(response.config.headers.StartTime);
          let duration = _propertyHelper.formatValue(
            endTime.diff(startTime),
            VALUE_TYPE.STRING
          );
          response.config.headers = {
            ...response.config.headers,
            ...{
              EndTime: new Date(),
              Duration: `${duration} ms`,
              HttpStatusCode: response.status,
              MessageBody: '',
              FullUrl: response.config.url,
            },
          };

          window.telemetry.logApiResponse(response.config.headers);
          return response;
        },
        async function (error: AxiosError) {
          // Checks if the AxiosError response object is null

          if (error) {
            // Log response
            if (error.config) {
              error.config.headers = {
                ...error.config.headers,
                ...{
                  EndTime: new Date(),
                  HttpStatusCode: error.response!.status,
                  MessageBody: error.config!.data,
                  FullUrl: error.response!.config!.url,
                },
              };
              window.telemetry.logApiResponse(error.config.headers);
            }

            // Log exception on App Insights
            if (!isNullOrUndefined(error.response)) {
              error.response!.data = {
                statusCode: error.response!.status,
                message: error.response!.statusText,
              };
            }
            window.telemetry.logException(
              error,
              TelemetryExceptionType.ApiError
            );

            // Perform null check on the global app dialog store prior to any further operations
            if (window.appDialogStore && !self.isOverrideDialog(error)) {
              let messageTitle: string;
              let messageContent: string;
              let action: Function | undefined;
              let buttonText: string;

              if (error.response) {
                switch (error.response!.status) {
                  case HTTP_RESPONSE_CODE.UNAUTHORISED:
                    let token = await window.getToken();

                    if (!token) {
                      messageTitle =
                        RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_MESSAGE_TITLE;
                      messageContent =
                        RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_MESSAGE_CONTENT;
                      action = () => {
                        window.appDialogStore!.setDialogClosed();
                        window.logOut();
                      };
                      buttonText =
                        RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_BUTTON_TEXT;
                      window.appDialogStore!.setDialogOpen(
                        messageTitle,
                        messageContent,
                        action,
                        false,
                        buttonText
                      );
                      return Promise.reject(error);
                    } else {
                      if (
                        error.config.headers.Authorization !==
                        `${HTTP_HEADER.BEARER}${token}`
                      ) {
                        error.config.headers.Authorization = `${HTTP_HEADER.BEARER}${token}`;
                      }
                      if (!error.config.headers.Retries) {
                        error.config.headers.Retries = 1;
                      } else {
                        error.config.headers.Retries++;
                      }
                      if (error.config.headers.Retries < 5) {
                        return _httpService.request(error.config);
                      } else {
                        messageTitle =
                          RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_INVALID_APP_MESSAGE_TITLE;
                        messageContent =
                          RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_INVALID_APP_MESSAGE_CONTENT;
                        action = () => {
                          window.appDialogStore!.setDialogClosed();
                          window.logOut();
                        };
                        buttonText =
                          RESPONSE_DIALOG_MESSAGE.UNAUTHORISED_BUTTON_TEXT;
                        window.appDialogStore!.setDialogOpen(
                          messageTitle,
                          messageContent,
                          action,
                          false,
                          buttonText
                        );
                        // propagate the original error
                        return Promise.reject(error);
                      }
                    }
                  case HTTP_RESPONSE_CODE.NOT_FOUND:
                    messageTitle =
                      RESPONSE_DIALOG_MESSAGE.NOT_FOUND_MESSAGE_TITLE;
                    messageContent =
                      RESPONSE_DIALOG_MESSAGE.NOT_FOUND_MESSAGE_CONTENT;
                    action = () => {
                      window.appDialogStore!.setDialogClosed();
                    };
                    buttonText = RESPONSE_DIALOG_MESSAGE.NOT_FOUND_BUTTON_TEXT;
                    window.appDialogStore!.setDialogOpen(
                      messageTitle,
                      messageContent,
                      action,
                      false,
                      buttonText
                    );
                    return Promise.reject(error);
                  default:
                    messageTitle =
                      RESPONSE_DIALOG_MESSAGE.DEFAULT_MESSAGE_TITLE;
                    messageContent =
                      RESPONSE_DIALOG_MESSAGE.DEFAULT_MESSAGE_CONTENT;
                    action = () => {
                      window.appDialogStore!.setDialogClosed();
                    };
                    buttonText = RESPONSE_DIALOG_MESSAGE.NOT_FOUND_BUTTON_TEXT;
                    window.appDialogStore!.setDialogOpen(
                      messageTitle,
                      messageContent,
                      action,
                      false,
                      buttonText
                    );
                    return Promise.reject(error);
                }
              }
            }
          }
        }
      );
    }
  }

  /**
   * Rules for overriding http response error related dialogs
   */
  private isOverrideDialog(error: AxiosError): boolean {
    if (error.response && error.response.config.url) {
      // 401s are never overridden
      return (
        error.response.status !== HTTP_RESPONSE_CODE.UNAUTHORISED &&
        // Prevent the dialog showing for killsheet documents that fail to load, as well as dashboard streams and the dashboard killsheet tile
        (_stringHelper.containsWords(error.response.config.url, [
          CONNECTION.DOCUMENT,
          CONNECTION.GET_STREAM,
          CONNECTION.SEASONOVERVIEW,
        ]) ||
          // Prevent the dialog showing for any api requests than were made with the OverrideDialog header parameter set to true
          error.response.config.headers.OverrideDialog === true ||
          // Prevent dialogs / response intercepting for searching killsheets by number
          URL_MATCHERS.KILLSHEET_BY_NUMBER_REGEX.test(
            error.response.config.url.replace(
              error.response.config.baseURL || '',
              ''
            )
          ))
      );
    }
    return false;
  }
}

export default HttpService;
