import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded';
import React from 'react';

const styles = createStyles({
  menuModalRoot: {
    zIndex: 1550,
  },
  menuPopoverPaper: {
    width: '12em',
    transform: 'translateY(-0.25em) !important',
    boxShadow: '0 0.05em 0.4em 0 rgba(0, 0, 0, 0.38)',
  },
});

const StyledMenuItem = withStyles(theme => ({
  root: {
    justifyContent: 'center',
    fontFamily: 'Graphik',
    fontSize: '0.6em',
    letterSpacing: '0.06em',
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgba(66, 123, 93, 0.1)',
  },
  selected: {
    fontFamily: 'Graphik-Medium',
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
    },
  },
}))(MenuItem);

interface Props extends WithStyles<typeof styles> {
  options: string[];
  selectedOption: string;
  onSelectedOptionChange: (option: string) => void;
}

interface State {
  anchorEl: null | HTMLElement;
}

class SearchSwitcher extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleMenuItemClick = (option: string) => {
    this.props.onSelectedOptionChange(option);
    this.handleClose();
  }

  render() {
    const { classes, options, selectedOption } = this.props;
    const { anchorEl } = this.state;
    return (
      <>
        <div className="c-search-switcher__button" onClick={this.handleClick}>
          {selectedOption}
          <ArrowDropDown />
        </div>
        <Menu
          ModalClasses={{ root: classes.menuModalRoot }}
          PopoverClasses={{ paper: classes.menuPopoverPaper }}
          MenuListProps={{ disablePadding: true }}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map(option => (
            <StyledMenuItem
              key={option}
              selected={option === selectedOption}
              onClick={() => { this.handleMenuItemClick(option); }}
            >
              {option}
            </StyledMenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withStyles(styles)(SearchSwitcher);
