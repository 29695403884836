class StringHelper {
  containsWords = (inputString: string, items: string[]) => {
    let found: boolean = true;
    for (let item of items) {
      if (!inputString.includes(item)) {
        found = false;        
      } else {
        found = true;
        break;
      }
    }
    return found;
  }
}

export default StringHelper;