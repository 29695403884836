import animateScrollTo from 'animated-scroll-to';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import * as DEFAULT_DATA from '../../components/App/Constants/DefaultData';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import * as TRANSITION from '../../components/App/Constants/Transition';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import KillSheetDetails from '../../components/KillSheetDetails/KillSheetDetails';
import Timeline from '../../components/Timeline/Timeline';

interface Props {
  authorizationStore?: AuthorizationStore;
}

@inject('authorizationStore')
@observer
class KillInformation extends Component<Props> {
  private selectedFarmId: string = DEFAULT_DATA.STRING;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      const { authorizationStore } = this.props;
      if (!authorizationStore!.isAllFarmsSelected && !authorizationStore!.toggleAllFarms && this.props.authorizationStore!.selectedFarmId !== this.selectedFarmId) {
        this.selectedFarmId = authorizationStore!.selectedFarmId;
        authorizationStore!.getFarmKillSheets(this.selectedFarmId);
      }
    });

    window.onorientationchange = () => {
      const _element = document.getElementById('kill-sheet-container');
      if (_element) {
        setTimeout(() => {
          animateScrollTo(TRANSITION.CONTAINER_RIGHT_POSITION, { element: _element, horizontal: true });
        }, TRANSITION.KILL_SHEET_ORIENTATION_DELAY);
      }
    };

  }

  componentDidMount = () => {
    window.telemetry.logEvent(PAGE_NAME.KILL_INFORMATION, TelemetryEventType.Page);
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.KILL_INFORMATION, TelemetryPageEventType.PageStart);
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  render() {
    return (
      <div className="c-kill-sheet-container" id="kill-sheet-container">
        <Timeline />
        <KillSheetDetails />
      </div>
    );
  }
}

export default KillInformation;
