import Grid from '@material-ui/core/Grid';
import ArtTrack from '@material-ui/icons/ArtTrack';
import Deck from '@material-ui/icons/Deck';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import Room from '@material-ui/icons/Room';
import classNames from 'classnames';
import _ from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as DASHBOARD from '../App/Constants/Dashboard';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import Tile from './Tile';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  loadData: Function;
  initialStatus?: TileStatus;
}

@observer
class LatestStream extends Tile<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleNewsClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_NEWS_STREAM);
  }

  handleSocialClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_SOCIAL_STREAM);
  }

  handleEventsClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_EVENTS_STREAM);
  }

  render() {

    const { dashboardStore } = this.props;
    const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

    if (dashboardStore!.latestArticlesStatus === TileStatus.LOADED || dashboardStore!.latestArticlesCurrentPage > 1) {
      return (
        <>
          {dashboardStore!.latestArticles.map(article => {
            if (article.type === DASHBOARD.STREAM_NEWS) {
              return (<div className="c-dashboard__tile-stream-news-card" key={article.id}>
                <div className="c-dashboard__tile-stream-news-info-header">
                  <span className="c-dashboard__tile-stream-news-info-header-icon">
                    <ArtTrack style={{ fontSize: 40 }} />
                  </span>
                  <span className={classNames('c-dashboard__tile-stream-events-info-header-title', 'c-dashboard__tile-stream-news-upper')}>
                    {article.type}
                  </span>
                </div>
                <div className={classNames('c-dashboard__tile-stream-news-image', _.isEmpty(article.thumbnail) ? 'no-thumbnail' : '')}>
                  <a href={article.moreInfo} target="_blank" onClick={this.handleNewsClick}>
                    <img src={article.thumbnail} />
                  </a>
                </div>
                <div className="c-dashboard__tile-stream-news-info">
                  <div className={classNames('c-dashboard__tile-stream-news-info-title-content', _.isEmpty(article.title) ? 'no-summary' : '')}>
                    <span className="c-dashboard__tile-stream-news-info-content-title">
                      <a href={article.moreInfo} target="_blank" onClick={this.handleNewsClick}>
                        {article.title}
                      </a>
                    </span>
                  </div>
                  <div className={classNames('c-dashboard__tile-stream-news-info-content', _.isEmpty(article.summary) ? 'no-summary' : '')}>
                    <ResponsiveEllipsis
                      className="c-dashboard__tile-stream-news-info-content-text"
                      text={article.summary}
                      maxLine="4"
                      ellipsis="..."
                    />
                  </div>
                  <div className="c-dashboard__tile-stream-news-info-footer">
                    <span>
                      {moment(article.publishingDate).format('D MMM YYYY')}
                    </span>
                  </div>
                </div>
              </div>);
            } else if (article.type === DASHBOARD.STREAM_SOCIAL) {
              return (<div className="c-dashboard__tile-stream-social-card" key={article.id}>
                <div className="c-dashboard__tile-stream-social-image" style={{ backgroundImage: `url(${article.thumbnail})` }}>
                  <a href={article.moreInfo} target="_blank" onClick={this.handleSocialClick}>
                    <img src={article.thumbnail} />
                  </a>
                </div>
                <div className="c-dashboard__tile-stream-social-info">
                  <div className="c-dashboard__tile-stream-social-info-header">
                    <span className="c-dashboard__tile-stream-social-info-header-latesticon">
                      <OutdoorGrillIcon style={{ fontSize: 35 }} />
                    </span>
                    <span className={classNames('c-dashboard__tile-stream-events-info-header-title', 'c-dashboard__tile-stream-news-upper')}>
                      {article.type}
                    </span>
                  </div>
                  <div className="c-dashboard__tile-stream-social-info-content">
                    <ResponsiveEllipsis
                      className="c-dashboard__tile-stream-social-info-content-text"
                      text={article.summary}
                      maxLine="4"
                      ellipsis="..."
                    />
                  </div>
                  <div className="c-dashboard__tile-stream-news-info-footer">
                    <span>
                      {moment(article.publishingDate).format('D MMM YYYY')}
                    </span>
                  </div>
                </div>
              </div>
              );
            } else if (article.type === 'Event') {
              return (<div className="c-dashboard__tile-stream-events-card" key={article.id}>
                <div className="c-dashboard__tile-stream-events-info-header">
                  <span className="c-dashboard__tile-stream-events-info-header-icon">
                    <Deck style={{ fontSize: 35 }} />
                  </span>
                  <span className="c-dashboard__tile-stream-events-info-header-title">
                    EVENTS
                          </span>
                </div>
                <div className={classNames('c-dashboard__tile-stream-events-image', _.isEmpty(article.thumbnail) ? 'no-thumbnail' : '')}>
                  <a href={article.moreInfo} target="_blank" onClick={this.handleEventsClick}>
                    <img src={article.thumbnail} />
                  </a>
                </div>
                <div className="c-dashboard__tile-stream-events-info">
                  <div className={classNames('c-dashboard__tile-stream-events-info-title-content', _.isEmpty(article.title) ? 'no-summary' : '')}>
                    <span className="c-dashboard__tile-stream-events-info-content-title">
                      <a href={article.moreInfo} target="_blank" onClick={this.handleEventsClick}>
                        {article.title}
                      </a>
                    </span>
                  </div>
                  <div className={classNames('c-dashboard__tile-stream-events-info-content', _.isEmpty(article.summary) ? 'no-summary' : '')}>
                    <ResponsiveEllipsis
                      className="c-dashboard__tile-stream-events-info-content-text"
                      text={article.summary}
                      maxLine="4"
                      ellipsis="..."
                    />
                  </div>
                  <Grid item={true} xs={12}>
                    <div className="c-dashboard__tile-stream-events-info-footer">
                      <div className="c-dashboard__tile-stream-events-info-date">
                        <div>Event Date: {moment(article.eventDate).format('D MMM YYYY')}</div>
                      </div>
                      <div className="c-dashboard__tile-stream-events-info-footer-right">
                        <div className="c-dashboard__tile-stream-events-info-locationicon">
                          <Room style={{ fontSize: 19 }} />
                        </div>
                        {article.location}
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
              );
            } else {
              return (
                <>
                </>
              );
            }
          })}
        </>
      );
    } else {
      return super.render();
    }

  }
}

export default LatestStream;