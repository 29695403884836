import HttpService from './HttpService';

import * as CONNECTION from '../Constants/Connection';

class FeedbackService extends HttpService {
  postFeedback = (comment: string, originateFrom?: string | null | undefined) => {
    const postFeedbackApiUrl = `${CONNECTION.USER_MANAGEMENT}${CONNECTION.API_VERSION}${CONNECTION.FEEDBACK}`;
    return super.post(postFeedbackApiUrl, { comment, originateFrom });
  }
}

export default FeedbackService;