import { Divider, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import * as SETTINGS from '../App/Constants/Settings';
import SettingsActionItem from '../App/Models/SettingsActionItem';
import SettingsComponentItem from '../App/Models/SettingsComponentItem';
import SettingsListSection from '../App/Models/SettingsListSection';
import DialogStore from '../App/Stores/DialogStore';

const styles = (theme: Theme) =>
  createStyles({
    list: {
      paddingTop: 0
    },
    listItem: {
      '&:focus': {
        backgroundColor: 'rgba(76, 123, 93, 0.07)',
      },
      '&:hover': {
        backgroundColor: 'rgba(76, 123, 93, 0.07)',
      },

    }
  });

export interface Props extends WithStyles<typeof styles> {
  dialogStore?: DialogStore;
  handleComponentChange: Function;
}

@inject('dialogStore')
@observer
class SettingsList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  logout = () => {
    window.logOut();
  }

  openDialog = (title: string, content: string, action?: Function) => {
    this.props.dialogStore!.setDialogOpen(title, content, action);
  }

  executeAction = (event: React.MouseEvent<HTMLElement, MouseEvent>, item: any) => {
    const { handleComponentChange } = this.props;
    const { action, content, prompt, title } = item;

    if (item instanceof SettingsComponentItem) { // Scenario 1: render a component on the RHS of the screen
      if (item.component) {
        handleComponentChange(item.component);
      }
    } else if (item instanceof SettingsActionItem) {
      if (prompt && title && content && action) { // Scenario 2: prompt the user with a dialog pop up and if the user clicks OK, the passed in function will be executed
        this.openDialog(title, content, action);
      } else {
        action(); // Scenario 3: do no prompt the user and execute function immediately
      }
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <Paper className="c-settings-item__container">
        {SETTINGS.ITEMS.map((section: SettingsListSection, index: number) => {
          return (
            <div key={index}>
              <div className="c-settings-item__header">{section.header}</div>
              <Divider />
              <List className={classes.list} component="nav" disablePadding={false}>
                {section.items.map((item: SettingsActionItem | SettingsComponentItem, itemIndex: number) => {
                  return (
                    <div key={`settings-index-${itemIndex}`}>
                      <ListItem className={classes.listItem} button={true} onClick={e => this.executeAction(e, item)}>
                        <ListItemText primary={<div className="c-settings-item__body" >{item.label}</div>} />
                        <ListItemIcon>
                          <KeyboardArrowRight />
                        </ListItemIcon>
                      </ListItem>
                      <Divider />
                    </div>
                  );
                })}
              </List>
            </div>
          );
        })}
      </Paper>
    );
  }
}

export default withStyles(styles)(SettingsList);