import React from 'react';

interface Props {
  title: string;
}

class StockHeader extends React.Component<Props> {
  render() {
    const { title } = this.props;

    return (
      <div className="c-stocks__header-container">
        <h1 className="c-stocks__header">{title}</h1>
      </div>
    );
  }
}

export default StockHeader;