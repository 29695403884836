import React, { Component } from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import MyAccountItem from './Models/MyAccountItem';
import MyAccountListItem from './MyAccountListItem';

interface Props {
  myAccountTable: Array<MyAccountItem>;
}

class MyAccountList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_ACCOUNT, TelemetryPageEventType.PageStop);
  }

  render() {
    const { myAccountTable } = this.props;
    return (
      <div className="c-my-account-item__container">
        {myAccountTable.map((item: any, index: number) => {
          return (
            <MyAccountListItem
              key={index}
              label={item.label}
              value={item.value}
            />
          );
        })}
      </div>
    );
  }

}

export default MyAccountList;
