import React from 'react';

interface Props {
  isDisplayed: boolean;
  year: string;
}

class TimelineYear extends React.Component<Props> {
  render() {
    if (this.props.isDisplayed) {
      return (
        <div className="c-timeline__initial-year-container">
          <div className="c-timeline__initial-year">{this.props.year}</div>
        </div>
      );
    }
    return <div />;
  }
}

export default TimelineYear;
