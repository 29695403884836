import HttpService from '../Services/HttpService';

import * as CONNECTION from '../Constants/Connection';

class ForecastService extends HttpService {
  getForecasts = (supplierId: string) => {
    const getForecastsApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_FORECASTS}`;

    return super.get(getForecastsApiUrl);
  }
  getForecastsDashboard = (supplierId: string) => {
    const getForecastsApiUrl =
      `${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${supplierId}/${CONNECTION.GET_FORECASTS}`;

    return super.get(getForecastsApiUrl, undefined, undefined, undefined, true);
  }
}

export default ForecastService;