import * as pdfjslib from 'pdfjs-dist';
import { PDFJSStatic } from 'pdfjs-dist';
import * as React from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as DOCUMENT from '../App/Constants/Document';
import * as MIMETYPE from '../App/Constants/MimeType';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import DocumentHelper from './../App/Helpers/DocumentHelper';
import Schedule from './../App/Models/Schedule';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const StartingPageNumber: number = 1;
const HundredPercentScale: number = 1;
const ScalingFactor: number = 2;

interface Props {
  schedule?: Schedule;
}

export class PdfSchedule extends React.Component<Props> {
  private pdfCanvas: any;
  private _documentHelper: DocumentHelper = new DocumentHelper();

  componentDidMount = () => {
    const { schedule } = this.props;
    this.renderPDFInCanvas(schedule!.base64Data);
    window.telemetry.triggerPageTimer(PAGE_NAME.SCHEDULE, TelemetryPageEventType.PageStop);
  }

  openPDF = (event: any) => {
    const { schedule } = this.props;
    if (schedule!.blobData) {
      this._documentHelper.accessDocument(
        schedule!.blobData,
        MIMETYPE.PDF,
        DOCUMENT.OPEN_IN_NEW_TAB_ACTION,
        DEFAULT_DATA.STRING,
        DEFAULT_DATA.STRING
      );
    }
  }

  public render() {
    const { schedule } = this.props;
    return (
      <div className="c-schedule__canvasDiv">
        <div className="c-schedule__text">{schedule!.bodyText}</div>
        <canvas
          className="c-schedule__canvas"
          ref={e => (this.pdfCanvas = e)}
          onClick={this.openPDF}
        />
      </div>
    );
  }

  private renderPDFInCanvas(base64Data: string | undefined) {
    let pdfData = this.base64ToUint8Array(base64Data);

    PDFJS.disableWorker = false;
    if (!pdfData) {
      console.warn('No data to render as a PDF');
      return;
    }

    pdfjslib.getDocument(pdfData).promise.then(pdf => {
      pdf.getPage(StartingPageNumber).then(page => {
        let canvas: any = this.pdfCanvas;
        let pdfWidth: number = page.getViewport({ scale: HundredPercentScale }).width;
        let pdfHeight: number = page.getViewport({ scale: HundredPercentScale }).height;
        canvas.width = pdfWidth * ScalingFactor;
        canvas.height = pdfHeight * ScalingFactor;

        let scale = canvas.width / page.getViewport({ scale: HundredPercentScale }).width;
        let viewport = page.getViewport({ scale: scale });
        let context = canvas!.getContext('2d');
        page.render({ canvasContext: context, viewport: viewport });
      });
    });
  }

  private base64ToUint8Array(base64: string | undefined) {
    if (!base64) {
      return undefined;
    }

    var raw = atob(base64);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  }
}

export default PdfSchedule;
