export const NAME = 'Supplier Website';

export const PAGE_NAME = {
  DASHBOARD: 'Dashboard',
  KILL_INFORMATION: 'Kill Information',
  MY_ACCOUNT: 'My Account',
  MY_STOCK: 'My Stock',
  MY_SHARES: 'My Shares',
  SCHEDULE: 'Schedule',
  PAYMENTS: 'Payments',
  WHATS_NEW: 'What\'s New',
  CONTACT_US: 'Contact Us',
  ANALYTICS: 'Analytics',
  SETTINGS: 'Settings',
  REGISTRATION: 'Complete Registration'
};