import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PowerBIDashboardStore from '../App/Stores/PowerBIDashboardStore';
import Loading from '../Loading/Loading';
import React, { Component } from 'react';
import {
    withStyles,
    createStyles,
    WithStyles,
    Paper,
    Tabs,
    Tab,
    Fade,
} from '@material-ui/core';

import PowerBiTabs from './PowerBiTabs';
import * as CONSTANTS from '../App/Constants/PowerBIDashboard';
import LoadError from '../LoadError/LoadError';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';
import { FADE_DELAY } from '../App/Constants/Transition';
import TileEmbedDetail from '../App/Models/TileEmbedDetail';

const styles = (theme: any) =>
  createStyles({
  root: {
      flexGrow: 1,
      marginTop: '60px',
      width: '100%',
      backgroundColor: theme.palette.background.paper
  },
  appBar: {
      color: 'inherit',
      backgroundColor: theme.palette.background.paper
    }
  });

interface Props extends WithStyles<typeof styles> {
  selectedSpecies: string | undefined;
  supplierId: string;
  theme: any;
}

interface State {
  analyticTiles: TileEmbedDetail[];
  analyticTilesReady: boolean;
}

@observer
class PowerBIDashboard extends React.Component<Props, State> {
  @observable public store: PowerBIDashboardStore;
  @observable public supplierId: string;

  constructor(props: Props) {
    super(props);
    this.store = new PowerBIDashboardStore();
    this.store.selectedSpecies = this.props.selectedSpecies!;
    this.store.supplierId = this.props.supplierId;
    this.state = {
      analyticTiles: [],
      analyticTilesReady: false
    }
  }

  handleButtonClick() {
    this.store.service.analyticsHasError = false;
    this.store.service.analyticsIsLoaded = false;
    this.store.getEmbeddedAnalyticsReports();
  }

  async componentDidUpdate() {
    
    if (this.store.supplierId !== this.props.supplierId) {
      console.log('Grabbing new reports');
      this.store.supplierId = this.props.supplierId;
      await this.store.getEmbeddedAnalyticsReports();
      this.setState({
        analyticTiles: this.store.analyticTileDetails.tileDetails,
        analyticTilesReady: true
      });
    }  
  }

  public render() {
    if (this.store.service.analyticsIsLoaded) {
      if (this.store.analyticTileDetails.tileDetails !== undefined && this.state.analyticTiles !== null) {
        return (
          <PowerBiTabs reports={this.state.analyticTiles!} supplierId={this.props.supplierId} />
        );
      } else {
        return (
        <Fade in={true} timeout={FADE_DELAY}>
                 <div className="c-my-powerbi-report__container">
        <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className="c-my-powerbi-report__tabs"
      >
        <Tab label="Analytics" className="c-dashboard__tile-stream-tab" />
      </Tabs>
      <SwipeableViews
        id="powerbiError"
        disabled={true}
        index={0}
        className={classNames('p-dashboard__scroll-container', 'c-dashboard__tile-stream-tabs-view')}
                  >
                      <Paper className="c-power-bi__error error">
        <LoadError onPress={() => { this.handleButtonClick(); }} />
    </Paper>                       
      </SwipeableViews>
      </div>
        </Fade>
        ); }
    } else if (this.store.service.analyticsHasError === false && this.store.service.analyticsIsLoaded === false) {
      return <Loading />;
    } else {
      return (
        <Fade in={true} timeout={FADE_DELAY}>
        <div className="c-my-powerbi-report__container">
        <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className="c-my-powerbi-report__tabs"
      >
        <Tab label="Analytics" className="c-dashboard__tile-stream-tab" />
      </Tabs>
      <SwipeableViews
        id="c-dashboard__tile-stream-tabs-view"
        disabled={true}
        index={0}
        className={classNames('p-dashboard__scroll-container', 'c-dashboard__tile-stream-tabs-view')}
                  >
                      <Paper className="c-power-bi__error error">
   
        <LoadError onPress={() => { this.handleButtonClick(); }} />
    </Paper>                       
      </SwipeableViews>
      </div>
        </Fade>
      
      ); }
    }

  async componentDidMount() {
    this.store.service.analyticsIsLoaded = false;
    await this.store.getEmbeddedAnalyticsReports();
    window.addEventListener(CONSTANTS.RESIZE, this.onResize);    
    console.log('---> Adding tiles');
    this.setState({
      analyticTiles: this.store.analyticTileDetails.tileDetails,
      analyticTilesReady: true
    });
  }
  
  componentWillUnmount() {
    window.removeEventListener(CONSTANTS.RESIZE, this.onResize);
  }

  onResize = () => {
    if (!this.store.resizeEvent) {
      clearTimeout(this.store.resizeEvent);
      this.store.resizeEvent = setTimeout(this.store.getEmbeddedAnalyticsReports, CONSTANTS.RESIZE_DELAY);
    }
  }
}

export default withStyles(styles, { withTheme: true })(PowerBIDashboard);