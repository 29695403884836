import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import animateScrollTo from 'animated-scroll-to';
import _ from 'lodash';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as DOCUMENT from '../App/Constants/Document';
import * as EVENT from '../App/Constants/Events';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as MIME_TYPE from '../App/Constants/MimeType';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as TRANSITION from '../App/Constants/Transition';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import DocumentHelper from '../App/Helpers/DocumentHelper';
import KillSheetHelper from '../App/Helpers/KillSheetHelper';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import KillSheet from '../App/Models/KillSheet';
import Grades from '../KillSheetDetails/Grades';
import HealthReport from '../KillSheetDetails/HealthReport';
import HitRate from '../KillSheetDetails/HitRate';
import PaymentsStore from '../App/Stores/PaymentsStore';
import { SUCCESS } from '../App/Constants/HttpResponseCode';

interface Props {
  killSheet?: KillSheet;
  killSheetPdfDocumentBlob?: any;
  killSheetXlsxDocumentBlob?: any;
  paymentsStore?: PaymentsStore;
}

interface State {
  anchorEl: null | HTMLElement;
  killsheetDataFileAnchorEl: null | HTMLElement;
}

const _documentHelper: DocumentHelper = new DocumentHelper();
const _propertyHelper: PropertyHelper = new PropertyHelper();
const _killSheetHelper: KillSheetHelper = new KillSheetHelper();


class FinalKillSheetDetails extends React.Component<Props> {
  state = {
    anchorEl: null,
    killsheetDataFileAnchorEl: null
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  
  handleKillsheetDataFileClick= (event: any) => {
    this.setState({ killsheetDataFileAnchorEl: event.currentTarget });
  }

  openOrSaveDocument = (event: any, blob?: any, mimeType?: string, action?: string) => {
    if (action === DOCUMENT.DOWNLOAD_ACTION) {
      switch (mimeType) {
        case MIME_TYPE.PDF:
          window.telemetry.logEvent(TELEMETRY.EVENTS.DOWNLOAD_KILLSHEET_PDF_DETAILS);
          break;
        case MIME_TYPE.EXCEL:
          window.telemetry.logEvent(TELEMETRY.EVENTS.DOWNLOAD_KILL_DATA_XLSX_DETAILS);
          break;
        default:

      }
    } else if (action === DOCUMENT.OPEN_IN_NEW_TAB_ACTION) {
      switch (mimeType) {
        case MIME_TYPE.PDF:
          window.telemetry.logEvent(TELEMETRY.EVENTS.VIEW_KILLSHEET_PDF_DETAILS);
          break;
        default:
      }
    }

    if (blob && blob !== EVENT.BACKDROP_CLICK && mimeType && action) {
      _documentHelper.accessDocument(blob, mimeType, action, this.props.killSheet!.supplier, _.toString(this.props.killSheet!.killsheetNumber));
    }

    this.setState({ anchorEl: null });
    this.setState({ killsheetDataFileAnchorEl: null });

  }

  componentDidMount = () => {
    const element = document.getElementById('kill-sheet-container');
    animateScrollTo(TRANSITION.CONTAINER_RIGHT_POSITION, { element: element!, horizontal: true });
    window.telemetry.logEvent(TELEMETRY.EVENTS.KILLSHEET_DETAIL_EXPANDED);
  }

  render() {
    if (!this.props.killSheet) {
      return <div />;
    }

    let _categories = this.props.killSheet.killsheetDetails.map(c =>
      _.upperCase(c.animalCategory)
    );

    let _speciesIcon = _killSheetHelper.getSpeciesIcon(
      this.props.killSheet.species
    );

    const killSheetButton = <Button onClick={this.handleClick}  target="_blank" className='c-final-kill-sheet-details__hover-view buttonEdgeLeft'><img src="/images/library_books-kill-sheet.png" alt="kill-sheet-button"/> &nbsp; More</Button>;
    // const killDataFileButton = <Button onMouseEnter={this.handleKillsheetDataFileClick} target="_blank" className='c-final-kill-sheet-details__hover-view buttonEdgeRight'><img src="/images/kill-data-button.png" alt="kill-data-file-button"/> &nbsp; Kill Data File</Button>;

    const downloadKillDataFileMenu = 
    (
      <Menu
          id="c-final-kill-sheet-details__download-menu"
          getContentAnchorEl={null}
          anchorEl={this.state.killsheetDataFileAnchorEl}
          open={Boolean(this.state.killsheetDataFileAnchorEl)}
          onClose={this.openOrSaveDocument}
          anchorOrigin = {{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
         {<MenuItem disabled={!this.props.killSheetXlsxDocumentBlob}  onClick={event => this.openOrSaveDocument(event, this.props.killSheetXlsxDocumentBlob, MIME_TYPE.EXCEL, DOCUMENT.DOWNLOAD_ACTION)}><img src="/images/download_button.png" alt="download-kill-data-file-button"/> <div>&nbsp;{KILLSHEET.DOWNLOAD_KILL_DATA_FILE}</div></MenuItem>} 
        </Menu>
    );

    const killsheetMenu = 
      (
        <Menu
          id="c-final-kill-sheet-details__download-menu"
          getContentAnchorEl={null}
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.openOrSaveDocument}
          anchorOrigin = {{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {<MenuItem disabled={!this.props.killSheetPdfDocumentBlob} onClick={event => this.openOrSaveDocument(event, this.props.killSheetPdfDocumentBlob, MIME_TYPE.PDF, DOCUMENT.OPEN_IN_NEW_TAB_ACTION)}><img src="/images/view_button.png" alt="view-kill-sheet-button"/> <div>&nbsp; {KILLSHEET.VIEW_MENU_PDF}</div></MenuItem>}
          <hr color='f4f4f4'/>
          {<MenuItem disabled={!this.props.killSheetPdfDocumentBlob} onClick={event => this.openOrSaveDocument(event, this.props.killSheetPdfDocumentBlob, MIME_TYPE.PDF, DOCUMENT.DOWNLOAD_ACTION)}><img src="/images/download_button.png" alt="download-kill-sheet-button"/> <div>&nbsp; {KILLSHEET.DOWNLOAD_MENU_XLSX}</div></MenuItem>}
          <hr color='f4f4f4'/>
          {<MenuItem disabled={!this.props.killSheetXlsxDocumentBlob}  onClick={event => this.openOrSaveDocument(event, this.props.killSheetXlsxDocumentBlob, MIME_TYPE.EXCEL, DOCUMENT.DOWNLOAD_ACTION)}><img src="/images/download_button.png" alt="download-kill-data-file-button"/> <div>&nbsp;{KILLSHEET.DOWNLOAD_KILL_DATA_FILE}</div></MenuItem>} 
        </Menu>
      ) ;

    return (
      <div className="c-final-kill-sheet-details__content-container" id={'final-kill-sheet-details'}>
        <h1 className="c-final-kill-sheet__header">{KILLSHEET.DETAILS_HEADER}</h1>
        <div className="c-final-kill-sheet-details__header">
          <div>
          {/* {killDataFileButton} */}
          {killSheetButton}
          </div>
          <div className="c-final-kill-sheet-details__header-content">
            <div className="c-final-kill-sheet-details__subject-container">
              <span className="c-final-kill-sheet-details__species-icon">
                {_speciesIcon ? (
                  <img src={_speciesIcon.iconUrl} alt={_speciesIcon.name} />
                ) : (
                    DEFAULT_DATA.STRING
                  )}
              </span>
              <span className="c-final-kill-sheet-details__species-text">
                {_.upperCase(this.props.killSheet.species)}
              </span>
              <span className="c-final-kill-sheet-details__categories">
                {_categories.map(category => (
                  <span
                    key={category}
                    className="c-final-kill-sheet-details__category"
                  >
                    {category}
                  </span>
                ))}
              </span>
            </div>
            <div className="c-final-kill-sheet-details__action-container">
              <span className="c-final-kill-sheet-details__date">
                {_.upperCase(_propertyHelper.formatValue(this.props.killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR))}
              </span>
              {killsheetMenu}
              {downloadKillDataFileMenu}
            </div>
          </div>
        </div>
        <div className="c-final-kill-sheet-details__content">
          <div className="c-final-kill-sheet-details__content-container">
            <div className="c-final-kill-sheet-details__primary-attributes-container">
              <div className="c-final-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.tally, VALUE_TYPE.STRING)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.TALLY}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.averageWeightPerHead, VALUE_TYPE.WEIGHT)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.AVERAGE_KG_PER_HEAD}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.averageGrossPricePerHead, VALUE_TYPE.CURRENCY)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.AVERAGE_GROSS_PRICE_PER_HEAD}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.averageGrossPricePerKg, VALUE_TYPE.CURRENCY)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.AVERAGE_GROSS_PRICE_PER_KG}
                </div>
              </div>
            </div>
            <div className="c-final-kill-sheet__dashed-bottom-border" />
            <div className="c-final-kill-sheet-details__secondary-attributes-container">
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.killsheetNumber, VALUE_TYPE.KILLSHEET_NUMBER)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.KILL_SHEET_NUMBER}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.DATE_PROCESSED}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.livestockRepresentative, VALUE_TYPE.STRING)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.LIVESTOCK_REPRESENTATIVE}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.totalPayment, VALUE_TYPE.CURRENCY)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.TOTAL_PAYMENT}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.paymentDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.PAYMENT_DATE}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__secondary-attribute">
                {_.capitalize(_propertyHelper.formatValue(this.props.killSheet.type, VALUE_TYPE.STRING))}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.PAYMENT_TYPE}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__row-attribute">
                {_.capitalize(_propertyHelper.formatValue(this.props.killSheet.presentationGrade, VALUE_TYPE.STRING))}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.PRESENTATION_GRADE}
                </div>
              </div>
              <div className="c-final-kill-sheet-details__row-attribute">
                {_.capitalize(_propertyHelper.formatValue(this.props.killSheet.allowancesAndDeductions, VALUE_TYPE.STRING))}
                <div className="c-final-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.ADDITIONAL_PREMIUMS_DEDUCTIONS}
                </div>
              </div>
            </div>
            <div className="c-final-kill-sheet__dashed-bottom-border" />
            <div className="c-final-kill-sheet-details__hit-rate-container">
              <HitRate killSheet={this.props.killSheet} />
            </div>
            {(this.props.killSheet.killsheetEatingQualitySpecification) && <div className="c-final-kill-sheet__dashed-bottom-border" />}
            <div className="c-final-kill-sheet-details__health-report-container">
              <HealthReport killSheetHealthReports={this.props.killSheet.killsheetHealthReports} />
            </div>
            <div className="c-final-kill-sheet__dashed-bottom-border" />
            <div className="c-final-kill-sheet-details__grades-container">
              <Grades killSheet={this.props.killSheet} />
            </div>
            <div className="c-final-kill-sheet__bottom-space" />
          </div>
        </div>
      </div>
    );
  }
}

export default FinalKillSheetDetails;
