import { Button, Collapse, Fade } from '@material-ui/core';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Info from '@material-ui/icons/Info';
import React from 'react';
import { PAGE_NAME } from '../App/Constants/Application';
import * as SHARES from '../App/Constants/Shares';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TelemetryPageEventType } from '../App/Constants/Telemetry';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PatronageEligibility from '../App/Models/PatronageEligibility';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import BarGraph from './BarGraph';
import ImageToggler from './ImageToggler';
import PatronageCalculator from './PatronageCalculator';
import cloneDeep from 'lodash/cloneDeep';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '2.5em',
      border: '0.05em solid rgb(65, 122, 93)',
      color: 'rgb(65, 122, 93)',
      fontFamily: 'Graphik-Medium',
      fontSize: '0.6em',
      padding: '0.3em 1.5em',
      '&:hover': {
        color: 'rgb(65, 122, 93)',
        textDecoration: 'none'
      }
    },
    infoIcon: {
      verticalAlign: 'middle',
      fontSize: 'small',
      marginRight: 4
    },
    patronageButton: {
      color: '#FFF',
      backgroundColor: 'rgb(65, 122, 93)',
      fontFamily: 'Graphik-Semibold',
      fontWeight: 'bold',
      letterSpacing: '0.075em',
      height: '2.6em',
      fontSize: '1em',
      width: '31em',
      textAlign: 'center',
      position: 'relative'
    },
    tooltipPopper: {
      opacity: 1
    },
    tooltip: {
      backgroundColor: '#FFF',
      maxWidth: '17.917em',
      color: 'rgb(51, 51, 51)',
      fontFamily: 'Graphik-Medium',
      fontSize: '0.6em',
      letterSpacing: '0.03em',
      boxShadow: '0 0.15em 0.8em 0 rgba(0, 0, 0, 0.16)',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        marginLeft: '-0.5em',
        borderWidth: '0.35em',
        borderStyle: 'solid',
        borderColor: '#FFF transparent transparent transparent'
      }
    }
  });

const _propertyHelper: PropertyHelper = new PropertyHelper();

const animals = {
  sheep: '/images/sheep_shares.svg',
  beef: '/images/beef_shares.svg',
  deer: '/images/deer_shares.svg',
  bobby: '/images/bobby_shares.svg'
};

interface Props extends WithStyles<typeof styles> {
  patronageEligibility?: PatronageEligibility;
  rolling12PatronageEligibility?: PatronageEligibility;
}

interface State {
  showPatronage: boolean;
  toolTipText: string;
  toggledPatronageEligibility?: PatronageEligibility;
}

class SharesGraph extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPatronage: false,
      toolTipText: SHARES.TOOLTIP_TEXT,
      toggledPatronageEligibility: new PatronageEligibility()
    };
  }

  showHidePatronage = () => {
    this.setState({ showPatronage: !this.state.showPatronage });
  }

  adjustToolTipText = (text: string) => {
    this.setState({ toolTipText: text });
  }

  adjustTogglePatronage = (isToggled: boolean) => {
    let { patronageEligibility, rolling12PatronageEligibility } = this.props;
    
    const toggledPatronageEligibility = cloneDeep(patronageEligibility);
    if (!isToggled && (toggledPatronageEligibility != null) && (rolling12PatronageEligibility != null)) {
      toggledPatronageEligibility.eligibleShares = rolling12PatronageEligibility.eligibleShares;
      toggledPatronageEligibility.stockUnits = rolling12PatronageEligibility.stockUnits;
      toggledPatronageEligibility.requiredShares = rolling12PatronageEligibility.requiredShares;
    }
    
    this.setState({
      toggledPatronageEligibility: toggledPatronageEligibility
    })
  }

  getInfoHeader(patronageEligibility: PatronageEligibility): string {
    if (!patronageEligibility.isEligible()) {
      return SHARES.INFO_HEADER_NOT_ELIGIBLE;
    }
    if (patronageEligibility.isOverShared()) {
      return SHARES.INFO_HEADER_OVER_SHARED;
    }
    if (patronageEligibility.percentFull() == 0) {
      return SHARES.INFO_HEADER_NO_SHARES_OWNED;
    }
    if (patronageEligibility.percentFull() == 100) {
      return SHARES.INFO_HEADER_FULLY_SHARED;
    }

    const neededShares = _propertyHelper.formatValue(patronageEligibility.neededShares(), VALUE_TYPE.NUMBER_WITH_COMMAS)
    return `${neededShares} needed`;
  }

  getInfoText(patronageEligibility: PatronageEligibility): string {
    if (!patronageEligibility.isEligible()) {
      return SHARES.INFO_TEXT_NOT_ELIGIBLE;
    }
    if (patronageEligibility.isOverShared()) {
      return SHARES.INFO_TEXT_OVER_SHARED;
    }
    if (patronageEligibility.percentFull() == 0) {
      return SHARES.INFO_TEXT_NO_SHARES_OWNED;
    }
    if (patronageEligibility.percentFull() == 100) {
      return SHARES.INFO_TEXT_FULLY_SHARED;
    }

    return SHARES.INFO_TEXT_SHARES_NEEDED;
  }

  componentDidMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_SHARES, TelemetryPageEventType.PageStop);
  }

  render() {
    const { patronageEligibility, classes } = this.props;
    
    return (
      <>
        <div className="c-shares-graph__container">
          <div className="c-shares-graph__graph-container">
            <div className="c-shares-graph__info-container">
              <div className="c-shares-graph__info">
                <div className="c-shares-graph__info-header">{this.getInfoHeader(this.state.toggledPatronageEligibility!)}</div>
                <div className="c-shares-graph__info-text">{this.getInfoText(this.state.toggledPatronageEligibility!)}</div>
              </div>
              <div className="c-shares-graph__button">
                <Button
                  className={classes.button}
                  href={SHARES.FIND_OUT_MORE_URL}
                  onClick={() => window.telemetry.logEvent(TELEMETRY.EVENTS.MY_SHARES_PATRONAGE_FIND_OUT_MORE)}
                  target="_blank"
                >
                  FIND OUT MORE
                                </Button>
              </div>
            </div>
            <BarGraph
              percentFull={this.state.toggledPatronageEligibility!.percentFull()}
              nil={!this.state.toggledPatronageEligibility!.isEligible()}
              overShared={this.state.toggledPatronageEligibility!.isOverShared()}
              pointerText={!this.state.toggledPatronageEligibility!.isEligible() ? '' : SHARES.INFO_HEADER_FULLY_SHARED}
              pointerTextSize="small"
              arrowSize={!this.state.toggledPatronageEligibility!.isEligible() ? 'hidden' : 'small'}
              borderStyle="dashed"
            />
            <div className="c-shares-graph__shares-owned">
              <div className="c-shares-graph__num-shares">
                {_propertyHelper.formatValue(this.state.toggledPatronageEligibility!.totalShares, VALUE_TYPE.NUMBER_WITH_COMMAS)}
              </div>
              {SHARES.SHARES_OWNED}
            </div>
            <div className="c-shares-graph__stock-supplied">
              <div className="c-shares-graph__num-stock">
                {_propertyHelper.formatValue(this.state.toggledPatronageEligibility!.stockUnits, VALUE_TYPE.NUMBER_WITH_COMMAS)}
              </div>
              <CustomTooltip
                classes={{
                  popper: classes.tooltipPopper,
                  tooltip: classes.tooltip
                }}
                onClose={() => window.telemetry.logEvent(TELEMETRY.EVENTS.MY_SHARES_TOOL_TIP)}
                placement="top"
                disableTouchListener={true}
                title={
                  <>
                    <div className="c-shares-graph__tooltip-text">{this.state.toolTipText}</div>
                    <div className="c-shares-graph__tooltip">
                      <img src={animals.sheep} className="c-shares-graph__img" />
                      <div className="c-shares-graph__tooltip-animal">{SHARES.TOOLTIP_TEXT_LAMB}</div>
                    </div>
                    <div className="c-shares-graph__tooltip">
                      <img src={animals.bobby} className="c-shares-graph__img" />
                      <div className="c-shares-graph__tooltip-animal">{SHARES.TOOLTIP_TEXT_BOBBY}</div>
                    </div>
                    <div className="c-shares-graph__tooltip">
                      <img src={animals.deer} className="c-shares-graph__img" />
                      <div className="c-shares-graph__tooltip-animal">{SHARES.TOOLTIP_TEXT_DEER}</div>
                    </div>
                    <div className="c-shares-graph__tooltip">
                      <img src={animals.beef} className="c-shares-graph__img" />
                      <div className="c-shares-graph__tooltip-animal">{SHARES.TOOLTIP_TEXT_CATTLE}</div>
                    </div>
                  </>
                }
              >
                <Info className={classes.infoIcon} />
              </CustomTooltip>{SHARES.STOCK_SUPPLIED}
            </div>
            <ImageToggler toolTipTextHandler={this.adjustToolTipText} adjustTogglePatronageHandler={this.adjustTogglePatronage} />
          </div>
          <div className="c-shares-graph__button-container">
            <Collapse in={!this.state.showPatronage}>
              <Button className={classes.patronageButton} size="large" onClick={this.showHidePatronage}>PATRONAGE CALCULATOR</Button>
            </Collapse>
          </div>
        </div>
        <Fade in={this.state.showPatronage} mountOnEnter unmountOnExit>
          <PatronageCalculator showHidePatronage={this.showHidePatronage} patronageEligibility={patronageEligibility} />
        </Fade>
      </>
    );
  }
}

export default withStyles(styles)(SharesGraph);
