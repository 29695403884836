import { FormControl, MenuItem, Select } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import FilterItem from '../App/Models/FilterItem';

interface Props {
  categories: Array<FilterItem>;
  selectedCategory: string;
  setStockFilter: Function;
}

interface State {
  open: boolean;
}

class StockFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleChange = (event: any) => {
    this.props.setStockFilter(event.target.value);
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  render() {
    return (
      <FormControl className="c-stock-filter__form-control">
        <Select
          open={this.state.open}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          value={this.props.selectedCategory}
          onChange={this.handleChange}
          inputProps={{
            name: 'selectedCategory',
            id: 'c-stock-filter__select'
          }}
        >
          {this.props.categories.map(category => (
            <MenuItem key={category.name} value={category.value}>
              <div className="category-item">
                <div
                  className={
                    'category-name' +
                    (category.value === this.props.selectedCategory
                      ? DEFAULT_DATA.WHITESPACE + 'selected'
                      : DEFAULT_DATA.STRING)
                  }
                >
                  {_.upperCase(category.name)}
                </div>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>);
  }
}

export default StockFilter;