import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as TELEMETRY from '../App/Constants/Telemetry';
import { TileStatus } from '../App/Constants/TileStatus';
import DashboardStore from '../App/Stores/DashboardStore';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import Tile from './Tile';

interface Props {
  authorizationStore: AuthorizationStore;
  dashboardStore: DashboardStore;
  title: string;
  loadData: Function;
  initialStatus?: TileStatus;
}

@observer
class SocialStream extends Tile<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleSocialClick = () => {
    window.telemetry.logEvent(TELEMETRY.EVENTS.DASHBOARD_SOCIAL_STREAM);
  }

  render() {

    const { dashboardStore } = this.props;

    const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

    if (dashboardStore!.socialArticlesStatus === TileStatus.LOADED || dashboardStore!.socialArticlesCurrentPage > 1) {
      return (
        <>
          {dashboardStore!.socialArticles.map(article => (
            <div className="c-dashboard__tile-stream-social-card" key={article.id}>
              <div className="c-dashboard__tile-stream-social-image" style={{ backgroundImage: `url(${article.thumbnail})` }}>
                <a href={article.moreInfo} target="_blank" onClick={this.handleSocialClick}>
                  <img src={article.thumbnail} />
                </a>
              </div>
              <div className="c-dashboard__tile-stream-social-info">
                <div className="c-dashboard__tile-stream-social-info-header">
                  <span className="c-dashboard__tile-stream-social-info-header-icon">
                    <OutdoorGrillIcon style={{ fontSize: 32 }} />
                  </span>
                  <span className="c-dashboard__tile-stream-social-info-header-title">
                    SOCIAL
                </span>
                </div>
                <div className="c-dashboard__tile-stream-social-info-content">
                  <ResponsiveEllipsis
                    className="c-dashboard__tile-stream-social-info-content-text"
                    text={article.summary}
                    maxLine="4"
                    ellipsis="..."
                  />
                </div>
                <div className="c-dashboard__tile-stream-social-info-footer">
                  <span>
                    {moment(article.publishingDate).format('D MMM YYYY')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    } else {
      return super.render();
    }

  }
}

export default SocialStream;