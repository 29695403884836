import { inject, observer } from 'mobx-react';
import React from 'react';
import * as KILLSHEET from '../App/Constants/KillSheet';
import AuthorizationStore from '../App/Stores/AuthorizationStore';

interface Props {
  authorizationStore?: AuthorizationStore;
}

@inject('authorizationStore')
@observer
class KillSheetLegend extends React.Component<Props> {
  render() {
    return (
      <div className="c-timeline__legend">
        <span>
          <span className="c-timeline__legend-icon c-timeline__legend-icon-killsheet" />
          <span className="c-timeline__legend-text">
            {KILLSHEET.KILLSHEET_LEGEND_TEXT}
          </span>
        </span>
        <span>
          <span className="c-timeline__legend-icon c-timeline__legend-icon-interim" />
          <span className="c-timeline__legend-text">
            {KILLSHEET.INTERIM_KILLSHEET_LEGEND_TEXT}
          </span>
        </span>
      </div>
    );
  }
}

export default KillSheetLegend;
