import { observer } from 'mobx-react';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import KillSheet from '../App/Models/KillSheet';

interface Props {
  isDisplayed: boolean;
  killSheet?: KillSheet;
}

const _propertyHelper = new PropertyHelper();

@observer
class TimelineFarmDetails extends React.Component<Props> {
  render() {
    const { isDisplayed, killSheet } = this.props;
    const farmDetailsContainerClass = `c-farm-details__container${DEFAULT_DATA.WHITESPACE}${killSheet!.status === KILLSHEET.INTERIM_TYPE ? 'interim' : 'final'}`;

    if (isDisplayed && killSheet) {
      return (
        <div className={farmDetailsContainerClass}>
          <div className="c-farm-details__farm-name">{`${_propertyHelper.formatValue(killSheet.farmName, VALUE_TYPE.STRING)}`}</div>
          <div className="c-farm-details__supplier-id">{`${_propertyHelper.formatValue(killSheet.supplier, VALUE_TYPE.STRING)}`}</div>
        </div>
      );
    }
    return <div />;
  }
}

export default TimelineFarmDetails;