import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Button,
} from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import * as SHARES from "../App/Constants/Shares";
import * as VALUE_TYPE from "../App/Constants/ValueType";
import PropertyHelper from "../App/Helpers/PropertyHelper";
import SharePriceHelper from "../App/Helpers/SharePriceHelper";
import DashboardStore from "../App/Stores/DashboardStore";
import { TileStatus } from "../App/Constants/TileStatus";
import * as DEFAULT_DATA from "../App/Constants/DefaultData";
import * as DATE_FORMAT from "../App/Constants/DateFormat";
import AuthorizationStore from "../App/Stores/AuthorizationStore";
import MenuStore from "../App/Stores/MenuStore";
import Tile from "../Dashboard/Tile";
import { observer } from "mobx-react";

const styles = (theme: Theme) =>
  createStyles({
    patronageButton: {
      color: "#FFF",
      backgroundColor: "rgb(65, 122, 93)",
      fontFamily: "Graphik-Semibold",
      fontWeight: "bold",
      letterSpacing: "0.075em",
      height: "2.6em",
      fontSize: "1em",
      width: "25em",
      textAlign: "center",
      position: "relative",
    },
  });

interface Props extends WithStyles<typeof styles> {
  farmName: string;
  dashboardStore: DashboardStore;
  authorizationStore: AuthorizationStore;
  title: string;
  menuStore: MenuStore;
  loadData: Function;
  initialStatus?: TileStatus;
  indexChange: (index: number) => void;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();
const _sharePriceHelper: SharePriceHelper = new SharePriceHelper();

@observer
class SharePriceCard extends Tile<Props> {
  render() {
    const { classes, dashboardStore } = this.props;
    const shareTileTrend = dashboardStore!.shareTileTrend;
    const shareTileTrendIcon = _sharePriceHelper.getSharePriceGreenIcon(
      shareTileTrend
    );
    let priceChange = _propertyHelper.formatValue(
      dashboardStore!.shareTilePriceDifference,
      VALUE_TYPE.CURRENCY
    );

    if (shareTileTrend === SHARES.SHARE_TREND_FLAT) {
      priceChange = 'No Change';
    }

    if (dashboardStore!.shareTileStatus === TileStatus.LOADED) {
      return (
        <>
          <div className="c-share-price-card__container">
            <div className="c-dashboard__tile-shareprice-leftdiv">
              <span className="c-share-price-card__tile-shareprice-price">
                {_propertyHelper.formatValue(
                  dashboardStore!.shareTileSharePrice,
                  VALUE_TYPE.CURRENCY
                )}
              </span>
              <span className="c-share-price-card__tile-shareprice-updatedate">
                Last updated{" "}
                {_propertyHelper.formatValue(
                  dashboardStore!.shareTileUpdateDate,
                  VALUE_TYPE.DATE,
                  DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR
                )}
              </span>
            </div>
            <div className="c-share-price-card__tile-shareprice-rightdiv">
              {shareTileTrendIcon ? (
                <img
                  src={shareTileTrendIcon.iconUrl}
                  className={classNames(
                    "c-share-price-card__tile-shareprice-icon",
                    shareTileTrendIcon.name === SHARES.SHARE_TREND_FLAT
                      ? "c-dashboard__tile-shareprice-iconflat"
                      : ""
                  )}
                />
              ) : (
                DEFAULT_DATA.STRING
              )}
              <span className="c-share-price-card__tile-shareprice-difference">
                {priceChange}
              </span>
            </div>
          </div>
          <div className="c-share-price-card__button-container">
            <Button
              className={classes.patronageButton }
              size="large"
              onClick={() => this.props.indexChange(0)}
            >
              VIEW MY PATRONAGE REWARD
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className="c-share-price-card__alternative-container">
          {super.render()}
        </div>
      );
    }
  }
}

export default withStyles(styles)(SharePriceCard);
