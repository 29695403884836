import { observable } from 'mobx';
import { HttpService } from './HttpService';
import * as CONNECTION from '../Constants/Connection';

class PowerBIDashboardService extends HttpService {
  @observable public isLoaded: boolean = false;
  @observable public hasError: boolean = false;

  @observable public analyticsIsLoaded: boolean = false;
  @observable public historyIsLoaded: boolean = false;
  @observable public analyticsHasError: boolean = false;
  @observable public historyHasError: boolean = false;
  
  getEmbeddedAnalyticDetails = async (supplierId: string, filter: string = 'all') => {
    if (!filter) {
      filter = 'all';
    }
    const url = `${window!.appSettings.apiUrl}${CONNECTION.SUPPLIER}${CONNECTION.API_VERSION_2}${CONNECTION.ANALYTICS_EMBED}`;
    return super.get(`${url}/${'supplierId'}/${filter}`);
  }
  
  getSliceAndDiceReport = async (supplierId: string) => {
    const url = `${window!.appSettings.apiUrl}${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${supplierId}${CONNECTION.SLICE_AND_DICE_REPORT}`;
    return super.get(url);
  }

  getEmbeddedSharePriceHistoryDetails = async () => {
    const url = `${window!.appSettings.apiUrl}${CONNECTION.CONTENT}${CONNECTION.API_VERSION}${CONNECTION.SHARE_PRICE_HISTORY_EMBED}`;
    return super.get(`${url}`);
  }
}

export default PowerBIDashboardService;