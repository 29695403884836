import { Button, createStyles, InputBase, Theme, withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';

const buttonRootStyle = {
  minWidth: '2.5em',
  width: '2.5em',
  height: '2.5em',
  backgroundColor: '#fff',
  borderRadius: '0.25em',
  color: 'rgba(85, 85, 85, 0.36)',
  fontSize: 'large',
  '&:hover': {
    backgroundColor: 'rgba(65, 122, 93, 0.05)'
  }
};

const MinusButton = withStyles({
  root: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ...buttonRootStyle
  }
})(Button);

const PlusButton = withStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ...buttonRootStyle
  }
})(Button);

const _propertyHelper: PropertyHelper = new PropertyHelper();

const useStyles = (theme: Theme) => createStyles({
  input: {
    fontSize: 'large',
    fontFamily: '"Graphik-Medium", "Roboto", "sans-serif"',
    letterSpacing: '0.03em',
    textAlign: 'center',
    '&::-ms-clear': {
      display: 'none',
    },
  },
});

interface Props extends WithStyles<typeof useStyles> {
  value: number;
  onMinus: () => void;
  onPlus: () => void;
  onChange: (newValue: number) => void;
  isError?: boolean;
}

class PlusMinusButton extends React.Component<Props> {
  static defaultProps: any = {
    isError: false,
  };

  sanitizeInput = (input: string) => {
    const sanitized = input.replace(/[^0-9]/g, '');
    if (sanitized.length === 0) return '0';
    return sanitized;
  }

  render() {
    const { classes, value, onMinus, onPlus, isError } = this.props;

    return (
      <div className={classNames({
        'c-plus-minus-button__container': true,
        'c-plus-minus-button__error': isError,
      })}>
        <MinusButton onClick={onMinus}>—</MinusButton>
        <div className="c-plus-minus-button__text">
          <InputBase
            type='text'
            classes={{
              input: classes.input,
            }}
            value={_propertyHelper.formatValue(value, VALUE_TYPE.NUMBER_WITH_COMMAS)}
            onChange={(event) => this.props.onChange(parseInt(this.sanitizeInput(event.target.value)))}
          />
        </div>
        <PlusButton onClick={onPlus}>+</PlusButton>
      </div>
    );
  }
}

export default withStyles(useStyles)(PlusMinusButton);

// test specific exports
if (process.env.NODE_ENV === 'test') {
  exports.MinusButton = MinusButton;
  exports.PlusButton = PlusButton;
}
