import { AxiosError } from 'axios';
import { inject } from 'mobx-react';
import React, { Component } from 'react';
import * as DEFAULT_DATA from '../../components/App/Constants/DefaultData';
import * as STORAGE from '../../components/App/Constants/Storage';
import { EVENTS, TelemetryExceptionType } from '../../components/App/Constants/Telemetry';
import ClaimsHelper from '../../components/App/Helpers/ClaimsHelper';
import StorageHelper from '../../components/App/Helpers/StorageHelper';
import AuthStore from '../../components/App/Stores/AuthStore';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import { TelemetryEventType } from '../../components/App/Constants/Telemetry';

interface Props {
    authStore: AuthStore;
}

@inject('authStore')
export class CompleteRegistration extends Component<Props, {}> {

    private _storageHelper: StorageHelper = new StorageHelper();
    private _claimsHelper: ClaimsHelper = new ClaimsHelper();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount = () => {
    window.telemetry.logEvent(PAGE_NAME.REGISTRATION, TelemetryEventType.Page);
  }

  render() {
    window.telemetry.logEvent(EVENTS.COMPLETE_REG_START);
    if (this.props.authStore.IsAuthenticated()) {
      let authToken = this._storageHelper.getKeyValue(STORAGE.MSAL_IDTOKEN) || DEFAULT_DATA.STRING;

      if (this._claimsHelper.hasTokenExpired(authToken) || this.props.authStore.getUserPolicy() === window.appSettings.policyName) {
        this._storageHelper.clearAll();
        window.location.reload();
      } else if (authToken === DEFAULT_DATA.STRING) {
        this.props.authStore.logOut();
      } else {
        this.props.authStore.completeRegistration(authToken).then((result) => {
          window.telemetry.logEvent(EVENTS.COMPLETE_REG_AUTHENTICATED);
          this.props.authStore.logOut();
        }).catch((error: AxiosError) => {
          window.telemetry.logException(error, TelemetryExceptionType.ApiError);
          this.props.authStore.logOut();
        });

      }
      return (
        <div>Registration complete, please wait....</div>
      );
    } else {
      window.telemetry.logEvent(EVENTS.COMPLETE_REG_NOTAUTHENTICATED);
      let urlVariables = window.location.pathname.split('/');
      let pathCount = urlVariables.length;
      if (pathCount !== 3) {
        window.telemetry.logEvent(EVENTS.COMPLETE_REG_PATH_INCOMPLETE);
        this.props.authStore.logOut();
      } else {
        window.telemetry.logEvent(EVENTS.COMPLETE_REG_CALL_B2C);
        this._storageHelper.clearAll();
        let newToken = urlVariables[2];
        let linkVersion = urlVariables[1];
        if (this.props.authStore.passwordRegistration(newToken, linkVersion)) {
         window.telemetry.logEvent(EVENTS.COMPLETE_REG_CALLED_B2C_SUCCESSFUL); 
         return (
            <div>Redirecting to complete registration page...</div>
          );
        } else {
          window.telemetry.logEvent(EVENTS.COMPLETE_REG_CALLED_B2C_FAILED);
          this.props.authStore.logOut();
        }
      }
    }
  }    
}

export default CompleteRegistration;