import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import animateScrollTo from 'animated-scroll-to';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as TELEMETRY from '../App/Constants/Telemetry';
import * as TRANSITION from '../App/Constants/Transition';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import KillSheet from '../App/Models/KillSheet';
import AuthorizationStore from '../App/Stores/AuthorizationStore';
import TimelineFarmDetails from '../Timeline/TimelineFarmDetails';

interface Props {
  authorizationStore?: AuthorizationStore;
  isFarmDetailsDisplayed: boolean;
  killSheet: KillSheet;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

@inject('authorizationStore')
@observer
class InterimKillSheetSummary extends Component<Props> {
  setSelectedKillSheetId = (killSheetId: number) => {
    const { authorizationStore } = this.props;

    authorizationStore!.isKillSheetDetailsLoaded = false;
    setTimeout(() => {
      authorizationStore!.setSelectedKillSheetId(killSheetId);
    }, TRANSITION.TIMELINE_DELAY);
  }

  render() {
    const { authorizationStore, isFarmDetailsDisplayed, killSheet } = this.props;

    let _categories = killSheet.killsheetDetails.map(c =>
      _.upperCase(c.animalCategory)
    );

    const contentChevronClass =
      `c-timeline__selected-chevron interim 
      ${(killSheet.id === authorizationStore!.selectedKillSheetId
        ? `${DEFAULT_DATA.WHITESPACE}show`
        : `${DEFAULT_DATA.WHITESPACE}hide`)}`;

    const contentShadowClass =
      `c-timeline__content 
      ${(killSheet.id === authorizationStore!.selectedKillSheetId
        ? ' interim-shadow'
        : DEFAULT_DATA.STRING)} 
        ${authorizationStore!.isAllFarmsSelected || authorizationStore!.isKillSheetsSearchResult ? `${DEFAULT_DATA.WHITESPACE}farm-details` : DEFAULT_DATA.STRING}`;

    const contentContainerClass = `c-timeline__content-container-interim c-timeline__right${authorizationStore!.isAllFarmsSelected || authorizationStore!.isKillSheetsSearchResult ? `${DEFAULT_DATA.WHITESPACE}farm-details` : DEFAULT_DATA.STRING}`;

    return (
      <div
        className={contentContainerClass}
        onClick={this.onClickKillSheetSummary}
      >
        <div className="c-timeline__period">
          {_.upperCase(_propertyHelper.formatValue(killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.DAY_AND_MONTH_ONLY))}
        </div>
        <TimelineFarmDetails
          isDisplayed={isFarmDetailsDisplayed}
          killSheet={killSheet}
        />
        <div className={contentShadowClass}>
          <div className="c-timeline__type interim">
            <div className="c-timeline__type-content">
              <div className="c-timeline__type-content-text">
                <div className="c-timeline__type-content-categories">
                  {_categories.map(category => (
                    <span
                      key={category}
                      className="c-timeline__type-content-category c-timeline__category-interim"
                    >
                      {category}
                    </span>
                  ))}
                </div>
                <div className="c-timeline__type-content-species">
                  {_.upperCase(killSheet.species)}
                </div>
              </div>
            </div>
          </div>
          <div className="c-timeline__type-detail">
            <div className="c-timeline__message-container c-timeline__interim-dashed-bottom-border">
              {'Your invoice is being processed...'}
            </div>
            <div className="c-timeline__attribute-container c-timeline__interim-dashed-bottom-border">
              <div className="c-timeline__attribute primary interim primary-attribute-column-1">
                {_propertyHelper.formatValue(killSheet.tally, VALUE_TYPE.STRING)}
                <div className="c-timeline__attribute-subheading">Tally</div>
              </div>
              <div className="c-timeline__attribute primary interim primary-attribute-column-2">
                {_propertyHelper.formatValue(killSheet.averageWeightPerHead, VALUE_TYPE.WEIGHT)}
                <div className="c-timeline__attribute-subheading">
                  Average kg/Head
                </div>
              </div>
            </div>
            <div className="c-timeline__attribute-container">
              <div className="c-timeline__attribute secondary secondary-attribute-column">
                {_propertyHelper.formatValue(killSheet.killsheetNumber, VALUE_TYPE.KILLSHEET_NUMBER)}
                <div className="c-timeline__attribute-subheading">
                  Kill Sheet Number
                </div>
              </div>
              <div className="c-timeline__attribute secondary secondary-attribute-column">
                {_propertyHelper.formatValue(killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
                <div className="c-timeline__attribute-subheading">
                  Date Processed
                </div>
              </div>
              <div className="c-timeline__attribute secondary secondary-attribute-column">
                {_propertyHelper.formatValue(killSheet.livestockRepresentative, VALUE_TYPE.STRING)}
                <div className="c-timeline__attribute-subheading">
                  Livestock Representative
                </div>
              </div>
            </div>
          </div>
          <div className={contentChevronClass}>
            <KeyboardArrowLeftIcon />
          </div>
        </div>
      </div>
    );
  }

  onClickKillSheetSummary = () => {
    const { authorizationStore, killSheet } = this.props;
    window.telemetry.logEvent(TELEMETRY.EVENTS.INTERM_KILLSHEET_DETAIL);

    const element = document.getElementById('kill-sheet-container');

    if (authorizationStore!.selectedKillSheetId !== killSheet.id) {
      this.setSelectedKillSheetId(killSheet.id);
      /*
        Code below checks for manual scroll horizontally after scroll has been animated previously.
        By 1) Calculating if the user has moved horizontally from the far right point and 2) Check if this is the initial kill sheet selected. 
      */
      if (element!.scrollLeft + element!.clientWidth < TRANSITION.KILL_SHEET_DETAIL_AND_SUMMARY_WIDTH && authorizationStore!.selectedKillSheetId !== DEFAULT_DATA.NUMBER) {
        setTimeout(() => {
          animateScrollTo(TRANSITION.CONTAINER_RIGHT_POSITION, { element: element!, horizontal: true, });
        }, TRANSITION.TIMELINE_EXPAND_DELAY);
      }
    } else {
      animateScrollTo(TRANSITION.CONTAINER_LEFT_POSITION, { element: element!, horizontal: true, });
      setTimeout(() => {
        this.setSelectedKillSheetId(DEFAULT_DATA.NUMBER);
      }, TRANSITION.TIMELINE_SHRINK_DELAY);
    }
  }
}

export default InterimKillSheetSummary;
