import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment, { Moment } from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-dates';
// @ts-ignore allow import with no type definition
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

const styles = createStyles({
  buttonRoot: {
    color: 'rgba(51, 51, 51, 0.3)',
    fontFamily: ['"Graphik"', 'Roboto', 'sans-serif'].join(','),
    margin: '0 1em',
    padding: '0 0.5em',
    height: '100%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  closeIconButtonRoot: {
    position: 'absolute',
    right: '0.35em',
  },
});

interface Props extends WithStyles<typeof styles> {
  onDatesChange: (change: { startDate: Moment | null, endDate: Moment | null }) => void;
  onClose: () => void;
}

interface State {
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: 'startDate' | 'endDate' | null;
}

class DateRangePickerSearchInput extends React.Component<Props, State> {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  getNavNode = (position: 'left' | 'right', icon: React.ReactNode) => {
    return (
      <div className={`DayPickerNavigation_customButton ${position}`}>
        {icon}
      </div>
    );
  }

  handleDatesChange = (change: { startDate: Moment | null, endDate: Moment | null }) => {
    this.setState(change);
    this.props.onDatesChange(change);
  }

  checkIsOutsideRange = (day: Moment) => {
    // block dates in the future
    return isAfterDay(day, moment());
  }

  render() {
    const { classes, onClose } = this.props;

    return (
      <>
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="search_start_date"
          endDate={this.state.endDate}
          endDateId="search_end_date"
          onDatesChange={this.handleDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          isOutsideRange={this.checkIsOutsideRange}
          initialVisibleMonth={() => moment().subtract(1, 'months')}
          noBorder={true}
          customArrowIcon={<ArrowForward />}
          navPrev={this.getNavNode('left', <KeyboardArrowLeft />)}
          navNext={this.getNavNode('right', <KeyboardArrowRight />)}
          calendarInfoPosition="top"
          renderCalendarInfo={() => (
            <div className="DayPickerHeader">DATE RANGE PICKER</div>
          )}
          hideKeyboardShortcutsPanel={true}
          displayFormat="DD/MM/YYYY"
          minimumNights={0}
        />
        <IconButton classes={{ root: classes.closeIconButtonRoot }} color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </>
    );
  }
}

export default withStyles(styles)(DateRangePickerSearchInput);
