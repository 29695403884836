import PowerBIDashboardService from "../Services/PowerBIDashboardService";
import * as pbi from "powerbi-client";
import { observable } from "mobx";
import TilesEmbedDetail from "../Models/TilesEmbedDetail";
import TileEmbedDetail from "../Models/TileEmbedDetail";
import * as CONSTANTS from "../Constants/PowerBIDashboard";
import { AxiosError } from "axios";
import { PAGE_NAME } from "../Constants/Application";
import { TelemetryPageEventType } from "../Constants/Telemetry";

export class PowerBIDashboardStore {
  public service = new PowerBIDashboardService();
  public powerbi: pbi.service.Service;
  @observable public selectedSpecies: string | undefined;
  @observable public supplierId: string;
  @observable public analyticTiles: pbi.Tile[] = [];
  @observable public analyticTileDetails: TilesEmbedDetail = new TilesEmbedDetail();
  @observable public historyTiles: pbi.Tile[] = [];
  @observable public historyTileDetails: TilesEmbedDetail = new TilesEmbedDetail();
  @observable public analyticEmbedContainer: HTMLDivElement;
  @observable public historyEmbedContainer: HTMLDivElement;
  @observable public resizeEvent: any = false;
  public timesCalled: number = 0;

  constructor() {
    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
  }

  getConfig = (tileDetail: TileEmbedDetail, width: number, height: number) => {
    let config: pbi.IEmbedConfiguration = {
      accessToken: tileDetail.embeddedToken,
      embedUrl: tileDetail.embedUrl,
      id: tileDetail.tileId,
      tokenType: pbi.models.TokenType.Embed,
      type: CONSTANTS.TILE,
      dashboardId: tileDetail.dashboardId,
      width: width,
      height: height,
    };
    return config;
  };

  getEmbeddedAnalyticsReports = async () => {
    await this.service
      .getSliceAndDiceReport(this.supplierId)
      .then((result) => {
        this.analyticTileDetails.tileDetails = [];
        this.analyticTileDetails.tileDetails = result!.data
          .tileDetails as TileEmbedDetail[];
      })
      .then(() => {
        while (this.analyticEmbedContainer && this.analyticEmbedContainer.firstChild) {
          this.analyticEmbedContainer.removeChild(this.analyticEmbedContainer.firstChild);
        }
        this.analyticTiles = [];
        this.analyticTileDetails.tileDetails.forEach((tile, index) => {
          this.timesCalled++;
          if (this.timesCalled === index * 3) {
            window.telemetry.triggerPageTimer(
              PAGE_NAME.ANALYTICS,
              TelemetryPageEventType.PageStop
            );
          }

          let config: pbi.IEmbedConfiguration;
          if (tile.name === CONSTANTS.TABLE_NAME) {
            config = this.getConfig(
              tile,
              CONSTANTS.TABLE_WIDTH,
              CONSTANTS.TABLE_HEIGHT
            );
          } else if (
            window.innerWidth > CONSTANTS.WIDTH_BREAKPOINT_1 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_1 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else if (
            window.innerWidth < CONSTANTS.WIDTH_BREAKPOINT_2 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_3 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else if (
            window.innerWidth < CONSTANTS.WIDTH_BREAKPOINT_1 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_4 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_5 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          }
          let tileDiv = document.createElement(CONSTANTS.DIV);
          this.analyticEmbedContainer.appendChild(tileDiv);
          if (tile.name === CONSTANTS.TABLE_NAME) {
            tileDiv.classList.add(CONSTANTS.TABLE_CLASS);
          } else {
            tileDiv.classList.add(CONSTANTS.GRAPH_CLASS);
          }
          this.analyticTiles.push(this.powerbi.embed(tileDiv, config) as pbi.Tile);
        });

        this.service.analyticsIsLoaded = true;
        this.service.analyticsHasError = false;
        this.resizeEvent = false;
      })
      .catch((e: AxiosError) => {
        window.appDialogStore!.setDialogClosed();
        this.service.analyticsIsLoaded = true;
        this.service.analyticsHasError = true;
      });
  };

  getEmbeddedShareHistoryReport = () => {
    this.service
      .getEmbeddedSharePriceHistoryDetails()
      .then((result) => {
        this.historyTileDetails.tileDetails = result!.data.tileDetails as TileEmbedDetail[];
      })
      .then(() => {
        while (this.historyEmbedContainer && this.historyEmbedContainer.firstChild) {
          this.historyEmbedContainer.removeChild(this.historyEmbedContainer.firstChild);
        }
        this.historyTiles = [];
        this.historyTileDetails.tileDetails.forEach((tile, index) => {

          let config: pbi.IEmbedConfiguration;
          if (tile.name === CONSTANTS.TABLE_NAME) {
            config = this.getConfig(
              tile,
              CONSTANTS.TABLE_WIDTH,
              CONSTANTS.TABLE_HEIGHT
            );
          } else if (
            window.innerWidth > CONSTANTS.WIDTH_BREAKPOINT_1 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_1 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else if (
            window.innerWidth < CONSTANTS.WIDTH_BREAKPOINT_2 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_3 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else if (
            window.innerWidth < CONSTANTS.WIDTH_BREAKPOINT_1 &&
            tile.name !== CONSTANTS.TABLE_NAME
          ) {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_4 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          } else {
            config = this.getConfig(
              tile,
              CONSTANTS.REPORT_SIZE_MODIFIER_5 * window.innerWidth,
              CONSTANTS.REPORT_SIZE_MODIFIER_2 * window.innerHeight
            );
          }
          config.type = 'report';
          config.settings = {
            ...config.settings,
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
            background: pbi.models.BackgroundType.Transparent
          }
          let tileDiv = document.createElement(CONSTANTS.DIV);
          this.historyEmbedContainer.appendChild(tileDiv);

          if (tile.name === CONSTANTS.TABLE_NAME) {
            tileDiv.classList.add(CONSTANTS.TABLE_CLASS);
          } else {
            tileDiv.classList.add(CONSTANTS.HISTORY_GRAPH_CLASS);
          }
          this.historyTiles.push(this.powerbi.embed(tileDiv, config) as pbi.Tile);
        });

        this.service.historyIsLoaded = true;
        this.service.historyHasError = false;
      })
      .catch((e: AxiosError) => {
        window.appDialogStore!.setDialogClosed();
        this.service.historyIsLoaded = true;
        this.service.historyHasError = true;
      });
  };
}

export default PowerBIDashboardStore;
