import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as URL from '../App/Constants/ExternalUrls';
import * as MENU from '../App/Constants/Menu';
import MenuStore from '../App/Stores/MenuStore';
import Logo from '../Logo/Logo';
import MenuItem from './MenuItem';

const styles = (theme: Theme) =>
  createStyles({
    fullLogo: {
      opacity: 1,
    },
    fullLogoHidden: {
      display: 'none',
      opacity: 0,
    },
    logo: {
      opacity: 1,
    },
    logoHidden: {
      display: 'none',
      opacity: 0,
    },
    topLogoContainerOpen: {
      backgroundColor: 'transparent',
      position: 'relative',
      top: 0,
      zIndex: 100,
      width: MENU.DEFAULT_WIDTH,
      transform: 'translate3d(0,0,0)',
    },
    topLogoContainerClosed: {
      backgroundColor: 'transparent',
      position: 'relative',
      top: 0,
      zIndex: 100,
      width: theme.spacing(10),
      transform: 'translate3d(0,0,0)',
    },
    bottomMenuContainer: {
      bottom: 0,
      width: '100%',
      backgroundColor: 'white',
      paddingBottom: 61,
    },
    root: {
      display: 'flex',
    },
    menuButtonContainer: {
      backgroundColor: 'white',
      position: 'fixed',
      bottom: 0,
      zIndex: 100,
    },
    menuButton: {
      padding: 16,
      marginBottom: 4,
      marginLeft: 6,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: MENU.DEFAULT_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    content: {
      flexGrow: 1,
      padding: 0,
    },
    /* Drawer Open/Closed variations */
    drawerOpen: {
      width: MENU.DEFAULT_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      msOverflowStyle: 'none',
      overflow: '-moz-scrollbars-none',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
      scrollbarWidth: 'none',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(10),
      msOverflowStyle: 'none',
      overflow: '-moz-scrollbars-none',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
      scrollbarWidth: 'none',
    },
    drawerOpenLogo: {
      width: '7.5em',
      height: '6em',
      margin: '2em 1.5em 1em 1.5em',
    },
    drawerCloseLogo: {
      width: '1.5em',
      height: '1.5em',
      marginTop: '1.5em',
      marginLeft: '1.1em',
      marginBottom: '1.8em',
    },
    drawerOpenLogoContainer: {
      maxWidth: 'auto',
      overflow: 'unset',
    },
    drawerCloseLogoContainer: {
      maxWidth: 58,
      overflow: 'hidden',
    },
    drawerOpenMenuButtonContainer: {
      width: MENU.DEFAULT_WIDTH,
    },
    drawerCloseMenuButtonContainer: {
      width: theme.spacing(9),
    },
    menuItemContainer: {
      msOverflowStyle: 'none',
      overflow: '-moz-scrollbars-none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
    },
  });

export interface Props extends WithStyles<typeof styles> {
  menuStore?: MenuStore;
}

@inject('menuStore')
@observer
class Menu extends React.Component<Props> {
  componentWillMount = () => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    if (mediaQuery.matches) {
      this.handleDrawerOpen();
    } else {
      this.handleDrawerClose();
    }
    mediaQuery.addListener((mq) => {
      if (mq.matches) {
        this.handleDrawerOpen();
      } else {
        this.handleDrawerClose();
      }
    });
  };

  handleDrawerOpen = () => {
    const { menuStore } = this.props;
    menuStore!.setIsMenuOpen(true);
  };

  handleDrawerClose = () => {
    const { menuStore } = this.props;
    menuStore!.setIsMenuOpen(false);
  };

  toggleDrawer = () => {
    const { menuStore } = this.props;
    menuStore!.setIsMenuOpen(!menuStore!.isMenuOpen);
  };

  render() {
    const { classes, menuStore } = this.props;
    const drawerResponsiveClass = classNames(classes.drawer, {
      [classes.drawerOpen]: menuStore!.isMenuOpen,
      [classes.drawerClose]: !menuStore!.isMenuOpen,
    });

    const drawerPaperResponsiveClass = {
      paper: classNames(classes.drawerPaper, {
        [classes.drawerOpen]: menuStore!.isMenuOpen,
        [classes.drawerClose]: !menuStore!.isMenuOpen,
      }),
    };

    const logoContainerResponsiveClass = classNames({
      [classes.drawerOpenLogoContainer]: menuStore!.isMenuOpen,
      [classes.drawerCloseLogoContainer]: !menuStore!.isMenuOpen,
    });

    const logoResponsiveClass = classNames({
      [classes.logo]: !menuStore!.isMenuOpen,
      [classes.logoHidden]: menuStore!.isMenuOpen,
      [classes.drawerOpenLogo]: menuStore!.isMenuOpen,
      [classes.drawerCloseLogo]: !menuStore!.isMenuOpen,
    });

    const fullLogoResponsiveClass = classNames({
      [classes.fullLogo]: menuStore!.isMenuOpen,
      [classes.fullLogoHidden]: !menuStore!.isMenuOpen,
      [classes.drawerOpenLogo]: menuStore!.isMenuOpen,
      [classes.drawerCloseLogo]: !menuStore!.isMenuOpen,
    });

    const menuButtonContainerResponsiveClass = classNames(
      classes.menuButtonContainer,
      {
        [classes.drawerOpenMenuButtonContainer]: menuStore!.isMenuOpen,
        [classes.drawerCloseMenuButtonContainer]: !menuStore!.isMenuOpen,
      }
    );

    const topLogoContainerResponsiveClass = classNames({
      [classes.topLogoContainerOpen]: menuStore!.isMenuOpen,
      [classes.topLogoContainerClosed]: !menuStore!.isMenuOpen,
    });

    const bottomContainerMenuItems = menuStore!.menuItems
      .filter((m) => m.IsBottom)
      .sort((a, b) => a.Index - b.Index);

    const topContainerMenuItems = menuStore!.menuItems
      .filter((m) => !m.IsBottom)
      .sort((a, b) => a.Index - b.Index);

    const handleClickAway = () => {
      const mediaQuery = window.matchMedia('(max-width: 1024px)');
      if (mediaQuery.matches && menuStore!.isMenuOpen) {
        menuStore!.setIsMenuOpen(false);
      }
    };

    const isDialogDisplayed =
      window.appDialogStore!.isDialogOpen ||
      window.appLatestFeaturesDialogStore!.isDialogOpen;

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Drawer
          id='c-menu__drawer'
          variant='permanent'
          className={drawerResponsiveClass}
          classes={drawerPaperResponsiveClass}
          open={true}
        >
          <div className={topLogoContainerResponsiveClass}>
            <Logo
              href={URL.ROOT}
              src={'/images/logo.png'}
              alt={'Silver Fern Farms Logo'}
              target={'_blank'}
              containerClass={logoContainerResponsiveClass}
              className={fullLogoResponsiveClass}
            />
            <Logo
              href={URL.ROOT}
              src={'/images/logo_only.png'}
              alt={'Silver Fern Farms Logo'}
              target={'_blank'}
              containerClass={logoContainerResponsiveClass}
              className={logoResponsiveClass}
            />
          </div>
          <div
            className={classes.menuItemContainer}
            id='c-menu__item-container'
          >
            <div>
              <List>
                {topContainerMenuItems.map((menuItem) => (
                  <MenuItem
                    key={menuItem.Index}
                    menuItem={menuItem}
                    isDisabled={isDialogDisplayed}
                  />
                ))}
              </List>
            </div>
            <div className={classes.bottomMenuContainer}>
              {bottomContainerMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.Index}
                  menuItem={menuItem}
                  isDisabled={isDialogDisplayed}
                />
              ))}
            </div>
            <div className={menuButtonContainerResponsiveClass}>
              <Divider />
              <IconButton
                className={classes.menuButton}
                color='inherit'
                aria-label='Menu'
                onClick={this.toggleDrawer}
                disabled={isDialogDisplayed}
              >
                {menuStore!.isMenuOpen ? <ArrowBackIcon /> : <MenuIcon />}
              </IconButton>
            </div>
          </div>
        </Drawer>
      </ClickAwayListener>
    );
  }
}

(Menu as React.ComponentClass<Props>).propTypes = {
  classes: PropTypes.object.isRequired,
} as any;

export default withStyles(styles)(Menu);
