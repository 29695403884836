export const daysHidden = 0; // days to hide the banner after close button is clicked
export const daysReminder = 0; // days to hide banner after "Go" button is clicked
export const appStoreLanguage = 'nz'; // App Store Language
export const title = 'Silver Fern Farmer'; // App title
export const author = ''; // Author
export const button = 'Go'; // Display on install button
export const storeText = { 
  ios: 'On the App Store', 
  android: 'In Google Play'
}; // Store text
export const price = { 
  ios: 'FREE', 
  android: 'FREE' 
}; // Price text
export const appMeta = {
  ios: 'ios-app',
  android: 'android-app'
};
export const position = 'top'; // Display position on screen ('top'/'bottom')
export const ignoreIosVersion = true; // ignore the iOS native banner and use this one