export const ALL = 'All Stock Types';
export const ARRIVAL_DATE = 'arrivalDate';
export const BOOKING_DATE_LABEL = 'Date';
export const BOOKING_HEADER_TITLE = 'Bookings';
export const CONTRACT_NUMBER_LABEL = 'Contract Number';
export const FORECAST_DATE_LABEL = 'Week Beginning';
export const FORECAST_HEADER_TITLE = 'Forecasts';
export const SPECIAL_PROGRAMME_LABEL = 'Programme';
export const WEEK_START_DATE = 'weekStartDate';

export const ICONS = [
  {
    name: 'Sheep',
    iconUrl: '/images/sheep_stock.svg'
  },
  {
    name: 'Beef',
    iconUrl: '/images/beef_stock.svg'
  },
  {
    name: 'Deer',
    iconUrl: '/images/deer_stock.svg'
  }
];

export const ANIMAL_ICON_SHARES = [
  { name: 'sheep', iconUrl: '/images/sheep_shares.svg' },
  { name: 'beef', iconUrl: '/images/beef_shares.svg' },
  { name: 'deer', iconUrl: '/images/deer_shares.svg' },
  { name: 'bobby', iconUrl: '/images/bobby_shares.svg' }
];