import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import BorderFlag from '@material-ui/icons/BookmarkBorderSharp';
import FilledFlag from '@material-ui/icons/BookmarkSharp';
import React from 'react';
import * as SHARES from '../App/Constants/Shares';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import PatronageEligibility from '../App/Models/PatronageEligibility';
import BarGraph from './BarGraph';

const rightFlagStyle = {
  transform: 'rotate(90deg)',
  fontSize: '1.2em',
  marginLeft: '0.2em',
  color: 'rgb(65, 122, 93)',
};

const useStyles = (theme: Theme) => createStyles({
  rightFlagFilled: {
    ...rightFlagStyle,
    color: 'rgb(65, 122, 93)',
  },
  rightFlagBorder: {
    ...rightFlagStyle,
    color: 'black',
  },
  leftFlag: {
    transform: 'translate(5px, -14px) rotate(90deg)',
    fontSize: '1.44em',
    color: 'rgb(65, 122, 93)',
  },
  nil: {
    '&:after': {
      content: '""',
    },
  },
});

const _propertyHelper: PropertyHelper = new PropertyHelper();

interface Props extends WithStyles<typeof useStyles> {
  patronageEligibility: PatronageEligibility;
}

const MIN = -6.75;
const MAX = 100;

/**
 * Normalize the width of left flag to fit a non-standard range.
 *
 * See BarGraph normalize function.
 */
const normalize = (value: number) => {
  if (value === 0) return value;
  if (value < 1) value = 1;
  if (value > 111) value = MAX;
  return (value - MIN) * 100 / (MAX - MIN);
};

/**
 * Specialized BarGraph for use inside the PatronageCalculator component.
 */
class CalculatorBarGraph extends React.Component<Props> {
  isLeftFlagVisible() {
    const { patronageEligibility } = this.props;

    return patronageEligibility.isEligible() && patronageEligibility.percentFull() > 0 && patronageEligibility.percentFull() <= 111;
  }

  getHeaderText() {
    const { patronageEligibility } = this.props;

    if (!patronageEligibility.isEligible()) {
      return SHARES.PATRONAGE_HEADER_NOT_ELIGIBLE;
    }
    if (patronageEligibility.isOverShared()) {
      return SHARES.PATRONAGE_HEADER_OVER_SHARED;
    }
    if (patronageEligibility.percentFull() === 100) {
      return SHARES.PATRONAGE_HEADER_FULLY_SHARED;
    }

    return SHARES.PATRONAGE_HEADER_NEEDED;
  }

  getHeaderBoldText() {
    const { patronageEligibility } = this.props;

    if (!patronageEligibility.isEligible()) {
      return '';
    }
    if (patronageEligibility.isOverShared()) {
      return _propertyHelper.formatValue(patronageEligibility.excessShares(), VALUE_TYPE.NUMBER_WITH_COMMAS);
    }
    if (patronageEligibility.percentFull() === 100) {
      return '';
    }

    return _propertyHelper.formatValue(patronageEligibility.neededShares(), VALUE_TYPE.NUMBER_WITH_COMMAS);
  }

  getSubheaderText() {
    const { patronageEligibility } = this.props;

    if (!patronageEligibility.isEligible()) {
      return SHARES.PATRONAGE_SUBHEADER_NOT_ELIGIBLE;
    }
    if (patronageEligibility.isOverShared()) {
      return SHARES.PATRONAGE_SUBHEADER_OVER_SHARED;
    }
    if (patronageEligibility.percentFull() === 100) {
      return '';
    }

    return SHARES.PATRONAGE_SUBHEADER_NEEDED;
  }

  getLeftFlagWidth() {
    const { patronageEligibility } = this.props;

    const percentFull = normalize(patronageEligibility.percentFull());

    if (percentFull > 0 && percentFull < 1) return '1%';
    if (percentFull >= 111) return '100%';

    return percentFull + '%';
  }


  isFilledFlag() {
    const { patronageEligibility } = this.props;

    return (patronageEligibility.isEligible() && patronageEligibility.percentFull() === 100) || patronageEligibility.isOverShared();
  }

  render() {
    const { classes, patronageEligibility } = this.props;

    return (
      <>
        <div className="c-patronage__graph-header-container">
          <div className="c-patronage__graph-header-bold">{this.getHeaderBoldText()}</div>
          <div className="c-patronage__graph-header-text">{this.getHeaderText()}</div>
          {this.isFilledFlag()
            ? <FilledFlag className={classes.rightFlagFilled} />
            : <BorderFlag className={classes.rightFlagBorder} />}
        </div>
        <div className="c-patronage__graph-stem-container">
          {this.isLeftFlagVisible()
            ? <div className="c-patronage__graph-left-flag" style={{ width: this.getLeftFlagWidth() }}>
              <FilledFlag className={classes.leftFlag} />
            </div>
            : <div className="c-patronage__graph-left-flag-hidden" />}
          <div className="c-patronage__graph-subheader">{this.getSubheaderText()}</div>
        </div>
        <BarGraph
          classes={{ nil: classes.nil }}
          percentFull={patronageEligibility.percentFull()}
          nil={!patronageEligibility.isEligible()}
          overShared={patronageEligibility.isOverShared()}
          pointerText={patronageEligibility.isEligible()
            ? _propertyHelper.formatValue(patronageEligibility.requiredShares, VALUE_TYPE.NUMBER_WITH_COMMAS)
            : '-'}
          pointerTextSize="large"
          arrowSize="large"
          borderStyle="none"
        />
      </>
    );
  }
}

export default withStyles(useStyles)(CalculatorBarGraph);

// test specific exports
if (process.env.NODE_ENV === 'test') {
  exports.FilledFlag = FilledFlag;
  exports.BorderFlag = BorderFlag;
}
