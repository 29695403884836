export const COMPONENT_DESTRUCTION_DELAY = 500;
export const CONTAINER_TOP_POSITION = 0;
export const CONTAINER_LEFT_POSITION = 0;
export const CONTAINER_RIGHT_POSITION = 9999;

export const SETTINGS_SHRINK_DELAY = 50;
export const SETTINGS_EXPAND_DELAY = 500;

export const TIMELINE_DELAY = 100;
export const TIMELINE_SHRINK_DELAY = 50;
export const TIMELINE_EXPAND_DELAY = 500;
export const TIMELINE_SCROLL_TOP__SPEED = 50;
export const TIMELINE_SCROLL_NEXT_PAGE_SPEED = 1000;

export const ARTICLE_EXPAND_DELAY = 50;
export const ARTICLE_SHRINK_DELAY = 250;
export const ARTICLE_LOAD_DELAY = 250;

export const FADE_DELAY = 750;

export const KILL_SHEET_ORIENTATION_DELAY = 250;

export const KILL_SHEET_DETAIL_AND_SUMMARY_WIDTH = 1700;
export const SETTINGS_PANEL_WIDTH = 1850;

export const DIALOG_DELAY = 500;

export const TIMELINE_SCROLL_DELAY = 150;
export const PAYMENT_SCROLL_DELAY = 150;

export const STREAM_SCROLL_TOP_SPEED = 0;
export const DASHBOARD_ORIENTATION_DELAY = 250;
