import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CarcaseReportStore from '../App/Stores/CarcaseReportStore';
import { observable } from 'mobx';
import LoadError from '../../components/LoadError/LoadError';
import * as TELEMETRY from '../App/Constants/Telemetry';

interface Props {
  supplierId: string;
  killSheetNumber?: string;
  referenceNumber?: string;
  category?: string;
  isInterim: boolean;
}

@inject('authorizationStore')
@observer
class CarcaseReport extends React.Component<Props> {
  @observable public store: CarcaseReportStore;
  @observable public supplierId: string;
  @observable public killSheetNumber?: string;
  @observable public referenceNumber?: string;
  @observable public category?: string;
  @observable public isInterim: boolean;
    
  constructor(props: Props) {
      super(props);
      this.store = new CarcaseReportStore();
      this.store.supplierId = this.props.supplierId;
      this.store.killSheetNumber = this.props.killSheetNumber;
      this.store.referenceNumber = this.props.referenceNumber;
      this.store.category = this.props.category;
      this.store.isInterim = this.props.isInterim;
  }

  handleButtonClick() {
    this.store.service.hasError = false;
    this.store.service.isLoaded = false;

    if (this.store.isInterim) {
      window.telemetry.logEvent(TELEMETRY.EVENTS.INTERIM_KILLSHEET_VIEW_CARCASE_REPORT);
    } else { 
      window.telemetry.logEvent(TELEMETRY.EVENTS.FINAL_KILLSHEET_VIEW_CARCASE_REPORT);
    }
    this.store.getEmbedReport(this.store.supplierId, this.store.isInterim, this.store.killSheetNumber, this.store.referenceNumber, this.store.category);
  }

  public render() {
    if (this.store.service.hasError) {
      return (
        <div className="c-dashboard__tile error c-power-bi__Carcase-container">
            <LoadError onPress={() => { this.handleButtonClick(); }} />
        </div>
      );
    } else {
      return (
        <div className="c-power-bi__Carcase-container">
            <div className="c-power-bi__Carcase-graphShow" ref={(div) => { if (div) { this.store.embedContainer = div; } }} />
        </div>
      );
    }
  }

  componentDidMount() {
    this.store.service.isLoaded = false;
    this.store.getEmbedReport(this.store.supplierId, this.store.isInterim, this.store.killSheetNumber, this.store.referenceNumber, this.store.category);
  }
}

export default CarcaseReport;
