import { observable, action } from 'mobx';

export class ProgressStore {
  @observable public isLoading: boolean = true;
  @observable public message: string;
  @observable public isComponentLoading: boolean;
  @observable public componentMessage: string;

  @action
  public startInitialDataLoad = (message: string) => {
    this.message = message;
    this.isLoading = true;
  }

  @action
  public endInitialDataLoad = () => {
    this.message = '';
    this.isLoading = false;
  }

  @action
  public startComponentLoad = (message?: string) => {
    this.componentMessage = message ? message : 'Loading Data';
    this.isComponentLoading = true;
  }

  @action
  public endComponentLoad = () => {
    this.componentMessage = '';
    this.isComponentLoading = false;
  }
}

export default ProgressStore;