import { AxiosError, AxiosResponse } from 'axios';
import { observable, action } from 'mobx';

import * as DEFAULT_DATA from '../Constants/DefaultData';
import * as DOCUMENT_TYPE from '../Constants/DocumentType';
import InvoiceService from '../Services/InvoiceService';
import OutstandingInvoiceSummaryLine from '../Models/OutstandingInvoiceSummaryLine';
import KillSheetService from '../Services/KillSheetService';
import PaymentDocument from '../Models/PaymentDocument';

class InvoiceStore {
  @observable public isInvoiceLoaded: boolean;
  @observable public isInvoiceLoadError: boolean;
  @observable public selectedSupplierId: string;
  @observable public outstandingInvoiceSummaryLines: Array<OutstandingInvoiceSummaryLine>;
  @observable public downloadedInvoiceDocuments: Array<PaymentDocument>;

  private _invoiceService: InvoiceService = new InvoiceService();
  private _killSheetService: KillSheetService = new KillSheetService();

  constructor() {
    this.isInvoiceLoaded = false;
    this.isInvoiceLoadError = false;
    this.selectedSupplierId = DEFAULT_DATA.STRING;
    this.outstandingInvoiceSummaryLines = new Array<OutstandingInvoiceSummaryLine>();
    this.downloadedInvoiceDocuments = new Array<PaymentDocument>();
  }

  @action
  setInvoiceSelectedSupplierId = (supplierId: string) => {
    this.isInvoiceLoaded = false;
    this.isInvoiceLoadError = false;
    this.outstandingInvoiceSummaryLines = new Array<OutstandingInvoiceSummaryLine>();
    this.selectedSupplierId = supplierId;
  }

  @action
  getInvoicesByFarm = (isAllFarms: boolean, supplierId: string) => {
    this.isInvoiceLoaded = false;
    this.isInvoiceLoadError = false;

    if (isAllFarms) {
      const getAllInvoicesTask = this.getAllInvoices();
      return this.resolveRequest(getAllInvoicesTask);
    } else {
      const getInvoicesByFarmIdTask = this.getInvoicesByFarmId(supplierId);
      return this.resolveRequest(getInvoicesByFarmIdTask);
    }
  }

  @action
  resetPaymentDocuments = () => {
    this.downloadedInvoiceDocuments = new Array<PaymentDocument>();
  }

  @action
  downloadPaymentDocument = (supplierId: string, killSheetNumber: number, reference: string) => {
    if (killSheetNumber) {
      const downloadTask = this.getDocument(supplierId, killSheetNumber, reference, DOCUMENT_TYPE.PDF);
      if (downloadTask) {
        downloadTask.then(response => {
          if (response) {
            let invoiceDocument: PaymentDocument = { referenceNumber: reference, document: response.data };
            this.downloadedInvoiceDocuments!.push(invoiceDocument);
          } else {
            let invoiceDocument: PaymentDocument = { referenceNumber: reference, document: DEFAULT_DATA.UNDEFINED };
            this.downloadedInvoiceDocuments!.push(invoiceDocument);
          }
        }).catch(() => {
          console.warn('No document found');
        });
      }
    } else {
      let invoiceDocument: PaymentDocument = { referenceNumber: reference, document: DEFAULT_DATA.UNDEFINED };
      this.downloadedInvoiceDocuments!.push(invoiceDocument);
    }
  }

  private getDocument = (supplierId: string, killSheetNumber: number, reference: string, type: string) => {
    if (supplierId && killSheetNumber && reference) {
      return this._killSheetService.getDocument(supplierId, killSheetNumber, reference, type);
    }
    return DEFAULT_DATA.UNDEFINED;
  }

  private getInvoicesByFarmId = (supplierId: string) => {
    return this._invoiceService.getInvoiceByFarm(supplierId);
  }

  private getAllInvoices = () => {
    return this._invoiceService.getAllInvoices();
  }

  private resolveRequest = (invoiceRequest: Promise<AxiosResponse<any> | undefined>) => {
    return invoiceRequest.then(response => {
      if (response!.data) {
        this.outstandingInvoiceSummaryLines = response!.data!.invoiceGroups;
      } else {
        this.outstandingInvoiceSummaryLines = new Array<OutstandingInvoiceSummaryLine>();
      }
      this.isInvoiceLoaded = true;
    })
      .catch((error: AxiosError) => {
        console.warn(error);
        this.isInvoiceLoaded = true;
        this.isInvoiceLoadError = true;
      });
  }
}

export default InvoiceStore;
