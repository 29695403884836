export const DIALOG_HEADER_TITLE = 'Feedback has been sent!';
export const DIALOG_CONTENT_MESSAGE = 'Thanks, your feedback has been received';
export const DIALOG_ERROR_TITLE = 'Something went wrong...';
export const DIALOG_ERROR_CONTENT_MESSAGE = 'Please try again. Should the issue persist, please contact the system administrator.';
export const INPUT_PLACEHOLDER_TEXT = 'We love our new app and hope you do too. Please give us your feedback on what you like about it, what you don’t like, and any ideas you have for new functionality.';
export const INPUT_TEXTAREA_ROWS = 5;
export const MAX_INPUT_LENGTH = 200;
export const ORIGIN = 'WebsiteSilverFernFarmer';
export const SUBMIT_BUTTON_TEXT = 'Send Your Feedback';
export const TITLE = 'App Feedback';
export const APPLICATION_ERROR = 'We track these errors automatically, but if the problem persists feel free to contact us. Please try again or sign in below.';