import { Fade, Tab, Tabs, withStyles, WithStyles, createStyles, Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';
import { autorun, IReactionDisposer } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { PAGE_NAME } from '../../components/App/Constants/Application';
import { TelemetryEventType, TelemetryPageEventType } from '../../components/App/Constants/Telemetry';
import { FADE_DELAY } from '../../components/App/Constants/Transition';
import PatronageEligibility from '../../components/App/Models/PatronageEligibility';
import AuthorizationStore from '../../components/App/Stores/AuthorizationStore';
import ShareholderStore from '../../components/App/Stores/ShareholderStore';
import DashboardStore from '../../components/App/Stores/DashboardStore';
import LoadError from '../../components/LoadError/LoadError';
import Loading from '../../components/Loading/Loading';
import SharesGraph from '../../components/SharesGraph/SharesGraph';
import SharesHistoryList from '../../components/SharesHistoryList/SharesHistoryList';
import SharePriceCard from '../../components/SharePriceCard/SharePriceCard';
import SharesList from '../../components/SharesList/SharesList';
import MenuStore from '../../components/App/Stores/MenuStore';
import * as TILE_TITLES from '../../components/App/Constants/TileTitles';
import SharePriceHistory from '../../components/SharePriceCard/SharePriceHistory';

const styles = (theme: any) =>
  createStyles({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  label: {
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap',
    'display': 'block',
  },
});

interface Props extends WithStyles<typeof styles> {
  shareholderStore?: ShareholderStore;
  authorizationStore?: AuthorizationStore;
  dashboardStore?: DashboardStore;
  menuStore?: MenuStore;
  theme: any;
}

interface State {
  isDisplayLoadingAnimation: boolean;
  value: number;
  tabsScrollableProperty: 'auto' | 'on' | 'off';
}

@inject('shareholderStore')
@inject('authorizationStore')
@inject('dashboardStore')
@inject('menuStore')
@observer
class MyShares extends Component<Props, State> {
  private selectedFarmId: string;
  private disposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.disposer = autorun(() => {
      if (this.selectedFarmId !== this.props.authorizationStore!.selectedFarmId) {
        this.selectedFarmId = this.props.authorizationStore!.selectedFarmId;
        this.props.shareholderStore!.getShareholderDetails(this.selectedFarmId);
      }
    })
    this.state = {
      value: (this.props.dashboardStore!.tabIndex > -1) ? this.props.dashboardStore!.tabIndex : 0,
      isDisplayLoadingAnimation: false,
      tabsScrollableProperty: 'auto'
    };

    this.props.dashboardStore!.tabIndex = -1;

    window.addEventListener('resize', this.handleResize);

  }

  componentDidMount = () => {
    window.telemetry.logEvent(PAGE_NAME.MY_SHARES, TelemetryEventType.Page);
    this.handleResize();
  }

  componentWillMount = () => {
    window.telemetry.triggerPageTimer(PAGE_NAME.MY_SHARES, TelemetryPageEventType.PageStart);
  }

  handleChange = (event: any, value: any) => {
    this.setState({ value });
  }

  handleChangeIndex = (index: number) => {
    this.setState({ value: index });
  }

  handleResize = () => {
    if (window.matchMedia('(max-width: 880px)').matches) {
      /* the viewport is less than or exactly 880 pixels wide */
      this.setState({tabsScrollableProperty: 'on'});
    } else {
      /* the viewport is more than 880 pixels wide */
      this.setState({tabsScrollableProperty: 'off'});
    }
  }

  componentWillUnmount = () => {
    this.disposer();
  }

  render() {
    const { shareholderStore, authorizationStore, dashboardStore, menuStore, theme, classes } = this.props;

    if (shareholderStore!.isShareholderDetailLoadError) {
      return (
        <div className="c-my-shares__load-error-container">
          <LoadError onPress={() => shareholderStore!.getShareholderDetails(this.selectedFarmId)} />
        </div>
      );
    } else if (authorizationStore!.isUserFarmsLoaded && shareholderStore!.isShareholderDetailLoaded) {
      const patronageEligibility: PatronageEligibility | undefined =
        shareholderStore!.shareholderDetail && shareholderStore!.shareholderDetail.currentPatronageEligibility;
      const rolling12PatronageEligibility: PatronageEligibility | undefined =
        shareholderStore!.shareholderDetail && shareholderStore!.shareholderDetail.rolling12PatronageEligibility;

      return (
        <Fade in={true} timeout={FADE_DELAY}>
          <div className="c-my-shares__container">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={this.state.tabsScrollableProperty}
              aria-label="scrollable auto tabs example"
              className="c-my-shares__tabs"
            >
              <Tab className="c-my-shares__tab" label="Patronage Reward" disableRipple={true} />
              <Tab className="c-my-shares__tab" label="My Shares" disableRipple={true} />
              <Tab className="c-my-shares__tab" label="Shares History" disableRipple={true} />
              <Tab className="c-my-shares__tab c-my-shares__tab-shrinkable" label={<span className={classes.label}>Share Price</span>} disableRipple={true}/>
            </Tabs>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
              containerStyle={{ height: '100%' }}
              style={{ height: '100%' }}
            >
              <div className="c-my-shares-item__responsive-container">
                <SharesGraph patronageEligibility={patronageEligibility} rolling12PatronageEligibility={rolling12PatronageEligibility}/>
              </div>
              <div className="c-my-shares-item__container">
                <SharesList farmName={authorizationStore!.selectedFarm!.name} shares={shareholderStore!.shareholderDetail.currentShareList} />
              </div>
              <div className="c-my-shares-item__container">
                <SharesHistoryList farmName={authorizationStore!.selectedFarm!.name} historicalPatronageEligibility={shareholderStore!.shareholderDetail.historicalPatronageEligibility} />
              </div>
              <div className="c-my-shares-item__responsive-container">
                  <Grid item={true} md={12} lg={6}>
                  <div className="c-my-shares-item__container-item">
                    <SharePriceCard dashboardStore={dashboardStore!} menuStore={menuStore!} authorizationStore={authorizationStore!} farmName={authorizationStore!.selectedFarm!.name} indexChange={this.handleChangeIndex}  title={TILE_TITLES.SHARE_PRICE} loadData={() => dashboardStore!.getSharePriceTileData()} />
                    </div>
                  </Grid>
                  <Grid item={true} md={12} lg={6}>
                  <div className="c-my-shares-item__container-item-history">
                    <SharePriceHistory dashboardStore={dashboardStore!} menuStore={menuStore!} authorizationStore={authorizationStore!} farmName={authorizationStore!.selectedFarm!.name} indexChange={this.handleChangeIndex}  title={TILE_TITLES.SHARE_PRICE_HISTORY} loadData={() => dashboardStore!.getSharePriceTileData()} />
                    </div>
                  </Grid>
                </div>
            </SwipeableViews>
          </div>
        </Fade>
      );
    }

    return <Loading />;
  }
}

export default withStyles(styles, { withTheme: true })(MyShares);