import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import * as INVOICE from '../App/Constants/Invoice';

class OutstandingInvoiceDetailLine_Header extends PureComponent {

  render() {
    return (
      <Grid container={true} spacing={32} className="header">
        <Grid item={true} xs={1} className="c-outstandingInvoiceDetailLine__item-subheading filler-container" />
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.DATE}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.SUPPLIER_ID}</div>
          </div>
        </Grid>
        <Grid item={true} xs={2}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.FARM_NAME}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.TALLY}</div>
          </div>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.SPECIES}</div>
          </div>
        </Grid>
        <Grid item={true} xs={2}>
        </Grid>
        <Grid item={true} xs={2}>
        </Grid>
        <Grid item={true} xs={1}>
          <div className="c-outstandingInvoiceDetailLine__item">
            <div className="c-outstandingInvoiceDetailLine__item-subheading">{INVOICE.AMOUNT}</div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default OutstandingInvoiceDetailLine_Header;