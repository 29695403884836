import React, { Component } from 'react';

interface Props { }

class ContactUs extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <p>Contact Us page</p>
    );
  }
}

export default ContactUs;
