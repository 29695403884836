import _ from 'lodash';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import KillSheetHealthReport from '../App/Models/KillSheetHealthReport';

interface Props {
  killSheetHealthReports: Array<KillSheetHealthReport>;
}

class HealthReport extends React.Component<Props> {
  render() {
    if (!this.props.killSheetHealthReports) {
      return <div />;
    }

    const totalDefectCount = _.sumBy(this.props.killSheetHealthReports, KILLSHEET.HEALTH_REPORT_QUANTITY);
    const defectCountContainerClass = `c-health-report__defect-count-container ${totalDefectCount ? DEFAULT_DATA.STRING : 'c-health-report__full_width'}`;
    const defectStatisticsContainerClass = `c-health-report__defect-statistics-container ${totalDefectCount ? DEFAULT_DATA.STRING : 'c-health-report__hidden'}`;

    return (
      <div className="c-health-report__container">
        <div className={defectCountContainerClass}>
          <div className="c-health-report__report-title">Animal Health Report</div>
          <div className="c-health-report__total-defect-count">{totalDefectCount}</div>
          <div className="c-health-report__report-sub-text">Number of Defects</div>
        </div>
        <div className={defectStatisticsContainerClass}>
          {this.props.killSheetHealthReports.map(defect => (
            <div className="c-health-report__defect" key={defect.defect}>
              <div className="c-health-report__name">{defect.defect}</div>
              <div className="c-health-report__value">{defect.quantity}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default HealthReport;