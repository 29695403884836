import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

interface Props {
  className?: string; 
}

class SheepIcon extends React.Component<Props> {

  constructor(props: Props) {
    super(props);    
  }

  render() {
    return (
      <SvgIcon width="215.304" height="188.068" viewBox="0 0 215.304 188.068" className={this.props.className}>
        <path 
          id="Path_528" 
          data-name="Path 528" 
          d="M70.063,269.176c1.894.629.882,3.373,1.081,5.773s4.193,2.223,8.178,2.292,4.969-1.825,2.884-3.9-5.32-9.56-5.351-12.672-.193-21.81,3.634-25.82,10.05-11.331,11.184-8.916-2.245,8.111,2.148,12.258-.146,9.1,2.8,15.386S95.5,264.814,100.643,266.1s1.02,4.868,6.478,5.343c6.631.567,10.549.544,8.525-2.5-2.376-3.58-4.186-3.618-6.493-6.049s-2.821-13.952-3.726-16.122-3.825-10.135-.192-17.019,6.01-8.287,7.735-13.293,4.561-6.148,13.661-2.4,39.887,4.531,47.737,3.043c8.624-1.633,11.185-.437,11.185-.437-1.2,1.664,4.216,11.737,3.787,20.216-.107,2.185,1.817,7.935-.1,16.191-1.3,5.55,1.924,7.536,1.572,8.969-.751,3.1-2.76,3.841.115,6.049s-1.265,6.854,3.611,7.4c5.964.667,14.481.728,7.881-4.048-5.558-4.025-2.369-7.551-2.445-9.245-.253-5.481-1.832-11.929-2.016-14.8s1.664-15.547,3.833-19.349.966-9.084,3.158-9.767,2.859,1,2.576,4.117,1.464,10.809,3.3,13.24.353,7.191-.161,10.058,1.541,9.176.736,14.435,6.486,5.144,6.662,8.5,2.377,4.439,10.15,3.68c4.7-.46-.268-4.707-2.346-6.662s-5.834-2.492-5.727-9.207-.36-21.78,2.078-27.016,4.676-11.346,7.758-13.454,2.522,1.334,13.27-9.506,12.067-19.441,13.14-27.575,4.071-19.342.537-33.057-4.132-17.571,2.193-17.939,11.507-1,16.182.276,4.524-3.756,4.784-4.952,2.615-2.108,1.749-6.44A19.67,19.67,0,0,0,269.375,102.9c-7.935-3.005-7.59-9.713-13.922-10.541s-12.235,1.71-15.516,1.173-6.256-5.136-10.265-4.247-2.407,3.312-2.407,3.312-6.309-1.549-8.678-.629,1.3,6.731,4.339,7.743,1.825,3.388-4.592,7.59-9.353,11.829-12.258,17.287-17.08,9.3-29.039,8.609-30.067.928-49.968-3.005-43.459.452-53.012,9.874-8.043,18.077-5.121,25.8,2.284,18.491,1.25,24.225-1.38,12.68,2.99,18.269,3.572,11.323-1.15,12.918-5.328,9.736-3.772,15.033,1.05,22.071-.645,24.915-.583,6.938,2.454,7.95" 
          transform="translate(-66.719 -89.175)" 
          fill="currentColor"
        />
      </SvgIcon>
    );
  }
}

export default SheepIcon;