// Default values for HTTP headers
export const ATTACHMENT = 'attachment';
export const BEARER = 'Bearer ';
export const CONTENT_DISPOSITION = 'Content-Disposition';
export const CONTENT_TYPE = 'Content-Type';
export const JSON_RESPONSE_TYPE = 'json';
export const NO_CACHE = 'no-cache';
export const BLOB_RESPONSE_TYPE = 'blob';
export const TIMEOUT_IN_MILLISECONDS = 60000;

// Request type verbs
export const GET_REQUEST_TYPE = 'GET';
export const POST_REQUEST_TYPE = 'POST';
export const PATCH_REQUEST_TYPE = 'PATCH';