import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import SmartBanner from 'react-smartbanner';
import * as CONNECTION from '../../components/App/Constants/Connection';
import * as SMARTBANNER from '../../components/App/Constants/SmartBanner';
import AuthStore from '../../components/App/Stores/AuthStore';
import { CompleteRegistration } from '../CompleteRegistration/CompleteRegistration';
import Login from '../Login/Index';

interface Props {
  authStore?: AuthStore;
  match?: any;
}

@inject('authStore')
@observer
export class UnauthenticatedLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <div className="row content-wrapper">
        <SmartBanner
          daysHidden={SMARTBANNER.daysHidden}
          daysReminder={SMARTBANNER.daysReminder}
          title={SMARTBANNER.title}
          author={SMARTBANNER.author}
          position="top"
          appStoreLanguage={SMARTBANNER.appStoreLanguage}
          button={SMARTBANNER.button}
          store={SMARTBANNER.storeText}
          price={SMARTBANNER.price}
          ignoreIosVersion={SMARTBANNER.ignoreIosVersion}
          appMeta={SMARTBANNER.appMeta}
        />
        <div className="col-12">
          <Switch>
            <Route path={`/${CONNECTION.COMPLETE_REGISTRATION}`} component={CompleteRegistration} />
            <Route path={`/${CONNECTION.COMPLETE_REGISTRATION2}`} component={CompleteRegistration} />
            <Route component={Login} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default UnauthenticatedLayout;
