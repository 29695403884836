export const RESIZE: string = 'resize';
export const TILE: string = 'tile';
export const REPORT: string = 'report';
export const TABLE_NAME: string = 'Total Head, Inspec %, CWT';
export const TABLE_CLASS: string = 'c-power-bi__table';
export const GRAPH_CLASS: string = 'c-power-bi__graph';
export const HISTORY_GRAPH_CLASS: string = 'c-power-bi-share-history__graph';
export const GRAPH_CARCASE_CLASS: string = 'c-power-bi__Carcase-graph';
export const DIV: string = 'div';
export const WIDTH_BREAKPOINT_1: number = 1200;
export const WIDTH_BREAKPOINT_2: number = 768;
export const REPORT_SIZE_MODIFIER_1: number = 0.375;
export const REPORT_SIZE_MODIFIER_2: number = 0.5;
export const REPORT_SIZE_MODIFIER_3: number = 0.82;
export const REPORT_SIZE_MODIFIER_4: number = 0.75;
export const REPORT_SIZE_MODIFIER_5: number = 0.92;
export const RESIZE_DELAY: number = 100;
export const TABLE_HEIGHT: number = 600;
export const TABLE_WIDTH: number = 1600;
export const CARCASE_REPORT: string = 'Carcase Performance';
export const FINANCIAL_REPORT: string = 'Supplier Experience Financial';
export const PERFORMANCE_REPORT: string = 'Supplier Experience Performance';
export const VOLUME_REPORT: string = 'Supplier Experience Volume';