import { Badge, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import MenuListItem from '../App/Models/MenuListItem';

const styles = () =>
  createStyles({
    badgeIcon: {
      '&& span': {
        top: 5,
        right: 5,
        height: 10,
        minWidth: 10,
        backgroundColor: '#E0544A'
      }
    },
    icon: {
      margin: 0,
      display: 'inline-block'
    }
  });

interface Props extends WithStyles<typeof styles> {
  menuItem: MenuListItem;
}

class MenuItemIcon extends React.Component<Props> {
  render() {
    const IconComponent: ComponentType<SvgIconProps> = this.props.menuItem.Icon;
    const { classes } = this.props;
    const badgeIconClass = classNames(
      classes.icon,
      classes.badgeIcon,
      'notification-badge'
    );

    if (this.props.menuItem.Badge) {
      return (
        <Badge className={badgeIconClass} color="secondary">
          <IconComponent style={this.props.menuItem.IconItemStyle} fontSize="small" />
        </Badge>
      );
    }
    return <IconComponent style={this.props.menuItem.IconItemStyle} fontSize="small" />;
  }
}

export default withStyles(styles)(MenuItemIcon);
