import React, { Component } from 'react';

interface Props {
  label: string;
  value: any;
}

class MyAccountListItem extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { label, value } = this.props;
    return (
      <div className="c-my-account-item__field">
        <div className="c-my-account-item__label">{label}</div>
        {renderValue(value)}
      </div>
    );
  }
}

// Render value accordingly to recieved value, either 1) String or 2) String[]
const renderValue = (value: any) => {
  if (typeof value === 'string') {
    return renderSingleValue(value);
  } else {
    return renderArrayValue(value);
  }
};

const renderSingleValue = (value: string) => (
  <div className="c-my-account-item__value">{value}</div>
);

const renderArrayValue = (value: string[]) => {
  return (
    <div className="c-my-account-item__value-array-container">
      {value.map((item: string, index: number) => {
        const last = (index === value.length - 1) ? 'value-array-last' : 'value-array';
        return (
          <div key={index} className={`c-my-account-item__${last}`}>
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default MyAccountListItem;
