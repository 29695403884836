import { ClickAwayListener } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import moment, { Moment } from 'moment';
import React from 'react';
import { DayPickerRangeController } from 'react-dates';
// @ts-ignore allow import with no type definition
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

interface Props {
  onDatesChange: (change: { startDate: Moment | null, endDate: Moment | null }) => void;
  onClickAway: (event: React.ChangeEvent<{}>) => void;
}

interface State {
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: 'startDate' | 'endDate' | null;
}

class StandaloneDateRangePicker extends React.Component<Props, State> {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  };

  checkIsOutsideRange = (day: Moment) => {
    // block dates in the future
    return isAfterDay(day, moment());
  }

  getNavNode = (position: 'left' | 'right', icon: React.ReactNode) => {
    return (
      <div className={`DayPickerNavigation_customButton ${position}`}>
        {icon}
      </div>
    );
  }

  render() {
    // style prop is coming from the parent component being a material-ui Transition
    // see https://v3.material-ui.com/utils/transitions/#transitions
    const muiTransitionStyle = 'style' in (this.props as any) ? (this.props as any).style : null;
    return (
      <ClickAwayListener onClickAway={this.props.onClickAway}>
        <div className="StandaloneDateRangePicker" style={muiTransitionStyle}>
          <DayPickerRangeController
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={change => {
              this.setState(change);
              this.props.onDatesChange(change);
            }}
            focusedInput={this.state.focusedInput || 'startDate'}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            isOutsideRange={this.checkIsOutsideRange}
            numberOfMonths={2}
            initialVisibleMonth={() => moment().subtract(1, 'months')}
            navPrev={this.getNavNode('left', <KeyboardArrowLeft />)}
            navNext={this.getNavNode('right', <KeyboardArrowRight />)}
            calendarInfoPosition="top"
            renderCalendarInfo={() => (
              <div className="DayPickerHeader">DATE RANGE PICKER</div>
            )}
            hideKeyboardShortcutsPanel={true}
            keepOpenOnDateSelect={false}
            minimumNights={0}
          />
        </div>
      </ClickAwayListener>
    );
  }
}

export default StandaloneDateRangePicker;