import * as React from 'react';
import AppStore from '../../components/App/Stores/AppStore';
import Home from '../Home/Index';

interface Props {
  styleName: string;
  appStore: AppStore;
}

export class Render extends React.Component<Props> {
  render() {
    return (
      <div className={this.props.styleName}>
        <Home/>
      </div>
    );
  }

}

export default Render;