import animateScrollTo from 'animated-scroll-to';
import _ from 'lodash';
import React from 'react';
import * as DATE_FORMAT from '../App/Constants/DateFormat';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as TRANSITION from '../App/Constants/Transition';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import KillSheetHelper from '../App/Helpers/KillSheetHelper';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import KillSheet from '../App/Models/KillSheet';
import Grades from '../KillSheetDetails/Grades';
import HealthReport from '../KillSheetDetails/HealthReport';
import HitRate from '../KillSheetDetails/HitRate';

interface Props {
  killSheet?: KillSheet;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();
const _killSheetHelper: KillSheetHelper = new KillSheetHelper();

class InterimKillSheetDetails extends React.Component<Props> {

  componentDidMount = () => {
    const element = document.getElementById('kill-sheet-container');
    animateScrollTo(TRANSITION.CONTAINER_RIGHT_POSITION, { element: element!, horizontal: true });
  }

  render() {
    if (!this.props.killSheet) {
      return <div />;
    }

    let _categories = this.props.killSheet.killsheetDetails.map(c =>
      _.upperCase(c.animalCategory)
    );

    let _speciesIcon = _killSheetHelper.getSpeciesIcon(
      this.props.killSheet.species
    );

    return (
      <div className="c-interim-kill-sheet-details__content-container">
        <h1 className="c-interim-kill-sheet__header">{KILLSHEET.DETAILS_HEADER}</h1>
        <div className="c-interim-kill-sheet-details__header">
          <div className="c-interim-kill-sheet-details__header-content">
            <div className="c-interim-kill-sheet-details__subject-container">
              <span className="c-interim-kill-sheet-details__species-icon">
                {_speciesIcon ? (
                  <img src={_speciesIcon.iconUrl} alt={_speciesIcon.name} />
                ) : (
                    DEFAULT_DATA.STRING
                  )}
              </span>
              <span className="c-interim-kill-sheet-details__species-text">
                {_.upperCase(this.props.killSheet.species)}
              </span>
              <span className="c-interim-kill-sheet-details__categories">
                {_categories.map(category => (
                  <span
                    key={category}
                    className="c-interim-kill-sheet-details__category"
                  >
                    {category}
                  </span>
                ))}
              </span>
            </div>
            <div className="c-interim-kill-sheet-details__action-container">
              <span className="c-interim-kill-sheet-details__date">
                {_.upperCase(_propertyHelper.formatValue(this.props.killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR))}
              </span>
            </div>
          </div>
        </div>
        <div className="c-interim-kill-sheet-details__content">
          <div className="c-interim-kill-sheet-details__content-container">
            <div className="c-interim-kill-sheet-details__message">
              {'Your invoice is being processed...'}
            </div>
            <div className="c-interim-kill-sheet__dashed-bottom-border" />
            <div className="c-interim-kill-sheet-details__primary-attributes-container">
              <div className="c-interim-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.tally, VALUE_TYPE.STRING)}
                <div className="c-interim-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.TALLY}
                </div>
              </div>
              <div className="c-interim-kill-sheet-details__primary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.averageWeightPerHead, VALUE_TYPE.WEIGHT)}
                <div className="c-interim-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.AVERAGE_KG_PER_HEAD}
                </div>
              </div>
            </div>
            <div className="c-interim-kill-sheet__dashed-bottom-border" />
            <div className="c-interim-kill-sheet-details__secondary-attributes-container">
              <div className="c-interim-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.killsheetNumber, VALUE_TYPE.KILLSHEET_NUMBER)}
                <div className="c-interim-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.KILL_SHEET_NUMBER}
                </div>
              </div>
              <div className="c-interim-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.processedDate, VALUE_TYPE.DATE, DATE_FORMAT.FULL_DAY_SHORTENED_MONTH_YEAR)}
                <div className="c-interim-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.DATE_PROCESSED}
                </div>
              </div>
              <div className="c-interim-kill-sheet-details__secondary-attribute">
                {_propertyHelper.formatValue(this.props.killSheet.livestockRepresentative, VALUE_TYPE.STRING)}
                <div className="c-interim-kill-sheet-details__attribute-subheading">
                  {KILLSHEET.LIVESTOCK_REPRESENTATIVE}
                </div>
              </div>
            </div>
            <div className="c-interim-kill-sheet__dashed-bottom-border" />
            <div className="c-interim-kill-sheet-details__hit-rate-container">
              <HitRate killSheet={this.props.killSheet} />
            </div>
            {(this.props.killSheet.killsheetEatingQualitySpecification) && <div className="c-interim-kill-sheet-details__health-report-container" />}
            <div className="c-interim-kill-sheet-details__health-report-container">
              <HealthReport killSheetHealthReports={this.props.killSheet.killsheetHealthReports} />
            </div>
            <div className="c-interim-kill-sheet__dashed-bottom-border" />
            <div className="c-interim-kill-sheet-details__grades-container">
              <Grades killSheet={this.props.killSheet} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InterimKillSheetDetails;
