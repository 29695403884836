import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import React from 'react';
import * as DEFAULT_DATA from '../App/Constants/DefaultData';
import * as KILLSHEET from '../App/Constants/KillSheet';
import * as VALUE_TYPE from '../App/Constants/ValueType';
import PropertyHelper from '../App/Helpers/PropertyHelper';
import KillSheetDetailsGrade from '../App/Models/KillSheetDetailsGrade';

interface Props {
  expanded?: string;
  handleChange: Function;
  killSheetDetailsGrade: KillSheetDetailsGrade;
  species: string;
  tally: number;
}

const _propertyHelper: PropertyHelper = new PropertyHelper();

class GradeItem extends React.Component<Props> {
  render() {
    const species = this.props.species;
    const expanded = this.props.expanded;
    const killSheetDetailsGrade = this.props.killSheetDetailsGrade;

    const headerClass = 'c-grades__grade' + DEFAULT_DATA.WHITESPACE + _.lowerCase(species);
    const iconBarClass = 'c-grades__more-icon-bar' + DEFAULT_DATA.WHITESPACE + _.lowerCase(species);
    const panelName = `${species}-${killSheetDetailsGrade.grade}-${killSheetDetailsGrade.amount}`;
    const percentageValue = this.props.tally ? Math.round(killSheetDetailsGrade.quantity / this.props.tally * DEFAULT_DATA.PERCENTAGE) : DEFAULT_DATA.HYPHEN;

    return (
      <ExpansionPanel
        key={killSheetDetailsGrade.grade}
        className="c-grades__panel"
        expanded={expanded === panelName}
        onChange={this.props.handleChange(panelName)}
      >
        <ExpansionPanelSummary className="c-grades__summary">
          <div className="c-grades__header">
            <div className={headerClass}>Grade {killSheetDetailsGrade.grade} {percentageValue === 0 ? '< 1' : percentageValue}{KILLSHEET.PERCENTAGE_UNIT}</div>
            <div className="c-grades__quantity">{killSheetDetailsGrade.quantity}</div>
            <div
              className={iconBarClass}
              style={{ width: `${percentageValue}${KILLSHEET.PERCENTAGE_UNIT}` }}
            >
              <ArrowDropDownIcon />
            </div>
            <div className="c-grades__divider" />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="c-grades__details">
          <div className="c-grades__property">
            <div className="c-grades__property-name">Tally</div>
            <div className="c-grades__property-value">{_propertyHelper.formatValue(killSheetDetailsGrade.quantity, VALUE_TYPE.STRING)}</div>
          </div>
          <div className="c-grades__property">
            <div className="c-grades__property-name">Meat kg</div>
            <div className="c-grades__property-value">{_propertyHelper.formatValue(killSheetDetailsGrade.weight, VALUE_TYPE.WEIGHT)}</div>
          </div>
          <div className="c-grades__property">
            <div className="c-grades__property-name">Average kg/Head</div>
            <div className="c-grades__property-value">{_propertyHelper.formatValue(killSheetDetailsGrade.averageWeightPerHead, VALUE_TYPE.WEIGHT)}</div>
          </div>
          <div className="c-grades__property">
            <div className="c-grades__property-name">Average Price/Head</div>
            <div className="c-grades__property-value">{_propertyHelper.formatValue(killSheetDetailsGrade.averagePricePerHead, VALUE_TYPE.CURRENCY)}</div>
          </div>
          <div className="c-grades__property">
            <div className="c-grades__property-name">Average Price/kg</div>
            <div className="c-grades__property-value">{_propertyHelper.formatValue(killSheetDetailsGrade.averagePricePerKG, VALUE_TYPE.CURRENCY)}</div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default GradeItem;