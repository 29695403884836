import _ from 'lodash';
import * as SHARES from '../Constants/Shares';

class SharePriceHelper {

  public getSharePriceIcon = (trend: number) => {
    return SHARES.SHARE_TREND_ICONS.find(i => i.name === trend);
  }

  public getSharePriceGreenIcon = (trend: number) => {
    return SHARES.SHARE_TREND_GREEN_ICONS[SHARES.SHARE_TREND_ICONS.findIndex(i => i.name === trend)];
  }
}

export default SharePriceHelper;